import React, { useState, Fragment } from 'react';
import { Translate } from 'react-redux-i18n';
import AnamnesisUpdate from '../AnamnesisUpdate';
import './AnamnesisUpdateList.scss';

const AnamnesisUpdateList = (props) => {
  const { summariesList } = props;
  const [showAll, setShowAll] = useState(false);

  if (!Array.isArray(summariesList)) {
    return null;
  }

  const truncatedSummaries = summariesList.slice(0, 3);

  return (
    <Fragment>
      <div className="anamnesis-update-list">
        {(showAll ? summariesList : truncatedSummaries).map((summary, i) => {
          return <AnamnesisUpdate key={i} summary={summary} />;
        })}
      </div>
      {truncatedSummaries.length && truncatedSummaries.length < summariesList.length ? (
        <div>
          <span className={`text-button chevron-${showAll ? 'up' : 'down'}`} onClick={() => setShowAll(!showAll)}>
            <Translate value={`decision_support_view.anamnesis_updates.${showAll ? 'hide' : 'show'}_older`} />
          </span>
        </div>
      ) : null}
    </Fragment>
  );
};

export default AnamnesisUpdateList;
