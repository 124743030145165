import semver from 'semver';
import * as types from '../constants/actionTypes';
import { extractProperties, extractCaregivers } from '../utils';
import {
  ANDROID,
  ANDROID_VERSION_WITH_TREATMENT_GOALS,
  IOS,
  ANDROID_VERSION_WITH_HARD_CODED_DOCTOR_TITLE,
  IOS_VERSION_WITH_HARD_CODED_DOCTOR_TITLE,
  TEMPLATE_TYPE
} from '../constants';

const initialState = {
  avatar: null,
  loadingAvatar: false,
  loadingMember: false,
  chatDisabledForNurse: true
};

const currentMemberReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_PROFILE_REQUEST:
      return { ...state, loadingMember: true };
    case types.SET_CURRENT_MEMBER: {
      let member = extractProperties({ ...action.payload.user, ...action.payload.userExtras });
      member = extractCaregivers(member);

      return {
        ...state,
        loadingMember: false,
        getUserProfileError: undefined,
        ...member
      };
    }
    case types.GET_USER_PROFILE_ERROR:
      return { ...state, loadingMember: false, getUserProfileError: action.payload };
    case types.CLEAR_CURRENT_MEMBER:
      return initialState;
    case types.GET_MEMBER_DEVICES_REQUEST:
      return { ...state, loadingMemberDevices: true };
    case types.GET_MEMBER_DEVICES_SUCCESS:
      return {
        ...state,
        loadingMemberDevices: false,
        memberDevice: action.payload,
        memberDevicesError: undefined,
        riskFactorAndGoalsDisabled:
          action.payload.platform === ANDROID &&
          semver.lt(action.payload.clientVersion, ANDROID_VERSION_WITH_TREATMENT_GOALS),
        chatDisabledForNurse:
          action.payload.platform === ANDROID
            ? semver.lte(action.payload.clientVersion, ANDROID_VERSION_WITH_HARD_CODED_DOCTOR_TITLE)
            : action.payload.platform === IOS
            ? semver.lte(action.payload.clientVersion, IOS_VERSION_WITH_HARD_CODED_DOCTOR_TITLE)
            : false
      };
    case types.GET_MEMBER_DEVICES_ERROR:
      return { ...state, loadingMemberDevices: false, memberDevicesError: action.payload };
    case types.MARK_MEMBER_INACTIVE_REQUEST:
      return { ...state, markingMemberAsInactive: true };
    case types.MARK_MEMBER_INACTIVE_SUCCESS:
      return { ...state, markingMemberAsInactive: false, ...extractProperties(action.payload) };
    case types.MARK_MEMBER_INACTIVE_ERROR:
      return { ...state, markingMemberAsInactive: false, markMemberAsInactiveError: action.payload };
    case types.GET_PROFILE_IMAGE_REQUEST:
      return { ...state, loadingAvatar: true, avatar: true };
    case types.GET_PROFILE_IMAGE_SUCCESS:
      return { ...state, loadingAvatar: false, avatar: window.URL.createObjectURL(action.payload) };
    case types.GET_PROFILE_IMAGE_ERROR:
      return { ...state, loadingAvatar: false, avatar: null };
    case types.CLEAR_PROFILE_IMAGE:
      return { ...state, avatar: null };
    case types.POST_PHYSICAL_EXAMINATION_REFERRAL_SUCCESS:
      return { ...state, pendingDiagnosis: 'referred' };
    case types.GENERATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        pendingDiagnosis:
          action.payload.templateType === TEMPLATE_TYPE.diagnosisScreening ? 'referred' : state.pendingDiagnosis
      };
    case types.GET_MEMBER_MONITORS_REQUEST:
      return { ...state };
    case types.GET_MEMBER_MONITORS_SUCCESS: {
      const currentMonitor = action.payload.sort((a, b) => {
        if (a.lastUsedTimestamp === b.lastUsedTimestamp) {
          return 0;
        }

        if (a.lastUsedTimestamp > b.lastUsedTimestamp) {
          return -1;
        }

        return 1;
      })[0];

      return { ...state, currentMonitor, monitorError: undefined };
    }
    case types.GET_MEMBER_MONITORS_ERROR:
      return { ...state, monitorError: action.payload };
    default:
      return state;
  }
};

export default currentMemberReducer;
