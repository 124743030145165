import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import ModalDialog from '../ModalDialog';
import ContentLoader from '../ContentLoader';
import LoadingSpinner from '../LoadingSpinner';
import { closeFilePreview, setPreviewNumPages, toggleDocumentSigned } from '../../actions';

// Future: bundla med Webpack?
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class FilePreview extends Component {
  constructor(props) {
    super(props);

    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
  }

  onDocumentLoadSuccess({ numPages }) {
    this.props.setPreviewNumPages(numPages);
  }

  render() {
    const { preview, isCompleting, onActionCompleted, closeFilePreview, actionI18nKey, toggleDocumentSigned } =
      this.props;

    return (
      <ModalDialog
        header={`${I18n.t('global.preview')} – ${preview.currentFile.filename}`}
        actionI18nKey={actionI18nKey}
        visible={preview.modalActive}
        onClose={closeFilePreview}
        onActionCompleted={onActionCompleted}
        actionCompletable={true}
        actionCompleting={isCompleting}
        size="medium-small"
        hasPrintAction={true}
        showConfirmCheck={
          preview.currentFile.contentType === 'referral' || preview.currentFile.contentType === 'certificate'
        }
        confirmChecked={preview.isSigned}
        onToggleConfirm={toggleDocumentSigned}
      >
        <ContentLoader
          isLoading={preview.loading}
          error={preview.previewFileError}
          errorTitleI18n="patient_view.notes.preview_error"
        >
          <div data-sensitive>
            <Document
              file={preview.fileBlob}
              loading={
                <div className="center-preview-loader">
                  <LoadingSpinner size="small" />
                </div>
              }
              noData={''}
              error={I18n.t('patient_view.notes.preview_error')}
              onLoadError={() => <Translate value="patient_view.notes.preview_error" />}
              onLoadSuccess={this.onDocumentLoadSuccess}
              externalLinkTarget="_blank"
            >
              {Array.from(new Array(preview.numPages > -1 ? preview.numPages : 0), (el, index) => (
                <Page
                  width={window.innerWidth * 0.4 + 10}
                  loading={''}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                />
              ))}
            </Document>
          </div>
        </ContentLoader>
      </ModalDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    preview: state.preview
  };
};

const mapActionsToProps = {
  closeFilePreview,
  setPreviewNumPages,
  toggleDocumentSigned
};

export default connect(mapStateToProps, mapActionsToProps)(FilePreview);
