import * as types from '../constants/actionTypes';

const initialState = {
  memberFiles: [],
  memberImages: [],
  uploadFileModalActive: false,
  selectedContentType: null,
  file: null,
  filename: '',
  uploadingFile: false,
  createDocumentModalActive: false,
  selectedDocumentType: null,
  generatingDocument: false,
  documentContent: {},
  unsupportedFileTypeSelected: false
};

const filesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MEMBER_FILES_REQUEST:
      return { ...state, loadingMemberFiles: true };
    case types.GET_MEMBER_FILES_SUCCESS:
      return {
        ...state,
        loadingMemberFiles: false,
        memberFiles: action.payload.filter((file) => file.contentType !== 'image').reverse(),
        memberImages: action.payload.filter((file) => file.contentType === 'image').reverse(),
        memberFilesError: undefined
      };
    case types.GET_MEMBER_FILES_ERROR:
      return { ...state, loadingMemberFiles: false, memberFilesError: action.payload };
    case types.CLEAR_MEMBER_FILES:
      return { ...state, memberFiles: [] };

    case types.SHOW_UPLOAD_FILE_MODAL:
      return { ...state, uploadFileModalActive: true };
    case types.HIDE_UPLOAD_FILE_MODAL:
      return { ...state, uploadFileModalActive: false, file: null, filename: '', selectedContentType: null };
    case types.SELECT_CONTENT_TYPE:
      return { ...state, selectedContentType: action.payload };
    case types.SELECT_FILE_TO_UPLOAD:
      return { ...state, ...action.payload };
    case types.EDIT_FILENAME_BEFORE_UPLOAD:
      return { ...state, filename: action.payload.target.value };
    case types.UPLOAD_FILE_REQUEST:
      return { ...state, uploadingFile: true };
    case types.UPLOAD_FILE_SUCCESS:
      // Clear the file selected in the file input element
      action.payload.fileInputElement.current.value = '';

      return {
        ...state,
        uploadingFile: false,
        memberFiles: [action.payload.response, ...state.memberFiles],
        uploadError: undefined,
        uploadFileModalActive: false,
        file: null,
        filename: '',
        selectedContentType: null
      };
    case types.UPLOAD_FILE_ERROR:
      return { ...state, uploadingFile: false, uploadError: action.payload };
    case types.POST_FILE_TO_CHAT_REQUEST:
      return { ...state, postingFileToChat: true };
    case types.POST_CHAT_MESSAGE_SUCCESS:
      return {
        ...state,
        postingFileToChat: false,
        postFileToChatError: undefined,
        previewModalActive: false,
        fileBlob: null,
        currentPreviewFile: {},
        numPages: -1
      };
    case types.POST_FILE_TO_CHAT_ERROR:
      return { ...state, postingFileToChat: false, postFileToChatError: action.payload };
    case types.SET_PREFILLED_DOCUMENT_DATA:
      return { ...state, documentContent: { ...state.documentContent, ...action.payload } };
    case types.RESET_SELECTED_DOCUMENT_TYPE:
      return { ...state, selectedDocumentType: null };
    case types.SELECT_DOCUMENT_TYPE:
      return { ...state, selectedDocumentType: action.payload };
    case types.UPDATE_TEMPLATE_CONTENT:
      return {
        ...state,
        documentContent: {
          ...state.documentContent,
          [action.payload.property]: action.payload.event.target.value
        }
      };
    case types.GENERATE_DOCUMENT_REQUEST:
      return { ...state, generatingDocument: true };
    case types.GENERATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        generatingDocument: false,
        createDocumentModalActive: false,
        documentContent: {},
        memberFiles: [action.payload.response, ...state.memberFiles],
        generateDocumentError: undefined,
        selectedDocumentType: null
      };
    case types.GENERATE_DOCUMENT_ERROR:
      return {
        ...state,
        generatingDocument: false,
        createDocumentModalActive: false,
        generateDocumentError: action.payload
      };
    case types.GET_DOCUMENT_TEMPLATE_CONTENT_REQUEST:
      return { ...state, loadingDocumentTemplateContent: true };
    case types.GET_DOCUMENT_TEMPLATE_CONTENT_SUCCESS:
      return {
        ...state,
        loadingDocumentTemplateContent: false,
        documentContent: {
          ...state.documentContent,
          ...action.payload.templateContent.reduce(
            (prev, curr) => ({
              ...prev,
              [curr.key]: curr.value
            }),
            {}
          )
        }
      };
    case types.GET_DOCUMENT_TEMPLATE_CONTENT_ERROR:
      return { ...state, loadingDocumentTemplateContent: false };
    default:
      return state;
  }
};

export default filesReducer;
