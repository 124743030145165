import React, { Component, Fragment } from 'react';
import { Translate } from 'react-redux-i18n';
import ErrorMessage from '../../ErrorMessage';
import LoaderButton from '../../LoaderButton';
import { makeDisableable } from '../../../utils/hoc';
import './DecisionSupportTable.scss';
import HasAuthority from '../../HasAuthority';
import { AUTHORITY } from '../../../constants';

class DecisionSupportTable extends Component {
  render() {
    const {
      data,
      error,
      errorTitleI18n,
      titleI18n,
      emptyI18n,
      columnHeaders,
      dataProperty,
      offsetColumnHeaders,
      children,
      onSaveChanges,
      isSavable,
      isSaving,
      saveI18nKey
    } = this.props;

    const RowComponent = this.props.rowType;

    if (error) {
      return <ErrorMessage error={error} errorI18nKey={errorTitleI18n} />;
    }

    if ((!data || !data.length) && emptyI18n && !children) {
      return titleI18n ? (
        <div>
          <h3 className="section-sub-header">
            <Translate value={titleI18n} />
          </h3>
          <span className="field__empty">
            <Translate value={emptyI18n} />
          </span>
        </div>
      ) : null;
    }

    return (
      <Fragment>
        {titleI18n ? (
          <h3 className="section-sub-header">
            <Translate value={titleI18n} />
          </h3>
        ) : null}
        <div className={`decision-support-table ${this.props.className || ''} ${isSavable ? 'mb-10' : 'mb-30'}`}>
          {columnHeaders ? (
            columnHeaders.length < 4 ? (
              <div className="columns decision-support-table-column-headers">
                <div className={`column is-4 ${offsetColumnHeaders ? 'offset' : ''}`}>{columnHeaders[0]}</div>
                <div className="column is-7">{columnHeaders[1]}</div>
                <div className="column is-1">{columnHeaders[2]}</div>
              </div>
            ) : (
              <div className="columns decision-support-table-column-headers">
                <div className="column">{columnHeaders[0]}</div>
                <div className="column">{columnHeaders[1]}</div>
                <div className="column is-1">{columnHeaders[2]}</div>
                <div className="column">{columnHeaders[3]}</div>
                <div className="column is-3">{columnHeaders[4]}</div>
                <div className="column">{columnHeaders[5]}</div>
              </div>
            )
          ) : null}
          {!children
            ? data &&
              data.map((item, i) => {
                return (
                  <RowComponent
                    key={item.itemId || item.id || i + 1000}
                    {...item}
                    {...this.props}
                    displayValue={item[dataProperty]}
                  />
                );
              })
            : children}
        </div>
        {onSaveChanges && isSavable ? (
          <div className="columns">
            <div className="column no-padding right-align">
              <HasAuthority authority={AUTHORITY.CAREGIVER}>
                <LoaderButton
                  buttonI18nKey={saveI18nKey || 'global.buttons.save'}
                  isLoading={isSaving}
                  onClick={onSaveChanges}
                />
              </HasAuthority>
            </div>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

export default makeDisableable(DecisionSupportTable);
