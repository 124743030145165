import React, { Component, Fragment } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants';
import ModalDialog from '../../ModalDialog';
import './AnamnesisUpdate.scss';

class AnamnesisUpdate extends Component {
  constructor(props) {
    super(props);

    this.hideFullDetails = this.hideFullDetails.bind(this);
    this.showFullDetails = this.showFullDetails.bind(this);
    this.toggleShowUnchangedAnswers = this.toggleShowUnchangedAnswers.bind(this);

    this.state = {
      fullDetailsVisible: false,
      unchangedAnswersVisible: false
    };
  }

  hideFullDetails() {
    this.setState({
      fullDetailsVisible: false
    });
  }

  showFullDetails() {
    this.setState({
      fullDetailsVisible: true
    });
  }

  toggleShowUnchangedAnswers() {
    this.setState({
      unchangedAnswersVisible: !this.state.unchangedAnswersVisible
    });
  }

  render() {
    const { summary } = this.props;

    if (!summary) return null;

    const numChangedAnswers = summary.anamnesisQuestions.filter((q) => q.answerChanged).length;
    const numUnansweredQuestions = summary.anamnesisQuestions.filter((q) => q.unanswered).length;
    const changedAnswersI18nKey = `decision_support_view.anamnesis_updates.changed_answer${
      numChangedAnswers === 0 || numChangedAnswers > 1 ? 's' : ''
    }`;
    const changedAnswers = summary.anamnesisQuestions.filter((q) => q.answerChanged);
    const unchangedAnswers = summary.anamnesisQuestions.filter((q) => !q.answerChanged);
    const newInfo =
      !summary.signedOff &&
      numUnansweredQuestions === 0 &&
      summary.additionalQuestions &&
      summary.additionalQuestions.length &&
      summary.additionalQuestions.some((q) => q.answer && q.answer.answer);
    const highlight = !summary.signedOff;

    return (
      <Fragment>
        <div className={`anamnesis-update-container ${highlight ? 'new' : ''}`}>
          <div>
            <strong>
              <Translate value="decision_support_view.anamnesis_updates.header" />
            </strong>
          </div>
          <div className="anamnesis-update-date">{moment(summary.timestamp, DATE_FORMAT).format('YYYY-MM-DD')}</div>
          {summary.checkupNote ? (
            summary.checkupNote
          ) : (
            <Fragment>
              <div>
                <strong>
                  {numChangedAnswers} <Translate value={changedAnswersI18nKey} />
                </strong>
              </div>
              {numChangedAnswers === 0 && numUnansweredQuestions === 0 ? (
                <div>
                  <em>
                    <Translate value="decision_support_view.anamnesis_updates.no_changes_all_answered" />
                  </em>
                </div>
              ) : null}
              {numUnansweredQuestions > 0 ? (
                <div>
                  <em>
                    {numUnansweredQuestions}{' '}
                    <Translate
                      value={`decision_support_view.anamnesis_updates.unanswered_question${
                        numUnansweredQuestions > 1 ? 's' : ''
                      }`}
                    />
                  </em>
                </div>
              ) : null}
              <div className="anamnesis-update-content-preview" data-sensitive>
                {newInfo ? `${I18n.t('decision_support_view.anamnesis_updates.new_info')}: ` : null}
                {summary.additionalQuestions &&
                summary.additionalQuestions.length &&
                summary.additionalQuestions[0].answer &&
                summary.additionalQuestions[0].answer.answer
                  ? summary.additionalQuestions[0].answer.answer.length > 32
                    ? `${summary.additionalQuestions[0].answer.answer.slice(0, 32)}...`
                    : summary.additionalQuestions[0].answer.answer
                  : null}
              </div>
              <div>
                <span className="text-button" onClick={this.showFullDetails}>
                  <Translate value="global.show" />
                </span>
              </div>
            </Fragment>
          )}
        </div>
        <ModalDialog
          headerI18nKey={`decision_support_view.anamnesis_updates.header_${summary.type}`}
          subHeader={`${moment(summary.timestamp, DATE_FORMAT).format('YYYY-MM-DD')}`}
          actionI18nKey="global.buttons.ok"
          visible={this.state.fullDetailsVisible}
          onClose={this.hideFullDetails}
          size="large"
          contentClass="modal-content-max-height"
        >
          <div className="anamnesis-update-modal">
            {summary.additionalQuestions &&
              summary.additionalQuestions
                .filter((q) => q.answer !== null)
                .map((item, i) => {
                  return (
                    <Fragment key={i}>
                      <div className="additional-question">
                        <div>
                          <LineBreakContent content={item.question} />
                        </div>
                      </div>
                      <div className="additional-answer" data-sensitive>
                        <LineBreakContent content={item.answer && item.answer.answer} />
                      </div>
                    </Fragment>
                  );
                })}
            <div className="num-changed-answers">
              {numChangedAnswers} <Translate value={changedAnswersI18nKey} />
            </div>
            {changedAnswers.map((item, i) => {
              return (
                <div key={i} className="anamnesis-update">
                  <div className="anamnesis-question">
                    <LineBreakContent content={item.question} />
                  </div>
                  <div className="columns">
                    <div className="column new-answer">
                      <div className="timestamp">
                        <Translate value="decision_support_view.anamnesis_updates.new_answer" />,{' '}
                        {moment(item.answer.timestamp, DATE_FORMAT).format('YYYY-MM-DD')}
                      </div>
                      <div className="anamnesis-answer" data-sensitive>
                        <LineBreakContent content={item.answer.answer} />
                      </div>
                    </div>
                    <div className="column">
                      {item.previousAnswer ? (
                        <Fragment>
                          <div className="timestamp">
                            <Translate value="decision_support_view.anamnesis_updates.previous_answer" />,{' '}
                            {moment(item.previousAnswer.timestamp, DATE_FORMAT).format('YYYY-MM-DD')}
                          </div>
                          <div className="anamnesis-answer" data-sensitive>
                            <LineBreakContent content={item.previousAnswer.answer} />
                          </div>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <div className="timestamp">
                            <Translate value="decision_support_view.anamnesis_updates.previous_answer" />
                          </div>
                          <div className="anamnesis-answer">
                            <Translate value="decision_support_view.anamnesis_updates.unanswered" />
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            {this.state.unchangedAnswersVisible
              ? unchangedAnswers.map((item, i) => {
                  return (
                    <div key={i} className="anamnesis-update">
                      <div className="anamnesis-question">
                        <LineBreakContent content={item.question} />
                      </div>
                      <div className="columns">
                        <div className="column">
                          {item.previousAnswer ? (
                            <Fragment>
                              <div className="timestamp">
                                <Translate value="decision_support_view.anamnesis_updates.current_answer" />,{' '}
                                {moment((item.answer || item.previousAnswer).timestamp, DATE_FORMAT).format(
                                  'YYYY-MM-DD'
                                )}
                              </div>
                              <div className="anamnesis-answer" data-sensitive>
                                <LineBreakContent content={item.previousAnswer.answer} />
                              </div>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <div className="anamnesis-answer__empty">
                                <Translate value="decision_support_view.anamnesis_updates.question_never_answered" />
                              </div>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
            {unchangedAnswers.length ? (
              <div className={this.state.unchangedAnswersVisible ? 'hide-rows' : 'show-all-data'}>
                <span onClick={this.toggleShowUnchangedAnswers}>
                  <Translate
                    value={`decision_support_view.anamnesis_updates.${
                      this.state.unchangedAnswersVisible ? 'hide' : 'show'
                    }_unchanged_answers`}
                  />
                </span>
              </div>
            ) : null}
          </div>
        </ModalDialog>
      </Fragment>
    );
  }
}

export default AnamnesisUpdate;

const LineBreakContent = ({ content }) => {
  if (!content) return null;

  return (
    <div>
      {content.split('\n').map((item, key) => {
        return (
          <span key={key}>
            {item}
            <br />
          </span>
        );
      })}
    </div>
  );
};
