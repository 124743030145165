import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { logout } from '../../actions';
import { removeItem } from '../../services/localStorage';

class LogoutView extends Component {
  componentDidMount() {
    removeItem('auth');

    this.props.logout();
  }

  render() {
    return <Redirect to="/login" />;
  }
}

const mapActionsToProps = {
  logout
};

export default connect(null, mapActionsToProps)(LogoutView);
