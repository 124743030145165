import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import AnamnesisChapter from './AnamnesisChapter';
import EditableTextField from '../EditableTextField';
import ContentLoader from '../ContentLoader';
import { addNote } from '../../actions';
import './HealthProfile.scss';
import {
  MANDATORY_QUESTIONS_BEFORE_CHECKUP_CHAPTER_ID,
  MANDATORY_QUESTIONS_ONBOARDING_CHAPTER_ID
} from '../../constants';

class HealthProfile extends Component {
  constructor(props) {
    super(props);

    this.addRiskNote = this.addRiskNote.bind(this);
    this.getRiskProfileText = this.getRiskProfileText.bind(this);
    this.getGoalText = this.getGoalText.bind(this);
  }

  addRiskNote(riskNoteText) {
    return () => {
      if (window.confirm(I18n.t('patient_view.confirm.risk_profile'))) {
        this.props.addNote(this.props.authToken, this.props.memberGuid, 'risknote', riskNoteText);
      }
    };
  }

  getRiskProfileText() {
    const { notes } = this.props;
    return notes && notes.latestRiskNote && notes.latestRiskNote.text;
  }

  getGoalText() {
    const { profileData } = this.props;
    return profileData && profileData.goals && profileData.goals.goalText;
  }

  render() {
    const { anamnesisChapters, profileData, personalGoal, healthProfile, notes } = this.props;
    const goalText = this.getGoalText();
    const riskText = this.getRiskProfileText();
    const hash = this.props.location.hash;

    return (
      <div className="columns">
        <div className="column is-8 no-padding">
          <ContentLoader
            isLoading={this.props.isFetching}
            error={healthProfile.error}
            errorTitleI18n="patient_view.anamnesis.error"
          >
            {anamnesisChapters.error ? (
              <div className="error-message">
                <Translate value="global.error_loading_data" />
              </div>
            ) : (
              <ul className="anamnesis-chapters">
                {anamnesisChapters &&
                  anamnesisChapters.chapters &&
                  anamnesisChapters.chapters
                    .filter(
                      (chapter) =>
                        ![
                          MANDATORY_QUESTIONS_ONBOARDING_CHAPTER_ID,
                          MANDATORY_QUESTIONS_BEFORE_CHECKUP_CHAPTER_ID
                        ].includes(chapter.chapterId)
                    )
                    .map((chapter, i) => {
                      return (
                        <AnamnesisChapter
                          chapter={chapter}
                          orderNumber={i}
                          key={chapter.chapterId}
                          profileData={profileData}
                          hash={hash}
                        />
                      );
                    })}
              </ul>
            )}
          </ContentLoader>
        </div>
        <div className="column is-4 no-padding">
          <EditableTextField
            headerI18nKey="patient_view.anamnesis.risk_profile_header"
            actionI18nKey="global.buttons.update"
            onUpdate={this.addRiskNote}
            isLoading={notes.addingNote}
            defaultText={riskText}
            history={notes && notes.notes && notes.notes.filter((n) => n.noteType === 'risknote')}
          />
          <EditableTextField
            headerI18nKey="patient_view.anamnesis.member_goal_header"
            actionI18nKey="global.buttons.update"
            isLoading={healthProfile.settingGoals}
            defaultText={goalText}
            isReadOnly={true}
          />
          <div className="member-info-container">
            <h3>
              <Translate value="patient_view.anamnesis.personal_goal_header" />
            </h3>
            <p>{personalGoal || <Translate value="patient_view.anamnesis.no_personal_goal" />}</p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    memberGuid: state.currentMember.guid,
    isFetching: state.patientHealthProfile.isFetching,
    profileData: state.patientHealthProfile.profileData,
    personalGoal: state.patientHealthProfile.personalGoal,
    anamnesisChapters: state.anamnesisChapters,
    healthProfile: state.patientHealthProfile,
    notes: state.notes
  };
};

const mapActionsToProps = {
  addNote
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(HealthProfile));
