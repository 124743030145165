import React, { Component, Fragment } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { Line } from 'react-chartjs-2';
import { customOptions as options, legendOptions, commonDataSetOptions } from '../../../../utils/graph.js';
import ModalDialog from '../../../ModalDialog';
import RiskFactorRecommendationRow from '../RiskFactorRecommendationRow';
import RiskFactorStatusRow from '../RiskFactorStatusRow';

const weightDataOptions = {
  backgroundColor: '#d8384a',
  borderColor: '#d8384a',
  pointBorderColor: '#d8384a',
  pointHoverBackgroundColor: '#d8384a',
  pointHoverBorderColor: '#d8384a'
};

const chartData = {
  labels: [],
  datasets: [{ ...commonDataSetOptions, ...weightDataOptions }]
};

class RiskFactorWeight extends Component {
  constructor(props) {
    super(props);

    this.toggleWeightGraph = this.toggleWeightGraph.bind(this);

    this.state = {
      graphVisible: false
    };
  }

  toggleWeightGraph() {
    this.setState({
      graphVisible: !this.state.graphVisible
    });
  }

  render() {
    const { weightObjectiveProgress, weightObjective, recommendation, locale } = this.props;

    if (weightObjectiveProgress && weightObjective && recommendation.memberAccepted) {
      const weightData = [
        { value: weightObjective.start, timestamp: recommendation.memberAccepted },
        ...weightObjectiveProgress
      ];

      chartData.datasets[0].data = weightData
        .filter((w) => w !== null)
        .map((w) => {
          return { y: w.value, x: w.timestamp };
        });
      options.scales.yAxes[0].ticks.suggestedMin =
        Math.min.apply(
          null,
          weightObjectiveProgress.filter((w) => w !== null && w.value).map((w) => w.value)
        ) - 20;
      options.scales.yAxes[0].ticks.suggestedMax =
        Math.max.apply(
          null,
          weightObjectiveProgress.filter((w) => w !== null && w.value).map((w) => w.value)
        ) + 20;
      options.annotation.annotations[0].value = weightObjective.objective;
      chartData.datasets[0].label = I18n.t('decision_support_view.risk_profile_section.weight_label');
    }

    return (
      <Fragment>
        <div className="risk-factor">
          <RiskFactorStatusRow {...this.props} />

          {weightObjective ? (
            <div className="bordered-box">
              <div className="columns">
                <div className="column">
                  <div>
                    <Translate value="decision_support_view.risk_profile_section.members_goal" />:
                  </div>
                  <div>
                    <Translate value="decision_support_view.risk_profile_section.lose" />{' '}
                    {weightObjective.start &&
                      weightObjective.objective &&
                      Number(weightObjective.start - weightObjective.objective).toFixed(1)}{' '}
                    kg
                  </div>
                </div>
                <div className="column">
                  <div>
                    <Translate value="decision_support_view.risk_profile_section.starting_weight" />:{' '}
                    {weightObjective.start} kg
                  </div>
                  {weightObjective.end ? (
                    <Fragment>
                      <div>
                        <Translate value="decision_support_view.risk_profile_section.end_weight" />:{' '}
                        {weightObjective.end} kg
                      </div>
                      <div>
                        <Translate value="decision_support_view.risk_profile_section.total_weight_loss" />:{' '}
                        {weightObjective.end >= weightObjective.start ? '+' : '-'}
                        {Number(Math.abs(weightObjective.start - weightObjective.end)).toFixed(1)} kg
                      </div>
                    </Fragment>
                  ) : null}
                </div>
                <div className="column">
                  {weightObjectiveProgress && weightObjectiveProgress.length > 1 ? (
                    <Fragment>
                      <div>
                        <Translate value="decision_support_view.risk_profile_section.results_week_by_week_in_kg" />
                      </div>
                      <div>
                        {weightObjectiveProgress.slice(1).map((p, i) => {
                          if (!p.diff) {
                            return (
                              <span key={i} className="weight-result">
                                <span className="weekly-result unknown"></span>
                              </span>
                            );
                          }
                          let sign = p.diff > 0 ? '+' : '';

                          return (
                            <span key={i} className="weight-result">{`${sign}${p.diff.toLocaleString(locale, {
                              minimumFractionDigits: 1
                            })}`}</span>
                          );
                        })}
                      </div>
                      <div>
                        <span className="text-button fs-16" onClick={this.toggleWeightGraph}>
                          <Translate value="decision_support_view.risk_profile_section.show_results_in_graph" />
                        </span>
                      </div>
                    </Fragment>
                  ) : (
                    <Translate value="decision_support_view.risk_profile_section.no_reported_weight_change" />
                  )}
                </div>
              </div>
            </div>
          ) : null}

          <RiskFactorRecommendationRow {...this.props} isNonStandardRiskFactorRow={true} />
        </div>
        <ModalDialog
          headerI18nKey="decision_support_view.weight_graph_header"
          actionI18nKey="global.buttons.ok"
          visible={this.state.graphVisible}
          onClose={this.toggleWeightGraph}
          onActionCompleted={this.toggleWeightGraph}
          actionCompletable={true}
        >
          <div>
            <div className="line-graph">
              <Line data={chartData} legend={legendOptions} options={options} />
            </div>
            <div className="line-graph-legend">
              <Translate className="legend-weight" value="decision_support_view.legend_weight" />
              <Translate className="legend-weight-goal" value="decision_support_view.legend_weight_goal" />
            </div>
          </div>
        </ModalDialog>
      </Fragment>
    );
  }
}

export default RiskFactorWeight;
