import React, { useState, useRef, useCallback, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import Cropper from 'react-easy-crop';
import ModalDialog from '../ModalDialog';
import api from '../../api/apiClient';
import { showNotification, caregiverProfileImageUpdateSuccess } from '../../actions';
import './ProfileImageUploadModal.scss';

const createImage = (src) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => {
      reject(error);
    });
    image.src = src;
  });
};

const getCroppedImage = async (imageSrc, croppedArea, asBase64) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = image.width;
  canvas.height = image.height;

  ctx.drawImage(image, 0, 0);
  const imageData = ctx.getImageData(0, 0, image.width, image.height);

  // Resize canvas to crop size. This resets the context.
  canvas.width = croppedArea.width;
  canvas.height = croppedArea.height;

  // Put the stored image data onto the canvas with the correct offsets for x, y crop values.
  ctx.putImageData(imageData, -croppedArea.x, -croppedArea.y);

  return new Promise((resolve) => {
    if (asBase64) {
      resolve(canvas.toDataURL());
    } else {
      canvas.toBlob((file) => {
        resolve(file);
      }, 'image/jpeg');
    }
  });
};

const AppPreview = ({ imageSrc, caregiver, caregiverRole }) => {
  const caregiverTitle = caregiverRole === 'doctor' ? 'Leg. läkare' : 'Leg. sjuksköterska';
  const dummyChatText = `Hej! Jag heter ${caregiver.givenName} och kommer vara din ${
    caregiverRole === 'doctor' ? 'läkare' : 'sjuksköterska'
  } här på Blodtrycksdoktorn. Jag finns här för att se till att du får bästa möjliga behandling för ditt blodtryck.`;

  return (
    <>
      <h3 className="mb-20">
        <Translate value="profile_image_upload.app_preview_header" />
      </h3>
      <div className="app-preview">
        <div className="chat-wrapper">
          <div className="app-profile-image">
            <img src={imageSrc} />
          </div>
          <div className={`chat-content caregiver-role__${caregiverRole}`}>{dummyChatText}</div>
        </div>
        <div className="chat-meta">{`${caregiver.givenName} ${
          caregiver.familyName
        }, ${caregiverTitle}, ${moment().format('YYYY-MM-DD HH:mm')}`}</div>
      </div>
    </>
  );
};

const ProfilePictureConfirmation = ({ getCroppedImage, imagePreview, croppedArea, caregiver, caregiverRole }) => {
  const [imageSrc, setImageSrc] = useState();

  useEffect(() => {
    if (imagePreview) {
      getCroppedImage(imagePreview, croppedArea, true)
        .then((src) => setImageSrc(src))
        .catch((error) => console.log('error', error));
    }
  }, [getCroppedImage, imagePreview, croppedArea]);

  return imageSrc ? (
    <>
      <div className="profile-picture-crop-preview">
        <img src={imageSrc} />
      </div>
      <div className="mt-20">
        <AppPreview imageSrc={imageSrc} caregiver={caregiver} caregiverRole={caregiverRole} />
      </div>
    </>
  ) : null;
};

const ProfileImageUploadModal = ({
  isVisible,
  onClose,
  authToken,
  memberGuid,
  getProfileImage,
  hasProfileImage,
  showNotification,
  user,
  authorities,
  caregiverProfileImageUpdateSuccess
}) => {
  const fileInput = useRef();
  const videoRef = useRef();
  const [imagePreview, setImagePreview] = useState();
  const [filename, setFilename] = useState();
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState();
  const [isDragging, setIsDragging] = useState(false);
  const [step, setStep] = useState(1);
  const [hasWebcam, setHasWebcam] = useState(false);
  const [webcamModalVisible, setWebcamModalVisible] = useState(false);
  const [webcamPermissionDenied, setWebcamPermissionDenied] = useState(false);
  const [webcamImageCaptured, setWebcamImageCaptured] = useState(false);
  const validFileTypes = ['image/jpeg', 'image/png', 'image/gif'];

  useEffect(() => {
    const checkForWebcam = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        setHasWebcam(devices.some((device) => device.kind === 'videoinput'));
      } catch {
        setHasWebcam(false);
      }
    };
    checkForWebcam();
  }, []);

  const dismiss = () => {
    onClose();
    if (fileInput.current) {
      fileInput.current.value = '';
    }
    setImagePreview(null);
    setZoom(1);
    setCrop({ x: 0, y: 0 });
    setFilename(null);
    setIsDragging(false);
    setStep(1);
  };

  const getImagePreview = (file) => {
    if (file && validFileTypes.includes(file.type)) {
      setFilename(file.name);
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.addEventListener('load', function () {
        setImagePreview(this.result);
      });
    }
  };

  const onFileInputChange = () => {
    const file = fileInput.current?.files[0];
    getImagePreview(file);
    setWebcamImageCaptured(false);
  };

  const uploadFile = () => {
    setIsUploadingImage(true);
    getCroppedImage(imagePreview, croppedArea)
      .then((croppedImage) => {
        const formData = new FormData();
        const newProfileImage = new File([croppedImage], 'profile-image', { type: croppedImage.type });
        formData.append('file', newProfileImage);
        return api.uploadFile(authToken, memberGuid, newProfileImage.name, formData.get('file'), 'profileImage');
      })
      .then((response) => {
        setIsUploadingImage(false);
        dismiss();
        caregiverProfileImageUpdateSuccess(response, webcamImageCaptured);
        getProfileImage(authToken, memberGuid, response.id, user.guid);
        showNotification(I18n.t('notification.upload_profile_picture.success'), 'success');
      })
      .catch(() => {
        showNotification(I18n.t('notification.upload_profile_picture.error'), 'error');
        setIsUploadingImage(false);
      });
  };

  const onCropComplete = useCallback((_, croppedArea) => {
    setCroppedArea(croppedArea);
  }, []);

  const dragEnter = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDragging(true);
  };

  const dragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDragging(false);
  };

  const onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer?.files[0];
    setIsDragging(false);
    getImagePreview(file);
    setWebcamImageCaptured(false);
  };

  const setVideoStream = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: false })
      .then((stream) => {
        setWebcamPermissionDenied(false);
        videoRef.current.srcObject = stream;
      })
      .catch(() => {
        setWebcamPermissionDenied(true);
      });
  };

  const startWebcamCapture = () => {
    setVideoStream();
    setWebcamModalVisible(true);
  };

  const captureWebcamImage = () => {
    const canvas = document.createElement('canvas');
    canvas.width = 720;
    canvas.height = 540;

    canvas.getContext('2d').drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    let imageDataUrl = canvas.toDataURL('image/jpeg');
    setImagePreview(imageDataUrl);
    setWebcamModalVisible(false);
    stopWebcamCapture();
    setFilename('');
    setWebcamImageCaptured(true);
  };

  const stopWebcamCapture = () => {
    setWebcamModalVisible(false);
    videoRef.current.srcObject.getTracks().forEach((track) => {
      track.stop();
    });
  };

  const webcamButton = (
    <>
      <Translate value="profile_image_upload.or" />{' '}
      <span className="text-button" onClick={startWebcamCapture}>
        <Translate value="profile_image_upload.take_webcam_photo" />
      </span>
    </>
  );

  return (
    <>
      <ModalDialog
        headerI18nKey={`profile_image_upload.header${step === 2 ? '_confirm' : !hasProfileImage ? '_new' : ''}`}
        actionI18nKey={`profile_image_upload.${step === 1 ? 'preview' : 'action'}`}
        visible={isVisible}
        onClose={dismiss}
        onGoBack={step === 2 ? () => setStep(1) : undefined}
        onActionCompleted={step === 2 ? uploadFile : () => setStep(2)}
        actionCompletable={!!imagePreview}
        actionCompleting={isUploadingImage}
        size={step === 1 ? 'medium' : undefined}
      >
        {step === 1 ? (
          <div className="h-100" onDragOver={dragOver} onDragEnter={dragEnter} onDragLeave={dragLeave} onDrop={onDrop}>
            {imagePreview ? (
              <div className="cropper-wrapper">
                <button className="button is-primary" onClick={() => setZoom(zoom + 0.1)} disabled={zoom === 3}>
                  +
                </button>
                <button className="button is-primary" onClick={() => setZoom(zoom - 0.1)} disabled={zoom === 1}>
                  -
                </button>
                <Cropper
                  image={imagePreview}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  cropShape="round"
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>
            ) : null}
            <div
              className={`${!imagePreview ? 'profile-image-upload__drop-target' : ''} ${
                isDragging ? 'is-dragging' : ''
              }`}
            >
              <div className={`file-select-container ${isDragging ? 'pointer-events-none' : ''}`}>
                <input
                  type="file"
                  id="profile-image-input"
                  onChange={onFileInputChange}
                  accept="image/*"
                  ref={fileInput}
                />
                <label htmlFor="profile-image-input">
                  <Translate value="profile_image_upload.select_file" />
                </label>
                {imagePreview && hasWebcam ? <span className="ml-5">{webcamButton}</span> : null}
                {!imagePreview && (
                  <div className="block ml-5">
                    <span>
                      <Translate value="profile_image_upload.drag_here" />
                    </span>
                    {hasWebcam ? <div>{webcamButton}</div> : null}
                  </div>
                )}
                {filename || imagePreview ? <span className="ml-5 fw-bold">{filename}</span> : null}
              </div>
            </div>
          </div>
        ) : (
          <ProfilePictureConfirmation
            getCroppedImage={getCroppedImage}
            imagePreview={imagePreview}
            croppedArea={croppedArea}
            caregiver={user}
            caregiverRole={authorities.includes('caregiver') ? 'doctor' : 'nurse'}
          />
        )}
      </ModalDialog>
      <ModalDialog
        headerI18nKey="profile_image_upload.webcam_header"
        actionI18nKey="profile_image_upload.webcam_action"
        visible={webcamModalVisible}
        onClose={stopWebcamCapture}
        onActionCompleted={captureWebcamImage}
        actionCompletable={!webcamPermissionDenied}
      >
        <div className="flex-center">
          {webcamPermissionDenied ? (
            <div>
              <Translate value="profile_image_upload.camera_access_denied" />{' '}
              <span className="text-button" onClick={setVideoStream}>
                <Translate value="profile_image_upload.webcam_retry" />
              </span>{' '}
              <Translate value="profile_image_upload.camera_access_try_again" />
            </div>
          ) : (
            <video ref={videoRef} width="500" autoPlay></video>
          )}
        </div>
      </ModalDialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    user: state.auth.token.user,
    authorities: state.auth.authorities
  };
};

const mapActionsToProps = {
  showNotification,
  caregiverProfileImageUpdateSuccess
};

export default connect(mapStateToProps, mapActionsToProps)(ProfileImageUploadModal);
