import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { showNotification, removeAuscultationAssessment } from '../../actions';
import api from '../../api/apiClient';
import { APPLICATION_BASE_PATH } from '../../constants';
import { eventType, Mixpanel } from '../../utils/mixpanel';
import ModalDialog from '../ModalDialog';
import { PhysicalExamination } from '../NotesView/Examinations';
import TopBar from '../TopBar';
import styles from './AuscultationAssessmentView.module.scss';

const AuscultationAssessmentView = ({ authToken, showNotification, match, history, removeAuscultationAssessment }) => {
  const [examination, setExamination] = useState({ recordings: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [postingAssessment, setPostingAssessment] = useState();
  const [rejectComment, setRejectComment] = useState('');
  const [acceptComment, setAcceptComment] = useState('');
  const [rejectionModalVisible, setRejectionModalVisible] = useState(false);
  const [acceptModalVisible, setAcceptModalVisible] = useState(false);

  useEffect(() => {
    Mixpanel.resetSuperProperties();
    setIsLoading(true);
    api
      .getAuscultationAssessment(authToken, match.params.assessmentId)
      .then((res) => {
        setExamination(res);
        Mixpanel.time_event(eventType.assessTestAuscultation);
      })
      .catch(setError)
      .finally(() => setIsLoading(false));
  }, []);

  const postAssessment = async (status) => {
    setPostingAssessment(status);
    try {
      await api.postAuscultationAssessment(
        authToken,
        match.params.assessmentId,
        status,
        status === 'rejected' ? rejectComment : acceptComment
      );
      Mixpanel.track(eventType.assessTestAuscultation, {
        type: status,
        hasComment: status === 'rejected' ? rejectComment.length > 0 : acceptComment.length > 0
      });
      removeAuscultationAssessment(match.params.assessmentId);
      history.replace(`/${APPLICATION_BASE_PATH}/auscultation-assessments`);
    } catch (err) {
      showNotification(I18n.t('notification.post_auscultation_assessment.error', 'error'));
    } finally {
      setPostingAssessment(undefined);
    }
  };

  return (
    <>
      <TopBar header={I18n.t('auscultation_assessment.page_title')} />
      <div className="wrapper mw-900">
        {examination.status && examination.status !== 'pending' ? (
          <div className={`${styles.status} ${styles[examination.status]} mb-20`}>
            <Translate value={`auscultation_assessment.status.${examination.status}`} />
          </div>
        ) : null}
        <PhysicalExamination
          isAssessment
          headerI18nKey="auscultation_assessment.assessment_header"
          cardiopulmonaryExamination={examination}
          hasExaminationData={!!examination?.recordings.length}
          isLoading={isLoading}
          error={error}
        />
        {!examination.status ? (
          <div className="flex space-between mt-30">
            <button className="button is-ghost" onClick={() => setRejectionModalVisible(true)}>
              <Translate value="auscultation_assessment.rejection_modal.header" />
            </button>
            <button className="button is-secondary" onClick={() => setAcceptModalVisible(true)}>
              <Translate value="auscultation_assessment.accept_modal.header" />
            </button>
          </div>
        ) : null}
      </div>
      <ModalDialog
        headerI18nKey="auscultation_assessment.rejection_modal.header"
        actionI18nKey="auscultation_assessment.rejection_modal.action"
        visible={rejectionModalVisible}
        onClose={() => setRejectionModalVisible(false)}
        onActionCompleted={() => postAssessment('rejected')}
        actionCompletable={rejectComment.length > 0}
        actionCompleting={postingAssessment === 'rejected'}
      >
        <h4>
          <Translate value="auscultation_assessment.rejection_modal.comment_header" />
        </h4>
        <textarea value={rejectComment} onChange={(e) => setRejectComment(e.target.value)} className={styles.comment} />
      </ModalDialog>
      <ModalDialog
        headerI18nKey="auscultation_assessment.accept_modal.header"
        actionI18nKey="auscultation_assessment.accept_modal.action"
        visible={acceptModalVisible}
        onClose={() => setAcceptModalVisible(false)}
        onActionCompleted={() => postAssessment('accepted')}
        actionCompletable={true}
        actionCompleting={postingAssessment === 'accepted'}
      >
        <h4>
          <Translate value="auscultation_assessment.accept_modal.comment_header" />
        </h4>
        <textarea value={acceptComment} onChange={(e) => setAcceptComment(e.target.value)} className={styles.comment} />
      </ModalDialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt
  };
};

const mapActionsToProps = {
  showNotification,
  removeAuscultationAssessment
};

export default connect(mapStateToProps, mapActionsToProps)(AuscultationAssessmentView);
