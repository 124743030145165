import React, { Fragment } from 'react';

const CustomLabResultType = (props) => {
  return (
    <Fragment>
      <span className="mr-5">{props.value}</span>
      <span>{props.original.unit ? `(${props.original.unit})` : null}</span>
    </Fragment>
  );
};

export default CustomLabResultType;
