import React, { Component } from 'react';
import { connect } from 'react-redux';
import metadata from '../../metadata.json';
import ModalDialog from '../ModalDialog';
import LoadingSpinner from '../LoadingSpinner/index.js';
import { CURRENT_ENV } from '../../constants';

const env = process.env.REACT_APP_API_ENV || CURRENT_ENV;
const basePath = metadata.environments[env].apiUrl.replace(/\/+$/, '');

class ImageViewer extends Component {
  constructor(props) {
    super(props);

    this.closePreview = this.closePreview.bind(this);

    this.state = {
      imageUrl: null
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.imageSrc && this.props.imageSrc !== prevProps.imageSrc) {
      fetch(`${basePath}${this.props.imageSrc}`, {
        headers: {
          Authorization: `Bearer ${this.props.authToken}`,
          'Accumbo-Client-ID': this.props.clientId
        }
      })
        .then((response) => response.blob())
        .then((image) => {
          this.setState({
            imageUrl: URL.createObjectURL(image)
          });
        });
    }
  }

  closePreview() {
    this.setState({
      imageUrl: null
    });

    this.props.onClose();
  }

  render() {
    const { isVisible, headerI18nKey, size } = this.props;

    return (
      <ModalDialog
        headerI18nKey={headerI18nKey}
        actionI18nKey="global.buttons.done"
        visible={isVisible}
        onClose={this.closePreview}
        onActionCompleted={this.closePreview}
        actionCompletable={true}
        noOverlay={true}
        hasPrintAction={true}
        size={size}
      >
        {this.state.imageUrl ? (
          <div className="h-100 text-center" data-sensitive>
            <img className="h-100" src={this.state.imageUrl} />
          </div>
        ) : (
          <div className="h-100 center-align">
            <LoadingSpinner />
          </div>
        )}
      </ModalDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    clientId: state.auth.clientId
  };
};

export default connect(mapStateToProps)(ImageViewer);
