import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import TopBar from '../TopBar';
import ErrandRow from '../ErrandRow';
import ContentLoader from '../ContentLoader';
import { clearDssData, notifyClearDss, sortUnhandledEvents } from '../../actions';
import { APPLICATION_BASE_PATH } from '../../constants';
import SortControl from '../InboxView/SortControl';
import { useEffect } from 'react';
import { Mixpanel } from '../../utils/mixpanel';

const UnhandledErrandsView = ({ events, isNurse, user, ...props }) => {
  useEffect(() => {
    Mixpanel.resetSuperProperties();
  }, []);

  const navigateToMember = (member) => {
    return () => {
      if (!props.decisionSupport.isCleared || props.decisionSupport.lastTwoWeekAverage !== null) {
        props.clearDssData();
        props.notifyClearDss();
        return;
      }
      props.history.push(`/${APPLICATION_BASE_PATH}/member/${member.guid}`);
    };
  };

  return (
    <>
      <TopBar header="unhandled_errands_view.header" />
      <div className="wrapper">
        <div className="member-row-container">
          <div className="columns">
            <div className="column is-8 no-padding">
              <h2>
                <Translate value="unhandled_errands_view.header" />
              </h2>
            </div>
            <div className="column is-4">
              <SortControl currentlySortedBy={events.unhandledSortedBy} onSort={props.sortUnhandledEvents} />
            </div>
          </div>
          <div className="columns errand-headings">
            <div className="column">
              <Translate value="global.name" />
            </div>
            <div className="column is-3">
              <Translate value="inbox_view.wait_time" />
            </div>
            <div className="column is-1">
              <Translate value={`inbox_view.responsible_${isNurse ? 'nurse' : 'doctor'}`} />
            </div>
            <div className="column is-2">
              <Translate value="global.source" />
            </div>
            <div className="column"></div>
          </div>

          <ContentLoader
            isLoading={events.loadingUnhandledEvents}
            error={events.unhandledEventsError}
            errorTitleI18n="unhandled_errands_view.error"
            hasContent={events.unhandledEvents.length > 0}
          >
            <div className="errand-row-container">
              {events.unhandledEvents.length ? (
                events.unhandledEvents.map((userEvent) => (
                  <ErrandRow
                    visited
                    key={userEvent.member.guid}
                    userEvent={userEvent}
                    currentUser={user}
                    onClick={navigateToMember(userEvent.member)}
                    authorities={props.authorities}
                    isNurse={isNurse}
                  />
                ))
              ) : (
                <div className="no-table-rows">
                  <Translate value="unhandled_errands_view.empty" />
                </div>
              )}
            </div>
          </ContentLoader>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    events: state.events,
    isNurse: state.auth.isNurse,
    user: state.auth.token.user,
    decisionSupport: state.decisionSupport,
    authorities: state.auth.authorities
  };
};

const mapActionsToProps = {
  clearDssData,
  notifyClearDss,
  sortUnhandledEvents
};

export default connect(mapStateToProps, mapActionsToProps)(UnhandledErrandsView);
