import React, { Component, Fragment } from 'react';
import { Translate } from 'react-redux-i18n';
import ErrorMessage from '../../ErrorMessage';
import './MedicationTable.scss';

class MedicationTable extends Component {
  render() {
    const { data, error, errorTitleI18n, titleI18n, emptyI18n, ColumnHeaderComponent, children } = this.props;

    const RowComponent = this.props.rowType;

    if (error) {
      return <ErrorMessage error={error} errorI18nKey={errorTitleI18n} />;
    }

    if ((!data || !data.length) && emptyI18n) {
      return (
        <Fragment>
          {titleI18n ? (
            <h3 className="section-sub-header">
              <Translate value={titleI18n} />
            </h3>
          ) : null}
          <div className="medication-table__empty">
            <Translate value={emptyI18n} />
          </div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        {titleI18n ? (
          <h3 className="section-sub-header">
            <Translate value={titleI18n} />
          </h3>
        ) : null}
        <div className="medication-table">
          <div className="columns medication-table-column-headers">
            <ColumnHeaderComponent />
          </div>
          {!children
            ? data &&
              data.map((item, i) => {
                if (item) {
                  return <RowComponent key={item.itemId || i} item={item} {...item} {...this.props} />;
                }
              })
            : children}
        </div>
      </Fragment>
    );
  }
}

export default MedicationTable;
