import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import moment from 'moment';
import api from '../../../api/apiClient';
import { CHECKUP_TYPES_WITH_PREPARATIONS, DATE_FORMAT } from '../../../constants';
import LoadingSpinner from '../../LoadingSpinner';
import ModalDialog from '../../ModalDialog';
import DecisionSupportSection from '../DecisionSupportSection';
import {
  editCheckup,
  postChatMessageSuccess,
  showNotification,
  handleEventSuccess,
  editCheckupSuccess
} from '../../../actions';
import './PatientNotReady.scss';

const findLastTwoWeeksMeasurements = (measurement) =>
  measurement.bpmeasurements.length &&
  moment(measurement.bpmeasurements[0].timestamp, DATE_FORMAT).isAfter(moment().add(-2, 'weeks'));

const PatientNotReady = ({
  isLoading,
  latestAnamnesisUpdate = { anamnesisQuestions: [] },
  activeEvents,
  measurements,
  authToken,
  checkups,
  member,
  showNotification,
  postChatMessageSuccess,
  handleEventSuccess,
  editCheckupSuccess,
  user,
  chat
}) => {
  const [loadingActionsBypass, setLoadingActionsBypass] = useState(false);
  const [bypassComplete, setBypassComplete] = useState(false);
  const [nudgeModalActive, setNudgeModalActive] = useState(false);
  const [isNudging, setIsNudging] = useState(false);
  const [nudgeButtonDisabled, setNudgeButtonDisabled] = useState(false);
  const [chatMessage, setChatMessage] = useState('');

  useEffect(() => {
    const numMeasurementsInLastTwoWeeks = measurements.filter(findLastTwoWeeksMeasurements).length;
    const allAnswered = latestAnamnesisUpdate.anamnesisQuestions.every((question) => !question.unanswered);

    if (numMeasurementsInLastTwoWeeks === 0 && allAnswered) {
      setChatMessage(I18n.t('decision_support_view.patient_not_ready.prefilled_chat_message.no_measurements'));
    } else if (numMeasurementsInLastTwoWeeks > 0 && !allAnswered) {
      setChatMessage(I18n.t('decision_support_view.patient_not_ready.prefilled_chat_message.unanswered_questions'));
    } else if (numMeasurementsInLastTwoWeeks === 0 && !allAnswered) {
      setChatMessage(I18n.t('decision_support_view.patient_not_ready.prefilled_chat_message.not_prepared_at_all'));
    }
  }, [latestAnamnesisUpdate]);

  const patientNotReadyEvents = activeEvents.filter((event) => event.eventType === 'patientNotReady');

  if (!patientNotReadyEvents.length) {
    return null;
  }

  if (!latestAnamnesisUpdate.anamnesisQuestions.length) {
    return null;
  }

  const nextApplicableCheckup = checkups.checkupList.find(
    (checkup) =>
      CHECKUP_TYPES_WITH_PREPARATIONS.includes(checkup.type) &&
      !checkup.cancellationReason &&
      moment(checkup.checkupDate, DATE_FORMAT).isSameOrAfter(moment())
  );

  if (!nextApplicableCheckup) {
    return null;
  }

  const toggleNudgeModal = () => setNudgeModalActive(!nudgeModalActive);

  const BypassCheckupActions = () => {
    setLoadingActionsBypass(true);

    api
      .editCheckup(authToken, member.guid, {
        ...nextApplicableCheckup,
        ignoreMemberActions: true
      })
      .then((checkup) => {
        editCheckupSuccess(checkup);

        // In case there's more than 1 patientNotReady event we mark them all as handled.
        return Promise.all(
          patientNotReadyEvents.map((event) =>
            api.handleEvent(authToken, event.id, {
              handledTimestamp: moment().format(DATE_FORMAT),
              timestamp: event.timestamp,
              eventType: event.eventType
            })
          )
        );
      })
      .then(() => {
        patientNotReadyEvents.forEach((event) => handleEventSuccess(event));
        setLoadingActionsBypass(false);
        setBypassComplete(true);
        showNotification(I18n.t('notification.bypass_actions.success'), 'success');
      })
      .catch(() => {
        setLoadingActionsBypass(false);
        showNotification(I18n.t('notification.bypass_actions.error'), 'error');
      });
  };

  const HandleEventsAndPostChatMessage = () => {
    setIsNudging(true);

    Promise.all(
      patientNotReadyEvents.map((event) =>
        api.handleEvent(authToken, event.id, {
          handledTimestamp: moment().format(DATE_FORMAT),
          timestamp: event.timestamp,
          eventType: event.eventType
        })
      )
    )
      .then(() => {
        patientNotReadyEvents.forEach((event) => handleEventSuccess(event));

        if (chatMessage.length) {
          return api.postChatMessage(authToken, member.guid, { message: chatMessage, messageType: 'draft' });
        }

        return Promise.resolve();
      })
      .then((message) => {
        if (chatMessage.length) {
          postChatMessageSuccess(message, `${user.givenName} ${user.familyName}`, 'manual');
        }
        setIsNudging(false);
        toggleNudgeModal();
        setNudgeButtonDisabled(true);
      })
      .catch(() => {
        setIsNudging(false);
        showNotification(I18n.t('notification.generic.error'), 'error');
      });
  };

  return (
    <>
      <DecisionSupportSection isLoading={isLoading}>
        <div className="flex">
          <div className="mr-15">
            <span className="icons patient-not-ready"></span>
          </div>
          <div className="columns">
            <div className="column no-padding">
              <strong className="fs-18">
                <Translate value="decision_support_view.patient_not_ready.header" />
              </strong>
              <CheckupCompliance>
                <MeasurementCompliance measurements={measurements} />
                <AnamnesisUpdateCompliance latestAnamnesisUpdate={latestAnamnesisUpdate} />
              </CheckupCompliance>
              <button
                className="button is-primary mt-20"
                onClick={toggleNudgeModal}
                disabled={bypassComplete || nudgeButtonDisabled}
              >
                <Translate value="decision_support_view.patient_not_ready.nudge_and_snooze" />
              </button>
            </div>
            <div className="column no-padding text-right">
              <button
                className={`button is-primary ${bypassComplete || nudgeButtonDisabled ? 'disabled' : ''} ${
                  loadingActionsBypass ? 'pe-none' : ''
                }`}
                onClick={BypassCheckupActions}
              >
                <Translate value="decision_support_view.patient_not_ready.mark_as_ready_for_doctor" />
                <span className="ml-5">
                  {loadingActionsBypass ? <LoadingSpinner size="smaller" /> : null}
                  {bypassComplete ? <span className="icons icon action-done"></span> : null}
                </span>
              </button>
            </div>
          </div>
        </div>
      </DecisionSupportSection>
      <ModalDialog
        headerI18nKey="decision_support_view.patient_not_ready.nudge_and_snooze"
        actionI18nKey={`decision_support_view.patient_not_ready.snooze${chatMessage.length ? '_and_send_message' : ''}`}
        visible={nudgeModalActive}
        onClose={toggleNudgeModal}
        onActionCompleted={HandleEventsAndPostChatMessage}
        actionCompletable={chatMessage.length ? chat.canSendMessage : true}
        actionCompleting={isNudging}
      >
        <h4>
          <Translate value="decision_support_view.patient_not_ready.chat_input_header" />
        </h4>
        <textarea
          className="chat-prefilled"
          value={chatMessage}
          onChange={(e) => setChatMessage(e.target.value)}
        ></textarea>
        <div className="mt-10 mb-20">
          <Translate value="decision_support_view.patient_not_ready.chat_message_notice" />
        </div>
        <div>
          <Translate value="decision_support_view.patient_not_ready.event_info" />
        </div>
        {!chat.canSendMessage && chatMessage.length ? (
          <div className="notice mt-10 fs-16">
            <Translate value="decision_support_view.patient_not_ready.existing_draft_notice" />
          </div>
        ) : null}
      </ModalDialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    isNurse: state.auth.isNurse,
    member: state.currentMember,
    checkups: state.checkups,
    events: state.events,
    user: state.auth.token.user,
    chat: state.patientChat
  };
};

const mapActionsToProps = {
  editCheckup,
  showNotification,
  postChatMessageSuccess,
  handleEventSuccess,
  editCheckupSuccess
};

export default connect(mapStateToProps, mapActionsToProps)(PatientNotReady);

const CheckupCompliance = ({ children }) => {
  return <ul className="onboarding mt-20">{children}</ul>;
};

const MeasurementCompliance = ({ measurements }) => {
  const numMeasurementsInLastTwoWeeks = measurements.filter(findLastTwoWeeksMeasurements).length;

  return numMeasurementsInLastTwoWeeks === 0 ? (
    <li className="incomplete">
      <Translate value="decision_support_view.patient_not_ready.measurements.none" />
    </li>
  ) : numMeasurementsInLastTwoWeeks === 1 ? (
    <li className="partial">
      <Translate value="decision_support_view.patient_not_ready.measurements.single" />
    </li>
  ) : (
    <li className="done">
      <Translate
        value="decision_support_view.patient_not_ready.measurements.multiple"
        numMeasurements={numMeasurementsInLastTwoWeeks}
      />
    </li>
  );
};

const AnamnesisUpdateCompliance = ({ latestAnamnesisUpdate }) => {
  const allUnanswered = latestAnamnesisUpdate.anamnesisQuestions.every((question) => question.unanswered);
  const someUnanswered = latestAnamnesisUpdate.anamnesisQuestions.some((question) => question.unanswered);

  return (
    <li className={`${allUnanswered ? 'incomplete' : someUnanswered ? 'partial' : 'done'}`}>
      <Translate
        value={`decision_support_view.patient_not_ready.anamnesis.${
          allUnanswered ? 'none' : someUnanswered ? 'partial' : 'all'
        }`}
      />
    </li>
  );
};
