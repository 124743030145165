import './SkeletonComponent.scss';

const SkeletonComponent = ({ width = '100%', height = '30px', margin = '5px', count = 1 }) => {
  return (
    <>
      {new Array(count).fill(0).map((_, i) => (
        <div className="skeleton" key={i} style={{ width, height, marginBottom: margin }}></div>
      ))}
    </>
  );
};

export default SkeletonComponent;
