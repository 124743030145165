import { useEffect } from 'react';
import { connect } from 'react-redux';
import { DebounceInput } from 'react-debounce-input';
import { Translate } from 'react-redux-i18n';
import ModalDialog from '../ModalDialog';
import {
  selectContentType,
  selectDocumentType,
  generateDocument,
  updateTemplateContentProperty,
  setPrefilledDocumentData
} from '../../actions';
import { TEMPLATE_TYPE } from '../../constants';

const CreateDocumentModal = ({
  caregiver,
  isVisible,
  files,
  selectDocumentType,
  updateTemplateContentProperty,
  templateType,
  setPrefilledDocumentData,
  member,
  medications,
  generateDocument,
  authToken,
  onClose,
  onDocumentGenerationComplete,
  headerI18nKey = 'patient_view.notes.create_document',
  actionI18nKey = 'patient_view.notes.create_document',
  recipient
}) => {
  useEffect(() => {
    if (isVisible) {
      const memberCurrentMedications = medications.currentMedications
        .map((medication, index) => {
          return `${index + 1}. ${medication.current.name} (${medication.current.form}), ${
            medication.current.strength || ''
          }, ${medication.current.dosageRegimen || ''} \n`;
        })
        .join('');

      const prefilledData = {
        caregiverName: `${caregiver.givenName} ${caregiver.familyName}`,
        caregiverPhoneNumber: caregiver.phoneNumber,
        memberName: `${member.givenName} ${member.familyName}`,
        memberPersonalNumber: member.personalNumber,
        memberAddress:
          member.streetaddress && member.postalCode && member.city
            ? `${member.streetaddress}, ${member.postalCode} ${member.city}`
            : '',
        memberPhoneNumber: member.phoneNumber,
        memberCurrentMedications
      };

      setPrefilledDocumentData(prefilledData);
    }
  }, [isVisible]);

  const generate = () => {
    generateDocument(
      authToken,
      member.guid,
      files.selectedDocumentType || templateType,
      files.documentContent,
      recipient,
      (...args) => {
        onDocumentGenerationComplete && onDocumentGenerationComplete(...args);
        onClose();
      }
    );
  };

  return (
    <ModalDialog
      headerI18nKey={headerI18nKey}
      actionI18nKey={actionI18nKey}
      visible={isVisible}
      onClose={onClose}
      onActionCompleted={generate}
      actionCompletable={files.selectedDocumentType !== null || templateType === TEMPLATE_TYPE.diagnosisScreening}
      actionCompleting={files.generatingDocument}
      contentClass="pt-10"
      size="slim"
    >
      <div className="fw-input">
        <DocumentTypeSelector
          selectedDocumentType={files.selectedDocumentType}
          onChangeType={selectDocumentType}
          documentTemplateType={templateType}
        />
        {files.selectedDocumentType !== null || templateType === TEMPLATE_TYPE.diagnosisScreening ? (
          <ContactDetails
            documentContent={files.documentContent}
            updateTemplateContentProperty={updateTemplateContentProperty}
          />
        ) : null}
        {files.selectedDocumentType === TEMPLATE_TYPE.referral || templateType === TEMPLATE_TYPE.diagnosisScreening ? (
          <ReferralDocumentFields
            documentContent={files.documentContent}
            hasUnsignedMedications={medications.unsignedMedications.length}
            updateTemplateContentProperty={updateTemplateContentProperty}
          />
        ) : null}
        {files.selectedDocumentType === TEMPLATE_TYPE.certificate ? (
          <CertificateDocumentFields
            documentContent={files.documentContent}
            updateTemplateContentProperty={updateTemplateContentProperty}
          />
        ) : null}
      </div>
    </ModalDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    caregiver: state.auth.token.user,
    authToken: state.auth.token.jwt,
    member: state.currentMember,
    files: state.files,
    medications: state.medications
  };
};

const mapActionsToProps = {
  selectContentType,
  selectDocumentType,
  generateDocument,
  updateTemplateContentProperty,
  setPrefilledDocumentData
};

export default connect(mapStateToProps, mapActionsToProps)(CreateDocumentModal);

const DocumentTypeSelector = ({ selectedDocumentType, onChangeType, documentTemplateType }) => {
  if (documentTemplateType === TEMPLATE_TYPE.diagnosisScreening) {
    return null;
  }

  return (
    <div className="mb-20">
      <span>
        <input
          type="radio"
          id="document-type-referral"
          value={TEMPLATE_TYPE.referral}
          name="document-type"
          checked={selectedDocumentType === TEMPLATE_TYPE.referral}
          onChange={onChangeType}
        />
        <label htmlFor="document-type-referral">
          <Translate value="global.referral" />
        </label>
      </span>
      <span className="ml-15">
        <input
          type="radio"
          id="document-type-certificate"
          value={TEMPLATE_TYPE.certificate}
          name="document-type"
          checked={selectedDocumentType === TEMPLATE_TYPE.certificate}
          onChange={onChangeType}
        />
        <label htmlFor="document-type-certificate">
          <Translate value="global.certificate" />
        </label>
      </span>
    </div>
  );
};

const ContactDetails = ({ documentContent, updateTemplateContentProperty }) => {
  return (
    <>
      <fieldset>
        <legend>
          <Translate value="patient_view.notes.referring_doctor" />
        </legend>
        <div className="columns">
          <div className="column no-padding pad-right">
            <h4>
              <Translate value="global.name" />
            </h4>
            <DebounceInput
              type="text"
              value={documentContent.caregiverName}
              debounceTimeout={300}
              onChange={(e) => {
                updateTemplateContentProperty('caregiverName', e);
              }}
            />
          </div>
          <div className="column no-padding">
            <h4>
              <Translate value="global.phone" />
            </h4>
            <DebounceInput
              type="text"
              value={documentContent.caregiverPhoneNumber || '-'}
              debounceTimeout={300}
              onChange={(e) => {
                updateTemplateContentProperty('caregiverPhoneNumber', e);
              }}
            />
          </div>
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <Translate value="patient_view.notes.patient_details" />
        </legend>
        <div className="columns">
          <div className="column no-padding pad-right">
            <h4>
              <Translate value="global.name" />
            </h4>
            <DebounceInput type="text" disabled={true} value={documentContent.memberName} />
          </div>
          <div className="column no-padding">
            <h4>
              <Translate value="global.personalNumber" />
            </h4>
            <DebounceInput type="text" disabled={true} value={documentContent.memberPersonalNumber} />
          </div>
        </div>
        <div className="columns">
          <div className="column no-padding">
            <h4>
              <Translate value="global.address" />
            </h4>
            <DebounceInput
              type="text"
              value={documentContent.memberAddress}
              debounceTimeout={300}
              onChange={(e) => {
                updateTemplateContentProperty('memberAddress', e);
              }}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column no-padding">
            <h4>
              <Translate value="global.phone_number" />
            </h4>
            <DebounceInput
              type="text"
              value={documentContent.memberPhoneNumber}
              debounceTimeout={300}
              onChange={(e) => {
                updateTemplateContentProperty('memberPhoneNumber', e);
              }}
            />
          </div>
        </div>
      </fieldset>
    </>
  );
};

const ReferralDocumentFields = ({ documentContent, hasUnsignedMedications, updateTemplateContentProperty }) => {
  return (
    <>
      <div className="columns">
        <div className="column no-padding">
          <h4>
            <Translate value="patient_view.notes.document_field.referral_recipient" />
          </h4>
          <DebounceInput
            type="text"
            maxLength="57"
            value={documentContent.referralRecipient}
            debounceTimeout={300}
            onChange={(e) => {
              updateTemplateContentProperty('referralRecipient', e);
            }}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column no-padding">
          <h4>
            <Translate value="patient_view.notes.document_field.requested_examination" />
          </h4>
          <DebounceInput
            type="text"
            maxLength="54"
            value={documentContent.requestedExamination}
            debounceTimeout={300}
            onChange={(e) => {
              updateTemplateContentProperty('requestedExamination', e);
            }}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column no-padding">
          <h4>
            <Translate value="patient_view.notes.document_field.diagnosis_and_query" />
          </h4>
          <DebounceInput
            type="text"
            maxLength="50"
            value={documentContent.diagnosis}
            debounceTimeout={300}
            onChange={(e) => {
              updateTemplateContentProperty('diagnosis', e);
            }}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column no-padding">
          <h4>
            <Translate value="patient_view.notes.document_field.anamnesis" />
          </h4>
          <DebounceInput
            element="textarea"
            maxLength="770"
            value={documentContent.anamnesis}
            className="h-10"
            debounceTimeout={300}
            onChange={(e) => {
              updateTemplateContentProperty('anamnesis', e);
            }}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column no-padding">
          <h4>
            <Translate value="patient_view.notes.document_field.current_medications" />
          </h4>
          {hasUnsignedMedications ? (
            <h4 className="error-message">
              <Translate value="patient_view.notes.has_unsigned_medications_warning" />
            </h4>
          ) : null}
          <DebounceInput
            element="textarea"
            value={documentContent.memberCurrentMedications}
            className="h-10"
            debounceTimeout={300}
            onChange={(e) => {
              updateTemplateContentProperty('memberCurrentMedications', e);
            }}
          />
        </div>
      </div>
    </>
  );
};

const CertificateDocumentFields = ({ documentContent, updateTemplateContentProperty }) => {
  return (
    <>
      <div className="columns">
        <div className="column no-padding">
          <h4>
            <Translate value="patient_view.notes.document_field.recipient" />
          </h4>
          <DebounceInput
            type="text"
            maxLength="60"
            value={documentContent.referralRecipient}
            debounceTimeout={300}
            onChange={(e) => {
              updateTemplateContentProperty('referralRecipient', e);
            }}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column no-padding">
          <h4>
            <Translate value="patient_view.notes.document_field.diagnosis" />
          </h4>
          <DebounceInput
            type="text"
            maxLength="65"
            value={documentContent.diagnosis}
            debounceTimeout={300}
            onChange={(e) => {
              updateTemplateContentProperty('diagnosis', e);
            }}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column no-padding">
          <h4>
            <Translate value="patient_view.notes.document_field.anamnesis_summary" />
          </h4>
          <DebounceInput
            element="textarea"
            maxLength="1600"
            value={documentContent.anamnesis}
            className="h-10"
            debounceTimeout={300}
            onChange={(e) => {
              updateTemplateContentProperty('anamnesis', e);
            }}
          />
        </div>
      </div>
    </>
  );
};
