export const defaultOptions = {
  maintainAspectRatio: false,
  spanGaps: true,
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false
        },
        ticks: {
          display: false
        }
      }
    ],
    yAxes: [
      {
        position: 'right',
        gridLines: {
          display: true,
          color: '#e8deda',
          drawBorder: false
        },
        ticks: {
          fontColor: '#76615c',
          fontFamily: 'IsidoraSans-Bold, sans-serif',
          fontSize: 12,
          suggestedMin: 30,
          suggestedMax: 200
        }
      }
    ]
  }
};

export const customOptions = {
  maintainAspectRatio: false,
  spanGaps: true,
  scales: {
    xAxes: [
      {
        type: 'time',
        time: {
          unit: 'day'
        },
        gridLines: {
          display: false
        },
        ticks: {
          display: true,
          fontColor: '#76615c',
          fontFamily: 'IsidoraSans-Bold, sans-serif',
          fontSize: 12
        }
      }
    ],
    yAxes: [
      {
        position: 'left',
        gridLines: {
          display: true,
          color: '#e8deda',
          drawBorder: false
        },
        ticks: {
          fontColor: '#76615c',
          fontFamily: 'IsidoraSans-Bold, sans-serif',
          fontSize: 12,
          suggestedMin: 50,
          suggestedMax: 200
        }
      }
    ]
  },
  annotation: {
    annotations: [
      {
        drawTime: 'afterDatasetsDraw',
        type: 'line',
        mode: 'horizontal',
        scaleID: 'y-axis-0',
        borderColor: 'rgba(216, 56, 74, 0.5)',
        borderWidth: 2,
        borderDash: [4, 2]
      }
    ]
  }
};

export const legendOptions = {
  display: false
};

export const commonDataSetOptions = {
  fill: false,
  lineTension: 0,
  borderCapStyle: 'butt',
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointStyle: 'circle',
  pointBackgroundColor: '#fff',
  pointBorderWidth: 8,
  pointHoverRadius: 8,
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10
};

export const systolicDataOptions = {
  label: 'Systoliskt, mmHg',
  backgroundColor: '#d8384a',
  borderColor: '#d8384a',
  pointBorderColor: '#d8384a',
  pointHoverBackgroundColor: '#d8384a',
  pointHoverBorderColor: '#d8384a'
};

export const diastolicDataOptions = {
  label: 'Diastoliskt, mmHg',
  backgroundColor: '#3e5fcb',
  borderColor: '#3e5fcb',
  pointBorderColor: '#3e5fcb',
  pointHoverBackgroundColor: '#3e5fcb',
  pointHoverBorderColor: '#3e5fcb'
};

export const heartRateDataOptions = {
  label: 'BPM',
  backgroundColor: '#efb300',
  borderColor: '#efb300',
  pointBorderColor: '#efb300',
  pointHoverBackgroundColor: '#efb300',
  pointHoverBorderColor: '#efb300',
  borderDash: [11, 7]
};
