import { useEffect } from 'react';
import { useRef } from 'react';
import SkeletonComponent from '../../../SkeletonComponent';

const SkeletonKioskView = ({ numQuestions = 3, onAnimationEnd, ...props }) => {
  const ref = useRef();

  useEffect(() => {
    ref.current?.addEventListener('animationend', onAnimationEnd);

    return () => ref.current?.removeEventListener('animationend', onAnimationEnd);
  }, []);

  return (
    <div {...props} ref={ref}>
      <header>
        <SkeletonComponent width="30%" height="80px" />
        <div>
          <SkeletonComponent width="200px" height="43px" />
          <SkeletonComponent width="200px" height="43px" />
        </div>
      </header>
      <SkeletonComponent width="60%" height="20px" />
      <div className="mt-30 flex space-between">
        <div className="w-100 mr-20">
          <SkeletonComponent height="30px" width="60%" margin="20px" />
          {new Array(numQuestions).fill(0).map((_, i) => (
            <SkeletonComponent key={i} width="100%" height="48px" margin="2px" />
          ))}
        </div>
        <div className="w-100">
          <SkeletonComponent height="60px" width="100%" margin="20px" />
          <SkeletonComponent height="30px" width="60%" />
          <SkeletonComponent height="50px" width="100%" margin="20px" />
          <SkeletonComponent height="30px" width="40%" />
          <SkeletonComponent height="300px" width="100%" />
        </div>
      </div>
    </div>
  );
};

export default SkeletonKioskView;
