import './PatientBadge.scss';

const PatientBadge = ({ text, shortText, extraInfo, spaceSaving, type }) => {
  return (
    <div className={`patient-badge ${type} ${spaceSaving ? 'space-saving' : ''}`}>
      {!spaceSaving ? text : shortText}
      {extraInfo ? <span className="extra-info"> — {extraInfo}</span> : null}
    </div>
  );
};

export default PatientBadge;
