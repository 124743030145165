import React, { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import { Translate, I18n } from 'react-redux-i18n';
import initials from 'initials';
import sanitizeHtml from 'sanitize-html';
import linkifyHtml from 'linkify-html';
import { configuredMoment as moment } from '../../../utils';
import { MALE_PLACEHOLDER_AUTHOR_ICON, FEMALE_PLACEHOLDER_AUTHOR_ICON, DATE_FORMAT } from '../../../constants';
import DeliveredIcon from '../../../assets/delivered-checkmark-copy.svg';
import './ChatMessage.scss';

const sanitizeOptions = {
  allowedTags: ['a', 'mark', 'br'],
  allowedAttributes: {
    a: ['href', 'target', 'rel'],
    mark: ['class']
  }
};

const linkifyOptions = {
  target: '_blank',
  rel: 'noopener noreferrer'
};

const ChatMessage = React.memo(
  ({
    message,
    member,
    dateTimeFormat,
    locale,
    onThumbnailClick,
    onDelete,
    countdownText,
    showDraftActions,
    user,
    onStartEdit,
    activeFilters
  }) => {
    if (activeFilters.includes('image') && message.type === 'file') {
      return null;
    }

    if (activeFilters.includes('pal') && message.caregiverRole === 'doctor') {
      return null;
    }

    if (activeFilters.includes('pas') && message.caregiverRole === 'nurse') {
      return null;
    }

    if (activeFilters.includes('pat') && !message.caregiverRole && message.type === 'message') {
      return null;
    }

    const messageDirection = message.author !== member.guid ? 'outgoing' : 'incoming';
    const showMessageRead = message.author !== member.guid && message.memberRead;

    const today = moment();
    moment.locale(locale);

    const hasAuthorIcon =
      message.icon && message.icon !== MALE_PLACEHOLDER_AUTHOR_ICON && message.icon !== FEMALE_PLACEHOLDER_AUTHOR_ICON;

    return (
      <Fragment>
        <div
          className={`chat-message message-${messageDirection} author-type__${
            message.author !== member.guid ? message.caregiverRole || 'doctor' : 'member'
          }`}
          id={message.id}
        >
          {messageDirection === 'incoming' ? (
            <div className="columns">
              <div className="column chat-avatar no-padding">
                <span
                  className="chat-avatar__placeholder"
                  style={{
                    backgroundImage: hasAuthorIcon ? `url(data:image/jpg;base64,${message.icon})` : 'none'
                  }}
                  data-sensitive
                >
                  {!hasAuthorIcon && initials(`${member.givenName} ${member.familyName}`)}
                </span>
              </div>
              <div className="column chat-message__content">
                {message.type === 'file' && message.fileinfo.contentType === 'image' ? (
                  <ChatMessageThumbnail
                    imageSrc={message.fileinfo.filePath}
                    onClick={() => onThumbnailClick(message.fileinfo.filePath)}
                    messageId={message.id}
                    objectUrl={message.objectUrl}
                  />
                ) : null}
                {(message.highlight || message.text).split('\n').map((item, key) => {
                  return (
                    <span key={key} data-sensitive>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: linkifyHtml(sanitizeHtml(item, sanitizeOptions), linkifyOptions)
                        }}
                      />
                      <br />
                    </span>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="flexrow">
              <div className="flex1"></div>
              <div className={`flex0 chat-message__content ${message.type === 'draft' ? 'message-draft' : ''}`}>
                {(message.highlight || message.text).split('\n').map((item, key) => {
                  return (
                    <span key={key} data-sensitive>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: linkifyHtml(sanitizeHtml(item, sanitizeOptions), linkifyOptions)
                        }}
                      />
                      <br />
                    </span>
                  );
                })}
              </div>
            </div>
          )}
          {message.type === 'draft' ? (
            <Fragment>
              <div className="message-actions">
                <time>
                  <span className="draft-countdown">
                    <Translate value={countdownText.key} time={countdownText.time} />
                  </span>
                  {showDraftActions && message.author === user.guid && (
                    <span
                      className="question-mark"
                      data-tip={I18n.t('patient_view.chat.draft.countdown_tooltip')}
                    ></span>
                  )}
                </time>
                {showDraftActions && message.author === user.guid ? (
                  <Fragment>
                    <span onClick={onStartEdit(message)}>
                      <Translate value="patient_view.chat.draft.edit" />
                    </span>
                    <span onClick={onDelete(message.id)}>
                      <Translate value="patient_view.chat.draft.delete" />
                    </span>
                  </Fragment>
                ) : null}
              </div>
              <ReactTooltip multiline={true} effect="solid" className="custom-tooltip" />
            </Fragment>
          ) : null}
          <div className="chat-message__timestamp">
            {messageDirection === 'outgoing' && message.type !== 'draft' ? (
              <span className="caregiver-name">
                {message.name}
                {message.title ? `, ${message.title}` : null}
              </span>
            ) : null}
            {showMessageRead ? <img className="delivered-icon" src={DeliveredIcon} alt="Meddelande läst" /> : null}
            {message.type !== 'draft'
              ? today.isSame(message.timestamp, 'd')
                ? moment(message.timestamp, DATE_FORMAT).calendar()
                : moment(message.timestamp, DATE_FORMAT).format(dateTimeFormat)
              : null}
          </div>
        </div>
      </Fragment>
    );
  }
);

export default ChatMessage;

const ChatMessageThumbnail = ({ imageSrc, onClick, messageId, objectUrl }) => {
  return (
    <div
      className="chat-message__thumbnail pointer"
      data-thumbnail-image-src={imageSrc}
      data-message-id={messageId}
      onClick={onClick}
      data-sensitive
    >
      {objectUrl ? <img src={objectUrl} /> : null}
    </div>
  );
};
