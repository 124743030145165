import React, { Fragment, useState, useRef, useEffect } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { CSSTransition } from 'react-transition-group';
import './CannedResponseSelector.scss';
import LoadingSpinner from '../../LoadingSpinner';

const CannedResponseSelector = React.memo(({ responses, onSelection, isLoading, menuOpen, onToggle }) => {
  const inputEl = useRef();
  const parentNode = useRef();

  useEffect(() => {
    const handleClick = (e) => {
      if (menuOpen) {
        if (parentNode.current.contains(e.target)) {
          return;
        }

        onToggle(false);
      }
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [menuOpen, onToggle]);

  useEffect(() => {
    inputEl.current && inputEl.current.focus();
  }, [menuOpen]);

  const selectResponse = (response) => {
    onSelection(response);
  };

  const ResponseSelectorMenuTrigger = ({ open, onClick, children, isLoading }) => {
    return (
      <div className="dropdown-trigger">
        <button className="button" onClick={!isLoading ? onClick : undefined}>
          <span className="icons canned-response"></span>
          <Translate value="canned_response.button" />
          {isLoading ? (
            <span className="ml-5">
              <LoadingSpinner size="smaller" />
            </span>
          ) : null}
        </button>

        {open ? children : null}
      </div>
    );
  };

  const ResponseSelectorMenu = () => {
    const [activeMenu, setActiveMenu] = useState('main');
    const [filter, setFilter] = useState('');

    const ResponseItem = ({ response, onClick }) => {
      return (
        <span className="menu-item menu-item__response" onClick={onClick}>
          {response.title}
        </span>
      );
    };

    const CategoryItem = ({ category }) => {
      return (
        <span className="menu-item menu-item__category" onClick={() => setActiveMenu(category.id)}>
          {category.title} ({category.children.length})
        </span>
      );
    };

    const BackButton = ({ toMenu }) => {
      return (
        <span className="menu-item menu-item__back" onClick={() => setActiveMenu(toMenu)}>
          <span className="canned-response__nav back">
            <Translate value="canned_response.back" />
          </span>
        </span>
      );
    };

    const filteredResponses = filter.length
      ? responses.allResponses.filter(
          (response) =>
            response.body.toLowerCase().includes(filter.toLowerCase()) ||
            response.title.toLowerCase().includes(filter.toLowerCase())
        )
      : [];

    return (
      <div className="popover">
        {filter.length ? (
          filteredResponses.length ? (
            <div className="popover-menu">
              {filteredResponses.map((response) => {
                return (
                  <ResponseItem key={response.id} response={response} onClick={() => selectResponse(response.body)} />
                );
              })}
            </div>
          ) : (
            <div className="pl-10 pr-10 pb-10 pt-10 fs-14">
              <Translate value="canned_response.no_search_results" />
            </div>
          )
        ) : (
          <Fragment>
            <CSSTransition in={activeMenu === 'main'} timeout={0} classNames="menu-primary" unmountOnExit>
              <div className="popover-menu">
                {responses.original.map((category) => {
                  return <CategoryItem key={category.id} category={category} />;
                })}
              </div>
            </CSSTransition>

            {responses.flattened.map((category) => {
              return (
                <CSSTransition
                  key={category.id}
                  in={activeMenu === category.id}
                  timeout={200}
                  classNames="menu-secondary"
                  unmountOnExit
                >
                  <div className="popover-menu">
                    {category.children.map((child) => {
                      return child.isCategory ? (
                        <CategoryItem key={child.id} category={child} />
                      ) : (
                        <ResponseItem key={child.id} response={child} onClick={() => selectResponse(child.body)} />
                      );
                    })}
                    <BackButton toMenu={category.parent || 'main'} />
                  </div>
                </CSSTransition>
              );
            })}
          </Fragment>
        )}
        <div className="pl-10 pr-10 pb-10 pos-rel">
          <input
            type="text"
            className="w-100"
            placeholder={I18n.t('canned_response.search_placeholder')}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            ref={inputEl}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="canned-responses" ref={parentNode}>
      <ResponseSelectorMenuTrigger isLoading={isLoading} open={menuOpen} onClick={() => onToggle(!menuOpen)}>
        <ResponseSelectorMenu />
      </ResponseSelectorMenuTrigger>
    </div>
  );
});

export default CannedResponseSelector;
