import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { decamelize } from '../../../../utils';
import './Revision.scss';

const Revision = ({ date, revisions }) => {
  return (
    <div className="revision">
      <h3>{date}</h3>
      <div>
        {revisions.map((revision, i) => {
          return (
            <div className="revision-property" key={`${date}-${i}`}>
              <h4>
                <Translate value={`patient_view.medications.base.${decamelize(revision.property)}`} />
              </h4>
              <span className="strike mr-20">
                {revision.fallbackI18nKey ? (
                  <Translate value={revision.fallbackI18nKey} />
                ) : typeof revision.previousValue === 'boolean' ? (
                  I18n.t(`global.${revision.previousValue ? 'yes' : 'no'}`)
                ) : (
                  revision.previousValue
                )}
              </span>
              <span>
                {typeof revision.newValue === 'boolean'
                  ? I18n.t(`global.${revision.newValue ? 'yes' : 'no'}`)
                  : revision.newValue}
              </span>
              {i === revisions.length - 1 ||
              (revisions.length > 1 &&
                i < revisions.length &&
                revisions[i].changedByGuid !== revisions[i + 1].changedByGuid) ? (
                <h3 className="mt-10">{revision.changedByName}</h3>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Revision;
