import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import moment from 'moment';
import './ChatAttachment.scss';
import { DATE_FORMAT } from '../../../constants';

const ChatAttachment = React.memo(({ message, dateTimeFormat, onPreview }) => {
  return (
    <div className="chat-attachment">
      <div className="file-info">
        <h3>
          <Translate value={`global.${message.fileinfo.contentType}`} />
        </h3>
        <div className="file-info__filename" data-sensitive>
          {message.fileinfo.filename}
        </div>
        <div className="file-info__timestamp">{moment(message.timestamp, DATE_FORMAT).format(dateTimeFormat)}</div>
      </div>
      <div
        className="file-download"
        onClick={() => onPreview(message.fileinfo.id, message.fileinfo.filename, message.fileinfo.contentType)}
      >{`${I18n.t('global.show')} ${I18n.t(
        `global.${message.fileinfo.contentType !== 'other' ? message.fileinfo.contentType : 'document'}`
      ).toLowerCase()}`}</div>
    </div>
  );
});

export default ChatAttachment;
