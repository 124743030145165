import React, { Component } from 'react';
import DecisionSupportTable from '../../DecisionSupportTable';
import WeeklyResultsRow from '../../DecisionSupportTable/RowTypes/WeeklyResultsRow';
import RiskFactorRecommendationRow from '../RiskFactorRecommendationRow';
import RiskFactorStatusRow from '../RiskFactorStatusRow';

class RiskFactorLowPhysicalActivity extends Component {
  render() {
    const { lifestyleObjectives, lifestyleObjectivesProgress } = this.props;

    return (
      <div className="risk-factor">
        <RiskFactorStatusRow {...this.props} showTimestamp={true} />

        {lifestyleObjectives && (lifestyleObjectives.mediumIntensity || lifestyleObjectives.highIntensity) ? (
          <DecisionSupportTable className="mb-10">
            <WeeklyResultsRow
              typeI18nKey="decision_support_view.risk_profile_section.low_intensity_training"
              goal={lifestyleObjectives.mediumIntensity}
              results={lifestyleObjectivesProgress && lifestyleObjectivesProgress.mediumIntensityProgress}
            />
            <WeeklyResultsRow
              typeI18nKey="decision_support_view.risk_profile_section.high_intensity_training"
              goal={lifestyleObjectives.highIntensity}
              results={lifestyleObjectivesProgress && lifestyleObjectivesProgress.highIntensityProgress}
            />
          </DecisionSupportTable>
        ) : null}

        <RiskFactorRecommendationRow {...this.props} isNonStandardRiskFactorRow={true} />
      </div>
    );
  }
}

export default RiskFactorLowPhysicalActivity;
