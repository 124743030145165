import React, { Component, Fragment } from 'react';
import { Translate } from 'react-redux-i18n';
import LoaderButton from '../LoaderButton';
import ModalDialog from '../ModalDialog';
import HistoricalNote from '../NotesView/HistoricalNote';
import './EditableTextField.scss';

class EditableTextField extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.toggleHistoryModal = this.toggleHistoryModal.bind(this);

    this.state = {
      value: this.props.defaultText || '',
      historyModalOpen: false
    };
  }

  handleChange(event) {
    this.setState({
      value: event.target.value
    });
  }

  toggleHistoryModal() {
    this.setState({
      historyModalOpen: !this.state.historyModalOpen
    });
  }

  render() {
    const { headerI18nKey, actionI18nKey, onUpdate, defaultText = '', isLoading, history, isReadOnly } = this.props;

    return (
      <Fragment>
        <div className="editable-text-field-container">
          <div className="columns">
            <div className="column no-padding">
              <h3>
                <Translate value={headerI18nKey} />
              </h3>
            </div>
            <div className="column no-padding has-text-right">
              {history && history.length ? (
                <span className="history-button" onClick={this.toggleHistoryModal}>
                  <Translate value="patient_view.anamnesis.risk_profile_history_link" />
                </span>
              ) : null}
            </div>
          </div>
          {isReadOnly ? (
            <div className="mukta">{defaultText}</div>
          ) : (
            <textarea
              value={this.state.value !== undefined ? this.state.value : defaultText}
              onChange={this.handleChange}
            />
          )}

          {onUpdate ? (
            <div className="editable-text-field-actions">
              <LoaderButton
                buttonI18nKey={actionI18nKey}
                isLoading={isLoading}
                onClick={onUpdate(this.state.value)}
                disabled={isLoading}
              />
            </div>
          ) : null}
        </div>
        <ModalDialog
          headerI18nKey="patient_view.anamnesis.risk_profile_history_header"
          actionI18nKey="global.buttons.done"
          visible={this.state.historyModalOpen}
          onClose={this.toggleHistoryModal}
          onActionCompleted={this.toggleHistoryModal}
          actionCompletable={true}
          actionCompleting={false}
        >
          {history && history.length
            ? history.map((note, i) => {
                return <HistoricalNote key={i} note={note} dateTimeFormat="YYYY-MM-DD HH:mm" />;
              })
            : null}
        </ModalDialog>
      </Fragment>
    );
  }
}

export default EditableTextField;
