import React from 'react';
import { connect } from 'react-redux';

const HasAuthority = ({ authority, authorities, children, disable = false }) => {
  if (!authority) {
    return children;
  }

  if (Array.isArray(authority)) {
    const hasAuthority = authorities.some((a) => authority.indexOf(a) >= 0);

    if (!hasAuthority) {
      if (disable) {
        return React.cloneElement(children, { disabled: true });
      }

      return null;
    }
  } else {
    if (authorities.indexOf(authority) === -1) {
      if (disable) {
        return React.cloneElement(children, { disabled: true });
      }

      return null;
    }
  }

  return children;
};

const mapStateToProps = (state) => {
  return {
    authorities: state.auth.authorities
  };
};

export default connect(mapStateToProps)(HasAuthority);
