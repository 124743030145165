import React from 'react';
import { Translate } from 'react-redux-i18n';
import MedicationBase from '../MedicationBase';

const MedicationInformation = ({ medication, changes, onCancelMedication, onChangeDosageRegimen, onChangeReason }) => {
  return (
    <div className="medication-information">
      <div className="columns">
        <div className="column is-9 no-padding pr-40">
          <MedicationBase medication={medication} changes={changes} />
        </div>
        {!changes.some((change) => change.changeType === 'stop') ? (
          <div className="column is-3 no-padding">
            <div className="medication-change-button" onClick={onChangeDosageRegimen}>
              <Translate value="patient_view.medications.medication_modal.change_dose" />
            </div>
            <div className="medication-change-button" onClick={onChangeReason}>
              <Translate value="patient_view.medications.medication_modal.change_reason" />
            </div>

            <div className="medication-change-button cancel-medication mt-90" onClick={onCancelMedication}>
              <Translate value="patient_view.medications.medication_modal.cancel_medication" />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MedicationInformation;
