import React, { Component } from 'react';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../constants';
import './InlineComment.scss';

class InlineComment extends Component {
  render() {
    const { comment } = this.props;

    if (!comment || comment.comment === '') return null;

    return (
      <div className="inline-comment">
        <div className="comment-meta">
          {comment.savedBy ? (
            <span className="comment-author">{`${comment.savedBy.givenName} ${comment.savedBy.familyName}, `}</span>
          ) : null}
          <span className="comment-timestamp">{moment(comment.timestamp, DATE_FORMAT).format('YYYY-MM-DD HH:mm')}</span>
        </div>
        {comment.comment.split('\n').map((line, key) => {
          return (
            <span key={key} data-sensitive>
              {line}
              <br />
            </span>
          );
        })}
      </div>
    );
  }
}

export default InlineComment;
