import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

class CustomLabResultValue extends Component {
  render() {
    const { original, value, accessor, showLabEvaluation } = this.props;

    return (
      <Fragment>
        <div className="fs-16 columns">
          <div className="column no-padding is-2">
            <span
              className={`severity ${original.results[accessor].severity} ${
                original.results[accessor].labComment ? 'cursor-help' : ''
              }`}
              data-tip={original.results[accessor].labComment}
            >
              {value}
              {original.results[accessor].labComment ? (
                <span>
                  <sup className="lab-comment">*</sup>
                </span>
              ) : null}
            </span>
          </div>
          {showLabEvaluation ? (
            <div className="column no-padding">
              <small className="ml-5" title={original.results[accessor].evaluation}>
                {original.results[accessor].evaluation}
              </small>
            </div>
          ) : null}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showLabEvaluation: state.decisionSupport.showLabEvaluation
  };
};

export default connect(mapStateToProps)(CustomLabResultValue);
