import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { DebounceInput } from 'react-debounce-input';
import ContentLoader from '../../ContentLoader';
import JiraIssueRow from './JiraIssueRow';
import './JiraSupportModalContent.scss';

const JiraSupportModalContent = ({ content, onSelectIssueType, onChangeDescription, ...props }) => {
  const hasUnreadNudge = props.chat.nudgeDisabled;
  return (
    <div>
      {hasUnreadNudge ? (
        <div className="info-missing">
          <Translate value="patient_view.chat.active_nudge" />
        </div>
      ) : null}
      <h2>
        <Translate value="decision_support_view.support.select_issue_type" />
      </h2>
      <div className="select">
        <select onChange={onSelectIssueType} value={content.selectedSupportTypeId}>
          <option value="0">{I18n.t('decision_support_view.support.select_issue_type')}</option>
          {content.issueTypes.map((issueType) => (
            <option key={issueType.id} value={issueType.id} disabled={issueType.id === 2 && hasUnreadNudge}>
              {issueType.summary}
            </option>
          ))}
        </select>
      </div>
      {content.selectedSupportType ? (
        <div className="jira-description">
          <DebounceInput
            element="textarea"
            maxLength="2000"
            debounceTimeout={300}
            value={content.issueDescription}
            onChange={onChangeDescription}
          />
        </div>
      ) : null}
      <hr />
      <h2>
        <Translate value="decision_support_view.support.history_header" />
      </h2>
      <ContentLoader
        isLoading={content.loadingJiraIssues}
        error={content.jiraIssuesError}
        errorTitleI18n="decision_support_view.support.history_error"
      >
        {content.jiraIssues.length ? (
          <Fragment>
            <div className="columns fs-14">
              <div className="column is-5 no-padding">
                <Translate value="decision_support_view.support.history_column.issue" />
              </div>
              <div className="column is-4 no-padding">
                <Translate value="decision_support_view.support.history_column.created" />
              </div>
              <div className="column is-2 no-padding">
                <Translate value="decision_support_view.support.history_column.status" />
              </div>
              <div className="column no-padding is-1"></div>
            </div>
            {content.jiraIssues.map((issue) => {
              return <JiraIssueRow issue={issue} key={issue.key} />;
            })}
          </Fragment>
        ) : (
          <Translate value="decision_support_view.support.history_empty" />
        )}
      </ContentLoader>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    chat: state.patientChat
  };
};

export default connect(mapStateToProps)(JiraSupportModalContent);
