import React, { useState, useEffect } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';

const options = {
  maintainAspectRatio: true,
  spanGaps: true,
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false
        },
        ticks: {
          display: true
        }
      }
    ],
    yAxes: [
      {
        position: 'left',
        gridLines: {
          display: true,
          color: '#e8deda',
          drawBorder: false
        },
        ticks: {
          fontColor: '#76615c',
          fontFamily: 'IsidoraSans-Bold, sans-serif',
          fontSize: 12,
          suggestedMin: 0,
          suggestedMax: 30
        }
      }
    ]
  }
};

const FutureCheckupsGraph = ({ bookedCheckups, currentPeriod }) => {
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    const currentDay = moment(currentPeriod);
    const oneMonthFromNow = currentDay.clone().add(1, 'month');
    const days = [];

    while (currentDay.isBefore(oneMonthFromNow)) {
      days.push(currentDay.format('YYYYMMDD'));
      currentDay.add(1, 'day');
    }

    setChartData({
      labels: days.map((day) => moment(day).format('D MMM')),
      datasets: [
        {
          data: days.map((day) => bookedCheckups[day] || 0),
          label: I18n.t('inbox_view.dashboard.upcoming_checkups_label'),
          backgroundColor: 'rgb(209, 21, 106)',
          borderColor: 'rgb(255, 99, 132)'
        }
      ]
    });
  }, [currentPeriod]);

  return (
    <div className="bookings-graph mt-30">
      <div className="flex space-between align-center">
        <h2>
          <Translate value="inbox_view.dashboard.upcoming_checkups" />
        </h2>
        <div className="text-right">
          <strong>
            <Translate value="global.please_note" />
          </strong>{' '}
          <Translate value="inbox_view.dashboard.note" />
        </div>
      </div>
      <Bar data={chartData} options={options} legend={{ display: false }} />
    </div>
  );
};

export default FutureCheckupsGraph;
