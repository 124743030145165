import { useEffect, createContext, useContext, useState } from 'react';
import * as contentful from 'contentful';
import { Document } from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import lodash from 'lodash';
import { loadState, saveState } from '../../services/localStorage';

type ContentContextValue = {
  getCategory: (category: AssessmentCategory) => ContentfulClinicalAssessmentCategory;
  getConclusion: (conclusion: ClinicalConclusion) => ContentfulClinicalConclusion;
  getAssessment: (assessment: ContentfulClinicalAssessmentType) => ContentfulClinicalAssessment;
  getObservation: (requirement: ObservationData) => ContentfulObservation;
  getFinding: (requirement: ObservationData) => ContentfulFinding;
  getNotification: (type: string, category: string) => ContentfulErrand;
  getCarePlan: (type: string) => ContentfulCarePlan;
  getAppointmentReason: (reason: string) => ContentfulAppointmentReason;
  getProgram: (name: string) => ContentfulProgram;
  renderRichText: (contentfulDocument: Document) => React.ReactNode;
  getSuspendedProgram: (reason: string) => ContentfulSuspendedProgram;
  getActivity: (name: string, program?: string) => ContentfulActivity;
};

type ContentfulClinicalAssessmentType = string;

type ContentfulClinicalAssessmentCategory = {
  category: AssessmentCategory;
  title: string;
  shortDescription: string;
};

type ContentfulClinicalAssessment = {
  assessment: ContentfulClinicalAssessmentType;
  shortDescription: string;
  title: string;
  icon?: ContentfulImage;
};

type ContentfulClinicalConclusion = {
  codeSystem: CodeSystem;
  code: string;
  interpretation: Interpretation;
  shortDescription: string;
  title: string;
  lifestyleRisk?: string;
  riskFactorLevel?: string;
};

type ContentfulObservation = {
  codeSystem: CodeSystem;
  code: string;
  title: string;
  unit?: string;
  trueAnswer?: string;
  falseAnswer?: string;
  hideInterpretation?: boolean;
};

type ContentfulFinding = {
  codeSystem: CodeSystem;
  code: string;
  title: string;
};

type ContentfulErrand = {
  type: string;
  title: string;
  icon?: ContentfulImage;
  description?: string;
};

type ContentfulCarePlan = {
  type: string;
  title: string;
  description: string;
  velocity: string;
};

type ContentfulAppointmentReason = {
  reason: string;
  title: string;
  icon: ContentfulImage;
};

type ContentfulProgram = {
  name: string;
  title: string;
  followUpActivityDescription?: string;
  followUpActivityLongDescription?: string;
  image?: ContentfulImage;
  linkPath?: string;
  icon?: ContentfulImage;
};

type ContentfulSuspendedProgram = {
  moreInfoTitle: string;
  suspendedReason: string;
};

type ContentfulActivity = {
  name: string;
  title: string;
  image?: ContentfulImage;
  caregiverTitle?: string;
};

type ContentfulImage = FixMe;

const clientConfig: contentful.CreateClientParams = {
  // This is the space ID. A space is like a project folder in Contentful terms
  space: '4arhn4uoy6pi',
  // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
  accessToken: 'WB2nFW2S3bjAVbQZsQffS-CIupa9OBNHXoFw5Nyu7d8'
};

if (process.env.REACT_APP_CONTENTFUL_ENVIRONMENT) {
  clientConfig.environment = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT;
}

const contentfulClient = contentful.createClient(clientConfig);

export const ContentContext = createContext<ContentContextValue>({
  getCategory: () => ({
    title: 'Mocked',
    category: 'BLOOD_PRESSURE',
    shortDescription: 'Dummy'
  }),
  getConclusion: () => ({
    id: -1,
    title: 'Mocked',
    codeSystem: 'BTD',
    code: '000',
    interpretation: 'PRESENT',
    shortDescription: 'Mocked'
  }),
  getAssessment: () => ({
    assessment: 'Mocked',
    title: 'Mocked',
    shortDescription: 'Mocked'
  }),
  getObservation: () => ({
    codeSystem: 'BTD',
    code: '000',
    title: 'Mocked'
  }),
  getFinding: () => ({
    codeSystem: 'BTD',
    code: '000',
    title: 'Mocked'
  }),
  getNotification: () => ({
    type: 'Mocked',
    title: 'Mocked'
  }),
  getCarePlan: () => ({
    type: 'Mocked',
    title: 'Mocked',
    description: 'Mocked',
    velocity: '1'
  }),
  getAppointmentReason: () => ({
    reason: 'Mocked',
    title: 'Mocked',
    icon: 'Mocked'
  }),
  getProgram: () => ({
    name: 'Mocked',
    title: 'Mocked',
    followUpActivityDescription: 'Mocked',
    followUpActivityLongDescription: 'Mocked'
  }),
  renderRichText: () => <></>,
  getSuspendedProgram: () => ({ moreInfoTitle: 'Mocked', suspendedReason: 'Mocked' }),
  getActivity: () => ({ name: 'Mocked', title: 'Mocked' })
});

type ContentGroups = {
  clinicalAssessmentCategory: FixMe;
  clinicalAssessment: FixMe;
  clinicalConclusion: FixMe;
  observation: FixMe;
  finding: FixMe;
  errand: FixMe;
  carePlan: FixMe;
  appointmentType: FixMe;
  program: FixMe;
  suspendedProgram: FixMe;
  sequenceStep: FixMe;
};

const initialGroupValue = {
  clinicalAssessment: [],
  clinicalAssessmentCategory: [],
  clinicalConclusion: [],
  observation: [],
  finding: [],
  errand: [],
  carePlan: [],
  appointmentType: [],
  program: [],
  suspendedProgram: [],
  sequenceStep: []
};

const ContentProvider = ({ children }) => {
  const [groups, setGroups] = useState<ContentGroups>(initialGroupValue);

  useEffect(() => {
    let combined: FixMe[] = [];

    // Todo: Only retrieve the content Clinic needs
    contentfulClient
      .getEntries({
        limit: 1000
      })
      .then((content) => {
        combined = content.items;
        return contentfulClient.getEntries({ limit: 1000, skip: 1000 });
      })
      .then((content) => {
        combined = [...combined, ...content.items];
        return contentfulClient.getEntries({ limit: 1000, skip: 2000 });
      })
      .then((content) => {
        combined = [...combined, ...content.items];
        const groups: ContentGroups = lodash.groupBy(combined, 'sys.contentType.sys.id') as ContentGroups;
        setGroups(groups);
        saveState(groups, 'ContentfulGroups');
      })
      .catch(() => {
        setGroups(loadState('ContentfulGroups', true) || initialGroupValue);
      });
  }, []);

  const value = {
    getContent: (type: string) => groups[type],
    getCategory: (category: AssessmentCategory) =>
      groups.clinicalAssessmentCategory.find((x) => x.fields.category === category)?.fields ?? {
        category,
        title: category,
        shortDescription: 'Missing content for category'
      },
    getAssessment: (assessment: ContentfulClinicalAssessmentType) =>
      groups.clinicalAssessment.find((x) => x.fields.assessment === assessment)?.fields ?? {
        title: assessment,
        assessment,
        shortDescription: 'Missing content for assessment'
      },
    getConclusion: ({ codeSystem, code, interpretation }: ClinicalConclusion) => {
      return (
        groups.clinicalConclusion.find((x) => {
          return (
            x.fields.codeSystem === codeSystem && x.fields.code === code && x.fields.interpretation === interpretation
          );
        })?.fields ?? {
          id: -1,
          title: `${codeSystem}: ${code}: ${interpretation}`,
          code,
          codeSystem,
          interpretation,
          shortDescription: 'Missing content for conclusion'
        }
      );
    },
    getObservation: ({ codeSystem, code }: ObservationData) => {
      return (
        groups.observation.find((x) => {
          return x.fields.codeSystem === codeSystem && x.fields.code === code;
        })?.fields ?? {
          title: `Missing content for observation: ${codeSystem + ' ' + code}`,
          code,
          codeSystem
        }
      );
    },
    getFinding: ({ codeSystem, code }: ObservationData) => {
      return (
        groups.finding.find((x) => {
          return x.fields.codeSystem === codeSystem && x.fields.code === code;
        })?.fields ?? {
          title: `Missing content for finding: ${codeSystem + ' ' + code}`,
          code,
          codeSystem
        }
      );
    },
    getNotification: (type: string, category: string) => {
      return (
        groups.errand.find((x) => {
          return x.fields.type === type && x.fields.category === category;
        })?.fields ?? {
          type,
          title: type
        }
      );
    },
    getCarePlan: (type: string) => {
      return (
        groups.carePlan.find((x) => {
          return x.fields.type === type;
        })?.fields ?? {
          type,
          title: type,
          description: '',
          velocity: '1'
        }
      );
    },
    getAppointmentReason: (reason: string) => {
      return (
        groups.appointmentType.find((x) => {
          return x.fields.reason === reason;
        })?.fields ?? {
          reason,
          title: reason,
          icon: 'missing'
        }
      );
    },
    getProgram: (name: string) => {
      return (
        groups.program.find((x) => {
          return x.fields.name === name;
        })?.fields ?? {
          name,
          title: name,
          followUpActivityDescription: name
        }
      );
    },
    getSuspendedProgram: (reason: string) => {
      return (
        groups.suspendedProgram.find((x) => {
          return x.fields.suspendedReason === reason;
        })?.fields ?? {
          suspendedReason: reason,
          moreInfoTitle: 'Missing content'
        }
      );
    },
    getActivity: (name: string, program?: string) => {
      return (
        groups.sequenceStep.find((x) => {
          if (program && x.fields.program) {
            return x.fields.name === name && x.fields.program.fields.name === program;
          }

          return x.fields.name === name;
        })?.fields ?? {
          name,
          title: name
        }
      );
    },
    renderRichText: (contentfulDocument: Document) => {
      return <div dangerouslySetInnerHTML={{ __html: documentToHtmlString(contentfulDocument) }} />;
    }
  };

  return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
};

export function useContent() {
  const context = useContext(ContentContext);

  return context;
}

export default ContentProvider;
