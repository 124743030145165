import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { APPLICATION_BASE_PATH } from '../../constants';

class BasePathRedirector extends Component {
  render() {
    return <Redirect to={`${APPLICATION_BASE_PATH}`} />;
  }
}

export default BasePathRedirector;
