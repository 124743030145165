import * as types from '../constants/actionTypes';

const initialState = {
  referralPackages: [],
  historicReferrals: [],
  clinics: [{ id: 'VC', name: 'VC' }]
};

const referralsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_REFERRAL_SESSION_REQUEST:
      return { ...state, isLoading: true };
    case types.GET_REFERRAL_SESSION_SUCCESS:
      return { ...state, isLoading: false, referralSession: action.payload };
    case types.GET_REFERRAL_SESSION_ERROR:
      return { ...state, isLoading: false, error: action.payload };
    case types.CLEAR_REFERRAL_SESSION:
      return { ...state, referralSession: null };
    case types.GET_ALL_REFERRALS_REQUEST:
      return { ...state, allReferralsLoading: true };
    case types.GET_ALL_REFERRALS_SUCCESS:
      return { ...state, allReferralsLoading: false, allReferrals: action.payload };
    case types.GET_ALL_REFERRALS_ERROR:
      return { ...state, allReferralsLoading: false, allReferralsError: action.payload };
    case types.GET_ALL_LAB_RESULTS_REQUEST:
      return { ...state, allLabResultsLoading: true };
    case types.GET_ALL_LAB_RESULTS_SUCCESS:
      return { ...state, allLabResultsLoading: false, allLabResults: action.payload };
    case types.GET_ALL_LAB_RESULTS_ERROR:
      return { ...state, allLabResultsLoading: false, allLabResultsError: action.payload };
    case types.GET_REFERRAL_PACKAGES_REQUEST:
      return { ...state, loadingReferralPackages: true };
    case types.GET_REFERRAL_PACKAGES_SUCCESS:
      return {
        ...state,
        loadingReferralPackages: false,
        referralPackages: action.payload,
        referralPackagesError: undefined
      };
    case types.GET_REFERRAL_PACKAGES_ERROR:
      return { ...state, loadingReferralPackages: false, referralPackagesError: action.payload };
    case types.GET_REFERRALS_REQUEST:
      return { ...state, loadingReferrals: true };
    case types.GET_REFERRALS_SUCCESS:
      return { ...state, loadingReferrals: false, historicReferrals: action.payload, referralsError: undefined };
    case types.GET_REFERRALS_ERROR:
      return { ...state, loadingReferrals: false, referralsError: action.payload };
    case types.CLEAR_CURRENT_MEMBER:
      return { ...state, historicReferrals: [] };
    case types.POST_PHYSICAL_EXAMINATION_REFERRAL_REQUEST:
      return { ...state, postingPhysicalExaminationReferral: true };
    case types.POST_PHYSICAL_EXAMINATION_REFERRAL_SUCCESS:
      return { ...state, postingPhysicalExaminationReferral: false, physicalExaminationReferralError: undefined };
    case types.POST_PHYSICAL_EXAMINATION_REFERRAL_ERROR:
      return { ...state, postingPhysicalExaminationReferral: false, physicalExaminationReferralError: action.payload };
    case types.GET_CLINICS_REQUEST:
      return { ...state, isLoading: true };
    case types.GET_CLINICS_SUCCESS:
      return { ...state, clinics: action.payload, isLoading: false };
    case types.GET_CLINICS_ERROR:
      return { ...state, clinicsError: action.payload, isLoading: false };
    default:
      return state;
  }
};

export default referralsReducer;
