import React, { useState } from 'react';
import { Translate } from 'react-redux-i18n';
import initials from 'initials';
import { getAgeFromPersonalNumber, formatPersonalNumber, decamelize } from '../../../utils';
import LoadingSpinner from '../../LoadingSpinner';
import ChatSearch from '../ChatSearch';
import useClickOutside from '../../../hooks/useClickOutside';
import './ChatHeader.scss';

const messageFilters = [
  {
    labelI18nKey: 'patient_view.chat.filters.pal_message',
    value: 'pal'
  },
  {
    labelI18nKey: 'patient_view.chat.filters.pas_message',
    value: 'pas'
  },
  {
    labelI18nKey: 'patient_view.chat.filters.patient',
    value: 'pat'
  },
  {
    labelI18nKey: 'patient_view.chat.filters.system_message',
    value: 'system'
  },
  {
    labelI18nKey: 'patient_view.chat.filters.file',
    value: 'file'
  },
  {
    labelI18nKey: 'patient_view.chat.filters.image',
    value: 'image'
  }
];

const ChatHeader = ({
  chatMessages,
  showFullSizeImage,
  member,
  highlightMatchedText,
  onToggleFilter,
  activeFilters
}) => {
  const [filterPopoverIsVisible, setFilterPopoverIsVisible] = useState(false);
  const filterPopoverRef = useClickOutside(() => setFilterPopoverIsVisible(false));
  const { givenName, familyName, personalNumber, source, avatar, loadingAvatar, profileImage } = member;
  const previewOptions = {
    size: 'medium',
    headerI18nKey: 'global.profile_image'
  };

  return (
    <div className="chat-header-container">
      <div className="chat-header">
        <div className="patient-image no-padding">
          {avatar ? (
            loadingAvatar ? (
              <span className="profile-image__fallback">
                <LoadingSpinner size="smaller" />
              </span>
            ) : (
              <span data-sensitive>
                <img
                  onClick={() => showFullSizeImage(profileImage.filePath, previewOptions)}
                  src={avatar}
                  alt="Avatar"
                />
              </span>
            )
          ) : (
            <span className="profile-image__fallback">
              {givenName && familyName ? initials(`${givenName} ${familyName}`) : '...'}
            </span>
          )}
        </div>
        <div className="patient-info no-padding">
          {givenName ? (
            <div>
              <strong data-sensitive>
                {givenName} {familyName}
              </strong>
              , {getAgeFromPersonalNumber(personalNumber)} <Translate value="global.years" />
              {source ? <span className={`member-source ${source}`}>{decamelize(source, ' ', false)}</span> : null}
              <div data-sensitive>
                <small>{formatPersonalNumber(personalNumber)}</small>
              </div>
            </div>
          ) : (
            <Translate value="global.loading" />
          )}
        </div>
        <div ref={filterPopoverRef} className="chat-filter">
          {activeFilters.length ? (
            <span className="notice inline mr-5">
              <Translate value="patient_view.chat.chat_filtered_notice" />
            </span>
          ) : null}
          <span
            className={`text-button ${filterPopoverIsVisible ? '' : ''}`}
            onClick={() => setFilterPopoverIsVisible(!filterPopoverIsVisible)}
          >
            <Translate value="patient_view.chat.chat_filter_button" />
            <span className={`ml-5 icon chevron ${filterPopoverIsVisible ? 'rotated' : ''}`}></span>
          </span>
          <div className={`chat-filter-popover ${!filterPopoverIsVisible ? 'popover-hidden' : ''}`}>
            <form>
              {messageFilters.map((filter, i) => (
                <label key={i}>
                  <input
                    type="checkbox"
                    value={filter.value}
                    checked={!activeFilters.includes(filter.value)}
                    onChange={(e) => onToggleFilter(e.target.value)}
                  />
                  <Translate value={filter.labelI18nKey} />
                </label>
              ))}
            </form>
          </div>
        </div>
      </div>
      <ChatSearch chatMessages={chatMessages} onSearchResultClicked={highlightMatchedText} />
    </div>
  );
};

export default ChatHeader;
