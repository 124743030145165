import React, { Component, Fragment } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import InlineComment from '../InlineComment';
import './AttributeResponse.scss';

class AttributeResponse extends Component {
  constructor(props) {
    super(props);

    this.createMarkup = this.createMarkup.bind(this);
  }

  createMarkup(html) {
    return { __html: html };
  }

  render() {
    const { value, severity, showTimestamp } = this.props;

    return (
      <Fragment>
        <div className="attribute-response" data-sensitive>
          {value && value.values && value.values.length
            ? value.values.map((v, i) => {
                return (
                  <p key={i} className={`${severity ? `severity ${severity}` : ''}`}>
                    <span dangerouslySetInnerHTML={this.createMarkup(v)}></span>
                    {value.timestamp && showTimestamp ? (
                      <span className="inline-timestamp">, {moment(value.timestamp).format('YYYY-MM-DD')}</span>
                    ) : null}
                  </p>
                );
              })
            : '-'}
        </div>
        {value &&
          value.comments &&
          value.comments
            .filter((comment) => comment.comment !== null)
            .map((comment, i) => {
              return <InlineComment key={i} comment={comment} />;
            })}
      </Fragment>
    );
  }
}

AttributeResponse.propTypes = {
  value: PropTypes.object.isRequired
};

export default AttributeResponse;
