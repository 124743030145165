import React, { Component, Fragment } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import ReactTooltip from 'react-tooltip';
import ReactTable from 'react-table';
import { ReactTableDefaults } from 'react-table';
import ManageReferrals from '../ManageReferrals';
import 'react-table/react-table.css';
import './LabResultsDataTable.scss';

class LabResultsDataTable extends Component {
  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  render() {
    const {
      labResultData,
      onViewOlder,
      onViewNewer,
      labResultsStartIndex,
      labResultsEndIndex,
      totalLabResultColumns,
      onCheckboxToggle,
      showLabEvaluation,
      referrals,
      riskProfile
    } = this.props;

    return (
      <Fragment>
        <div className="mt-30">
          <h3 className="section-sub-header">
            <Translate value="decision_support_view.lab_data_header" />
          </h3>
          <div className="lab-results-data-table-container">
            <div className="columns mb-0">
              <div className="column is-2 no-padding">
                <span
                  onClick={onViewNewer}
                  className={`current-period-control-arrow left ${labResultsStartIndex > 0 ? 'enabled' : 'disabled'}`}
                >
                  &lsaquo;
                </span>
                <span
                  onClick={onViewOlder}
                  className={`current-period-control-arrow right ${
                    labResultsEndIndex >= totalLabResultColumns || totalLabResultColumns === 0 ? 'disabled' : 'enabled'
                  }`}
                >
                  &rsaquo;
                </span>
              </div>
              <ManageReferrals historicReferrals={referrals} />
            </div>
            <div className="right-align">
              <input
                className="styled-checkbox"
                type="checkbox"
                checked={showLabEvaluation}
                onChange={onCheckboxToggle}
                id="showEvaluationCheckbox"
              />
              <label htmlFor="showEvaluationCheckbox">
                <Translate value="decision_support_view.show_lab_evaluation" />
              </label>
            </div>
            <div className="lab-results-data-table-container">
              <ReactTable
                showPagination={false}
                pageSize={labResultData.labResults && labResultData.labResults.length}
                columns={[
                  ...labResultData.fixedColumns,
                  ...labResultData.labResultColumns.slice(labResultsStartIndex, labResultsEndIndex)
                ]}
                data={labResultData.labResults}
                column={{
                  ...ReactTableDefaults.column,
                  className: 'lab-result-cell',
                  headerClassName: 'lab-result-header'
                }}
                noDataText={I18n.t('decision_support_view.no_lab_results')}
                minRows={5}
              />
              <div className="footnote mukta mb-20">
                <sup>1</sup>
                <Translate value="decision_support_view.goal_value_footnote" />
                {riskProfile?.risk && riskProfile.risk[0] && !riskProfile.risk[0].severity ? (
                  <div>
                    <strong>OBS! {riskProfile.risk[0].value.values[0]}</strong>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <ReactTooltip multiline={true} effect="solid" className="custom-tooltip" />
      </Fragment>
    );
  }
}

export default LabResultsDataTable;
