import React, { Component } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import {
  DIASTOLIC_MIN_VALUE,
  DIASTOLIC_MAX_VALUE,
  SYSTOLIC_MIN_VALUE,
  SYSTOLIC_MAX_VALUE,
  AUTHORITY
} from '../../../../../constants';
import './BloodPressureGoalRow.scss';
import HasAuthority from '../../../../HasAuthority';

class BloodPressureGoalRow extends Component {
  constructor(props) {
    super(props);

    this.editGoals = this.editGoals.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.setNewSystolicGoal = this.setNewSystolicGoal.bind(this);
    this.setNewDiastolicGoal = this.setNewDiastolicGoal.bind(this);
    this.saveNewGoals = this.saveNewGoals.bind(this);

    this.state = {
      editingGoals: false,
      newSystolic: '',
      newDiastolic: ''
    };
  }

  editGoals() {
    this.setState({
      editingGoals: true
    });
  }

  cancelEdit() {
    this.setState({
      editingGoals: false
    });
  }

  setNewSystolicGoal(e) {
    let newValue = e.target.value;

    if (newValue.length) {
      newValue = parseInt(newValue, 10);
    }

    if (!isNaN(newValue)) {
      this.setState({
        newSystolic: newValue
      });
    }
  }

  setNewDiastolicGoal(e) {
    let newValue = e.target.value;

    if (newValue.length) {
      newValue = parseInt(newValue, 10);
    }

    if (!isNaN(newValue)) {
      this.setState({
        newDiastolic: newValue
      });
    }
  }

  saveNewGoals() {
    if (this.state.newDiastolic > DIASTOLIC_MAX_VALUE || this.state.newDiastolic < DIASTOLIC_MIN_VALUE) {
      return;
    }

    if (this.state.newSystolic > SYSTOLIC_MAX_VALUE || this.state.newSystolic < SYSTOLIC_MIN_VALUE) {
      return;
    }

    if (this.state.newSystolic && this.state.newDiastolic) {
      this.props.setSystolicGoal(this.state.newSystolic);
      this.props.setDiastolicGoal(this.state.newDiastolic);

      this.cancelEdit();
    }
  }

  render() {
    const { goalSystolic, goalDiastolic } = this.props;

    return (
      <div className="row-container">
        <div className="columns table-row">
          <div className="column row-response is-4">
            <Translate value="decision_support_view.treatment_goals_section.bp_goal_lead_text" />
          </div>
          <div className="column row-category is-7">
            {this.state.editingGoals ? (
              <div className="goals-edit-container">
                <div className="columns">
                  <div className="column no-padding">
                    <input
                      className={`${
                        this.state.newSystolic !== '' &&
                        (this.state.newSystolic > SYSTOLIC_MAX_VALUE || this.state.newSystolic < SYSTOLIC_MIN_VALUE)
                          ? 'goal-invalid'
                          : ''
                      }`}
                      type="text"
                      placeholder={goalSystolic}
                      value={this.state.newSystolic}
                      onChange={this.setNewSystolicGoal}
                    />
                  </div>
                  <div className="column no-padding is-1">/</div>
                  <div className="column no-padding">
                    <input
                      className={`${
                        this.state.newDiastolic !== '' &&
                        (this.state.newDiastolic > DIASTOLIC_MAX_VALUE || this.state.newDiastolic < DIASTOLIC_MIN_VALUE)
                          ? 'goal-invalid'
                          : ''
                      }`}
                      type="text"
                      placeholder={goalDiastolic}
                      value={this.state.newDiastolic}
                      onChange={this.setNewDiastolicGoal}
                    />
                  </div>
                  <div className="column no-padding goal-edit-action edit-goals-actions vertical-align">
                    <span className="text-button" onClick={this.saveNewGoals}>
                      OK
                    </span>
                  </div>
                  {/* <div className="column no-padding goal-edit-action edit-goals-actions"><a onClick={this.cancelEdit}><Translate value="global.buttons.cancel" /></a></div> */}
                </div>
              </div>
            ) : (
              <div>
                <span>
                  &lt;
                  {` ${goalSystolic || '-'}/${goalDiastolic || '-'}`}
                </span>
                {/* <div>
                    <a onClick={this.editGoals}>Redigera</a>
                  </div> */}
              </div>
            )}
          </div>
          <div className="column is-1">
            <div className="row-actions">
              <HasAuthority authority={AUTHORITY.CAREGIVER}>
                <span
                  className="pointer"
                  title={I18n.t('decision_support_view.treatment_goals_section.edit_bp_goal')}
                  onClick={this.editGoals}
                >
                  <span className="icons pen"></span>
                </span>
              </HasAuthority>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BloodPressureGoalRow;
