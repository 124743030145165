import { useEffect } from 'react';
import { Translate } from 'react-redux-i18n';
import moment from 'moment';
import { decamelize } from '../../../../utils';
import { ReactComponent as PlayIcon } from '../../../../assets/icons/play.svg';
import { ReactComponent as StopIcon } from '../../../../assets/icons/stop.svg';
import useAudioFileVisualization from '../../../../hooks/useAudioFileVisualization';
import {
  DATE_FORMAT,
  HEART_AUSCULTATION_DURATION,
  LUNGS_AUSCULTATION_DURATION,
  LUNGS_AUSCULTATION_DURATION_CHANGE_DATE,
  LUNGS_AUSCULTATION_DURATION_SHORT
} from '../../../../constants';

const AuscultationRecording = ({ isPlaying, type, location, order, base64Data, onPlay, onStop, recordingDate }) => {
  const { canvasRef, setAudioFile } = useAudioFileVisualization();
  const hasRecording = !!base64Data;

  useEffect(() => {
    fetch(base64Data)
      .then((response) => response.blob())
      .then((blob) => {
        setAudioFile(blob);
      });
  }, []);

  return (
    <div className="auscultation-recording">
      <h4>
        {order}. <Translate value={`patient_view.notes.examination.auscultation.${type}.${decamelize(location)}`} />
      </h4>
      <div className="player">
        {hasRecording ? (
          <div className="controls">{!isPlaying ? <PlayIcon onClick={onPlay} /> : <StopIcon onClick={onStop} />}</div>
        ) : null}
        {hasRecording ? (
          <div className="canvas-wrapper">
            <div
              className={`progress ${isPlaying ? 'playing' : ''}`}
              style={{
                '--recordingDuration': `${
                  type === 'heart'
                    ? HEART_AUSCULTATION_DURATION
                    : moment(recordingDate, DATE_FORMAT).isSameOrAfter(moment(LUNGS_AUSCULTATION_DURATION_CHANGE_DATE))
                    ? LUNGS_AUSCULTATION_DURATION_SHORT
                    : LUNGS_AUSCULTATION_DURATION
                }s`
              }}
            ></div>
            <canvas ref={canvasRef} height={50} />
          </div>
        ) : (
          <div>
            <Translate value="patient_view.notes.examination.auscultation.no_recording" />
          </div>
        )}
      </div>
    </div>
  );
};

export default AuscultationRecording;
