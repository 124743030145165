import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { Link } from 'react-router-dom';
import NewMemberRow from '../InboxView/NewMemberRow';
import SkeletonComponent from '../SkeletonComponent';
import { getNewMembers, assignResponsibleDoctor, clearAssignedMembers } from '../../actions';
import { APPLICATION_BASE_PATH } from '../../constants';
import TopBar from '../TopBar';
import UnauthorizedAccessWarning from '../UnauthorizedAccessWarning';
import { decamelize } from '../../utils';
import { Mixpanel } from '../../utils/mixpanel';

const UnassignedPatientsView = ({
  authToken,
  isNurse,
  newMembers,
  history,
  assignResponsibleDoctor,
  getNewMembers,
  clearAssignedMembers
}) => {
  useEffect(() => {
    Mixpanel.resetSuperProperties();
    getNewMembers(authToken, isNurse);

    return () => clearAssignedMembers();
  }, []);

  const selfAssignResponsibleDoctor = (memberGuid) => {
    return (e) => {
      e.preventDefault();
      e.stopPropagation();
      assignResponsibleDoctor(authToken, memberGuid, isNurse);
    };
  };

  const navigateToMember = (member) => {
    return () => {
      history.push(`/${APPLICATION_BASE_PATH}/member/${member.guid}`);
    };
  };

  return (
    <>
      <UnauthorizedAccessWarning ownPatients={newMembers.selfAssigned} />
      <TopBar header="unassigned_patients_view.header" />
      <div className="wrapper member-row-container">
        <div className="mb-30">
          <Link to={`/${APPLICATION_BASE_PATH}`}>
            <span className="text-button chevron-left">
              <Translate value="global.back_to_inbox" />
            </span>
          </Link>
        </div>
        <div className="columns errand-headings">
          <div className="column is-3">
            <Translate value="global.name" />
          </div>
          <div className="column is-2">
            <Translate value="inbox_view.registration_date" />
          </div>
          <div className="column is-2">
            <Translate value="global.source" />
          </div>
          <div className="column wait-time-column">
            <Translate value="inbox_view.wait_time" />
          </div>
          <div className="column new-member-status-column">
            <Translate value="member_list_view.column_headers.status" />
          </div>
        </div>

        {!newMembers.unassigned.length && newMembers.isLoading ? (
          <>
            {new Array(8).fill(0).map((_, i) => (
              <SkeletonComponent key={i} width="100%" height="74px" />
            ))}
          </>
        ) : newMembers.unassigned.length > 0 ? (
          <div>
            {newMembers.unassigned.map((member) => {
              return (
                <NewMemberRow
                  onClick={navigateToMember(member)}
                  key={member.guid}
                  member={member}
                  icons={member.assignmentReadyReason?.map((reason) => `reason-${decamelize(reason, '-')}`)}
                  assignedMembers={newMembers.selfAssigned}
                  onAssign={selfAssignResponsibleDoctor(member.guid)}
                  isAssigning={newMembers.assigningIds.indexOf(member.guid) > -1}
                />
              );
            })}
          </div>
        ) : (
          <div className="no-table-rows">
            <Translate value="unassigned_patients_view.empty" />
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    newMembers: state.newMembers,
    isNurse: state.auth.isNurse
  };
};

const mapActionsToProps = {
  getNewMembers,
  assignResponsibleDoctor,
  clearAssignedMembers
};

export default connect(mapStateToProps, mapActionsToProps)(UnassignedPatientsView);
