import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { NavLink, Link } from 'react-router-dom';
import { Translate, setLocale } from 'react-redux-i18n';
import { APPLICATION_BASE_PATH, AUTHORITY, MAIN_MENU_COLLAPSE_STATE_KEY } from '../../constants';
import { showCeModal, getCaregiverProfileImage, toggleCollapseMainMenu } from '../../actions';
import { initials } from '../../utils';
import logo from '../../assets/accumbo-heart.svg';
import './Sidebar.scss';
import ProfileImageUploadModal from '../ProfileImageUploadModal';
import { saveState } from '../../services/localStorage';
import HasAuthority from '../HasAuthority';
import { useInbox } from '../../contexts/InboxProvider';

const Sidebar = ({
  events,
  locale,
  user,
  ui,
  authorities,
  setLocale,
  showCeModal,
  authToken,
  getCaregiverProfileImage,
  toggleCollapseMainMenu,
  assessments
}) => {
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [profileImageUploadVisible, setProfileImageUploadVisible] = useState(false);
  const { numActiveErrands, lastVisitedTab } = useInbox();

  const handleInboxIconActive = (match, location) => {
    return (
      location.pathname === `/${APPLICATION_BASE_PATH}` ||
      location.pathname.startsWith(`/${APPLICATION_BASE_PATH}/member/`)
    );
  };

  const handleUnhandledIconActive = (match, location) => {
    return location.pathname.indexOf('unhandled') > -1;
  };

  const handleReferralIconActive = (match, location) => {
    return location.pathname.indexOf('referrals/') > -1;
  };

  const handleInactiveIconActive = (match, location) => {
    return location.pathname.indexOf('inactive-members') > -1;
  };

  const handleKioskIconActive = (match, location) => {
    return location.pathname.indexOf('kiosk') > -1;
  };

  const handleAuscultationIconActive = (match, location) => {
    return location.pathname.indexOf('auscultation') > -1;
  };

  const handleAssessmentIconActive = (match, location) => {
    return location.pathname.includes('inbox');
  };

  const handleSearchIconActive = (match, location) => {
    return location.pathname.includes('search');
  };

  const toggleLocale = () => {
    if (locale === 'en') {
      setLocale('sv');
    } else {
      setLocale('en');
    }

    window.location.reload();
  };

  const toggleMenu = () => {
    toggleCollapseMainMenu();
    saveState(!ui.mainMenuCollapsed, MAIN_MENU_COLLAPSE_STATE_KEY);
  };

  return (
    <>
      <div className={`main-menu ${ui.mainMenuCollapsed ? 'menu-collapsed' : ''}`}>
        <div
          className={`dropdown-overlay ${profileMenuOpen ? 'dropdown-overlay__active' : ''}`}
          onClick={() => setProfileMenuOpen(false)}
        ></div>
        <div className="logo">
          <NavLink to={`/${APPLICATION_BASE_PATH}`} className="logo-link">
            <img src={logo} alt="Accumbo-logga" className="sidebar__brand-logo" />
          </NavLink>
        </div>
        <ul className="menu-links">
          <li>
            <SidebarIconBadge count={events.viewingAllEvents ? events.allEventCount : events.ownEventCount} />
            <NavLink
              to={`/${APPLICATION_BASE_PATH}`}
              className="menu-link--inbox"
              activeClassName="menu-link--selected"
              isActive={handleInboxIconActive}
            >
              <Translate value="sidebar.menu_option.inbox" />
            </NavLink>
          </li>
          <HasAuthority authority={AUTHORITY.MEDICAL_MANAGEMENT}>
            <li>
              <SidebarIconBadge count={events.unhandledEventsCount} />
              <NavLink
                to={`/${APPLICATION_BASE_PATH}/unhandled`}
                className="menu-link--unhandled"
                activeClassName="menu-link--selected"
                isActive={handleUnhandledIconActive}
              >
                <Translate value="sidebar.menu_option.unhandled" />
              </NavLink>
            </li>
          </HasAuthority>
          <li>
            <NavLink
              exact
              to={`/${APPLICATION_BASE_PATH}/members`}
              className="menu-link--patient-list"
              activeClassName="menu-link--selected"
            >
              <Translate value="sidebar.menu_option.active_patients_list" />
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to={`/${APPLICATION_BASE_PATH}/referrals/all-referrals`}
              className="menu-link--referrals"
              activeClassName="menu-link--selected"
              isActive={handleReferralIconActive}
            >
              <Translate value="sidebar.menu_option.referrals" />
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to={`/${APPLICATION_BASE_PATH}/inactive-members`}
              className="menu-link--inactive"
              activeClassName="menu-link--selected"
              isActive={handleInactiveIconActive}
            >
              <Translate value="sidebar.menu_option.inactive_patients_list" />
            </NavLink>
          </li>
          <HasAuthority authority={[AUTHORITY.NURSE, AUTHORITY.CAREGIVER]}>
            <li>
              <SidebarIconBadge count={assessments.totalRemainingKioskAssessments} />
              <NavLink
                exact
                to={`/${APPLICATION_BASE_PATH}/kiosk`}
                className="menu-link--kiosk"
                activeClassName="menu-link--selected"
                isActive={handleKioskIconActive}
              >
                <Translate value="sidebar.menu_option.kiosk_review" />
              </NavLink>
            </li>
          </HasAuthority>
          <HasAuthority authority={AUTHORITY.HANDLE_AUSCULTATION}>
            <li>
              <SidebarIconBadge count={assessments.auscultationAssessments.length} />
              <NavLink
                exact
                to={`/${APPLICATION_BASE_PATH}/auscultation-assessments`}
                className="menu-link--auscultations"
                activeClassName="menu-link--selected"
                isActive={handleAuscultationIconActive}
              >
                <Translate value="sidebar.menu_option.auscultation_assessments" />
              </NavLink>
            </li>
          </HasAuthority>
          <HasAuthority authority={AUTHORITY.HANDLE_NEXT_GEN_PATIENTS}>
            <li>
              <SidebarIconBadge count={numActiveErrands} />
              <NavLink
                exact
                to={`/${APPLICATION_BASE_PATH}/inbox${lastVisitedTab === 'appointments' ? '/appointments' : ''}`}
                className="menu-link--inbox"
                activeClassName="menu-link--selected"
                isActive={handleAssessmentIconActive}
              >
                <Translate value="sidebar.menu_option.my_patients" />
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to={`/${APPLICATION_BASE_PATH}/search`}
                className="menu-link--search"
                activeClassName="menu-link--selected"
                isActive={handleSearchIconActive}
              >
                <Translate value="sidebar.menu_option.search" />
              </NavLink>
            </li>
          </HasAuthority>
        </ul>

        <div className="lower-menu">
          <div className="menu-toggler">
            <span className="pointer" onClick={toggleMenu}>
              {ui.mainMenuCollapsed ? '»' : '«'}
            </span>
          </div>
          <div
            className={`dropdown is-up ${profileMenuOpen ? 'is-active' : ''}`}
            id="profile-dropdown"
            onClick={() => setProfileMenuOpen(!profileMenuOpen)}
          >
            <div className="dropdown-trigger">
              {user.profileImage && !ui.profileImageError ? (
                <img src={ui.profileImageUrl} />
              ) : (
                <div>{user && initials(user.givenName, user.familyName)}</div>
              )}
            </div>
            <div className="dropdown-menu" id="dropdown-menu7" role="menu">
              <div className="dropdown-content">
                <div className="dropdown-item dropdown-header">
                  <div>
                    <strong>{user && `${user.givenName} ${user.familyName}`}</strong>
                    {user &&
                    (authorities.indexOf(AUTHORITY.CAREGIVER) > -1 ||
                      authorities.indexOf(AUTHORITY.HEALTH_SCIENTIST) > -1 ||
                      authorities.indexOf(AUTHORITY.NURSE) > -1) ? (
                      <p>
                        <Translate
                          value={`sidebar.role.${
                            authorities.indexOf(AUTHORITY.CAREGIVER) > -1
                              ? AUTHORITY.CAREGIVER
                              : authorities.indexOf(AUTHORITY.NURSE) > -1
                              ? AUTHORITY.NURSE
                              : AUTHORITY.HEALTH_SCIENTIST
                          }`}
                        />
                      </p>
                    ) : null}
                  </div>
                </div>
                <hr className="dropdown-divider" />
                <div className="dropdown-item menu-item">
                  <a
                    href="https://drive.google.com/file/d/1T4zPPZtZ08bgrfg5V_j7jdHaUHTxS70U/view"
                    className="external"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Translate value="sidebar.tutorial" />
                  </a>
                </div>
                <div className="dropdown-item menu-item">
                  <a
                    href="https://blodtrycksdoktorn.se/internt/uppdateringar-i-clinic/"
                    className="external"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Translate value="sidebar.release_notes" />
                  </a>
                </div>
                <div className="dropdown-item menu-item">
                  <a
                    href="https://docs.google.com/document/d/1i1n56-n9DRcvK8L8Lud6DchWpWqY4nrPblUP5lzVRDk"
                    className="external"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Translate value="sidebar.faq_clinic" />
                  </a>
                </div>
                <div className="dropdown-item menu-item">
                  <a
                    href="https://docs.google.com/document/d/17beT9qerx7yH-q73Y6QzbU3rCICQ52nry8dYR5wvCKE"
                    className="external"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Translate value="sidebar.faq_medical" />
                  </a>
                </div>
                <div className="dropdown-item menu-item">
                  <a
                    href="https://docs.google.com/document/d/1Tr0yfit7H26venGwAdbns8vlzS-keodDEd9Mslhy80I"
                    className="external"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Translate value="sidebar.faq_support" />
                  </a>
                </div>
                <div className="dropdown-item menu-item">
                  <span onClick={toggleLocale}>
                    <Translate value="global.buttons.switch_language" />
                  </span>
                </div>
                <div className="dropdown-item menu-item" onClick={() => setProfileImageUploadVisible(true)}>
                  <Translate value="sidebar.change_profile_picture" />
                </div>
                <div className="dropdown-item menu-item" onClick={showCeModal}>
                  <Translate value="about_clinic.header" />
                </div>
                <div className="dropdown-item menu-item">
                  <Link to={`/${APPLICATION_BASE_PATH}/logout`}>
                    <Translate value="sidebar.logout" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProfileImageUploadModal
        user={user}
        isVisible={profileImageUploadVisible}
        onClose={() => setProfileImageUploadVisible(false)}
        authToken={authToken}
        memberGuid={user.guid}
        getProfileImage={getCaregiverProfileImage}
        hasProfileImage={user.profileImage && !ui.profileImageError}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    user: state.auth.token.user,
    events: state.events,
    locale: state.i18n.locale,
    authorities: state.auth.authorities,
    ui: state.ui,
    assessments: state.assessments
  };
};

const mapActionsToProps = {
  setLocale,
  showCeModal,
  getCaregiverProfileImage,
  toggleCollapseMainMenu
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(Sidebar));

const SidebarIconBadge = ({ count }) => {
  if (!count) {
    return null;
  }

  return (
    <span className="sidebar-notification-container">
      <span className="sidebar-notification">{count.toLocaleString()}</span>
    </span>
  );
};
