import { Translate } from 'react-redux-i18n';

const AssessmentRowHeadings = () => {
  return (
    <div className="columns errand-headings">
      <div className="column">
        <Translate value="global.name" />
      </div>
      <div className="column">
        <Translate value="global.updated" />
      </div>
      <div className="column">
        <Translate value="global.source" />
      </div>
      <div className="column">
        <Translate value="global.reason" />
      </div>
    </div>
  );
};

export default AssessmentRowHeadings;
