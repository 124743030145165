import React, { Component } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import LoaderButton from '../../LoaderButton';
import WaitTime from '../../WaitTime';
import { getAgeFromPersonalNumber, configuredMoment as moment } from '../../../utils';
import { DATE_FORMAT } from '../../../constants';

class NewMemberRow extends Component {
  render() {
    const { member, assignedMembers, onAssign, isAssigning, onClick, icons = [] } = this.props;
    const isAssigned = assignedMembers.includes(member.guid);
    const hasMessage = member.assignmentReadyReason.includes('chatMessage');

    if (isAssigned && hasMessage) {
      /**
       * If the patient has written a chat message prior to assignment we navigate to the patient view automatically.
       * This is so that nurses don't forget to respond to the message.
       */
      onClick();
    }

    return (
      <div className="member-row new-member-row" onClick={onClick}>
        <div className="columns errand-item">
          <div className="column is-3">
            <strong data-sensitive>{`${member.givenName} ${member.familyName}`}</strong>,{' '}
            {getAgeFromPersonalNumber(member.personalNumber)} <Translate value="global.years" />
          </div>
          <div className="column is-2 mukta">{moment(member.createdDate, DATE_FORMAT).format('YYYY-MM-DD')}</div>
          <div className="column is-2 mukta">{member.source || '-'}</div>
          <div className="column wait-time-column mukta">
            <WaitTime sinceTimestamp={member.waitTimeStart} />
          </div>
          <div
            className="column new-member-status-column mukta vertical-align"
            title={member.pendingDiagnosis ? I18n.t('unassigned_patients_view.undiagnosed_title') : undefined}
          >
            <Translate
              value={`unassigned_patients_view.status.${
                member.responsibleDoctor ? 'old' : member.pendingDiagnosis ? 'new_undiagnosed' : 'new'
              }`}
            />
            {!member.caregivers.length
              ? icons.map((icon) => <span key={`reason-icon-${icon}`} className={`icons ${icon}`}></span>)
              : null}
          </div>
          <div className="column is-1 right-align pt-0 pb-0">
            {isAssigned ? (
              <span className="checkmark"></span>
            ) : (
              <LoaderButton
                buttonI18nKey={`inbox_view.self_assign${hasMessage ? '_and_navigate' : ''}`}
                isLoading={isAssigning}
                onClick={onAssign}
                disabled={isAssigning}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default NewMemberRow;
