import React, { Component } from 'react';
import Draggable from 'react-draggable';
import { Translate } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import LoaderButton from '../LoaderButton';
import './ModalDialog.scss';

class ModalDialog extends Component {
  constructor(props) {
    super(props);

    this.keydownListener = this.keydownListener.bind(this);
  }

  keydownListener(e) {
    if (e.keyCode === 27 && this.props.visible) {
      // ESC
      this.props.onClose();
    }

    if (e.key === 'Enter' && this.props.actionCompletable && this.props.allowEnterCompletion) {
      this.props.onActionCompleted();
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.keydownListener);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.keydownListener);
  }

  render() {
    const {
      children,
      headerI18nKey,
      actionI18nKey,
      header,
      subHeader,
      visible,
      onClose,
      onActionCompleted,
      onGoBack,
      actionCompletable,
      actionCompleting,
      onDestructiveActionCompleted,
      destructiveActionCompleting,
      destructiveActionI18nKey,
      size,
      position,
      noOverlay,
      onAuxiliaryAction,
      auxiliaryIcon,
      contentClass,
      sizeClass,
      hasPrintAction,
      showConfirmCheck,
      confirmChecked,
      onToggleConfirm,
      opaque,
      onlyRenderVisible
    } = this.props;

    if (!visible && onlyRenderVisible) {
      return null;
    }

    return (
      <div className={`modal ${visible ? 'is-active' : ''} ${noOverlay ? 'pointer-events-none' : ''}`}>
        {!noOverlay ? <div className={`modal-background ${opaque ? 'opaque' : ''}`} onClick={onClose}></div> : null}
        <Draggable handle=".handle">
          <div
            className={`modal-content ${size ? `modal-content__${size}` : ''} ${
              onActionCompleted ? 'has-action' : ''
            } ${sizeClass || ''} ${position === 'topright' ? 'modal-top-right' : ''}`}
          >
            <div className="handle"></div>
            <div className="columns modal-content__header vertical-align">
              {onGoBack ? (
                <div className="column no-padding back-button" onClick={onGoBack}>
                  <Translate value="global.back" />
                </div>
              ) : null}
              <div className="column is-9 no-padding">
                <h3>{headerI18nKey ? <Translate value={headerI18nKey} /> : header}</h3>
                {subHeader ? <h4>{subHeader}</h4> : null}
              </div>
              <div className="column no-padding">
                {onClose ? <button className="modal-close-button" aria-label="close" onClick={onClose}></button> : null}
              </div>
            </div>
            <div className={`modal-content__children ${contentClass || ''}`}>
              <div ref={(el) => (this.childrenRef = el)}>{children}</div>
            </div>
            {onActionCompleted || hasPrintAction ? (
              <div className="modal-content__footer">
                <div className="columns">
                  <div className="column is-5 no-padding">
                    {showConfirmCheck && onActionCompleted ? (
                      <div>
                        <input
                          id="signed-confirm"
                          className="styled-checkbox"
                          type="checkbox"
                          checked={confirmChecked}
                          onChange={onToggleConfirm}
                        />
                        <label htmlFor="signed-confirm">
                          <Translate value="global.document_is_signed" />
                        </label>
                      </div>
                    ) : null}
                    {onDestructiveActionCompleted ? (
                      <div className="modal-action__destructive">
                        {destructiveActionCompleting !== undefined ? (
                          <LoaderButton
                            buttonI18nKey={destructiveActionI18nKey}
                            isLoading={actionCompleting}
                            onClick={onDestructiveActionCompleted}
                            disabled={destructiveActionCompleting || actionCompleting}
                            isDestructive={true}
                          />
                        ) : (
                          <button
                            className="button is-danger"
                            onClick={onDestructiveActionCompleted}
                            disabled={actionCompleting}
                          >
                            <Translate value={destructiveActionI18nKey} />
                          </button>
                        )}
                      </div>
                    ) : null}
                  </div>
                  <div className="column no-padding">
                    <div className="columns">
                      {onAuxiliaryAction ? (
                        <div className="column is-8 no-padding auxiliary-action">
                          <div className={`icon-${auxiliaryIcon}`} onClick={onAuxiliaryAction}></div>
                        </div>
                      ) : null}
                      <div className="column no-padding">
                        <div className="modal-action">
                          {onActionCompleted ? (
                            <LoaderButton
                              buttonI18nKey={actionI18nKey || headerI18nKey || 'global.ok'}
                              isLoading={actionCompleting !== undefined ? actionCompleting : false}
                              onClick={onActionCompleted}
                              disabled={
                                !actionCompletable ||
                                actionCompleting ||
                                destructiveActionCompleting ||
                                (showConfirmCheck && !confirmChecked)
                              }
                            />
                          ) : null}
                          {hasPrintAction ? (
                            <ReactToPrint
                              trigger={() => (
                                <button className="button is-primary ml-20">
                                  <Translate value="global.print" />
                                </button>
                              )}
                              content={() => this.childrenRef}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </Draggable>
      </div>
    );
  }
}

const requiredPropsCheck = (props, propName, componentName) => {
  if (!props.header && !props.headerI18nKey) {
    return new Error(`One of 'header' or 'headerI18nKey' is required by '${componentName}' component.`);
  }
};

ModalDialog.propTypes = {
  children: PropTypes.node,
  onActionCompleted: PropTypes.func,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  headerI18nKey: requiredPropsCheck,
  header: requiredPropsCheck,
  actionI18nKey: PropTypes.string,
  actionCompletable: PropTypes.bool,
  actionCompleting: PropTypes.bool,
  onDestructiveActionCompleted: PropTypes.func,
  destructiveActionCompleting: PropTypes.bool,
  destructiveActionI18nKey: PropTypes.string,
  size: PropTypes.oneOf(['large', 'medium', 'small', 'slim', 'wide', 'medium-small', 'x-small']),
  position: PropTypes.oneOf(['topright']),
  noOverlay: PropTypes.bool,
  onAuxiliaryAction: PropTypes.func,
  auxiliaryIcon: PropTypes.string,
  onlyRenderVisible: PropTypes.bool
};

export default ModalDialog;
