import { Translate } from 'react-redux-i18n';
import HasAuthority from '../../HasAuthority';
import { AUTHORITY } from '../../../constants';

const RequestedPrescription = ({ memberHasRequestedPrescription, memberPrescriptionRequestAnswer }) => {
  if (!memberHasRequestedPrescription) {
    return null;
  }

  return (
    <HasAuthority authority={AUTHORITY.CAREGIVER}>
      <span className="alert">
        <Translate
          value="patient_view.medications.patient_requested_prescription"
          medicineName={memberPrescriptionRequestAnswer}
        />
      </span>
    </HasAuthority>
  );
};

export default RequestedPrescription;
