import * as types from '../constants/actionTypes';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { CAREGIVER_ROLE, HTTP_STATUS_CODES } from '../constants';
import { removeItemFromArray, flattenChatResponses, updateObjectInArray, toggleItem } from '../utils';
import { FREE_TEXT_TEMPLATE_TAG, FREE_TEXT_TEMPLATE_TAG_REPLACEMENT } from '../constants';

momentDurationFormatSetup(moment);

const initialState = {
  message: '',
  error: null,
  postMessageError: null,
  isConflict: false,
  chatModalActive: false,
  chatMessages: [],
  isFetching: false,
  isFetchingInitial: null,
  canSendMessage: true,
  countdownText: null,
  showDraftActions: true,
  editDraftModalActive: false,
  originalDraftText: null,
  newDraftText: null,
  originalDraft: null,
  draftWillBeSentAt: null,
  messageType: 'draft',
  nudgeDisabled: true,
  chatResponses: {
    original: [],
    flattened: [],
    allResponses: []
  },
  chatResponseMenuOpen: false,
  responseType: 'manual',
  hasEditedDraft: false,
  messageFilters: [],
  hasDoctorFinalNote: false,
  hasEncounterFeedbackDraft: false
};

const patientChatReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CHAT_MESSAGES_REQUEST:
      return { ...state, isFetching: true, isFetchingInitial: state.isFetchingInitial !== null ? false : true };
    case types.GET_CHAT_MESSAGES_SUCCESS: {
      let chatMessages;
      let oldChatMessages = [...state.chatMessages];

      const receivedMessages = action.payload.messages.map((message) => {
        return { ...message, ...(action.payload.authors || []).filter((author) => author.guid === message.author)[0] };
      });

      chatMessages = receivedMessages;

      chatMessages = chatMessages.map((message, i) => {
        return {
          ...oldChatMessages[i],
          ...message
        };
      });

      const hasExistingDraft = action.payload.messages.filter((message) =>
        message.type.toLowerCase().includes('draft')
      ).length;
      let draftMessage = state.originalDraft;

      if (hasExistingDraft) {
        draftMessage = action.payload.messages.filter((message) => message.type.toLowerCase().includes('draft'))[0];
      }

      let countdownText;
      let draftWillBeSentAt = state.draftWillBeSentAt;

      if (draftMessage) {
        const draftMessageIndex = chatMessages.map((message) => message.id).indexOf(draftMessage.id);
        if (draftMessageIndex > -1) {
          chatMessages = removeItemFromArray(chatMessages, { index: draftMessageIndex });
        }

        chatMessages = [...chatMessages, draftMessage];
        draftWillBeSentAt = state.draftWillBeSentAt || moment(draftMessage.timestamp).add(2, 'm');
        countdownText = state.countdownText || getCountdownI18n(moment.duration(draftWillBeSentAt.diff(moment())));
      }

      return {
        ...state,
        chatMessages,
        canSendMessage:
          !chatMessages.some((message) => message.type.toLowerCase().includes('draft')) && !state.isPostingChatMessage,
        draftWillBeSentAt,
        countdownText: state.editDraftModalActive ? state.countdownText : countdownText,
        nudgeDisabled:
          !state.hasDoctorFinalNote || chatMessages.some((message) => message.type === 'nudge' && !message.memberRead),
        isFetching: false,
        isFetchingInitial: false,
        hasEncounterFeedbackDraft: chatMessages.some((message) => message.type === 'encFeedbackDraft'),
        error: undefined
      };
    }
    case types.GET_CHAT_MESSAGES_ERROR:
      return { ...state, isFetching: false, error: action.payload };
    case types.POST_CHAT_MESSAGE_REQUEST:
      return { ...state, canSendMessage: false, canSendDraftUpdate: false, isPostingChatMessage: true };
    case types.POST_CHAT_MESSAGE_SUCCESS: {
      let currentChatMessages = state.chatMessages;

      // Remove any existing draft first
      const existingDraft = currentChatMessages.filter((message) => message.type.toLowerCase().includes('draft'));
      if (existingDraft.length) {
        const draftIndex = currentChatMessages.map((message) => message.id).indexOf(existingDraft[0].id);
        currentChatMessages = removeItemFromArray(currentChatMessages, { index: draftIndex });
      }

      if (!state.chatMessages.some((message) => message.id === action.payload.id) && !state.isFetching) {
        if (action.payload.type === 'file' && existingDraft.length) {
          currentChatMessages = [
            ...currentChatMessages,
            { ...action.payload, name: action.authorName },
            existingDraft[0]
          ];
        } else if (action.payload.id) {
          currentChatMessages = [
            ...currentChatMessages,
            {
              ...action.payload,
              name: action.authorName,
              caregiverRole: action.isNurse ? 'nurse' : 'doctor',
              author: action.payload.author || action.authorGuid
            }
          ];
        }
      }

      return {
        ...state,
        message: action.isFile ? state.message : '',
        chatModalActive: false,
        draftWillBeSentAt:
          action.payload.type !== 'file' ? moment(action.payload.timestamp).add(2, 'm') : state.draftWillBeSentAt,
        countdownText:
          action.payload.type !== 'file'
            ? getCountdownI18n(moment.duration(moment(action.payload.timestamp).add(2, 'm').diff(moment())))
            : state.countdownText,
        showDraftActions: true,
        chatMessages: currentChatMessages,
        canSendMessage: !currentChatMessages.some((message) => message.type.toLowerCase().includes('draft')),
        editDraftModalActive: false,
        originalDraftText: null,
        newDraftText: null,
        originalDraft: null,
        postMessageError: undefined,
        hasEditedDraft: false,
        isPostingChatMessage: false,
        draftEncounterId: undefined
      };
    }
    case types.POST_CHAT_MESSAGE_ERROR: {
      const isConflict = action.payload && action.payload.status === HTTP_STATUS_CODES.CONFLICT;
      return {
        ...state,
        editDraftModalActive: false,
        postMessageError: action.payload,
        isConflict,
        canSendMessage: !state.chatMessages.some((message) => message.type.toLowerCase().includes('draft')),
        canSendDraftUpdate: !state.chatMessages.some((message) => message.type.toLowerCase().includes('draft')),
        isPostingChatMessage: false
      };
    }
    case types.CLEAR_CHAT_MESSAGES:
      return initialState;
    case types.DELETE_CHAT_DRAFT_REQUEST:
      return { ...state, deletingDraft: true };
    case types.DELETE_CHAT_DRAFT_SUCCESS: {
      const deletedDraftIndex = state.chatMessages.map((message) => message.id).indexOf(action.payload.messageId);

      return {
        ...state,
        draftWillBeSentAt: null,
        chatMessages: action.payload.removeFromUI
          ? removeItemFromArray(state.chatMessages, { index: deletedDraftIndex })
          : state.chatMessages,
        deletingDraft: false,
        canSendMessage: action.payload.removeFromUI,
        canSendDraftUpdate: true,
        responseType: action.payload.removeFromUI ? 'manual' : state.responseType,
        deleteDraftError: undefined,
        draftEncounterId: action.payload.encounterId
      };
    }
    case types.DELETE_CHAT_DRAFT_ERROR:
      return { ...state, deletingDraft: false, deleteDraftError: action.payload };
    case types.UPDATE_DRAFT_COUNTDOWN: {
      let canSendMessage = state.canSendMessage;

      if (!state.draftWillBeSentAt) {
        return state;
      }

      const remainingTime = moment.duration(state.draftWillBeSentAt.diff(moment()));
      const text = state.editDraftModalActive ? state.countdownText : getCountdownI18n(remainingTime);
      let showDraftActions = true;
      let updatedDraftWillBeSentAt = state.draftWillBeSentAt;
      let origDraft = state.originalDraft;

      if (remainingTime.asSeconds() <= 0) {
        showDraftActions = false;
        updatedDraftWillBeSentAt = null;
        origDraft = null;
        canSendMessage = !state.chatMessages.some((message) => message.type.toLowerCase().includes('draft'));
      }

      return {
        ...state,
        draftWillBeSentAt: updatedDraftWillBeSentAt,
        countdownText: text,
        originalDraft: origDraft,
        showDraftActions,
        canSendMessage
      };
    }
    case types.START_EDITING_DRAFT: {
      const originalDraft = state.chatMessages.filter((message) => message.type.toLowerCase().includes('draft'))[0];
      const originalDraftText = originalDraft.text;

      return {
        ...state,
        editDraftModalActive: true,
        originalDraftText,
        newDraftText: originalDraftText,
        originalDraft,
        countdownText: { key: 'patient_view.chat.draft.stopped', value: '' },
        canSendDraftUpdate: true
      };
    }
    case types.UPDATE_DRAFT_TEXT:
      return {
        ...state,
        newDraftText: action.payload,
        messageType: 'draft',
        responseType:
          action.payload === '' ? 'manual' : state.responseType === 'canned' ? 'edited canned' : state.responseType,
        hasEditedDraft: true
      };
    case types.UPDATE_MESSAGE_TEXT:
      return {
        ...state,
        message: state.messageType === 'nudge' ? '' : action.payload,
        messageType: 'draft',
        responseType:
          action.payload === '' ? 'manual' : state.responseType === 'canned' ? 'edited canned' : state.responseType,
        scrollToBottom: false
      };
    case types.TOGGLE_CHAT_MODAL:
      return { ...state, chatModalActive: !state.chatModalActive };
    case types.SET_MESSAGE_TYPE:
      return { ...state, messageType: action.payload };
    case types.POPULATE_CANNED_RESPONSE: {
      const regex = new RegExp(FREE_TEXT_TEMPLATE_TAG, 'g');
      const textToAdd = action.payload.replace(regex, FREE_TEXT_TEMPLATE_TAG_REPLACEMENT);

      return {
        ...state,
        messageType: 'draft',
        message: state.message.length ? state.message + `\n\n${textToAdd}` : textToAdd,
        chatResponseMenuOpen: false,
        responseType: 'canned',
        scrollToBottom: state.message.length ? true : false
      };
    }
    case types.GET_CHAT_RESPONSES_REQUESTS:
      return { ...state, loadingChatResponses: true };
    case types.GET_CHAT_RESPONSES_SUCCESS:
      return {
        ...state,
        loadingChatResponses: false,
        chatResponses: {
          original: action.payload,
          flattened: flattenChatResponses(action.payload),
          allResponses: flattenChatResponses(action.payload, true).filter((a) => !a.isCategory && a.body && a.title)
        },
        chatResponsesError: undefined
      };
    case types.GET_CHAT_RESPONSES_ERROR:
      return { ...state, loadingChatResponses: false, chatResponsesError: action.payload };
    case types.TOGGLE_CHAT_RESPONSE_MENU:
      return { ...state, chatResponseMenuOpen: action.payload };
    case types.POPULATE_NUDGE_MESSAGE:
      return { ...state, message: !state.message.length ? action.payload : state.message };
    case types.SET_INPUT_SCROLL:
      return { ...state, scrollToBottom: action.payload };
    case types.SET_CHAT_MESSAGE_HIGHLIGHT: {
      const highlightedMessageIndex = state.chatMessages.findIndex((message) => message.id === action.payload.id);
      const highlightRegex = new RegExp(action.payload.searchText.replace(/[?{}[\][()^+<>]/gi, ''), 'gi');

      return {
        ...state,
        chatMessages: updateObjectInArray(
          state.chatMessages.map((message) => {
            return { ...message, highlight: undefined };
          }),
          {
            index: highlightedMessageIndex,
            item: {
              ...state.chatMessages[highlightedMessageIndex],
              highlight: state.chatMessages[highlightedMessageIndex].text.replace(
                highlightRegex,
                `<mark class="${action.payload.type}">$&</mark>`
              )
            }
          }
        )
      };
    }
    case types.TOGGLE_CHAT_MESSAGE_FILTER: {
      const newFilters = toggleItem(state.messageFilters, action.payload);
      return {
        ...state,
        messageFilters: newFilters
      };
    }
    case types.SET_CHAT_IMAGE_URL: {
      const chatMessageIndex = state.chatMessages.findIndex((message) => message.id === action.payload.messageId);
      return {
        ...state,
        chatMessages: updateObjectInArray(state.chatMessages, {
          index: chatMessageIndex,
          item: { ...state.chatMessages[chatMessageIndex], objectUrl: action.payload.objectUrl }
        })
      };
    }
    case types.GET_NOTES_SUCCESS: {
      const hasDoctorFinalNote = action.payload.some(
        (note) => note.noteType === 'finalnote' && note.author.caregiverRole === CAREGIVER_ROLE.DOCTOR
      );
      return {
        ...state,
        nudgeDisabled: !hasDoctorFinalNote,
        hasDoctorFinalNote
      };
    }
    case types.CONVERT_DRAFT_TO_MESSAGE_SUCCESS: {
      const chatMessageIndex = state.chatMessages.findIndex((message) => message.id === action.payload.messageId);
      return {
        ...state,
        canSendMessage: true,
        chatMessages: updateObjectInArray(state.chatMessages, {
          index: chatMessageIndex,
          item: { ...state.chatMessages[chatMessageIndex], type: 'message' }
        })
      };
    }
    case types.CLEAR_MESSAGE:
      return { ...state, message: '', editDraftModalActive: false };
    default:
      return state;
  }
};

export default patientChatReducer;

const getCountdownI18n = (duration) => {
  if (!duration) return;
  const countdownSeconds = duration.asSeconds();
  return countdownSeconds > 0
    ? { key: 'patient_view.chat.draft.sending_in', time: duration.format('mm:ss') }
    : { key: 'patient_view.chat.draft.sending' };
};
