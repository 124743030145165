import React, { Component } from 'react';
import MeasurementPeriod from './MeasurementPeriod';
import LoadingSpinner from '../../../LoadingSpinner';
import './MeasurementPeriods.scss';

class MeasurementPeriods extends Component {
  render() {
    const { trendData, periodSize, isLoading } = this.props;

    if (!trendData) return null;

    return (
      <div className="measurement-periods-container">
        {isLoading ? (
          <div className="measurement-periods-loader">
            <LoadingSpinner size="large" />
          </div>
        ) : null}
        <div className={`columns measurement-periods ${isLoading ? 'loading' : ''}`}>
          {trendData.map((period, i) => {
            return <MeasurementPeriod key={i} period={period} periodSize={periodSize} />;
          })}
        </div>
      </div>
    );
  }
}

export default MeasurementPeriods;
