import * as types from '../constants/actionTypes';
import { HTTP_STATUS_CODES } from '../constants';

const initialState = {
  cardiopulmonary: { recordings: [] },
  hasExaminationData: false
};

const examinationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CARDIOPULMONARY_EXAMINATION_REQUEST:
      return { ...state, loadingCardiopulmonaryExamination: true };
    case types.GET_CARDIOPULMONARY_EXAMINATION_SUCCESS:
      return {
        ...state,
        loadingCardiopulmonaryExamination: false,
        cardiopulmonary: action.payload,
        hasExaminationData: !!action.payload?.recordings.length,
        auscultationError: undefined
      };
    case types.GET_CARDIOPULMONARY_EXAMINATION_ERROR:
      return {
        ...state,
        loadingCardiopulmonaryExamination: false,
        auscultationError:
          action.payload && action.payload.status !== HTTP_STATUS_CODES.NOT_FOUND ? action.payload : undefined
      };
    case types.CLEAR_CURRENT_MEMBER:
      return initialState;
    default:
      return state;
  }
};

export default examinationReducer;
