import moment from 'moment';
import xRegExp from 'xregexp';
import flatten from 'lodash.flattendeep';
import _initials from 'initials';
import {
  MEASUREMENT_COLOR_MORNING,
  MEASUREMENT_COLOR_DAY,
  MEASUREMENT_COLOR_EVENING,
  MEASUREMENT_COLOR_NIGHT,
  DEFAULT_LAB_RESULT_COLUMN_WIDTH,
  LAB_RESULT_COLUMN_WIDTH_WITHOUT_EVALUATION,
  DATE_FORMAT,
  CAREGIVER_ROLE
} from '../constants';

export const getAgeFromPersonalNumber = (personalNumber) => {
  if (!personalNumber) return;
  const year = personalNumber.substring(0, 4);
  const month = personalNumber.substring(4, 6);
  const day = personalNumber.substring(6, 8);

  return moment().diff(`${year}-${month}-${day}`, 'years');
};

export const getGenderFromPersonalNumber = (personalNumber) => {
  return parseInt(personalNumber.charAt(10), 10) % 2 === 0 ? 'female' : 'male';
};

export const formatPersonalNumber = (personalNumber) => {
  if (!personalNumber) return;
  return `${personalNumber.substr(0, 8)}-${personalNumber.substr(8, 4)}`;
};

export const camelcase = (str) => {
  return str
    .replace(/^[_.\- ]+/, '')
    .toLowerCase()
    .replace(/[_.\- ]+(\w|$)/g, (m, p1) => p1.toUpperCase());
};

export const isTokenNearingExpiry = (token, threshold = 15) => {
  if (!token || !token.expires) return false;
  const now = moment();
  const expires = moment(token.expires, DATE_FORMAT);

  if (now.isAfter(expires)) {
    return false;
  }

  const expiresGracePeriod = moment(token.expires, DATE_FORMAT).add(-threshold, 'minutes');

  return now.isAfter(expiresGracePeriod);
};

moment.updateLocale('sv', {
  calendar: {
    sameDay: '[I dag] \\k\\l\\. HH:mm'
  }
});

moment.updateLocale('en', {
  calendar: {
    sameDay: '[Today] \\a\\t HH:mm'
  }
});

export const configuredMoment = moment;

export const updateObjectInArray = (array, action) => {
  return array.map((item, index) => {
    if (index !== action.index) {
      return item;
    }
    return {
      ...item,
      ...action.item
    };
  });
};

export const removeItemFromArray = (array, action) => {
  return [...array.slice(0, action.index), ...array.slice(action.index + 1)];
};

export const calculateBmi = (height, weight) => {
  height = height / 100;
  const bmi = weight / (height * height);

  return Number.parseFloat(bmi).toPrecision(3);
};

export const groupBy = (objectArray, key) => {
  if (!objectArray) {
    return {};
  }

  return objectArray.reduce((acc, curr) => {
    if (!curr) return acc;

    (acc[curr[key]] = acc[curr[key]] || []).push(curr);

    return acc;
  }, {});
};

export const decamelize = (text, separator, lowerCase = true) => {
  if (typeof text !== 'string') {
    return text;
  }

  separator = typeof separator === 'undefined' ? '_' : separator;

  const regex1 = xRegExp('([\\p{Ll}\\d])(\\p{Lu})', 'g');
  const regex2 = xRegExp('(\\p{Lu}+)(\\p{Lu}[\\p{Ll}\\d]+)', 'g');

  let decamelized = text.replace(regex1, `$1${separator}$2`).replace(regex2, `$1${separator}$2`);

  return lowerCase ? decamelized.toLowerCase() : decamelized;
};

export const getMeasurementColor = (measurement) => {
  const measurementDate = new Date(measurement.date);
  const measurementHour = measurementDate.getHours();

  if (measurementHour >= 5 && measurementHour < 11) {
    return MEASUREMENT_COLOR_MORNING;
  }

  if (measurementHour >= 11 && measurementHour < 17) {
    return MEASUREMENT_COLOR_DAY;
  }

  if (measurementHour >= 17 && measurementHour <= 23) {
    return MEASUREMENT_COLOR_EVENING;
  }

  return MEASUREMENT_COLOR_NIGHT;
};

export const toggleItem = (selectedItems, toggledItem) => {
  let selectedItemsCopy = [...selectedItems];
  const toggledItemIndex = selectedItemsCopy.indexOf(toggledItem);

  if (toggledItemIndex > -1) {
    selectedItemsCopy.splice(toggledItemIndex, 1);
  } else {
    selectedItemsCopy = [...selectedItemsCopy, toggledItem];
  }

  return selectedItemsCopy;
};

export const getPeriodI18nKey = (periodSize) => {
  switch (periodSize) {
    case 1:
      return 'global.day';
    case 14:
      return 'global.week';
    case 30:
      return 'global.month';
    case 90:
      return 'global.day';
    default:
      return 'global.week';
  }
};

export const extractProperties = (member) => {
  let memberWithProperties = { ...member };

  if (member.properties) {
    member.properties.forEach((property) => {
      memberWithProperties[property.key] = property.value;
    });
  }

  return memberWithProperties;
};

export const extractCaregivers = (member) => {
  if (member.caregivers && member.caregivers.length) {
    let newMember = { ...member };

    let foundResponsibleNurse = member.caregivers.filter((c) => c.caregiver.caregiverRole === CAREGIVER_ROLE.NURSE);
    if (foundResponsibleNurse.length) {
      newMember.responsibleNurse = foundResponsibleNurse[0].caregiver;
    }

    let foundResponsibleDoctor = member.caregivers.filter(
      (c) => c.caregiver.caregiverRole === CAREGIVER_ROLE.DOCTOR && c.priority === 1
    );
    if (foundResponsibleDoctor.length) {
      newMember.responsibleDoctor = foundResponsibleDoctor[0].caregiver;
    }

    let foundSecondaryResponsibleDoctor = member.caregivers.filter(
      (c) => c.caregiver.caregiverRole === CAREGIVER_ROLE.DOCTOR && c.priority === 2
    );
    if (foundSecondaryResponsibleDoctor.length) {
      newMember.secondaryResponsibleDoctor = foundSecondaryResponsibleDoctor[0].caregiver;
    }

    let foundSecondaryResponsibleNurse = member.caregivers.filter(
      (c) => c.caregiver.caregiverRole === CAREGIVER_ROLE.NURSE && c.priority === 2
    );
    if (foundSecondaryResponsibleNurse.length) {
      newMember.secondaryResponsibleNurse = foundSecondaryResponsibleNurse[0].caregiver;
    }

    return newMember;
  }

  return member;
};

export const getNumberOfColumnsForWidth = (windowWidth = 1500, showLabEvaluation, chatCollapsed) => {
  let numColumns;
  const nonAllocatableHorizontalSpace = chatCollapsed ? 100 : 600;

  if (showLabEvaluation) {
    numColumns = Math.floor((windowWidth - nonAllocatableHorizontalSpace) / DEFAULT_LAB_RESULT_COLUMN_WIDTH);
  } else {
    numColumns = Math.floor((windowWidth - nonAllocatableHorizontalSpace) / LAB_RESULT_COLUMN_WIDTH_WITHOUT_EVALUATION);

    if (!chatCollapsed) {
      numColumns -= 4;
    }
  }

  return numColumns;
};

export const updateLabResultColumnWidth = (labResultColumns, showLabEvaluation) => {
  let newLabResultColumns = [...labResultColumns];

  newLabResultColumns.forEach((column) => {
    column.maxWidth = showLabEvaluation ? DEFAULT_LAB_RESULT_COLUMN_WIDTH : LAB_RESULT_COLUMN_WIDTH_WITHOUT_EVALUATION;
  });

  return newLabResultColumns;
};

export const getWeightObjectivesDiffs = (weightObjectivesProgress) => {
  if (!weightObjectivesProgress || !weightObjectivesProgress.length) return [];

  let currentWeight = weightObjectivesProgress[0].value;

  return weightObjectivesProgress.map((weightReport, i, all) => {
    if (i === 0) {
      return { ...weightReport, diff: null };
    }

    if (all[i - 1] && all[i - 1].value) {
      currentWeight = all[i - 1].value;
    }

    let diff = !all[i] || !all[i].value ? null : all[i].value - currentWeight;

    return { ...weightReport, diff };
  });
};

export const getEmptyMedicationListFallbackI18nKey = (healthProfile, medications) => {
  if (medications.memberMedications && !medications.currentMedications.length) {
    if (medications.unsignedMedications.length) {
      return 'patient_view.medications.empty.current_medications_empty_but_has_unsigned';
    }

    if (medications.previousMedications.length) {
      // Has no medications currently
      return 'patient_view.medications.empty.current_medications_empty';
    }

    if (healthProfile.medicationAnswer === '0') {
      // Answered yes
      return 'patient_view.medications.empty.current_medications_empty_for_now';
    } else if (healthProfile.medicationAnswer === '1') {
      // Answered no
      return 'patient_view.medications.empty.current_medications_definitely_empty';
    } else if (healthProfile.medicationAnswer === undefined) {
      // Has not answered the medication question
      return 'patient_view.medications.empty.current_medications_maybe_empty';
    }
  }

  return 'patient_view.medications.empty.current_medications_empty';
};

export const getRevisions = (changes, fallbackI18nKey) => {
  const revisions = changes
    .filter((c) => c.changeType !== 'new')
    .map((change) => {
      if (change.newBase) {
        let diffs = [];
        Object.getOwnPropertyNames(change.newBase).forEach((prop) => {
          if (change.previousBase && change.newBase[prop] !== change.previousBase[prop]) {
            diffs.push({
              date: moment(change.timestamp, DATE_FORMAT).format('YYYY-MM-DD'),
              property: prop,
              previousValue: change.previousBase[prop],
              newValue: change.newBase[prop],
              changedByName: `${change.changedBy.givenName} ${change.changedBy.familyName}`,
              changedByGuid: change.changedBy.guid,
              fallbackI18nKey:
                typeof change.previousBase[prop] !== 'boolean' && !change.previousBase[prop] ? fallbackI18nKey : null
            });
          }
        });
        if (diffs.length) {
          return diffs;
        }
      }
      return null;
    });

  return groupBy(flatten(revisions).reverse(), 'date');
};

export const getMimetype = (signature) => {
  switch (signature) {
    case '89504E47':
      return 'image/png';
    case '47494638':
      return 'image/gif';
    case '25504446':
      return 'application/pdf';
    case 'FFD8FFDB':
    case 'FFD8FFE0':
      return 'image/jpeg';
    case '504B0304':
      return 'application/zip';
    default:
      return 'Unknown filetype';
  }
};

export const getNumBookingsInWeek = (selectedDate, dayBookings) => {
  let weekStart = moment(selectedDate).format('YYYY-MM-DD');
  let weekEnd = moment(selectedDate).format('YYYY-MM-DD');

  weekStart = moment(weekStart).startOf('week');
  weekEnd = moment(weekEnd).endOf('week');

  const bookedDatesInSelectedWeek = dayBookings.filter((booking) =>
    moment(booking.checkupDate, DATE_FORMAT).isBetween(weekStart, weekEnd, 'days', '[]')
  );
  let numBookingsInWeek = 0;

  bookedDatesInSelectedWeek.forEach((booking) => (numBookingsInWeek += booking.bookingCount));

  return numBookingsInWeek;
};

export const getCheckupHighlights = (checkupCalendar) => {
  let highlights = [];

  for (let i = 1; i <= 6; i++) {
    highlights.push({
      [`date-appointment load-${i}`]: []
    });
  }

  checkupCalendar.forEach((booking) => {
    if (booking.bookingCount < 6) {
      highlights[0]['date-appointment load-1'].push(moment(booking.checkupDate, DATE_FORMAT).toDate());
    } else if (booking.bookingCount < 11) {
      highlights[1]['date-appointment load-2'].push(moment(booking.checkupDate, DATE_FORMAT).toDate());
    } else if (booking.bookingCount < 16) {
      highlights[2]['date-appointment load-3'].push(moment(booking.checkupDate, DATE_FORMAT).toDate());
    } else if (booking.bookingCount < 21) {
      highlights[3]['date-appointment load-4'].push(moment(booking.checkupDate, DATE_FORMAT).toDate());
    } else if (booking.bookingCount < 26) {
      highlights[4]['date-appointment load-5'].push(moment(booking.checkupDate, DATE_FORMAT).toDate());
    } else if (booking.bookingCount >= 26) {
      highlights[5]['date-appointment load-6'].push(moment(booking.checkupDate, DATE_FORMAT).toDate());
    }
  });

  return highlights;
};

export const getBloodPressureAverage = (measurements = []) => {
  const filteredMeasurements = measurements
    .filter((measurement) => {
      return measurement.measurementMode !== 'standing' && measurement.measurementMode !== 'symptoms';
    })
    .map((measurement) => {
      let sessionTotalSystolic = 0;
      let sessionTotalDiastolic = 0;

      measurement.bpmeasurements.forEach((bp) => {
        sessionTotalSystolic += bp.systolic;
        sessionTotalDiastolic += bp.diastolic;
      });

      return {
        systolic: sessionTotalSystolic / measurement.bpmeasurements.length,
        diastolic: sessionTotalDiastolic / measurement.bpmeasurements.length
      };
    });

  let averageSystolic = 0;
  let averageDiastolic = 0;

  let totalSystolic = filteredMeasurements.reduce((prev, curr) => {
    return (prev += curr.systolic);
  }, 0);

  let totalDiastolic = filteredMeasurements.reduce((prev, curr) => {
    return (prev += curr.diastolic);
  }, 0);

  averageSystolic = filteredMeasurements.length ? Math.round(totalSystolic / filteredMeasurements.length) : 0;
  averageDiastolic = filteredMeasurements.length ? Math.round(totalDiastolic / filteredMeasurements.length) : 0;

  return {
    systolic: averageSystolic,
    diastolic: averageDiastolic
  };
};

export const dedupe = (array) => {
  let deduped = [];

  array.forEach((item) => {
    if (!deduped.includes(item)) {
      deduped.push(item);
    }
  });

  return deduped;
};

export const flattenChatResponses = (array, flattenAll = false) => {
  let result = [];
  array.forEach((a) => {
    if (a.isCategory || flattenAll) {
      result.push(a);
      result = result.concat(flattenChatResponses(a.children, flattenAll));
    }
  });
  return result;
};

export const getBloodPressureAverageAtLastFinalNote = (notes, bloodPressureMeasurements = []) => {
  let averageBloodPressureAtLastFinalNote = {};
  const lastFinalNote = notes.filter((note) => note.noteType === 'finalnote')[0];
  const measurements = [...bloodPressureMeasurements];

  if (lastFinalNote) {
    const lastFinalNoteTimestamp = moment.utc(lastFinalNote.timestamp, DATE_FORMAT);
    const lastMeasurementBeforeLastFinalNote = measurements
      .reverse()
      .find((measurement) =>
        moment.utc(measurement.bpmeasurements[0].timestamp, DATE_FORMAT).isBefore(lastFinalNoteTimestamp)
      );

    if (lastMeasurementBeforeLastFinalNote) {
      const lastMeasurementBeforeLastFinalNoteTimestamp = moment.utc(
        lastMeasurementBeforeLastFinalNote.bpmeasurements[0].timestamp,
        DATE_FORMAT
      );
      const twoWeeksBeforeLastMeasurementBeforeLastFinalNoteTimestamp = moment
        .utc(lastMeasurementBeforeLastFinalNote.bpmeasurements[0].timestamp, DATE_FORMAT)
        .add(-13, 'd')
        .startOf('day');
      const measurementsTwoWeeksBeforeLastFinalNote = measurements.filter((measurement) => {
        return (
          moment
            .utc(measurement.bpmeasurements[0].timestamp, DATE_FORMAT)
            .isSameOrAfter(twoWeeksBeforeLastMeasurementBeforeLastFinalNoteTimestamp) &&
          moment
            .utc(measurement.bpmeasurements[0].timestamp, DATE_FORMAT)
            .isSameOrBefore(lastMeasurementBeforeLastFinalNoteTimestamp)
        );
      });

      averageBloodPressureAtLastFinalNote = {
        ...getBloodPressureAverage(measurementsTwoWeeksBeforeLastFinalNote),
        finalNote: lastFinalNote
      };
    }
  }

  return averageBloodPressureAtLastFinalNote;
};

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
};

export const getEarliestBeforeDate = (array, date) => {
  const momentDate = moment(date, DATE_FORMAT);

  for (let i = array.length - 1; i >= 0; i--) {
    if (moment(array[i].timestamp, DATE_FORMAT).isBefore(momentDate)) {
      return array[i].id;
    }
  }

  return null;
};

export const initials = (givenName, familyName) => {
  const firstTwoLetters = givenName.substring(0, 2).split('');
  return `${firstTwoLetters[0].toUpperCase()}${firstTwoLetters[1].toLowerCase()}${
    familyName.includes(' ') ? _initials(familyName) : familyName.substring(0, 1)
  }`;
};

export const snakeToCamel = (string) => {
  return string.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));
};

export const range = (start, end, step = 1) => {
  let output = [];

  if (typeof end === 'undefined') {
    end = start;
    start = 0;
  }

  for (let i = start; i < end; i += step) {
    output.push(i);
  }

  return output;
};

export const convertMarkDownLinksToATags = (str) => {
  if (!str || typeof str !== 'string') {
    return '';
  }

  return str.replace(/\[([^[]+)\](\(([^)]*))\)/gim, '<a href="$3" target="_blank" rel="noopener noreferrer">$1</a>');
};

export const getFinalNoteCountDuringIntervalInMonths = (notes = [], role = 'doctor', interval = 12) => {
  const dateCutoff = moment().add(-interval, 'months');
  const finalNotes = notes.filter(
    (note) =>
      note.noteType === 'finalnote' &&
      moment(note.timestamp, DATE_FORMAT).isSameOrAfter(dateCutoff, 'day') &&
      note.author?.caregiverRole === role
  );

  return finalNotes.length;
};

export const classnames = (stringArr) => {
  if (!stringArr || !stringArr.length) {
    return '';
  }

  return stringArr.filter(Boolean).join(' ');
};
