import React from 'react';
import Revision from './Revision';

const RevisionList = ({ revisions }) => {
  return (
    <div>
      {Object.getOwnPropertyNames(revisions).map((revisionDate, i) => {
        return <Revision key={i} date={revisionDate} revisions={revisions[revisionDate]} />;
      })}
    </div>
  );
};

export default RevisionList;
