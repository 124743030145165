import React, { Component } from 'react';

class SimpleHistory extends Component {
  render() {
    const { history } = this.props;

    if (!history || !history.length) return null;

    return (
      history &&
      history.map((value, i) => {
        return (
          <div className="history-row" key={i}>
            {value}
          </div>
        );
      })
    );
  }
}

export default SimpleHistory;
