import React, { Fragment } from 'react';
import { Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import LoadingSpinner from '../../LoadingSpinner';
import { toggleDssSection } from '../../../actions';
import './DecisionSupportSection.scss';

const DecisionSupportSection = (props) => {
  const {
    children,
    headerI18nKey,
    subHeader18nKey,
    isLoading,
    sectionNote,
    ui,
    name,
    toggleDssSection,
    minimizable = true,
    minimizeOverride
  } = props;

  const isExpanded = !ui.dssSectionsCollapsed[name] || minimizeOverride;

  return (
    <section className="dss-section columns">
      <div className="column no-padding rounded-container">
        {headerI18nKey ? (
          <div>
            {headerI18nKey ? (
              <Fragment>
                <div className="columns">
                  <div className="column is-9 no-padding">
                    <h2 className={`section-header ${isExpanded ? 'expanded' : ''}`}>
                      <Translate value={headerI18nKey} />
                    </h2>
                    {subHeader18nKey ? (
                      <h3 className="section-header-info">
                        (<Translate value={subHeader18nKey} />)
                      </h3>
                    ) : null}
                  </div>
                  <div className="column right-align no-padding">
                    {minimizable ? (
                      <span
                        onClick={() => toggleDssSection(name)}
                        className={`text-button chevron ${isExpanded ? 'up' : 'down'}`}
                      >
                        {isExpanded ? <Translate value="global.minimize" /> : <Translate value="global.show" />}
                      </span>
                    ) : null}
                  </div>
                </div>
              </Fragment>
            ) : null}

            {sectionNote ? <div className="section-note alert">{sectionNote}</div> : null}
          </div>
        ) : null}

        {isExpanded ? (
          <div className="section-content">
            {isLoading ? (
              <div className="section-loader">
                <LoadingSpinner size="small" />
              </div>
            ) : (
              <div className="child-content">{children}</div>
            )}
          </div>
        ) : null}
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    ui: state.ui
  };
};

const mapActionsToProps = {
  toggleDssSection
};

export default connect(mapStateToProps, mapActionsToProps)(DecisionSupportSection);
