import React, { Component } from 'react';
import { Translate } from 'react-redux-i18n';
import accumboSpinnerGrey from '../../assets/accumbo-spinner-white_s.png';
import accumboSpinner from '../../assets/accumbo-spinner_s.png';
import './LoaderButton.scss';

class LoaderButton extends Component {
  render() {
    const { isLoading, buttonI18nKey, isDestructive, isSecondary, isLarge, isGhost, children, ...rest } = this.props;

    return (
      <button
        className={`button ${isDestructive ? 'is-danger' : isSecondary ? 'is-secondary' : 'is-primary'} ${
          isLarge ? 'large' : ''
        } ${isGhost ? 'is-ghost' : ''}`}
        {...rest}
      >
        {isLoading ? (
          <div className="columns loader-button-container">
            <div className="column no-padding">{children || <Translate value={buttonI18nKey} />}</div>
            <div className="column no-padding">
              <img
                className={`loader-button-image ${isLarge ? 'large' : ''}`}
                src={!rest.disabled ? accumboSpinnerGrey : accumboSpinner}
                alt="loading..."
              />
            </div>
          </div>
        ) : (
          children || <Translate value={buttonI18nKey} />
        )}
      </button>
    );
  }
}

export default LoaderButton;
