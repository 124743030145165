import React, { Fragment, Component } from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { Translate, I18n } from 'react-redux-i18n';
import ContentLoader from '../../ContentLoader';
import { DATE_FORMAT } from '../../../constants';
import './MedicationOptions.scss';

class MedicationOptions extends Component {
  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  render() {
    const { options, onOptionChanged, selectedOption, isLoading, error, errorTitleI18n } = this.props;

    return (
      <Fragment>
        <div className="medication-options-wrapper">
          <h3 className="mb-15">
            <Translate value="patient_view.medications.header.medication_suggestions" />
          </h3>
          <ContentLoader isLoading={isLoading} error={error} errorTitleI18n={errorTitleI18n}>
            <div className="medication-options">
              {options ? (
                options.length ? (
                  options.map((option, i) => (
                    <MedicationOption
                      key={i}
                      index={i}
                      selectedOption={selectedOption}
                      onOptionChanged={onOptionChanged}
                      isPreferred={i === 0}
                      medication={option}
                    />
                  ))
                ) : (
                  <div>
                    <Translate value="patient_view.medications.medication_modal.no_medication_options_found" />
                  </div>
                )
              ) : null}
            </div>
          </ContentLoader>
        </div>
        <ReactTooltip multiline={false} effect="solid" className="custom-tooltip" />
      </Fragment>
    );
  }
}

export default MedicationOptions;

const MedicationOption = ({ medication, isPreferred, index, selectedOption, onOptionChanged }) => {
  return (
    <div className={`vara-medication-option ${isPreferred ? 'preferred' : ''}`}>
      {isPreferred ? (
        <div className="preferred-medication-note vertical-align">
          <span>
            <Translate value="patient_view.medications.medication_modal.recommended_medication_option" />
          </span>
          <span
            className="icons info"
            data-tip={I18n.t('patient_view.medications.medication_modal.recommended_option_reason')}
          ></span>
        </div>
      ) : null}
      <div className="columns fs-18">
        <div className="column no-padding is-1">
          <input
            type="radio"
            id={`medication-option-${index}`}
            name={`medication-option-${index}`}
            value={index}
            checked={selectedOption === index}
            onChange={onOptionChanged}
          />
          <label htmlFor={`medication-option-${index}`}></label>
        </div>
        <div className="column no-padding is-11">
          <label htmlFor={`medication-option-${index}`} className="pointer">
            <div className="columns">
              <div className="column no-padding medication-properties">
                <div className="columns mb-0">
                  <div className="column no-padding">
                    <Translate value="patient_view.medications.medication_option.name" />:
                  </div>
                  <div className="column no-padding">{medication.name}</div>
                </div>
                <div className="columns mb-0">
                  <div className="column no-padding">
                    <Translate value="patient_view.medications.medication_option.form" />:
                  </div>
                  <div className="column no-padding">{medication.form}</div>
                </div>
                <div className="columns mb-0">
                  <div className="column no-padding">
                    <Translate value="patient_view.medications.medication_option.strength" />:
                  </div>
                  <div className="column no-padding">{medication.strength}</div>
                </div>
                <div className="columns mb-0">
                  <div className="column no-padding">
                    <Translate value="patient_view.medications.medication_option.atc_code" />:
                  </div>
                  <div className="column no-padding">{medication.atcCode}</div>
                </div>
                <div className="columns mb-0">
                  <div className="column no-padding">
                    <Translate value="patient_view.medications.medication_option.package_content" />:
                  </div>
                  <div className="column no-padding">{medication.packageContent}</div>
                </div>
                <div className="columns mb-0">
                  <div className="column no-padding">
                    <Translate value="patient_view.medications.medication_option.approval_date" />:
                  </div>
                  <div className="column no-padding">
                    {medication.approvalDate ? (
                      moment(medication.approvalDate, DATE_FORMAT).format('YYYY-MM-DD')
                    ) : (
                      <Translate value="global.unknown_date" />
                    )}
                  </div>
                </div>
                {medication.unregisteredDate ? (
                  <div className="columns mb-0">
                    <div className="column no-padding">
                      <Translate value="patient_view.medications.medication_option.unregistered_date" />:
                    </div>
                    <div className="column no-padding">
                      {moment(medication.unregisteredDate, DATE_FORMAT).format('YYYY-MM-DD')}
                    </div>
                  </div>
                ) : null}
                {medication.salesStop ? (
                  <div className="columns mb-0">
                    <div className="column no-padding">
                      <Translate value="patient_view.medications.medication_option.sales_stop" />:
                    </div>
                    <div className="column no-padding">
                      <Translate value="global.yes" />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};
