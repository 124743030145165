import React, { Component } from 'react';
import { Translate } from 'react-redux-i18n';
import AnamnesisAnswer from '../AnamnesisAnswer';
import ChapterChevron from '../../../assets/icons/chapter-chevron.svg';
import './AnamnesisChapter.scss';

class AnamnesisChapter extends Component {
  constructor(props) {
    super(props);

    this.handleChapterClick = this.handleChapterClick.bind(this);

    this.state = {
      expanded: true
    };
  }

  handleChapterClick() {
    this.setState({
      expanded: !this.state.expanded
    });
  }

  render() {
    const { chapter, profileData, orderNumber, hash = '#' } = this.props;
    const chapterQuestions =
      profileData &&
      profileData.questions &&
      profileData.questions.filter((question) => {
        return question.chapterId === chapter.chapterId;
      });

    return (
      <li>
        <div className="anamnesis-chapter-title" onClick={this.handleChapterClick}>
          <div className="anamnesis-chapter-title__text">
            <h2>
              {orderNumber + 1}. {chapter.subtitle}
            </h2>
          </div>
          <div className="anamnesis-chapter-title__icon">
            <img src={ChapterChevron} alt="" className={this.state.expanded ? 'rotated' : ''} />
          </div>
        </div>
        <div className={`anamnesis-chapter-content ${!this.state.expanded ? 'hidden' : ''}`}>
          <ul>
            {chapterQuestions && chapterQuestions.length > 0 ? (
              chapterQuestions.map((question) => {
                const questionId = `anamnesis-${orderNumber + 1}-${question.number}`;
                return (
                  <li key={question.id}>
                    <div
                      id={questionId}
                      className={`anamnesis-chapter-content__question ${
                        question.mandatory && (!question.userAnswer || question.userAnswer.skipped) ? 'mandatory' : ''
                      } ${hash.substring(1) === questionId ? 'highlighted' : ''}`}
                    >
                      {orderNumber + 1}.{question.number} {question.question}
                    </div>
                    <AnamnesisAnswer question={question} />
                  </li>
                );
              })
            ) : (
              <ul>
                <li>
                  <Translate value="patient_view.anamnesis.empty_category" />
                </li>
              </ul>
            )}
          </ul>
        </div>
      </li>
    );
  }
}

export default AnamnesisChapter;
