import React, { Component, Fragment } from 'react';
import { Translate } from 'react-redux-i18n';
import { makeDisableable } from '../../../../../utils/hoc';
import './BloodPressureMeasureFrequencyRow.scss';

class BloodPressureMeasureFrequencyRow extends Component {
  render() {
    const { onMeasurementFrequencyChanged, measurementFrequency, allowedToEdit } = this.props;

    return (
      <div className="row-container">
        <div className="columns table-row">
          <div className="column row-response is-4">
            <Translate value="decision_support_view.treatment_goals_section.measurement_frequency_lead_text" />
          </div>
          <div className="column row-category is-7">
            <div className="mb-20">
              <input
                type="radio"
                id="1-per-week"
                value="1"
                name="measure-frequency"
                checked={measurementFrequency === 1}
                onChange={onMeasurementFrequencyChanged}
                disabled={!allowedToEdit}
              />
              <label htmlFor="1-per-week">
                <Translate value="decision_support_view.treatment_goals_section.measurement_frequency_1" />
                <br />
                <Translate
                  value="decision_support_view.treatment_goals_section.measurement_frequency_1_explanation"
                  className="margin-adjust"
                />
              </label>
            </div>
            <div className="mb-20">
              <input
                type="radio"
                id="2-per-week"
                value="2"
                name="measure-frequency"
                checked={measurementFrequency === 2}
                onChange={onMeasurementFrequencyChanged}
                disabled={!allowedToEdit}
              />
              <label htmlFor="2-per-week">
                <Translate value="decision_support_view.treatment_goals_section.measurement_frequency_2" />
                <br />
                <Translate
                  value="decision_support_view.treatment_goals_section.measurement_frequency_2_explanation"
                  className="margin-adjust"
                />
              </label>
            </div>
            <div className="mb-20">
              <input
                type="radio"
                id="4-per-week"
                value="4"
                name="measure-frequency"
                checked={measurementFrequency === 4}
                onChange={onMeasurementFrequencyChanged}
                disabled={!allowedToEdit}
              />
              <label htmlFor="4-per-week">
                <Translate value="decision_support_view.treatment_goals_section.measurement_frequency_4" />
                <br />
                <Translate
                  value="decision_support_view.treatment_goals_section.measurement_frequency_4_explanation"
                  className="margin-adjust"
                />
              </label>
            </div>
            <div>
              <input
                type="radio"
                id="1-daily"
                value="7"
                name="measure-frequency"
                checked={measurementFrequency === 7}
                onChange={onMeasurementFrequencyChanged}
                disabled={!allowedToEdit}
              />
              <label htmlFor="1-daily">
                <Translate value="decision_support_view.treatment_goals_section.measurement_frequency_7" />
                <br />
                <Translate
                  value="decision_support_view.treatment_goals_section.measurement_frequency_7_explanation"
                  className="margin-adjust"
                />
              </label>
            </div>
            {measurementFrequency ? (
              <Fragment>
                <div className="mt-30 generated-response">
                  <h4>
                    <Translate value="decision_support_view.treatment_goals_section.generated_response_header" />
                  </h4>
                  <p>
                    <Translate
                      value={`decision_support_view.treatment_goals_section.measurement_frequency_${measurementFrequency}_generated_response`}
                    />
                  </p>
                </div>
                <div className="mt-20 generated-response">
                  <h4>Info:</h4>
                  <p>
                    <Translate value="decision_support_view.treatment_goals_section.generated_response_info" />
                  </p>
                </div>
              </Fragment>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default makeDisableable(BloodPressureMeasureFrequencyRow);
