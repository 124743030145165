import React from 'react';
import { getRandomInt } from '../../../utils';

const ChatItemSkeleton = React.memo(({ index }) => {
  const messageDirection = index % 2 === 0 ? 'outgoing' : 'incoming';
  const lipsum =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse ullamcorper sed sapien vitae commodo. Sed iaculis sem nibh, eu sagittis eros fermentum eu. Phasellus et arcu vitae magna ullamcorper egestas. Integer placerat a risus fermentum vehicula. Ut a auctor dolor. Sed pharetra.';

  return (
    <div className="chat-message-skeleton">
      <div className={`chat-message message-${messageDirection}`}>
        {messageDirection === 'incoming' ? (
          <div className="columns">
            <div className="column chat-avatar no-padding">
              <span className="chat-avatar__placeholder"></span>
            </div>
            <div className="column chat-message__content">{lipsum.slice(0, getRandomInt(40, 120))}</div>
          </div>
        ) : (
          <div className="flexrow">
            <div className="flex1"></div>
            <div className="flex0 chat-message__content">{lipsum.slice(0, getRandomInt(40, 300))}</div>
          </div>
        )}
        <div className="chat-message__timestamp">
          {messageDirection === 'outgoing' ? <span className="caregiver-name">Namn Namnsson</span> : null}

          <span>2020-02-01 kl. 13:37</span>
        </div>
      </div>
    </div>
  );
});

export default ChatItemSkeleton;
