import { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { I18n, Translate } from 'react-redux-i18n';
import { getAuscultationAssessments } from '../../actions';
import { APPLICATION_BASE_PATH, DATE_FORMAT } from '../../constants';
import ErrorMessage from '../ErrorMessage';
import SkeletonComponent from '../SkeletonComponent';
import TopBar from '../TopBar';
import WaitTime from '../WaitTime';
import { Mixpanel } from '../../utils/mixpanel';

const AuscultationAssessmentIndexView = ({ assessments, isLoading, history, error }) => {
  useEffect(() => {
    Mixpanel.resetSuperProperties();
  }, []);

  const openAuscultationAssessment = (id) => {
    history.push(`/${APPLICATION_BASE_PATH}/auscultation-assessments/${id}`);
  };

  return (
    <>
      <TopBar header={I18n.t('auscultation_assessment.page_title')} />
      <div className="wrapper pb-40">
        <h1>
          <Translate value="auscultation_assessment.list_header" />
        </h1>
        <div className="member-row-container">
          <div className="columns errand-headings">
            <div className="column">
              <Translate value="global.name" />
            </div>
            <div className="column">
              <Translate value="auscultation_assessment.column.facility" />
            </div>
            <div className="column">
              <Translate value="auscultation_assessment.column.received" />
            </div>
          </div>
          <div className="errand-row-container mb-20">
            {assessments.length && !isLoading ? (
              assessments.map((assessment) => (
                <div
                  className="member-row"
                  key={assessment.id}
                  onClick={() => openAuscultationAssessment(assessment.id)}
                >
                  <div className="columns errand-item">
                    <div className="column">
                      <strong>{assessment.partnerUserName}</strong>
                    </div>
                    <div className="column errand-item__wait-time">{assessment.partnerFacility}</div>
                    <div className="column mukta">
                      {`${moment(assessment.completedAt, DATE_FORMAT).format('YYYY-MM-DD HH:mm')}`} (
                      <WaitTime sinceTimestamp={assessment.completedAt} withSuffix />)
                    </div>
                  </div>
                </div>
              ))
            ) : isLoading ? (
              <>
                {new Array(assessments.length || 3).fill(0).map((_, i) => (
                  <SkeletonComponent key={i} width="100%" height="70px" margin="8px" />
                ))}
              </>
            ) : error ? (
              <ErrorMessage error={error} errorI18nKey="auscultation_assessment.error.list_assessments" />
            ) : (
              <div className="no-table-rows">
                <Translate value="auscultation_assessment.no_assessments" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    assessments: state.assessments.auscultationAssessments,
    isLoading: state.assessments.loadingAuscultationAssessments,
    error: state.assessments.auscultationAssessmentsError
  };
};

const mapActionsToProps = {
  getAuscultationAssessments
};

export default connect(mapStateToProps, mapActionsToProps)(AuscultationAssessmentIndexView);
