import React, { Fragment, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import levenshtein from 'js-levenshtein';
import sanitizeHtml from 'sanitize-html';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants';
import './ChatSearch.scss';

const SEARCH_RESULT_TEXT_DISPLAY_BUFFER = 50;
const MIN_DISTANCE = 2;

const findBestLevenshteinMatch = (msgArray) => {
  let index = 0;
  let distance = 999;

  for (let i = 0; i < msgArray.length; i++) {
    if (msgArray[i].levenshtein < distance) {
      distance = msgArray[i].levenshtein;
      index = i;
    }
  }

  return msgArray[index];
};

const ChatSearch = React.memo(({ chatMessages = [], onSearchResultClicked }) => {
  const [matchingMessages, setMatchingMessages] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchResultsVisible, setSearchResultsVisible] = useState(false);
  const [selectedSearchResultIndex, setSelectedSearchResultIndex] = useState(-1);

  const handleSearch = (e) => {
    const searchText = e.target.value.toLowerCase();
    setSearchText(searchText);
    setSelectedSearchResultIndex(-1);

    let messages = chatMessages.filter(
      (msg) => msg.type === 'message' || (msg.type === 'file' && msg.fileinfo.contentType === 'image')
    );
    const exactMatches = messages.filter((msg) => msg.text.toLowerCase().includes(searchText));
    const levenshteinMatches = messages
      .map((msg) => {
        const distances = msg.text.split(/\s/).map((word) => {
          return {
            word,
            levenshtein: levenshtein(word.toLowerCase(), searchText)
          };
        });

        const bestMatch = findBestLevenshteinMatch(distances);

        return {
          ...msg,
          levenshteinMatch: bestMatch
        };
      })
      .filter((msg) => {
        if (msg.text.toLowerCase().includes(searchText)) {
          return false;
        }

        return msg.levenshteinMatch.levenshtein <= MIN_DISTANCE;
      })
      .sort((a, b) => a.levenshteinMatch.levenshtein - b.levenshteinMatch.levenshtein)
      .slice(0, 5);

    setMatchingMessages(exactMatches.concat(levenshteinMatches));
  };

  const getExcerptWithHighlight = (msg) => {
    let regex;

    if (!msg.levenshteinMatch) {
      regex = new RegExp(searchText.replace(/[?{}[\][()^+<>]/gi, ''), 'gi');
    } else {
      regex = new RegExp(msg.levenshteinMatch.word.replace(/[?{}[\][()^+<>]/gi, ''), 'gi');
    }

    let messageText = msg.text;
    const searchTextIndex = messageText
      .toLowerCase()
      .indexOf(!msg.levenshteinMatch ? searchText.toLowerCase() : msg.levenshteinMatch.word.toLowerCase());
    const sliceStart = Math.max(searchTextIndex - SEARCH_RESULT_TEXT_DISPLAY_BUFFER, 0);
    const sliceEnd = searchTextIndex + searchText.length + SEARCH_RESULT_TEXT_DISPLAY_BUFFER;

    messageText = messageText.slice(sliceStart, sliceEnd);

    if (sliceStart > 0) {
      messageText = '...' + messageText;
    }

    if (sliceEnd < msg.text.length) {
      messageText = messageText + '...';
    }

    const textWithHighlight = messageText.replace(
      regex,
      `<mark class="${!msg.levenshteinMatch ? 'exact' : 'approximate'}">$&</mark>`
    );
    return <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(textWithHighlight, { allowedTags: ['mark'] }) }} />;
  };

  const handleClick = (msgId) => {
    selectSearchResult(msgId);
  };

  const selectSearchResult = (msg) => {
    onSearchResultClicked(
      msg.id,
      !msg.levenshteinMatch ? searchText : msg.levenshteinMatch.word,
      !msg.levenshteinMatch ? 'exact' : 'approximate'
    );
    setSearchResultsVisible(false);
    setSelectedSearchResultIndex(-1);
  };

  const handleKeyDown = (e) => {
    setSearchResultsVisible(true);

    if (!matchingMessages.length) {
      return;
    }

    switch (e.keyCode) {
      case 40:
        e.preventDefault();
        if (selectedSearchResultIndex === matchingMessages.length - 1) {
          setSelectedSearchResultIndex(0);
        } else {
          setSelectedSearchResultIndex(selectedSearchResultIndex + 1);
        }
        break;
      case 38:
        e.preventDefault();
        if (selectedSearchResultIndex === 0) {
          setSelectedSearchResultIndex(matchingMessages.length - 1);
        } else {
          setSelectedSearchResultIndex(selectedSearchResultIndex - 1);
        }
        break;
      case 13:
        e.preventDefault();
        if (selectedSearchResultIndex > -1) {
          selectSearchResult(matchingMessages[selectedSearchResultIndex]);
        }
        break;
      default:
        return;
    }
  };

  return (
    <div className="chat-search-container">
      <div>
        <input
          type="text"
          placeholder={I18n.t('patient_view.chat.chat_search_placeholder')}
          onChange={handleSearch}
          onFocus={() => setSearchResultsVisible(true)}
          onKeyDown={handleKeyDown}
        />
      </div>

      {searchResultsVisible && searchText.length && matchingMessages.length ? (
        <div className="chat-search-results">
          <div className="dropdown is-active">
            <div className="dropdown-menu-test" id="dropdown-menu-test" role="menu">
              <div className="dropdown-content">
                {matchingMessages.map((msg, i) => (
                  <Fragment key={msg.id}>
                    <div
                      className={`dropdown-item ${i === selectedSearchResultIndex ? 'keyboard-selected' : ''}`}
                      onClick={() => handleClick(msg)}
                    >
                      <div>{getExcerptWithHighlight(msg)}</div>
                      <div>
                        <span className="author-name">{msg.name} &ndash; </span>
                        <span>{moment(msg.timestamp, DATE_FORMAT).format('YYYY-MM-DD HH:mm')}</span>
                      </div>
                    </div>
                    {i !== matchingMessages.length - 1 ? <hr className="dropdown-divider" /> : null}
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
});

export default ChatSearch;
