import { useEffect, useState, Fragment, useRef } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { Translate, I18n } from 'react-redux-i18n';
import confetti from 'canvas-confetti';
import moment from 'moment';
import { completeKioskAssessment, showNotification } from '../../../actions';
import api from '../../../api/apiClient';
import {
  APPLICATION_BASE_PATH,
  DATE_FORMAT,
  ASSESSMENT_STATUS,
  HTTP_STATUS_CODES,
  GUID_REGEX
} from '../../../constants';
import LoaderButton from '../../LoaderButton';
import BasicPatientInfo from '../../BasicPatientInfo';
import ContentLoader from '../../ContentLoader';
import styles from './KioskAssessmentView.module.scss';
import { eventType, Mixpanel } from '../../../utils/mixpanel';
import SkeletonKioskView from './SkeletonKioskView';
import AssessmentStatus from './AssessmentStatus';
import { decamelize } from '../../../utils';
import seFlagSrc from '../../../assets/se.png';
import gbFlagSrc from '../../../assets/gb.png';

const confettiSettings = {
  disableForReducedMotion: true,
  particleCount: 100,
  startVelocity: 60,
  spread: 55,
  colors: [
    '#fef6f9',
    '#fdedf4',
    '#fbd5e6',
    '#f8b9d6',
    '#d11569',
    '#b9135e',
    '#a61154',
    '#8b0e46',
    '#610a31',
    '#fdb022',
    '#fedf89',
    '#f6fef9',
    '#039855'
  ]
};

const KioskAssessmentView = ({
  authToken,
  assessments,
  match,
  history,
  completeKioskAssessment,
  showNotification,
  user,
  isNurse
}) => {
  const [loadingInitial, setLoadingInitial] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const [savingFinalNote, setSavingFinalNote] = useState(false);
  const [noMoreAssessments, setNoMoreAssessments] = useState(false);
  const [saveFinalNoteRequestCompleted, setSaveFinalNoteRequestCompleted] = useState(false);
  const [nextAssessmentRequestCompleted, setNextAssessmentRequestCompleted] = useState(false);
  const [nextAssessmentLoadingAnimationCompleted, setNextAssessmentLoadingAnimationCompleted] = useState(false);
  const [finalNoteLoadingAnimationCompleted, setFinalNoteLoadingAnimationCompleted] = useState(false);
  const [assessment, setAssessment] = useState(assessments.initialKioskAssessment);
  const [isOwnAssessment, setIsOwnAssessment] = useState(true);
  const [initialError, setInitialError] = useState();
  const [assessmentHandled, setAssessmentHandled] = useState(false);
  const wrapperRef = useRef();

  useEffect(() => {
    Mixpanel.resetSuperProperties();
  }, []);

  useEffect(() => {
    const preventNavigation = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };

    if (
      isNurse &&
      (assessment?.status === ASSESSMENT_STATUS.CaregiverPaused ||
        assessment?.status === ASSESSMENT_STATUS.AssessmentInProgress)
    ) {
      window.addEventListener('beforeunload', preventNavigation);
    }

    return () => {
      window.removeEventListener('beforeunload', preventNavigation);
    };
  }, [assessment, isNurse]);

  useEffect(() => {
    if (assessment?.sessionId !== match.params.sessionId) {
      setLoadingInitial(true);
      api
        .getKioskAssessment(authToken, match.params.sessionId)
        .then((res) => {
          setAssessment(res);
          Mixpanel.time_event(eventType.handleKioskAssessment);
          setIsOwnAssessment(res.assignedCaregiver?.guid === user.guid && res.status !== ASSESSMENT_STATUS.Completed);
        })
        .catch((err) => setInitialError(err))
        .finally(() => setLoadingInitial(false));
    }
  }, [match.params.sessionId]);

  useEffect(() => {
    if (loadingNext) {
      setNextAssessmentRequestCompleted(false);
      api
        .getNextKioskAssessment(authToken)
        .then((nextAssessment) => {
          setAssessment(nextAssessment);
          Mixpanel.time_event(eventType.handleKioskAssessment);
          setNextAssessmentRequestCompleted(true);
          setAssessmentHandled(false);
          history.replace(`/${APPLICATION_BASE_PATH}/kiosk/${nextAssessment.sessionId}`);
        })
        .catch((err) => {
          if (err?.status === HTTP_STATUS_CODES.NOT_FOUND) {
            setNoMoreAssessments(true);
          } else {
            showNotification(I18n.t('kiosk_assessment.actions.error.get_next'), 'error');
            setLoadingNext(false);
          }
        });
    }
  }, [loadingNext]);

  useEffect(() => {
    let timeout;
    if (noMoreAssessments) {
      confetti({
        angle: 60,
        origin: { x: 0.05, y: 0.6 },
        ...confettiSettings
      });
      confetti({
        angle: 120,
        origin: { x: 0.95, y: 0.6 },
        ...confettiSettings
      });
      timeout = setTimeout(() => {
        history.push(`/${APPLICATION_BASE_PATH}/kiosk`);
      }, 3000);
    }

    return () => clearTimeout(timeout);
  }, [noMoreAssessments]);

  /**
   * Animation handling effects
   */

  useEffect(() => {
    const animationEnd = () => {
      setFinalNoteLoadingAnimationCompleted(true);
    };

    wrapperRef.current?.addEventListener('animationend', animationEnd);

    return () => wrapperRef.current?.removeEventListener('animationend', animationEnd);
  }, []);

  useEffect(() => {
    if (nextAssessmentRequestCompleted && nextAssessmentLoadingAnimationCompleted) {
      setLoadingNext(false);
    }
  }, [nextAssessmentRequestCompleted, nextAssessmentLoadingAnimationCompleted]);

  useEffect(() => {
    if (saveFinalNoteRequestCompleted && finalNoteLoadingAnimationCompleted) {
      setSavingFinalNote(false);
      setLoadingNext(true);
    }
  }, [saveFinalNoteRequestCompleted, finalNoteLoadingAnimationCompleted]);

  const writeFinalNoteAndGetNext = async () => {
    // Needed for small screens or long assessments
    window.scrollTo({ top: 0 });

    setSavingFinalNote(true);
    setNextAssessmentLoadingAnimationCompleted(false);
    setFinalNoteLoadingAnimationCompleted(false);

    try {
      setSaveFinalNoteRequestCompleted(false);
      await api.updateKioskAssessmentStatus(authToken, assessment.sessionId, ASSESSMENT_STATUS.Completed);
      completeKioskAssessment();
      setSaveFinalNoteRequestCompleted(true);
      Mixpanel.track(eventType.handleKioskAssessment, { type: ASSESSMENT_STATUS.Completed });
    } catch (err) {
      showNotification(I18n.t('kiosk_assessment.actions.error.write_final_note'), 'error');
      setSavingFinalNote(false);
      return;
    }
  };

  const approveAssessment = async () => {
    try {
      await api.updateKioskAssessmentStatus(authToken, assessment.sessionId, ASSESSMENT_STATUS.Completed);
      Mixpanel.track(eventType.handleKioskAssessment, { type: ASSESSMENT_STATUS.Completed });
      setAssessmentHandled(true);
      history.push(`/${APPLICATION_BASE_PATH}/kiosk`);
    } catch (err) {
      showNotification(I18n.t('kiosk_assessment.actions.error.write_final_note'), 'error');
    }
  };

  const pauseAssessment = async () => {
    try {
      await api.updateKioskAssessmentStatus(authToken, assessment.sessionId, ASSESSMENT_STATUS.CaregiverPaused);
      Mixpanel.track(eventType.handleKioskAssessment, { type: ASSESSMENT_STATUS.CaregiverPaused });
      setAssessmentHandled(true);
      history.push(`/${APPLICATION_BASE_PATH}/kiosk`);
      showNotification(I18n.t('kiosk_assessment.actions.success.pause'), 'success');
    } catch (err) {
      showNotification(I18n.t('kiosk_assessment.actions.error.pause'), 'error');
    }
  };

  const rejectAssessment = async () => {
    try {
      await api.updateKioskAssessmentStatus(authToken, assessment.sessionId, ASSESSMENT_STATUS.CaregiverRejected);
      Mixpanel.track(eventType.handleKioskAssessment, { type: ASSESSMENT_STATUS.CaregiverRejected });
      setAssessmentHandled(true);
      history.push(`/${APPLICATION_BASE_PATH}/kiosk`);
      showNotification(I18n.t('kiosk_assessment.actions.success.reject'), 'success');
    } catch (err) {
      showNotification(I18n.t('kiosk_assessment.actions.error.reject'), 'error');
    }
  };

  const cancel = async () => {
    try {
      await api.updateKioskAssessmentStatus(authToken, assessment.sessionId, ASSESSMENT_STATUS.AwaitingAssessment);
      Mixpanel.track(eventType.handleKioskAssessment, { type: 'Canceled' });
      setAssessmentHandled(true);
      history.push(`/${APPLICATION_BASE_PATH}/kiosk`);
    } catch (err) {
      showNotification(I18n.t('kiosk_assessment.actions.error.cancel'), 'error');
    }
  };

  const actionsDisabled =
    assessment.status === ASSESSMENT_STATUS.CaregiverRejected ||
    assessment.status === ASSESSMENT_STATUS.Completed ||
    !isNurse ||
    !isOwnAssessment;

  const isActionable =
    assessment.status === ASSESSMENT_STATUS.AssessmentInProgress ||
    assessment.status === ASSESSMENT_STATUS.CaregiverPaused;

  return (
    <>
      <Prompt
        message={(location) => {
          if (
            isOwnAssessment &&
            isActionable &&
            !noMoreAssessments &&
            !assessmentHandled &&
            !GUID_REGEX.test(location.pathname)
          ) {
            return I18n.t('kiosk_assessment.leave_unhandled_warning', {
              section: I18n.t(`kiosk_assessment.assessment_list.${decamelize(assessment.status)}.header`)
            });
          }

          return true;
        }}
      />
      <div className={styles.wrapper}>
        <main
          ref={wrapperRef}
          className={`mt-20 ${styles.assessmentWrapper} ${
            savingFinalNote || loadingNext ? styles.isFetchingNext : styles.fadein
          } ${noMoreAssessments ? 'hidden' : ''}`}
        >
          <ContentLoader
            isLoading={loadingInitial}
            error={initialError}
            errorTitleI18n="kiosk_assessment.fetch_assessment_error"
          >
            <header>
              <div>
                <BasicPatientInfo
                  member={{
                    givenName: assessment.givenName,
                    familyName: assessment.familyName,
                    personalNumber: assessment.personalNumber,
                    phoneNumber: assessment.phoneNumber
                  }}
                />
                <AssessmentStatus assessment={assessment} user={user} />
              </div>
              <div className={styles.secondaryActions}>
                {isActionable ? (
                  <>
                    <button
                      className="button is-ghost"
                      onClick={pauseAssessment}
                      disabled={actionsDisabled || assessment?.status === ASSESSMENT_STATUS.CaregiverPaused}
                      title={!isNurse ? I18n.t('kiosk_assessment.non_nurse_info') : undefined}
                    >
                      <Translate value="kiosk_assessment.actions.pause_assessment" />
                    </button>
                    <button
                      className="button is-primary"
                      onClick={rejectAssessment}
                      disabled={actionsDisabled}
                      title={!isNurse ? I18n.t('kiosk_assessment.non_nurse_info') : undefined}
                    >
                      <Translate value="kiosk_assessment.actions.reject_assessment" />
                    </button>
                  </>
                ) : null}
                {assessment.status === ASSESSMENT_STATUS.Completed ||
                assessment.status === ASSESSMENT_STATUS.CaregiverRejected ? (
                  <button
                    className="button is-primary"
                    onClick={() => history.push(`/${APPLICATION_BASE_PATH}/member/${assessment.patientId}`)}
                  >
                    <Translate value="kiosk_assessment.open_dss" />
                  </button>
                ) : null}
              </div>
            </header>
            <div className={styles.assessmentMetadata}>
              <div>
                <Translate
                  value="kiosk_assessment.assessment_meta"
                  location={assessment.kioskLocation || I18n.t('kiosk_assessment.unknown_location')}
                  date={moment(assessment.measurementDate, DATE_FORMAT).format('DD MMM YYYY \\k\\l. HH:mm')}
                  dangerousHTML
                />
              </div>
              <div className={styles.assessmentLanguage}>
                <Translate value="kiosk_assessment.assessment_language" />
                <img src={assessment.locale === 'en' ? gbFlagSrc : seFlagSrc} />
                <Translate value={`global.language.${assessment.locale === 'en' ? 'english' : 'swedish'}`} />
              </div>
            </div>
            <div className="columns mb-30">
              <div className="column no-padding mr-20">
                <h2>
                  <Translate value="kiosk_assessment.anamnesis_summary" />
                </h2>
                <div className={styles.listWrapper} data-sensitive>
                  {assessment?.questionnaire?.map((item, i) => (
                    <div className={styles.listItem} key={i}>
                      <div>{item.questionText}</div>
                      <div>
                        <strong>{item.answer}</strong>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="column no-padding">
                <h2>&nbsp;</h2>
                <div className={styles.listWrapper} data-sensitive>
                  <div className={styles.listItem}>
                    <div>
                      <Translate value="global.blood_pressure" />
                    </div>
                    <div>
                      <span
                        className={`severity ${
                          assessment.assessmentEnum ? assessment.assessmentEnum.replace(/_/g, '-').toLowerCase() : ''
                        }`}
                      >
                        {assessment.systolic}/{assessment.diastolic}
                      </span>{' '}
                      mmHg
                    </div>
                  </div>
                </div>
                <h2 className="mt-20">
                  <Translate value="kiosk_assessment.medical_assessment" />
                </h2>
                <div className={styles.assessmentText}>{assessment.assessment}</div>
                <h2 className="mt-20">
                  <Translate value="kiosk_assessment.recommendation" />
                </h2>
                <div className={styles.assessmentText}>
                  {assessment.information?.map((infoSection, i) => (
                    <Fragment key={i}>
                      <h3>{infoSection.title}</h3>
                      <p>{infoSection.text}</p>
                    </Fragment>
                  ))}
                </div>
                {assessment?.btd_invite_type && (
                  <h3>
                    <Translate value={`kiosk_assessment.btd_invite_type.${decamelize(assessment.btd_invite_type)}`} />
                  </h3>
                )}
              </div>
            </div>
            <footer className={`${styles.primaryActions} ${!isActionable ? 'hidden' : ''}`}>
              {!actionsDisabled ? (
                <div className={styles.cancelButton}>
                  <span className="text-button chevron-left" onClick={cancel}>
                    <Translate
                      value={`kiosk_assessment.actions.cancel_assessment${
                        assessment?.status === ASSESSMENT_STATUS.CaregiverPaused ? '_paused' : ''
                      }`}
                    />
                  </span>
                </div>
              ) : null}
              <span className="mr-20">
                <LoaderButton
                  isSecondary
                  onClick={approveAssessment}
                  disabled={actionsDisabled}
                  title={!isNurse ? I18n.t('kiosk_assessment.non_nurse_info') : undefined}
                >
                  <Translate value="kiosk_assessment.actions.write_final_note" />
                </LoaderButton>
              </span>
              {assessment?.status !== ASSESSMENT_STATUS.CaregiverPaused ? (
                <LoaderButton
                  isSecondary
                  onClick={writeFinalNoteAndGetNext}
                  isLoading={false}
                  disabled={actionsDisabled}
                  title={!isNurse ? I18n.t('kiosk_assessment.non_nurse_info') : undefined}
                >
                  <Translate value="kiosk_assessment.actions.write_final_note_and_get_next" />
                  <span className={styles.rightArrow}></span>
                </LoaderButton>
              ) : null}
            </footer>
          </ContentLoader>
        </main>
        {!noMoreAssessments && !loadingInitial && !initialError ? (
          <SkeletonKioskView
            className={`${styles.assessmentWrapper} ${styles.skeleton} ${loadingNext ? styles.skeletonLoading : ''} ${
              savingFinalNote ? styles.skeletonScaleDown : ''
            }`}
            numQuestions={assessment?.questionnaire?.length}
            onAnimationEnd={() => {
              setNextAssessmentLoadingAnimationCompleted(true);
            }}
          />
        ) : null}
        {noMoreAssessments ? (
          <div className={`rounded-container mt-50 ${styles.finishedAssessments}`}>
            <Translate value="kiosk_assessment.all_assessments_completed" />
          </div>
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    assessments: state.assessments,
    user: state.auth.token.user,
    isNurse: state.auth.isNurse
  };
};

const mapActionsToProps = {
  completeKioskAssessment,
  showNotification
};

export default connect(mapStateToProps, mapActionsToProps)(KioskAssessmentView);
