import React, { useRef } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { DebounceInput } from 'react-debounce-input';
import { decamelize } from '../../utils';
import MedicationRow from '../MedicationView/MedicationTable/RowTypes/MedicationRow';
import CurrentMedicationsColumnHeaders from '../MedicationView/CurrentMedicationsColumnHeaders';
import MedicationTable from '../MedicationView/MedicationTable';
import './FinalNoteModalContent.scss';
import {
  DIAGNOSED_ICD_CODE,
  UNDIAGNOSED_ICD_CODE,
  MAX_NOTE_LENGTH,
  PATIENT_FEE_EXEMPTED_SOURCES
} from '../../constants';

const FinalNoteModalContent = ({
  isNurse,
  selectedReason,
  onReasonChanged,
  treatmentReason,
  onTreatmentReasonChanged,
  diagnosisCodes,
  additionalDiagnosisCodes,
  diagnosisCode,
  onDiagnosisCodeChanged,
  onConcatenatedCodeChange,
  onAdditionalDiagnosisCodeChanged,
  onToggleDiagnosisCode,
  medications,
  currentMedicationsEmptyI18nKey,
  availableReasons,
  selectedNurseTransferDelay,
  onSelectNurseTransferDelay,
  onPatientFeeSelection,
  standardAssessmentOptionState,
  onStandardAssessmentSelection,
  finalNoteTouched,
  hasPrescribedMedicationDuringCheckup,
  memberSource
}) => {
  const finalNoteTextArea = useRef();

  const selectStandardAssessment = (e) => {
    if (e.target.value !== 'unselected' && !finalNoteTextArea.current?.scrollTop) {
      finalNoteTextArea.current?.scrollTo({
        top: finalNoteTextArea.current.scrollHeight,
        behavior: 'smooth'
      });
    }

    setTimeout(onStandardAssessmentSelection, finalNoteTextArea.current?.scrollTop ? 0 : 500, e.target.value);
  };

  return (
    <div className="final-note-modal-container">
      <div className="columns">
        <div className="column no-padding">
          <h4>
            <Translate value="decision_support_view.checkup_section.reason_header" />
          </h4>
          <div className="columns">
            {availableReasons.map((reason, i) => {
              return (
                <div className={`column no-padding is-${availableReasons.length === 1 ? '4' : '2'}`} key={i}>
                  <input
                    type="radio"
                    id={`reason-${reason}`}
                    value={reason}
                    name={`reason-${reason}`}
                    checked={selectedReason === reason}
                    onChange={onReasonChanged}
                  />
                  <label htmlFor={`reason-${reason}`}>
                    <Translate value={`global.checkup_type.${decamelize(reason)}`} />
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column is-12 no-padding">
          <div className="columns mb-0">
            <div className="column no-padding">
              <h4>
                <Translate value="decision_support_view.checkup_section.treatment_reason" />
              </h4>
            </div>
            <div className="column no-padding text-right">
              <h4>
                <Translate value="global.remaining_characters" remaining={MAX_NOTE_LENGTH - treatmentReason.length} />
              </h4>
            </div>
          </div>
          <DebounceInput
            className="treatment-reason-text"
            element="textarea"
            maxLength={MAX_NOTE_LENGTH}
            value={treatmentReason}
            onChange={onTreatmentReasonChanged}
            inputRef={finalNoteTextArea}
            data-sensitive
          />
          {!isNurse ? (
            <div className="select">
              <select
                value={standardAssessmentOptionState}
                onChange={selectStandardAssessment}
                disabled={finalNoteTouched}
                title={
                  finalNoteTouched
                    ? I18n.t('decision_support_view.final_note.standard_assessment_disabled_title')
                    : undefined
                }
              >
                <option value="unselected">
                  {I18n.t('decision_support_view.final_note.select_standard_assessment')}
                </option>
                <option value="good">{I18n.t('decision_support_view.final_note.bp_normal')}</option>
                <option value="elevatedNoMedicationChange">
                  {I18n.t('decision_support_view.final_note.bp_elevated_medication_unchanged')}
                </option>
                <option value="elevatedMedicationChange">
                  {I18n.t('decision_support_view.final_note.bp_elevated_medication_changed')}
                </option>
              </select>
            </div>
          ) : null}
        </div>
      </div>
      <MedicationTable
        titleI18n="decision_support_view.medication_section.current_medication_header"
        data={medications.memberMedications && medications.currentMedications}
        error={medications.memberMedicationsError}
        errorTitleI18n="patient_view.medications.errors.loading_medications"
        emptyI18n={currentMedicationsEmptyI18nKey}
        rowType={MedicationRow}
        ColumnHeaderComponent={CurrentMedicationsColumnHeaders}
      />
      {!isNurse ? (
        <div className="columns mt-30">
          <div className="column is-6 no-padding">
            <div className="columns mb-20">
              <div className="column no-padding mr-10">
                <h4>
                  <span className="nowrap">
                    <Translate value="decision_support_view.checkup_section.diagnosis_code" />
                  </span>
                </h4>
                <DebounceInput value={diagnosisCode} onChange={onConcatenatedCodeChange} />
              </div>
              <div className="column no-padding">
                <h4>
                  <Translate value="decision_support_view.checkup_section.icd_selection" />
                </h4>
                <div className="select">
                  <select onChange={onDiagnosisCodeChanged}>
                    {diagnosisCodes.caregiver.map((code) => (
                      <option key={code.value} value={code.value}>
                        {code.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <a href="http://icd.internetmedicin.se/diagnos/" target="_blank" rel="noopener noreferrer">
              <Translate value="decision_support_view.checkup_section.search_diagnosis_code" />
            </a>{' '}
            <span>
              (<Translate value="decision_support_view.checkup_section.opens_new_tab" />)
            </span>
          </div>
        </div>
      ) : (
        <>
          <div className="mt-30 mb-20">
            <h4>
              <span className="nowrap">
                <Translate value="decision_support_view.checkup_section.diagnosis_code" />
              </span>
            </h4>
            <div className="flex">
              <div className="selected-diagnosis-code">{diagnosisCode}</div>
              <button
                className="button is-primary"
                onClick={() =>
                  onToggleDiagnosisCode(
                    diagnosisCode === DIAGNOSED_ICD_CODE ? UNDIAGNOSED_ICD_CODE : DIAGNOSED_ICD_CODE
                  )
                }
              >
                Byt till {diagnosisCode === DIAGNOSED_ICD_CODE ? UNDIAGNOSED_ICD_CODE : DIAGNOSED_ICD_CODE}
              </button>
            </div>
          </div>
          <div className="stack-1 mb-20">
            <h4>
              <Translate value="decision_support_view.final_note.other_treatment_codes" />
            </h4>
            {diagnosisCodes.nurse.map((code, i) => (
              <div key={code.value}>
                <input
                  type="checkbox"
                  value={code.value}
                  id={`diagnosis-code-${i}`}
                  checked={additionalDiagnosisCodes.includes(code.value)}
                  onChange={onAdditionalDiagnosisCodeChanged}
                />
                <label className="ml-5 fs-16" htmlFor={`diagnosis-code-${i}`}>
                  {code.value} - {code.label}
                </label>
              </div>
            ))}
          </div>
        </>
      )}
      {!isNurse ? (
        <>
          {!PATIENT_FEE_EXEMPTED_SOURCES.includes(memberSource) &&
          availableReasons.length === 1 &&
          availableReasons.includes('extraCheckup') ? (
            <div className="columns">
              <div className="column no-padding">
                <h2>
                  <Translate value="decision_support_view.final_note.patient_fee_header" />
                </h2>
                <div className="mt-10">
                  <div>
                    <input
                      type="radio"
                      value="no-fee"
                      id="patient-fee-none"
                      name="patient-fee"
                      checked={hasPrescribedMedicationDuringCheckup}
                      onChange={onPatientFeeSelection}
                    />
                    <label htmlFor="patient-fee-none">
                      <Translate value="decision_support_view.final_note.patient_fee_not_required" />
                    </label>
                  </div>
                  <div className="mt-5">
                    <input
                      type="radio"
                      value="fee"
                      id="patient-fee-required"
                      name="patient-fee"
                      checked={!hasPrescribedMedicationDuringCheckup}
                      onChange={onPatientFeeSelection}
                    />
                    <label htmlFor="patient-fee-required">
                      <Translate value="decision_support_view.final_note.patient_fee_required" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="mt-10">
            <h2>
              <Translate value="decision_support_view.final_note.role_transfer_header" />
            </h2>
            <div className="select">
              <select onChange={onSelectNurseTransferDelay} value={selectedNurseTransferDelay}>
                <option value="-1">{I18n.t('decision_support_view.final_note.role_transfer_default_option')}</option>
                <option value="0">{I18n.t('decision_support_view.final_note.role_transfer_delay.delay_0')}</option>
                <option value="7">{I18n.t('decision_support_view.final_note.role_transfer_delay.delay_7')}</option>
                <option value="14">{I18n.t('decision_support_view.final_note.role_transfer_delay.delay_14')}</option>
              </select>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default FinalNoteModalContent;
