export type InboxReducerState = {
  historyCutoffDays: number;
  isViewingAllErrands: boolean;
  caregivers: SystemUser[];
  selectedCaregiver?: SystemUser;
  filters: Record<string, string[]>;
  waitingTimeSortOrder: Record<string, SortOrder>;
  currentSectionPage: Record<string, number>;
  lastVisitedTab: InboxTab;
};

export type InboxTab = 'errands' | 'appointments';

type InboxAction =
  | { type: 'setHistoryCutoffDays'; numDays: number }
  | { type: 'toggleViewingAllErrands' }
  | { type: 'selectCaregiverFilter'; caregiver?: SystemUser }
  | { type: 'setFilter'; filter: string; reasons: string[] }
  | { type: 'setSortOrder'; section: string }
  | { type: 'setSectionPage'; section: string; page: number }
  | { type: 'setLastVisitedTab'; tab: InboxTab };

export const initialState: InboxReducerState = {
  historyCutoffDays: 30,
  isViewingAllErrands: false,
  caregivers: [],
  filters: {},
  waitingTimeSortOrder: {},
  currentSectionPage: {},
  lastVisitedTab: window.location.href.includes('appointments') ? 'appointments' : 'errands'
};

const reducer = (state: InboxReducerState = initialState, action: InboxAction): InboxReducerState => {
  switch (action.type) {
    case 'setHistoryCutoffDays':
      return { ...state, historyCutoffDays: action.numDays };
    case 'toggleViewingAllErrands':
      return {
        ...state,
        isViewingAllErrands: !state.isViewingAllErrands,
        selectedCaregiver: state.isViewingAllErrands ? undefined : state.selectedCaregiver,
        currentSectionPage: {}
      };
    case 'selectCaregiverFilter':
      return { ...state, selectedCaregiver: action.caregiver, currentSectionPage: {} };
    case 'setFilter':
      return { ...state, filters: { ...state.filters, [action.filter]: action.reasons }, currentSectionPage: {} };
    case 'setSortOrder':
      return {
        ...state,
        waitingTimeSortOrder: {
          ...state.waitingTimeSortOrder,
          [action.section]: state.waitingTimeSortOrder[action.section] === 'DESC' ? 'ASC' : 'DESC'
        }
      };
    case 'setSectionPage':
      return { ...state, currentSectionPage: { ...state.currentSectionPage, [action.section]: action.page } };
    case 'setLastVisitedTab':
      return { ...state, lastVisitedTab: action.tab };
    default:
      throw new Error(`Unhandled action type: ${action}`);
  }
};

export default reducer;
