export const loadState = (key, parse) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }

    return parse ? JSON.parse(serializedState) : serializedState;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state, key) => {
  try {
    const serializedState = typeof state === 'string' ? state : JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    // Ignore
  }
};

export const removeItem = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    // Ignore
  }
};
