import React, { Component } from 'react';
import { Translate } from 'react-redux-i18n';
import './AnamnesisAnswer.scss';

class AnamnesisAnswer extends Component {
  render() {
    const { question } = this.props;
    let answer = '';
    let answerIndices = [];
    let alternatives = [];

    if (!question.userAnswer) {
      return (
        <div className="anamnesis-chapter-content__answer">
          <Translate value="patient_view.anamnesis.no_answer" />
        </div>
      );
    }

    if (question.userAnswer.skipped) {
      return (
        <div className="anamnesis-chapter-content__answer skipped-question">
          <Translate value="patient_view.anamnesis.skipped_question" />
        </div>
      );
    }

    switch (question.type) {
      case 'single-choice':
        answer = question.alternatives[question.userAnswer.answers[0]];
        answerIndices = question.userAnswer.answers[0];
        alternatives = question.alternatives;
        break;
      case 'multi-choice':
        question.userAnswer.answers.forEach((answerIndex) => {
          answer += question.alternatives[answerIndex] + ', ';
        });
        answer = answer.substr(0, answer.length - 2);
        alternatives = question.alternatives;
        answerIndices = question.userAnswer.answers.map(Number);
        break;
      case 'selector':
        if (question.userAnswer.answers.length > 1) {
          let minSys = parseInt(question.range[0], 10);
          let minDia = parseInt(question.range[2], 10);
          let sys = minSys + parseInt(question.userAnswer.answers[0], 10);
          let dia = minDia + parseInt(question.userAnswer.answers[1], 10);
          answer = `${sys}/${dia}`;
        } else {
          let minValue = parseInt(question.range[0], 10);
          answer = minValue + parseInt(question.userAnswer.answers[0], 10);
        }
        break;
      case 'freetext':
        answer = question.userAnswer.answers[0];
        break;
      case 'medicationlist':
        answer = <Translate value="patient_view.anamnesis.see_medicine_list" />;
        break;
      case 'multi-text':
        answer = question.userAnswer.answers.join(', ');
        break;
      default:
        answer = '?';
    }

    return (
      <div className="anamnesis-chapter-content__answer" data-sensitive>
        {alternatives.length ? (
          alternatives.map((alternative, i) => {
            return (
              <div key={i} className={`${answerIndices.indexOf(i) > -1 ? 'answer-highlight' : 'question-alternative'}`}>
                {alternative}
              </div>
            );
          })
        ) : (
          <span className="answer" data-sensitive>
            {answer}
          </span>
        )}
      </div>
    );
  }
}

export default AnamnesisAnswer;
