import React, { Fragment } from 'react';
import { Translate, I18n } from 'react-redux-i18n';

const DosageWithFallback = ({ medication }) => {
  return (
    <Fragment>
      {medication.dosageRegimen ? (
        medication.whenNeeded ? (
          `${medication.dosageRegimen} (${I18n.t('patient_view.medications.when_needed')})`
        ) : (
          medication.dosageRegimen
        )
      ) : medication.whenNeeded ? (
        <Translate value="patient_view.medications.when_needed" />
      ) : (
        '-'
      )}
    </Fragment>
  );
};

export default DosageWithFallback;
