import React, { Fragment, useState } from 'react';
import { Translate } from 'react-redux-i18n';
import './ConsentMessage.scss';

const ConsentOption = ({ option }) => {
  const [expanded, setExpanded] = useState(false);
  const isExpandable = !!option.text;

  const onOptionClick = () => {
    if (isExpandable) {
      setExpanded(!expanded);
    }
  };

  return (
    <div className="row">
      <h4 className={isExpandable ? 'pointer' : ''} onClick={onOptionClick}>
        <Translate value={`patient_view.chat.consent_message.option_${option.consentType}`} />
        {isExpandable ? <span className={`text-button chevron ${expanded ? 'up' : 'down'}`}></span> : null}
      </h4>
      {expanded ? (
        <blockquote>
          <p>{option.text}</p>
          <a href={option.moreInfoLink} target="_blank" rel="noopener noreferrer">
            {option.moreInfoTitle}
          </a>
        </blockquote>
      ) : null}
    </div>
  );
};

const ConsentMessage = ({ message }) => {
  const [expanded, setExpanded] = useState(false);

  if (
    !message.checkupOptions ||
    !message.checkupOptions.availableOptions ||
    !message.checkupOptions.availableOptions.length
  ) {
    return null;
  }

  return (
    <div
      className="system-message-wrapper consent-message"
      onClick={!expanded ? () => setExpanded(!expanded) : undefined}
    >
      <div className={`system-message-container ${expanded ? 'expanded cursor-default' : ''}`}>
        {expanded ? (
          <div className="system-message">
            <div className="system-message__content p-5">
              <div className="pointer" onClick={() => setExpanded(!expanded)}>
                <h3>
                  {message.checkupOptions?.chosenOption ? (
                    <Translate value={`patient_view.chat.consent_message.${message.checkupOptions.chosenOption}`} />
                  ) : (
                    <Translate value="patient_view.chat.consent_message.option_not_chosen_short" />
                  )}
                </h3>
                <blockquote>
                  {message.text?.split('\n').map((item, key) => {
                    return (
                      <span key={key}>
                        {item}
                        <br />
                      </span>
                    );
                  })}
                </blockquote>
              </div>
              <div className="cursor-default">
                <a href={message.checkupOptions.moreInfoLink} target="_blank" rel="noopener noreferrer">
                  {message.checkupOptions.moreInfoTitle}
                </a>
                {message.checkupOptions.chosenOption ? (
                  <div className="chosen-option">
                    <span className="icons icon action-done"></span>
                    <Translate
                      className="pl-10"
                      value={`patient_view.chat.consent_message.option_${message.checkupOptions.chosenOption}`}
                    />
                  </div>
                ) : (
                  <>
                    <h3 className="mt-10">
                      <Translate value="patient_view.chat.consent_message.available_options" />
                    </h3>
                    <div className="consent-message-option-table">
                      {message.checkupOptions.availableOptions.map((option) => {
                        return <ConsentOption key={option.consentType} option={option} />;
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div onClick={() => setExpanded(!expanded)}>
            <Fragment>
              {message.checkupOptions?.chosenOption ? (
                <Translate value={`patient_view.chat.consent_message.${message.checkupOptions.chosenOption}`} />
              ) : (
                <Translate value="patient_view.chat.consent_message.option_not_chosen" />
              )}
              <span className="chevron"></span>
            </Fragment>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConsentMessage;
