/**
 * Authentication actions
 */

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const ACKNOWLEDGE_LOGIN_ERROR = 'ACKNOWLEDGE_LOGIN_ERROR';
export const CHECK_TOKEN_VALIDITY = 'CHECK_TOKEN_VALIDITY';
export const MANUAL_TOKEN_REFRESH_REQUEST = 'MANUAL_TOKEN_REFRESH_REQUEST';
export const MANUAL_TOKEN_REFRESH_SUCCESS = 'MANUAL_TOKEN_REFRESH_SUCCESS';

/**
 * Member data actions
 */

export const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST';
export const GET_USER_PROFILE_ERROR = 'GET_USER_PROFILE_ERROR';
export const PATIENT_DATA_REQUEST = 'PATIENT_DATA_REQUEST';
export const PATIENT_DATA_SUCCESS = 'PATIENT_DATA_SUCCESS';
export const PATIENT_DATA_ERROR = 'PATIENT_DATA_ERROR';
export const CLEAR_PATIENT_DATA = 'CLEAR_PATIENT_DATA';
export const MEMBER_MEASUREMENTS_REQUEST = 'MEMBER_MEASUREMENTS_REQUEST';
export const MEMBER_MEASUREMENTS_SUCCESS = 'MEMBER_MEASUREMENTS_SUCCESS';
export const MEMBER_MEASUREMENTS_ERROR = 'MEMBER_MEASUREMENTS_ERROR';
export const MEMBER_STATS_REQUEST = 'MEMBER_STATS_REQUEST';
export const MEMBER_STATS_SUCCESS = 'MEMBER_STATS_SUCCESS';
export const MEMBER_STATS_ERROR = 'MEMBER_STATS_ERROR';
export const SET_STATS_PERIOD_SIZE = 'SET_STATS_PERIOD_SIZE';
export const GET_MEMBER_DEVICES_REQUEST = 'GET_MEMBER_DEVICES_REQUEST';
export const GET_MEMBER_DEVICES_SUCCESS = 'GET_MEMBER_DEVICES_SUCCESS';
export const GET_MEMBER_DEVICES_ERROR = 'GET_MEMBER_DEVICES_ERROR';
export const MARK_MEMBER_INACTIVE_REQUEST = 'MARK_MEMBER_INACTIVE_REQUEST';
export const MARK_MEMBER_INACTIVE_SUCCESS = 'MARK_MEMBER_INACTIVE_SUCCESS';
export const MARK_MEMBER_INACTIVE_ERROR = 'MARK_MEMBER_INACTIVE_ERROR';
export const GET_PROFILE_IMAGE_REQUEST = 'GET_PROFILE_IMAGE_REQUEST';
export const GET_PROFILE_IMAGE_SUCCESS = 'GET_PROFILE_IMAGE_SUCCESS';
export const GET_PROFILE_IMAGE_ERROR = 'GET_PROFILE_IMAGE_ERROR';
export const CLEAR_PROFILE_IMAGE = 'CLEAR_PROFILE_IMAGE';
export const GET_MEMBER_JIRA_ISSUES_REQUEST = 'GET_MEMBER_JIRA_ISSUES_REQUEST';
export const GET_MEMBER_JIRA_ISSUES_SUCCESS = 'GET_MEMBER_JIRA_ISSUES_SUCCESS';
export const GET_MEMBER_JIRA_ISSUES_ERROR = 'GET_MEMBER_JIRA_ISSUES_ERROR';
export const POST_MEMBER_JIRA_ISSUE_REQUEST = 'POST_MEMBER_JIRA_ISSUE_REQUEST';
export const POST_MEMBER_JIRA_ISSUE_SUCCESS = 'POST_MEMBER_JIRA_ISSUE_SUCCESS';
export const POST_MEMBER_JIRA_ISSUE_ERROR = 'POST_MEMBER_JIRA_ISSUE_ERROR';
export const SHOW_JIRA_MODAL = 'SHOW_JIRA_MODAL';
export const HIDE_JIRA_MODAL = 'HIDE_JIRA_MODAL';
export const SET_JIRA_ISSUE_TYPE = 'SET_JIRA_ISSUE_TYPE';
export const SET_JIRA_ISSUE_DESCRIPTION = 'SET_JIRA_ISSUE_DESCRIPTION';
export const GET_MEMBER_COMMUNICATION_EVENTS_REQUEST = 'GET_MEMBER_COMMUNICATION_EVENTS_REQUEST';
export const GET_MEMBER_COMMUNICATION_EVENTS_SUCCESS = 'GET_MEMBER_COMMUNICATION_EVENTS_SUCCESS';
export const GET_MEMBER_COMMUNICATION_EVENTS_ERROR = 'GET_MEMBER_COMMUNICATION_EVENTS_ERROR';
export const SHOW_COMMUNICATION_EVENTS_CONTENT_MODAL = 'SHOW_COMMUNICATION_EVENTS_CONTENT_MODAL';
export const HIDE_COMMUNICATION_EVENTS_CONTENT_MODAL = 'HIDE_COMMUNICATION_EVENTS_CONTENT_MODAL';
export const GET_MEMBER_FULL_COMMUNICATION_EVENT_REQUEST = 'GET_MEMBER_FULL_COMMUNICATION_EVENT_REQUEST ';
export const GET_MEMBER_FULL_COMMUNICATION_EVENT_SUCCESS = 'GET_MEMBER_FULL_COMMUNICATION_EVENT_SUCCESS';
export const GET_MEMBER_FULL_COMMUNICATION_EVENT_ERROR = 'GET_MEMBER_FULL_COMMUNICATION_EVENT_ERROR';
export const SET_PATIENT_COMMUNICATION_VIEW_CURRENT_PAGE = 'SET_PATIENT_COMMUNICATION_VIEW_CURRENT_PAGE';
export const GET_MEMBER_MONITORS_REQUEST = 'GET_MEMBER_MONITORS_REQUEST';
export const GET_MEMBER_MONITORS_SUCCESS = 'GET_MEMBER_MONITORS_SUCCESS';
export const GET_MEMBER_MONITORS_ERROR = 'GET_MEMBER_MONITORS_ERROR';

/**
 * Anamnesis actions
 */

export const GET_CHAPTERS_REQUEST = 'GET_CHAPTERS_REQUEST';
export const GET_CHAPTERS_SUCCESS = 'GET_CHAPTERS_SUCCESS';
export const GET_CHAPTERS_ERROR = 'GET_CHAPTERS_ERROR';

/**
 * Medication actions
 */

export const GET_PRESCRIPTION_REQUEST = 'GET_PRESCRIPTION_REQUEST';
export const GET_PRESCRIPTION_SUCCESS = 'GET_PRESCRIPTION_SUCCESS';
export const GET_PRESCRIPTION_ERROR = 'GET_PRESCRIPTION_ERROR';
export const POST_PRESCRIPTION_REQUEST = 'POST_PRESCRIPTION_REQUEST';
export const POST_PRESCRIPTION_SUCCESS = 'POST_PRESCRIPTION_SUCCESS';
export const POST_PRESCRIPTION_ERROR = 'POST_PRESCRIPTION_ERROR';
export const CLEAR_MEDICATIONS = 'CLEAR_MEDICATIONS';
export const GET_MEMBER_MEDICATIONS_REQUEST = 'GET_MEMBER_MEDICATIONS_REQUEST';
export const GET_MEMBER_MEDICATIONS_SUCCESS = 'GET_MEMBER_MEDICATIONS_SUCCESS';
export const GET_MEMBER_MEDICATIONS_ERROR = 'GET_MEMBER_MEDICATIONS_ERROR';
export const OPEN_ALFA_ERECEPT_MODAL = 'OPEN_ALFA_ERECEPT_MODAL';
export const CLOSE_ALFA_ERECEPT_MODAL = 'CLOSE_ALFA_ERECEPT_MODAL';
export const EDIT_MEDICATION_REQUEST = 'EDIT_MEDICATION_REQUEST';
export const EDIT_MEDICATION_SUCCESS = 'EDIT_MEDICATION_SUCCESS';
export const EDIT_MEDICATION_ERROR = 'EDIT_MEDICATION_ERROR';
export const SIGN_MEDICATION_REQUEST = 'SIGN_MEDICATION_REQUEST';
export const SIGN_MEDICATION_SUCCESS = 'SIGN_MEDICATION_SUCCESS';
export const SIGN_MEDICATION_ERROR = 'SIGN_MEDICATION_ERROR';
export const SHOW_SIGN_MEDICATION_MODAL = 'SHOW_SIGN_MEDICATION_MODAL';
export const HIDE_SIGN_MEDICATION_MODAL = 'HIDE_SIGN_MEDICATION_MODAL';
export const CLOSE_SIGN_MEDICATION_MODAL = 'CLOSE_SIGN_MEDICATION_MODAL';
export const SELECT_MEDICATION = 'SELECT_MEDICATION';
export const UPDATE_MEDICATION_PROPERTY = 'UPDATE_MEDICATION_PROPERTY';
export const DISMISS_SIGNING_ERROR_MESSAGE = 'DISMISS_SIGNING_ERROR_MESSAGE';
export const SEARCH_MEDICATION_ALTERNATIVES_REQUEST = 'SEARCH_MEDICATION_ALTERNATIVES_REQUEST';
export const SEARCH_MEDICATION_ALTERNATIVES_SUCCESS = 'SEARCH_MEDICATION_ALTERNATIVES_SUCCESS';
export const SEARCH_MEDICATION_ALTERNATIVES_ERROR = 'SEARCH_MEDICATION_ALTERNATIVES_ERROR';
export const SELECT_MEDICATION_CONFLICT_OPTION = 'SELECT_MEDICATION_CONFLICT_OPTION';
export const SHOW_VARA_SEARCH_MODAL = 'SHOW_VARA_SEARCH_MODAL';
export const HIDE_VARA_SEARCH_MODAL = 'HIDE_VARA_SEARCH_MODAL';
export const SHOW_VARA_RESULT_SELECTION_MODAL = 'SHOW_VARA_RESULT_SELECTION_MODAL';
export const HIDE_VARA_RESULT_SELECTION_MODAL = 'HIDE_VARA_RESULT_SELECTION_MODAL';
export const VARA_GET_SUGGESTIONS_REQUEST = 'VARA_GET_SUGGESTIONS_REQUEST';
export const VARA_GET_SUGGESTIONS_SUCCESS = 'VARA_GET_SUGGESTIONS_SUCCESS';
export const VARA_GET_SUGGESTIONS_ERROR = 'VARA_GET_SUGGESTIONS_ERROR';
export const SELECT_VARA_SEARCH_RESULT = 'SELECT_VARA_SEARCH_RESULT';
export const SELECT_VARA_IMPORT_TYPE = 'SELECT_VARA_IMPORT_TYPE';
export const IMPORT_VARA_SEARCH_RESULT = 'IMPORT_VARA_SEARCH_RESULT';
export const ADD_MEDICATION_MANUALLY = 'ADD_MEDICATION_MANUALLY';
export const ADD_MEDICATION_REQUEST = 'ADD_MEDICATION_REQUEST';
export const ADD_MEDICATION_SUCCESS = 'ADD_MEDICATION_SUCCESS';
export const ADD_MEDICATION_ERROR = 'ADD_MEDICATION_ERROR';
export const VIEW_MEDICATION = 'VIEW_MEDICATION';
export const SHOW_MEDICATION_MODAL = 'SHOW_MEDICATION_MODAL';
export const HIDE_MEDICATION_MODAL = 'HIDE_MEDICATION_MODAL';
export const CANCEL_MEDICATION_REQUEST = 'CANCEL_MEDICATION_REQUEST';
export const CANCEL_MEDICATION_SUCCESS = 'CANCEL_MEDICATION_SUCCESS';
export const CANCEL_MEDICATION_ERROR = 'CANCEL_MEDICATION_ERROR';
export const INVALIDATE_MEDICATION_REQUEST = 'INVALIDATE_MEDICATION_REQUEST';
export const INVALIDATE_MEDICATION_SUCCESS = 'INVALIDATE_MEDICATION_SUCCESS';
export const INVALIDATE_MEDICATION_ERROR = 'INVALIDATE_MEDICATION_ERROR';
export const DISMISS_CANCELLATION_ERROR_MESSAGE = 'DISMISS_CANCELLATION_ERROR_MESSAGE';
export const UPDATE_CURRENT_MEDICATION_PROPERTY = 'UPDATE_CURRENT_MEDICATION_PROPERTY';
export const CHANGE_MEDICATION_PROPERTY_REQUEST = 'CHANGE_MEDICATION_PROPERTY_REQUEST';
export const CHANGE_MEDICATION_PROPERTY_SUCCESS = 'CHANGE_MEDICATION_PROPERTY_SUCCESS';
export const CHANGE_MEDICATION_PROPERTY_ERROR = 'CHANGE_MEDICATION_PROPERTY_ERROR';
export const SHOW_CHANGE_MEDICATION_PROPERTY_MODAL = 'SHOW_CHANGE_MEDICATION_PROPERTY_MODAL';
export const HIDE_CHANGE_MEDICATION_PROPERTY_MODAL = 'HIDE_CHANGE_MEDICATION_PROPERTY_MODAL';
export const GET_MEMBER_MEDICINE_EXTRAS_REQUEST = 'GET_MEMBER_MEDICINE_EXTRAS_REQUEST';
export const GET_MEMBER_MEDICINE_EXTRAS_SUCCESS = 'GET_MEMBER_MEDICINE_EXTRAS_SUCCESS';
export const GET_MEMBER_MEDICINE_EXTRAS_ERROR = 'GET_MEMBER_MEDICINE_EXTRAS_ERROR';
export const SET_VARA_BYPASS_REASON = 'SET_VARA_BYPASS_REASON';
export const SET_VARA_BYPASS_REASON_TEXT = 'SET_VARA_BYPASS_REASON_TEXT';
export const CONTINUE_WITHOUT_VARA_IMPORT = 'CONTINUE_WITHOUT_VARA_IMPORT';
export const TOGGLE_VARA_BYPASS_INFO = 'TOGGLE_VARA_BYPASS_INFO';

/**
 * Member list actions
 */

export const GET_ACTIVE_MEMBERS_REQUEST = 'GET_ACTIVE_MEMBERS_REQUEST';
export const GET_ACTIVE_MEMBERS_SUCCESS = 'GET_ACTIVE_MEMBERS_SUCCESS';
export const GET_ACTIVE_MEMBERS_ERROR = 'GET_ACTIVE_MEMBERS_ERROR';
export const SET_CURRENT_PAGE_ACTIVE = 'SET_CURRENT_PAGE_ACTIVE';
export const SET_SEARCH_QUERY_ACTIVE = 'SET_SEARCH_QUERY_ACTIVE';
export const GET_INACTIVE_MEMBERS_REQUEST = 'GET_INACTIVE_MEMBERS_REQUEST';
export const GET_INACTIVE_MEMBERS_SUCCESS = 'GET_INACTIVE_MEMBERS_SUCCESS';
export const GET_INACTIVE_MEMBERS_ERROR = 'GET_INACTIVE_MEMBERS_ERROR';
export const SET_CURRENT_PAGE_INACTIVE = 'SET_CURRENT_PAGE_INACTIVE';
export const SET_SEARCH_QUERY_INACTIVE = 'SET_SEARCH_QUERY_INACTIVE';

/**
 * Chat actions
 */

export const GET_CHAT_MESSAGES_REQUEST = 'GET_CHAT_MESSAGES_REQUEST';
export const GET_CHAT_MESSAGES_SUCCESS = 'GET_CHAT_MESSAGES_SUCCESS';
export const GET_CHAT_MESSAGES_ERROR = 'GET_CHAT_MESSAGES_ERROR';
export const POST_CHAT_MESSAGE_REQUEST = 'POST_CHAT_MESSAGE_REQUEST';
export const POST_CHAT_MESSAGE_SUCCESS = 'POST_CHAT_MESSAGE_SUCCESS';
export const POST_CHAT_MESSAGE_ERROR = 'POST_CHAT_MESSAGE_ERROR';
export const CLEAR_CHAT_MESSAGES = 'CLEAR_CHAT_MESSAGES';
export const DELETE_CHAT_DRAFT_REQUEST = 'DELETE_CHAT_DRAFT_REQUEST';
export const DELETE_CHAT_DRAFT_SUCCESS = 'DELETE_CHAT_DRAFT_SUCCESS';
export const DELETE_CHAT_DRAFT_ERROR = 'DELETE_CHAT_DRAFT_ERROR';
export const UPDATE_DRAFT_COUNTDOWN = 'UPDATE_DRAFT_COUNTDOWN';
export const START_EDITING_DRAFT = 'START_EDITING_DRAFT';
export const UPDATE_DRAFT_TEXT = 'UPDATE_DRAFT_TEXT';
export const UPDATE_MESSAGE_TEXT = 'UPDATE_MESSAGE_TEXT';
export const TOGGLE_CHAT_MODAL = 'TOGGLE_CHAT_MODAL';
export const POPULATE_CANNED_RESPONSE = 'POPULATE_CANNED_RESPONSE';
export const SET_MESSAGE_TYPE = 'SET_MESSAGE_TYPE';
export const GET_CHAT_RESPONSES_REQUESTS = 'GET_CHAT_RESPONSES_REQUESTS';
export const GET_CHAT_RESPONSES_SUCCESS = 'GET_CHAT_RESPONSES_SUCCESS';
export const GET_CHAT_RESPONSES_ERROR = 'GET_CHAT_RESPONSES_ERROR';
export const TOGGLE_CHAT_RESPONSE_MENU = 'TOGGLE_CHAT_RESPONSE_MENU';
export const POPULATE_NUDGE_MESSAGE = 'POPULATE_NUDGE_MESSAGE';
export const SET_INPUT_SCROLL = 'SET_INPUT_SCROLL';
export const SET_CHAT_MESSAGE_HIGHLIGHT = 'SET_CHAT_MESSAGE_HIGHLIGHT';
export const TOGGLE_CHAT_MESSAGE_FILTER = 'TOGGLE_CHAT_MESSAGE_FILTER';
export const SET_CHAT_IMAGE_URL = 'SET_CHAT_IMAGE_URL';
export const CONVERT_DRAFT_TO_MESSAGE_REQUEST = 'CONVERT_DRAFT_TO_MESSAGE_REQUEST';
export const CONVERT_DRAFT_TO_MESSAGE_SUCCESS = 'CONVERT_DRAFT_TO_MESSAGE_SUCCESS';
export const CONVERT_DRAFT_TO_MESSAGE_ERROR = 'CONVERT_DRAFT_TO_MESSAGE_ERROR';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

/**
 * Inbox actions
 */

export const GET_EVENTS_REQUEST = 'GET_EVENTS_REQUEST';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_ERROR = 'GET_EVENTS_ERROR';
export const SORT_EVENTS = 'SORT_EVENTS';
export const HANDLE_EVENT_REQUEST = 'HANDLE_EVENT_REQUEST';
export const HANDLE_EVENT_SUCCESS = 'HANDLE_EVENT_SUCCESS';
export const HANDLE_EVENT_ERROR = 'HANDLE_EVENT_ERROR';
export const GET_MEMBER_EVENT_HISTORY_REQUEST = 'GET_MEMBER_EVENT_HISTORY_REQUEST';
export const GET_MEMBER_EVENT_HISTORY_SUCCESS = 'GET_MEMBER_EVENT_HISTORY_SUCCESS';
export const GET_MEMBER_EVENT_HISTORY_ERROR = 'GET_MEMBER_EVENT_HISTORY_ERROR';
export const GET_MEMBER_ACTIVE_EVENTS_REQUEST = 'GET_MEMBER_ACTIVE_EVENTS_REQUEST';
export const GET_MEMBER_ACTIVE_EVENTS_SUCCESS = 'GET_MEMBER_ACTIVE_EVENTS_SUCCESS';
export const GET_MEMBER_ACTIVE_EVENTS_ERROR = 'GET_MEMBER_ACTIVE_EVENTS_ERROR';
export const GET_HANDLED_EVENTS_REQUEST = 'GET_HANDLED_EVENTS_REQUEST';
export const GET_HANDLED_EVENTS_SUCCESS = 'GET_HANDLED_EVENTS_SUCCESS';
export const GET_HANDLED_EVENTS_ERROR = 'GET_HANDLED_EVENTS_ERROR';
export const SCHEDULE_EVENT_REQUEST = 'SCHEDULE_EVENT_REQUEST';
export const SCHEDULE_EVENT_SUCCESS = 'SCHEDULE_EVENT_SUCCESS';
export const SCHEDULE_EVENT_ERROR = 'SCHEDULE_EVENT_ERROR';
export const TOGGLE_SHOW_OWN_EVENTS = 'TOGGLE_SHOW_OWN_EVENTS';
export const GET_NEW_MEMBERS_REQUEST = 'GET_NEW_MEMBERS_REQUEST';
export const GET_NEW_MEMBERS_SUCCESS = 'GET_NEW_MEMBERS_SUCCESS';
export const GET_NEW_MEMBERS_ERROR = 'GET_NEW_MEMBERS_ERROR';
export const ASSIGN_PAL_REQUEST = 'ASSIGN_PAL_REQUEST';
export const ASSIGN_PAL_SUCCESS = 'ASSIGN_PAL_SUCCESS';
export const ASSIGN_PAL_ERROR = 'ASSIGN_PAL_ERROR';
export const CLEAR_ASSIGNED_MEMBERS = 'CLEAR_ASSIGNED_MEMBERS';
export const CLEAR_MEMBER_EVENT_HISTORY = 'CLEAR_MEMBER_EVENT_HISTORY';
export const CLEAR_MEMBER_ACTIVE_EVENTS = 'CLEAR_MEMBER_ACTIVE_EVENTS';
export const TOGGLE_MEMBER_EVENT_LIST = 'TOGGLE_MEMBER_EVENT_LIST';
export const GET_PRIORITIZED_EVENTS_REQUEST = 'GET_PRIORITIZED_EVENTS_REQUEST';
export const GET_PRIORITIZED_EVENTS_SUCCESS = 'GET_PRIORITIZED_EVENTS_SUCCESS';
export const GET_PRIORITIZED_EVENTS_ERROR = 'GET_PRIORITIZED_EVENTS_ERROR';
export const GET_ACTIVE_CONSULTATIONS_REQUEST = 'GET_ACTIVE_CONSULTATIONS_REQUEST';
export const GET_ACTIVE_CONSULTATIONS_SUCCESS = 'GET_ACTIVE_CONSULTATIONS_SUCCESS';
export const GET_ACTIVE_CONSULTATIONS_ERROR = 'GET_ACTIVE_CONSULTATIONS_ERROR';
export const SNOOZE_EVENT_REQUEST = 'SNOOZE_EVENT_REQUEST';
export const SNOOZE_EVENT_SUCCESS = 'SNOOZE_EVENT_SUCCESS';
export const SNOOZE_EVENT_ERROR = 'SNOOZE_EVENT_ERROR';
export const GET_UNHANDLED_EVENTS_REQUEST = 'GET_UNHANDLED_EVENTS_REQUEST';
export const GET_UNHANDLED_EVENTS_SUCCESS = 'GET_UNHANDLED_EVENTS_SUCCESS';
export const GET_UNHANDLED_EVENTS_ERROR = 'GET_UNHANDLED_EVENTS_ERROR';
export const SORT_UNHANDLED_EVENTS = 'SORT_UNHANDLED_EVENTS';

/**
 * Referrals/lab results actions
 */

export const GET_REFERRAL_SESSION_REQUEST = 'GET_REFERRAL_SESSION_REQUEST';
export const GET_REFERRAL_SESSION_SUCCESS = 'GET_REFERRAL_SESSION_SUCCESS';
export const GET_REFERRAL_SESSION_ERROR = 'GET_REFERRAL_SESSION_ERROR';
export const CLEAR_REFERRAL_SESSION = 'CLEAR_REFERRAL_SESSION';
export const GET_ALL_REFERRALS_REQUEST = 'GET_ALL_REFERRALS_REQUEST';
export const GET_ALL_REFERRALS_SUCCESS = 'GET_ALL_REFERRALS_SUCCESS';
export const GET_ALL_REFERRALS_ERROR = 'GET_ALL_REFERRALS_ERROR';
export const GET_ALL_LAB_RESULTS_REQUEST = 'GET_ALL_LAB_RESULTS_REQUEST';
export const GET_ALL_LAB_RESULTS_SUCCESS = 'GET_ALL_LAB_RESULTS_SUCCESS';
export const GET_ALL_LAB_RESULTS_ERROR = 'GET_ALL_LAB_RESULTS_ERROR';
export const VIEW_OLDER_LAB_RESULTS = 'VIEW_OLDER_LAB_RESULTS';
export const VIEW_NEWER_LAB_RESULTS = 'VIEW_NEWER_LAB_RESULTS';
export const TOGGLE_SHOW_LAB_EVALUATION = 'TOGGLE_SHOW_LAB_EVALUATION';
export const GET_REFERRAL_PACKAGES_REQUEST = 'GET_REFERRAL_PACKAGES_REQUEST';
export const GET_REFERRAL_PACKAGES_SUCCESS = 'GET_REFERRAL_PACKAGES_SUCCESS';
export const GET_REFERRAL_PACKAGES_ERROR = 'GET_REFERRAL_PACKAGES_ERROR';
export const CREATE_REFERRAL_REQUEST = 'CREATE_REFERRAL_REQUEST';
export const CREATE_REFERRAL_SUCCESS = 'CREATE_REFERRAL_SUCCESS';
export const CREATE_REFERRAL_ERROR = 'CREATE_REFERRAL_ERROR';
export const GET_REFERRALS_REQUEST = 'GET_REFERRALS_REQUEST';
export const GET_REFERRALS_SUCCESS = 'GET_REFERRALS_SUCCESS';
export const GET_REFERRALS_ERROR = 'GET_REFERRALS_ERROR';
export const POST_PHYSICAL_EXAMINATION_REFERRAL_REQUEST = 'POST_PHYSICAL_EXAMINATION_REFERRAL_REQUEST';
export const POST_PHYSICAL_EXAMINATION_REFERRAL_SUCCESS = 'POST_PHYSICAL_EXAMINATION_REFERRAL_SUCCESS';
export const POST_PHYSICAL_EXAMINATION_REFERRAL_ERROR = 'POST_PHYSICAL_EXAMINATION_REFERRAL_ERROR';

/**
 * Note actions
 */

export const GET_NOTES_REQUEST = 'GET_NOTES_REQUEST';
export const GET_NOTES_SUCCESS = 'GET_NOTES_SUCCESS';
export const GET_NOTES_ERROR = 'GET_NOTES_ERROR';
export const CLEAR_NOTES = 'CLEAR_NOTES';
export const ADD_NOTE_REQUEST = 'ADD_NOTE_REQUEST';
export const ADD_NOTE_SUCCESS = 'ADD_NOTE_SUCCESS';
export const ADD_NOTE_ERROR = 'ADD_NOTE_ERROR';
export const SHOW_DAY_NOTE_MODAL = 'SHOW_DAY_NOTE_MODAL';
export const HIDE_DAY_NOTE_MODAL = 'HIDE_DAY_NOTE_MODAL';
export const ADD_DAY_NOTE_REQUEST = 'ADD_DAY_NOTE_REQUEST';
export const ADD_DAY_NOTE_SUCCESS = 'ADD_DAY_NOTE_SUCCESS';
export const ADD_DAY_NOTE_ERROR = 'ADD_DAY_NOTE_ERROR';
export const UPDATE_DAY_NOTE_CONTENT = 'UPDATE_DAY_NOTE_CONTENT';
export const TOGGLE_SHOW_ONLY_OWN_NOTES = 'TOGGLE_SHOW_ONLY_OWN_NOTES';
export const GET_MEMBER_COMMENT_REQUEST = 'GET_MEMBER_COMMENT_REQUEST';
export const GET_MEMBER_COMMENT_SUCCESS = 'GET_MEMBER_COMMENT_SUCCESS';
export const GET_MEMBER_COMMENT_ERROR = 'GET_MEMBER_COMMENT_ERROR';
export const SAVE_MEMBER_COMMENT_REQUEST = 'SAVE_MEMBER_COMMENT_REQUEST';
export const SAVE_MEMBER_COMMENT_SUCCESS = 'SAVE_MEMBER_COMMENT_SUCCESS';
export const SAVE_MEMBER_COMMENT_ERROR = 'SAVE_MEMBER_COMMENT_ERROR';
export const UPDATE_MEMBER_COMMENT_TEXT = 'UPDATE_MEMBER_COMMENT_TEXT';
export const TOGGLE_MEMBER_COMMENT_FIELD = 'TOGGLE_MEMBER_COMMENT_FIELD';

/**
 * Decision support actions
 */

export const CLEAR_DSS_DATA = 'CLEAR_DSS_DATA';

export const GET_DECISION_SUPPORT_SUMMARY_REQUEST = 'GET_DECISION_SUPPORT_SUMMARY_REQUEST';
export const GET_DECISION_SUPPORT_SUMMARY_SUCCESS = 'GET_DECISION_SUPPORT_SUMMARY_SUCCESS';
export const GET_DECISION_SUPPORT_SUMMARY_ERROR = 'GET_DECISION_SUPPORT_SUMMARY_ERROR';
export const ADD_DSS_OVERVIEW_ITEM_COMMENT_REQUEST = 'ADD_DSS_OVERVIEW_ITEM_COMMENT_REQUEST';
export const ADD_DSS_OVERVIEW_ITEM_COMMENT_SUCCESS = 'ADD_DSS_OVERVIEW_ITEM_COMMENT_SUCCESS';
export const ADD_DSS_OVERVIEW_ITEM_COMMENT_ERROR = 'ADD_DSS_OVERVIEW_ITEM_COMMENT_ERROR';
export const SET_DSS_RELEVANT_VALUES_REQUEST = 'SET_DSS_RELEVANT_VALUES_REQUEST';
export const SET_DSS_RELEVANT_VALUES_SUCCESS = 'SET_DSS_RELEVANT_VALUES_SUCCESS';
export const SET_DSS_RELEVANT_VALUES_ERROR = 'SET_DSS_RELEVANT_VALUES_ERROR';
export const GET_DSS_ITEM_HISTORY_REQUEST = 'GET_DSS_ITEM_HISTORY_REQUEST';
export const GET_DSS_ITEM_HISTORY_SUCCESS = 'GET_DSS_ITEM_HISTORY_SUCCESS';
export const GET_DSS_ITEM_HISTORY_ERROR = 'GET_DSS_ITEM_HISTORY_ERROR';
export const GET_DSS_ALLERGY_ITEM_REQUEST = 'GET_DSS_ALLERGY_ITEM_REQUEST';
export const GET_DSS_ALLERGY_ITEM_SUCCESS = 'GET_DSS_ALLERGY_ITEM_SUCCESS';
export const GET_DSS_ALLERGY_ITEM_ERROR = 'GET_DSS_ALLERGY_ITEM_ERROR';

export const SET_DSS_CURRENT_ITEM_NUMBER = 'SET_DSS_CURRENT_ITEM_NUMBER';
export const SET_DSS_RELEVANT_VALUES_TO_UPDATE = 'SET_DSS_RELEVANT_VALUES_TO_UPDATE';
export const SET_DSS_ITEM_COMMENT = 'SET_DSS_ITEM_COMMENT';
export const CLEAR_DSS_ITEM_EDIT_VALUES = 'CLEAR_DSS_ITEM_EDIT_VALUES';

export const GET_DSS_MEASUREMENT_STATUS_REQUEST = 'GET_DSS_MEASUREMENT_STATUS_REQUEST';
export const GET_DSS_MEASUREMENT_STATUS_SUCCESS = 'GET_DSS_MEASUREMENT_STATUS_SUCCESS';
export const GET_DSS_MEASUREMENT_STATUS_ERROR = 'GET_DSS_MEASUREMENT_STATUS_ERROR';

export const GET_DSS_BLOOD_PRESSURE_SUMMARY_REQUEST = 'GET_DSS_BLOOD_PRESSURE_SUMMARY_REQUEST';
export const GET_DSS_BLOOD_PRESSURE_SUMMARY_SUCCESS = 'GET_DSS_BLOOD_PRESSURE_SUMMARY_SUCCESS';
export const GET_DSS_BLOOD_PRESSURE_SUMMARY_ERROR = 'GET_DSS_BLOOD_PRESSURE_SUMMARY_ERROR';

export const GET_LAB_RESULTS_REQUEST = 'GET_LAB_RESULTS_REQUEST';
export const GET_LAB_RESULTS_SUCCESS = 'GET_LAB_RESULTS_SUCCESS';
export const GET_LAB_RESULTS_ERROR = 'GET_LAB_RESULTS_ERROR';

export const GET_BLOOD_PRESSURE_TREND_REQUEST = 'GET_BLOOD_PRESSURE_TREND_REQUEST';
export const GET_BLOOD_PRESSURE_TREND_SUCCESS = 'GET_BLOOD_PRESSURE_TREND_SUCCESS';
export const GET_BLOOD_PRESSURE_TREND_ERROR = 'GET_BLOOD_PRESSURE_TREND_ERROR';
export const SET_TREND_PERIOD_SIZE = 'SET_TREND_PERIOD_SIZE';
export const GET_TWO_WEEK_TREND_REQUEST = 'GET_TWO_WEEK_TREND_REQUEST';
export const GET_TWO_WEEK_TREND_SUCCESS = 'GET_TWO_WEEK_TREND_SUCCESS';
export const GET_TWO_WEEK_TREND_ERROR = 'GET_TWO_WEEK_TREND_ERROR';

export const GET_DSS_RISK_PROFILE_REQUEST = 'GET_DSS_RISK_PROFILE_REQUEST';
export const GET_DSS_RISK_PROFILE_SUCCESS = 'GET_DSS_RISK_PROFILE_SUCCESS';
export const GET_DSS_RISK_PROFILE_ERROR = 'GET_DSS_RISK_PROFILE_ERROR';
export const TOGGLE_RISK_FACTOR_RECOMMENDATION = 'TOGGLE_RISK_FACTOR_RECOMMENDATION';
export const POST_RISK_FACTOR_RECOMMENDATIONS_REQUEST = 'POST_RISK_FACTOR_RECOMMENDATIONS_REQUEST';
export const POST_RISK_FACTOR_RECOMMENDATIONS_SUCCESS = 'POST_RISK_FACTOR_RECOMMENDATIONS_SUCCESS';
export const POST_RISK_FACTOR_RECOMMENDATIONS_ERROR = 'POST_RISK_FACTOR_RECOMMENDATIONS_ERROR';

export const GET_OBJECTIVES_REQUEST = 'GET_OBJECTIVES_REQUEST';
export const GET_OBJECTIVES_SUCCESS = 'GET_OBJECTIVES_SUCCESS';
export const GET_OBJECTIVES_ERROR = 'GET_OBJECTIVES_ERROR';

export const POST_BLOOD_PRESSURE_GOALS_REQUEST = 'POST_BLOOD_PRESSURE_GOALS_REQUEST';
export const POST_BLOOD_PRESSURE_GOALS_SUCCESS = 'POST_BLOOD_PRESSURE_GOALS_SUCCESS';
export const POST_BLOOD_PRESSURE_GOALS_ERROR = 'POST_BLOOD_PRESSURE_GOALS_ERROR';

export const GET_DSS_TREATMENT_OPTIONS_REQUEST = 'GET_DSS_TREATMENT_OPTIONS_REQUEST';
export const GET_DSS_TREATMENT_OPTIONS_SUCCESS = 'GET_DSS_TREATMENT_OPTIONS_SUCCESS';
export const GET_DSS_TREATMENT_OPTIONS_ERROR = 'GET_DSS_TREATMENT_OPTIONS_ERROR';
export const DSS_TOGGLE_TREATMENT = 'DSS_TOGGLE_TREATMENT';
export const DSS_SET_MEASUREMENT_FREQUENCY = 'DSS_SET_MEASUREMENT_FREQUENCY';
export const SET_DSS_GOAL_SYSTOLIC = 'SET_DSS_GOAL_SYSTOLIC';
export const SET_DSS_GOAL_DIASTOLIC = 'SET_DSS_GOAL_DIASTOLIC';
export const POST_DSS_TREATMENT_OPTIONS_REQUEST = 'POST_DSS_TREATMENT_OPTIONS_REQUEST';
export const POST_DSS_TREATMENT_OPTIONS_SUCCESS = 'POST_DSS_TREATMENT_OPTIONS_SUCCESS';
export const POST_DSS_TREATMENT_OPTIONS_ERROR = 'POST_DSS_TREATMENT_OPTIONS_ERROR';

export const GET_DSS_STATUS_REQUEST = 'GET_DSS_STATUS_REQUEST';
export const GET_DSS_STATUS_SUCCESS = 'GET_DSS_STATUS_SUCCESS';
export const GET_DSS_STATUS_ERROR = 'GET_DSS_STATUS_ERROR';

export const SIGN_DSS_REQUEST = 'SIGN_DSS_REQUEST';
export const SIGN_DSS_SUCCESS = 'SIGN_DSS_SUCCESS';
export const SIGN_DSS_ERROR = 'SIGN_DSS_ERROR';

export const SAVE_DSS = 'SAVE_DSS';
export const SAVE_DSS_SUCCESS = 'SAVE_DSS_SUCCESS';
export const SAVE_DSS_ERROR = 'SAVE_DSS_ERROR';

export const POST_MEMBER_CHECKUP_REQUEST = 'POST_MEMBER_CHECKUP_REQUEST';
export const POST_MEMBER_CHECKUP_SUCCESS = 'POST_MEMBER_CHECKUP_SUCCESS';
export const POST_MEMBER_CHECKUP_ERROR = 'POST_MEMBER_CHECKUP_ERROR';
export const DELETE_MEMBER_CHECKUP_REQUEST = 'DELETE_MEMBER_CHECKUP_REQUEST';
export const DELETE_MEMBER_CHECKUP_SUCCESS = 'DELETE_MEMBER_CHECKUP_SUCCESS';
export const DELETE_MEMBER_CHECKUP_ERROR = 'DELETE_MEMBER_CHECKUP_ERROR';
export const EDIT_MEMBER_CHECKUP_REQUEST = 'EDIT_MEMBER_CHECKUP_REQUEST';
export const EDIT_MEMBER_CHECKUP_SUCCESS = 'EDIT_MEMBER_CHECKUP_SUCCESS';
export const EDIT_MEMBER_CHECKUP_ERROR = 'EDIT_MEMBER_CHECKUP_ERROR';

export const GET_CHECKUP_CALENDAR_REQUEST = 'GET_CHECKUP_CALENDAR_REQUEST';
export const GET_CHECKUP_CALENDAR_SUCCESS = 'GET_CHECKUP_CALENDAR_SUCCESS';
export const GET_CHECKUP_CALENDAR_ERROR = 'GET_CHECKUP_CALENDAR_ERROR';

export const SHOW_CANCELLATION_REASON_MODAL = 'SHOW_CANCELLATION_REASON_MODAL';
export const HIDE_CANCELLATION_REASON_MODAL = 'HIDE_CANCELLATION_REASON_MODAL';
export const SET_SELECTED_CHECKUP = 'SET_SELECTED_CHECKUP';
export const SET_CANCELLATION_REASON = 'SET_CANCELLATION_REASON';
export const SHOW_EDIT_CHECKUP_MODAL = 'SHOW_EDIT_CHECKUP_MODAL';
export const HIDE_EDIT_CHECKUP_MODAL = 'HIDE_EDIT_CHECKUP_MODAL';
export const SET_EDITED_CHECKUP_DATE = 'SET_EDITED_CHECKUP_DATE';
export const TOGGLE_REMINDER_REASON = 'TOGGLE_REMINDER_REASON';
export const SET_REMINDER_REASON_FREE_TEXT = 'SET_REMINDER_REASON_FREE_TEXT';

export const SHOW_NEW_CHECKUP_BOOKING = 'SHOW_NEW_CHECKUP_BOOKING';
export const SELECT_NEW_CHECKUP_CAREGIVER_TYPE = 'SELECT_NEW_CHECKUP_CAREGIVER_TYPE';
export const SELECT_NEW_CHECKUP_CATEGORY = 'SELECT_NEW_CHECKUP_CATEGORY';
export const SELECT_NEW_CHECKUP_TYPE = 'SELECT_NEW_CHECKUP_TYPE';
export const SELECT_NEW_CHECKUP_DATE = 'SELECT_NEW_CHECKUP_DATE';
export const SAVE_NEW_CHECKUP_REQUEST = 'SAVE_NEW_CHECKUP_REQUEST';
export const SAVE_NEW_CHECKUP_SUCCESS = 'SAVE_NEW_CHECKUP_SUCCESS';
export const SAVE_NEW_CHECKUP_ERROR = 'SAVE_NEW_CHECKUP_ERROR';
export const TOGGLE_NEW_REMINDER_REASON = 'TOGGLE_NEW_REMINDER_REASON';
export const SET_NEW_REMINDER_FREE_TEXT_REASON = 'SET_NEW_REMINDER_FREE_TEXT_REASON';
export const TOGGLE_INCLUDE_FREE_TEXT_REASON = 'TOGGLE_INCLUDE_FREE_TEXT_REASON';

export const SHOW_FINAL_NOTE_MODAL = 'SHOW_FINAL_NOTE_MODAL';
export const HIDE_FINAL_NOTE_MODAL = 'HIDE_FINAL_NOTE_MODAL';
export const SHOW_FINAL_NOTE_CONFIRMATION_MODAL = 'SHOW_FINAL_NOTE_CONFIRMATION_MODAL';
export const HIDE_FINAL_NOTE_CONFIRMATION_MODAL = 'HIDE_FINAL_NOTE_CONFIRMATION_MODAL';
export const UPDATE_FINAL_NOTE = 'UPDATE_FINAL_NOTE';
export const UPDATE_ADDITIONAL_DIAGNOSIS_CODES = 'UPDATE_ADDITIONAL_DIAGNOSIS_CODES';

export const GET_ANAMNESIS_UPDATE_SUMMARY_REQUEST = 'GET_ANAMNESIS_UPDATE_SUMMARY_REQUEST';
export const GET_ANAMNESIS_UPDATE_SUMMARY_SUCCESS = 'GET_ANAMNESIS_UPDATE_SUMMARY_SUCCESS';
export const GET_ANAMNESIS_UPDATE_SUMMARY_ERROR = 'GET_ANAMNESIS_UPDATE_SUMMARY_ERROR';

export const TOGGLE_DSS_SECTION = 'TOGGLE_DSS_SECTION';
export const TOGGLE_COLLAPSE_ALL_DSS_SECTIONS = 'TOGGLE_COLLAPSE_ALL_DSS_SECTIONS';

export const SAVE_FINAL_NOTE_REQUEST = 'SAVE_FINAL_NOTE_REQUEST';
export const SAVE_FINAL_NOTE_SUCCESS = 'SAVE_FINAL_NOTE_SUCCESS';
export const SAVE_FINAL_NOTE_ERROR = 'SAVE_FINAL_NOTE_ERROR';

export const SET_ACTIVE_CAREGIVER_REQUEST = 'SET_ACTIVE_CAREGIVER_REQUEST';
export const SET_ACTIVE_CAREGIVER_SUCCESS = 'SET_ACTIVE_CAREGIVER_SUCCESS';
export const SET_ACTIVE_CAREGIVER_ERROR = 'SET_ACTIVE_CAREGIVER_ERROR';

export const GET_PAYMENT_HISTORY_REQUEST = 'GET_PAYMENT_HISTORY_REQUEST';
export const GET_PAYMENT_HISTORY_SUCCESS = 'GET_PAYMENT_HISTORY_SUCCESS';
export const GET_PAYMENT_HISTORY_ERROR = 'GET_PAYMENT_HISTORY_ERROR';

export const GET_MEMBER_ACTION_TASKS_SUCCESS = 'GET_MEMBER_ACTION_TASKS_SUCCESS';

/**
 * Notification actions
 */

export const SHOW_NOTIFICATION_MESSAGE = 'SHOW_NOTIFICATION_MESSAGE';
export const HIDE_NOTIFICATION_MESSAGE = 'HIDE_NOTIFICATION_MESSAGE';
export const CLEAR_NOTIFICATION_MESSAGE = 'CLEAR_NOTIFICATION_MESSAGE';

/**
 * File actions
 */

export const GET_MEMBER_FILES_REQUEST = 'GET_MEMBER_FILES_REQUEST';
export const GET_MEMBER_FILES_SUCCESS = 'GET_MEMBER_FILES_SUCCESS';
export const GET_MEMBER_FILES_ERROR = 'GET_MEMBER_FILES_ERROR';
export const CLEAR_MEMBER_FILES = 'CLEAR_MEMBER_FILES';
export const SHOW_UPLOAD_FILE_MODAL = 'SHOW_UPLOAD_FILE_MODAL';
export const HIDE_UPLOAD_FILE_MODAL = 'HIDE_UPLOAD_FILE_MODAL';
export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';
export const SELECT_CONTENT_TYPE = 'SELECT_CONTENT_TYPE';
export const UPDATE_UPLOADED_FILENAME = 'UPDATE_UPLOADED_FILENAME';
export const SELECT_FILE_TO_UPLOAD = 'SELECT_FILE_TO_UPLOAD';
export const EDIT_FILENAME_BEFORE_UPLOAD = 'EDIT_FILENAME_BEFORE_UPLOAD';
export const POST_FILE_TO_CHAT_REQUEST = 'POST_FILE_TO_CHAT_REQUEST';
export const POST_FILE_TO_CHAT_SUCCESS = 'POST_FILE_TO_CHAT_SUCCESS';
export const POST_FILE_TO_CHAT_ERROR = 'POST_FILE_TO_CHAT_ERROR';
export const SET_PREFILLED_DOCUMENT_DATA = 'SET_PREFILLED_DOCUMENT_DATA';
export const RESET_SELECTED_DOCUMENT_TYPE = 'RESET_SELECTED_DOCUMENT_TYPE';
export const UPDATE_TEMPLATE_CONTENT = 'UPDATE_TEMPLATE_CONTENT';
export const SELECT_DOCUMENT_TYPE = 'SELECT_DOCUMENT_TYPE';
export const GENERATE_DOCUMENT_REQUEST = 'GENERATE_DOCUMENT_REQUEST';
export const GENERATE_DOCUMENT_SUCCESS = 'GENERATE_DOCUMENT_SUCCESS';
export const GENERATE_DOCUMENT_ERROR = 'GENERATE_DOCUMENT_ERROR';
export const GET_DOCUMENT_TEMPLATE_CONTENT_REQUEST = 'GET_DOCUMENT_TEMPLATE_CONTENT_REQUEST';
export const GET_DOCUMENT_TEMPLATE_CONTENT_SUCCESS = 'GET_DOCUMENT_TEMPLATE_CONTENT_SUCCESS';
export const GET_DOCUMENT_TEMPLATE_CONTENT_ERROR = 'GET_DOCUMENT_TEMPLATE_CONTENT_ERROR';

/**
 * File preview actions
 */

export const PREVIEW_FILE_REQUEST = 'PREVIEW_FILE_REQUEST';
export const PREVIEW_FILE_SUCCESS = 'PREVIEW_FILE_SUCCESS';
export const PREVIEW_FILE_ERROR = 'PREVIEW_FILE_ERROR';
export const PREVIEW_FILE_SET_NUM_PAGES = 'PREVIEW_FILE_SET_NUM_PAGES';
export const CLOSE_FILE_PREVIEW = 'CLOSE_FILE_PREVIEW';
export const SET_FILE_PREVIEW_PROPS = 'SET_FILE_PREVIEW_PROPS';
export const SHOW_FULLSIZE_IMAGE = 'SHOW_FULLSIZE_IMAGE';
export const HIDE_FULLSIZE_IMAGE = 'HIDE_FULLSIZE_IMAGE';
export const TOGGLE_DOCUMENT_SIGNED = 'TOGGLE_DOCUMENT_SIGNED';

/**
 * Dashboard actions
 */

export const GET_CAREGIVER_METRICS_REQUEST = 'GET_CAREGIVER_METRICS_REQUEST';
export const GET_CAREGIVER_METRICS_SUCCESS = 'GET_CAREGIVER_METRICS_SUCCESS';
export const GET_CAREGIVER_METRICS_ERROR = 'GET_CAREGIVER_METRICS_ERROR';

/**
 * Maintenance mode actions
 */

export const SET_MAINTENANCE_STARTS_AT = 'SET_MAINTENANCE_STARTS_AT';
export const SHOW_MAINTENTANCE_MODAL = 'SHOW_MAINTENTANCE_MODAL';
export const HIDE_MAINTENTANCE_MODAL = 'HIDE_MAINTENTANCE_MODAL';
export const SHOW_DEPLOY_IN_PROGRESS_MODAL = 'SHOW_DEPLOY_IN_PROGRESS_MODAL';
export const HIDE_DEPLOY_IN_PROGRESS_MODAL = 'HIDE_DEPLOY_IN_PROGRESS_MODAL';
export const SHOW_MAINTENANCE_COMPLETE_MODAL = 'SHOW_MAINTENANCE_COMPLETE_MODAL';
export const HIDE_MAINTENANCE_COMPLETE_MODAL = 'HIDE_MAINTENANCE_COMPLETE_MODAL';
export const HEALTH_PING_SUCCESS = 'HEALTH_PING_SUCCESS';
export const RESET_HEALH_PING_SUCCESSES = 'RESET_HEALH_PING_SUCCESSES';

/**
 * Caregiver actions
 */

export const GET_CAREGIVER_PROFILE_IMAGE_REQUEST = 'GET_CAREGIVER_PROFILE_IMAGE_REQUEST';
export const GET_CAREGIVER_PROFILE_IMAGE_SUCCESS = 'GET_CAREGIVER_PROFILE_IMAGE_SUCCESS';
export const GET_CAREGIVER_PROFILE_IMAGE_ERROR = 'GET_CAREGIVER_PROFILE_IMAGE_ERROR';
export const CAREGIVER_PROFILE_IMAGE_UPDATE_SUCCESS = 'CAREGIVER_PROFILE_IMAGE_UPDATE_SUCCESS';
export const GET_CLINICS_REQUEST = 'GET_CLINICS_REQUEST';
export const GET_CLINICS_SUCCESS = 'GET_CLINICS_SUCCESS';
export const GET_CLINICS_ERROR = 'GET_CLINICS_ERROR';

/**
 * Examination actions
 */

export const GET_CARDIOPULMONARY_EXAMINATION_REQUEST = 'GET_CARDIOPULMONARY_EXAMINATION_REQUEST';
export const GET_CARDIOPULMONARY_EXAMINATION_SUCCESS = 'GET_CARDIOPULMONARY_EXAMINATION_SUCCESS';
export const GET_CARDIOPULMONARY_EXAMINATION_ERROR = 'GET_CARDIOPULMONARY_EXAMINATION_ERROR';

/**
 * Assessment actions
 */

export const GET_KIOSK_ASSESSMENTS_ERROR = 'GET_KIOSK_ASSESSMENTS_ERROR';
export const SET_INITIAL_KIOSK_ASSESSMENT = 'SET_INITIAL_KIOSK_ASSESSMENT';
export const COMPLETE_KIOSK_ASSESSMENT = 'COMPLETE_KIOSK_ASSESSMENT';
export const SET_REMAINING_KIOSK_ASSESSMENT_COUNT = 'SET_REMAINING_KIOSK_ASSESSMENT_COUNT';
export const GET_AUSCULTATION_ASSESSMENTS_REQUEST = 'GET_AUSCULTATION_ASSESSMENTS_REQUEST';
export const GET_AUSCULTATION_ASSESSMENTS_SUCCESS = 'GET_AUSCULTATION_ASSESSMENTS_SUCCESS';
export const GET_AUSCULTATION_ASSESSMENTS_ERROR = 'GET_AUSCULTATION_ASSESSMENTS_ERROR';
export const REMOVE_AUSCULTATION_ASSESSMENT = 'REMOVE_AUSCULTATION_ASSESSMENT';

/**
 * Misc. actions
 */

export const SET_CURRENT_MEMBER = 'SET_CURRENT_MEMBER';
export const CLEAR_CURRENT_MEMBER = 'CLEAR_CURRENT_MEMBER';
export const CHANGE_TAB = 'CHANGE_TAB';
export const COLLAPSE_CHAT = 'COLLAPSE_CHAT';
export const EXPAND_CHAT = 'EXPAND_CHAT';
export const ACKNOWLEDGE_RELEASE_NOTES = 'ACKNOWLEDGE_RELEASE_NOTES';
export const WINDOW_RESIZED = 'WINDOW_RESIZED';
export const DISABLE_INSTANCE = 'DISABLE_INSTANCE';
export const SET_STOMP_CLIENT = 'SET_STOMP_CLIENT';
export const SET_BROADCAST_CHANNEL = 'SET_BROADCAST_CHANNEL';
export const SHOW_CE_MODAL = 'SHOW_CE_MODAL';
export const HIDE_CE_MODAL = 'HIDE_CE_MODAL';
export const NOTIFY_CLEAR_DSS = 'NOTIFY_CLEAR_DSS';
export const TOGGLE_MAIN_MENU_COLLAPSE = 'TOGGLE_MAIN_MENU_COLLAPSE';
