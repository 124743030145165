import { useState } from 'react';
import moment from 'moment';
import { Translate } from 'react-redux-i18n';
import { DATE_FORMAT, TEMPLATE_TYPE } from '../../../constants';
import DecisionSupportSection from '../DecisionSupportSection';
import CreateDocumentModal from '../../CreateDocumentModal';
import LoaderButton from '../../LoaderButton';

const PhysicalExaminationReferral = ({ isLoading, member, isPosting, onCreateReferral, existingReferral, clinics }) => {
  const [referralModalVisible, setReferralModalVisible] = useState(false);

  // Don't render any loading state since this component is not needed for most patients.
  if (isLoading) {
    return null;
  }

  // Don't render anything if it's an already-diagnosed patient or a patient with physical examination referral results.
  if (!member.pendingDiagnosis || member.pendingDiagnosis === 'results') {
    return null;
  }

  const { diagnosisScreeningClinic } = member;
  const isPartnerReferral = diagnosisScreeningClinic && diagnosisScreeningClinic !== 'VC';
  const clinic = clinics.find((x) => x.id?.toLowerCase() === diagnosisScreeningClinic?.toLowerCase()); // includes VC

  return (
    <>
      <DecisionSupportSection
        headerI18nKey="decision_support_view.physical_examination_referral.header"
        minimizable={false}
      >
        <div>
          <p>
            <Translate value="decision_support_view.physical_examination_referral.recommendation" />
          </p>
          <p className="mb-30">
            <strong>
              {!isPartnerReferral ? (
                <Translate value="decision_support_view.physical_examination_referral.other_clinic" />
              ) : (
                clinic?.name
              )}
            </strong>
          </p>

          {!member.pendingDiagnosis.startsWith('referred') ? (
            <LoaderButton
              disabled={isPosting}
              isLoading={isPosting}
              onClick={() => (isPartnerReferral ? onCreateReferral(clinic) : setReferralModalVisible(true))}
            >
              <Translate
                value={`decision_support_view.physical_examination_referral.${
                  isPartnerReferral ? 'partner' : 'other_clinic'
                }_button`}
              />
            </LoaderButton>
          ) : (
            <div>
              <Translate
                value="decision_support_view.physical_examination_referral.referred_at"
                date={
                  existingReferral
                    ? moment(existingReferral.timestamp, DATE_FORMAT).format('YYYY-MM-DD')
                    : moment().format('YYYY-MM-DD')
                }
              />{' '}
              <Translate
                value={`decision_support_view.physical_examination_referral.referred_${
                  member.pendingDiagnosis === 'referred' && isPartnerReferral ? 'partner' : 'other_clinic'
                }`}
              />
            </div>
          )}
        </div>
      </DecisionSupportSection>
      <CreateDocumentModal
        isVisible={referralModalVisible}
        onClose={() => setReferralModalVisible(false)}
        templateType={TEMPLATE_TYPE.diagnosisScreening}
        recipient="otherClinic"
        headerI18nKey="decision_support_view.physical_examination_referral.create_referral"
        actionI18nKey="decision_support_view.physical_examination_referral.create_referral"
      />
    </>
  );
};

export default PhysicalExaminationReferral;
