import React, { useState } from 'react';
import moment from 'moment';
import { Translate } from 'react-redux-i18n';
import { DATE_FORMAT } from '../../../../constants';
import ChapterChevron from '../../../../assets/icons/chapter-chevron.svg';

const STATUS_CLOSED = ['Canceled', 'Closed', 'Resolved'];

const JiraIssueRow = ({ issue }) => {
  const [commentsExpanded, setCommentsExpanded] = useState(false);

  return (
    <div
      className="expandable-row"
      onClick={() => {
        setCommentsExpanded(!commentsExpanded);
      }}
    >
      <div className="columns">
        <div className="column is-5 no-padding fw-bold">
          {issue.summary} <span className="fw-normal">({issue.comments.length})</span>
        </div>
        <div className="column is-4 no-padding">
          {moment(issue.createdDate, DATE_FORMAT).format('YYYY-MM-DD HH:mm')}
        </div>
        <div className="column is-2 no-padding">
          <Translate
            value={`decision_support_view.support.status_${STATUS_CLOSED.includes(issue.status) ? 'closed' : 'open'}`}
          />
        </div>
        <div className="column is-1 no-padding text-right">
          <img src={ChapterChevron} alt="Chevron" className={commentsExpanded ? 'rotated' : ''} />
        </div>
      </div>
      {commentsExpanded ? (
        <div>
          <div>
            <div className="mt-5 mb-5 fs-14">
              <Translate value="decision_support_view.support.issue" />
            </div>
            <div>{issue.description}</div>
          </div>
          <div className="mt-5 mb-5 fs-14">
            <Translate value="decision_support_view.support.updates" />
          </div>
          {issue.comments.length ? (
            <div className="issue-comment-container">
              {issue.comments.map((comment, i) => (
                <div key={i} className="issue-comment">
                  <div className="issue-comment__content">{comment.comment}</div>
                  <div className="issue-comment__meta">
                    {comment.author.displayName}{' '}
                    <time>{moment(comment.timestamp, DATE_FORMAT).format('YYYY-MM-DD HH:mm')}</time>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>
              <Translate value="decision_support_view.support.comments_empty" />
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default JiraIssueRow;
