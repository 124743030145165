import React, { Component } from 'react';
import { Tabs, TabList } from 'react-tabs';
import Draggable from 'react-draggable';
import './TabbedModalDialog.scss';
import '../ModalDialog/ModalDialog.scss';

class TabbedModalDialog extends Component {
  constructor(props) {
    super(props);

    this.keydownListener = this.keydownListener.bind(this);
  }

  keydownListener(e) {
    if (e.keyCode === 27 && this.props.visible) {
      // ESC
      this.props.onClose();
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.keydownListener);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.keydownListener);
  }

  render() {
    const { visible, onClose, tabList, tabContent } = this.props;

    if (!visible) {
      return null;
    }

    return (
      <div className="tabbed-modal modal is-active">
        <div className="modal-background" onClick={onClose}></div>
        <Draggable handle=".handle">
          <div className="modal-content tabbed-modal-content">
            <div className="handle"></div>
            <Tabs>
              <div className="modal-tabs">
                <div className="modal-close-button-container">
                  <button className="modal-close-button" aria-label="close" onClick={onClose}></button>
                </div>
                <TabList>{tabList}</TabList>
              </div>
              <div className="modal-tabs-content">{tabContent}</div>
            </Tabs>
          </div>
        </Draggable>
      </div>
    );
  }
}

export default TabbedModalDialog;
