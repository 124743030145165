import React, { Component } from 'react';
import './RiskFactorRecommendationRow.scss';

class RiskFactorRecommendationRow extends Component {
  render() {
    const {
      id,
      recommendation,
      editable,
      onCheckboxChange,
      selectedRiskFactors,
      isNonStandardRiskFactorRow,
      allowedToEdit
    } = this.props;

    const isChecked = selectedRiskFactors.indexOf(id) > -1;

    if (!recommendation) return null;

    return (
      <div
        className={`risk-factor-treatment ${
          isNonStandardRiskFactorRow ? 'risk-factor-type-non-standard' : 'risk-factor-type-standard'
        } ${isChecked ? 'selected' : ''}`}
      >
        <div className="columns">
          <div className="column is-4">
            <input
              type="checkbox"
              className="styled-checkbox"
              id={`risk-factor-${id}`}
              checked={isChecked}
              onChange={onCheckboxChange(id)}
              disabled={!editable || !allowedToEdit}
            />
            <label htmlFor={`risk-factor-${id}`}>{recommendation.leadText}</label>
          </div>
          <div className="column is-4">
            {recommendation.content.map((c, i) => {
              return <div key={i}>{c}</div>;
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default RiskFactorRecommendationRow;
