import moment from 'moment';
import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import ErrorMessage from '../../ErrorMessage';
import AuscultationRecordingList from './AuscultationRecordingList';
import { previewFile } from '../../../actions';
import { DATE_FORMAT } from '../../../constants';
import './Examinations.scss';

const Examinations = ({ authToken, patient, examination, previewFile, fullWidth }) => {
  const previewEcg = () => {
    if (!examination.cardiopulmonary?.ecgFile?.id) {
      return;
    }

    previewFile(
      authToken,
      patient.guid,
      examination.cardiopulmonary.ecgFile.id,
      examination.cardiopulmonary.ecgFile.filename,
      examination.cardiopulmonary.ecgFile.contentType
    );
  };

  return (
    <PhysicalExamination
      headerI18nKey="patient_view.notes.examination.header"
      cardiopulmonaryExamination={examination.cardiopulmonary}
      hasExaminationData={examination.hasExaminationData}
      isLoading={examination.loadingCardiopulmonaryExamination}
      onPreviewEcg={previewEcg}
      error={examination.auscultationError}
      isAssessment={fullWidth}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    patient: state.currentMember,
    examination: state.examination
  };
};

const mapActionsToProps = {
  previewFile
};

export default connect(mapStateToProps, mapActionsToProps)(Examinations);

export const PhysicalExamination = ({
  headerI18nKey,
  cardiopulmonaryExamination,
  isLoading,
  hasExaminationData,
  error,
  onPreviewEcg,
  isAssessment
}) => {
  const [volume, setVolume] = useState(1);
  const audioElement = useRef();
  const audioCtx = useRef();
  const gainNode = useRef();
  const [audioUrl, setAudioUrl] = useState();
  const [activeRecording, setActiveRecording] = useState();

  useEffect(() => {
    if (gainNode.current) {
      gainNode.current.gain.setValueAtTime(volume, audioCtx.current.currentTime);
    }
  }, [volume]);

  useEffect(() => {
    const handleAudioEnd = () => {
      setActiveRecording(null);
    };

    audioElement.current.addEventListener('ended', handleAudioEnd);
  }, [setActiveRecording]);

  const playRecording = (recording) => {
    if (!audioCtx.current) {
      audioCtx.current = new (window.AudioContext || window.webkitAudioContext)();
      gainNode.current = audioCtx.current.createGain();
      const mediaSource = audioCtx.current.createMediaElementSource(audioElement.current);
      mediaSource.connect(gainNode.current);
      gainNode.current.gain.setValueAtTime(volume, audioCtx.current.currentTime);
      gainNode.current.connect(audioCtx.current.destination);
    }

    fetch(cardiopulmonaryExamination.recordings.find((r) => r.key === recording)?.value)
      .then((response) => response.blob())
      .then((blob) => {
        setAudioUrl(window.URL.createObjectURL(blob));
        audioElement.current.play();
        setActiveRecording(recording);
      });
  };

  const stopRecording = () => {
    audioElement.current.pause();
    setActiveRecording(null);
  };

  return (
    <>
      <div className={`examination rounded-container ${!isAssessment ? 'mw-900' : ''}`}>
        <header className="examination-header">
          <h2>
            <Translate value={headerI18nKey} />
          </h2>
          <div className="vertical-align">
            <label htmlFor="volume-input">
              <Translate value="patient_view.notes.examination.volume_control" />:{' '}
            </label>
            <input
              type="range"
              id="volume-input"
              min={0}
              max={50}
              step={1}
              value={volume}
              onChange={(e) => setVolume(e.target.value)}
            />
          </div>
        </header>
        {!hasExaminationData && !isLoading && !error ? (
          <div>
            <Translate value="patient_view.notes.examination.no_examination" className="field__empty" />
          </div>
        ) : error ? (
          <ErrorMessage error={error} errorI18nKey="patient_view.notes.examination.auscultation.error" />
        ) : (
          <>
            <div className="columns">
              <div className="column no-padding mr-20">
                <AuscultationRecordingList
                  isLoading={isLoading}
                  type="heart"
                  activeRecording={activeRecording}
                  recordings={cardiopulmonaryExamination.recordings}
                  onPlay={playRecording}
                  onStop={stopRecording}
                  recordingDate={cardiopulmonaryExamination.completedAt}
                />
              </div>
              <div className="column no-padding">
                <AuscultationRecordingList
                  isLoading={isLoading}
                  type="lungs"
                  activeRecording={activeRecording}
                  recordings={cardiopulmonaryExamination.recordings}
                  onPlay={playRecording}
                  onStop={stopRecording}
                  recordingDate={cardiopulmonaryExamination.completedAt}
                />
              </div>
            </div>
            <div className="columns mt-30">
              {!isAssessment ? (
                <div className="column no-padding">
                  <h3>
                    <Translate value="global.ecg" />
                  </h3>
                  {cardiopulmonaryExamination?.ecgFile?.filename ? (
                    <span className="pointer hover-underline" onClick={onPreviewEcg}>
                      {cardiopulmonaryExamination?.ecgFile?.filename}
                    </span>
                  ) : (
                    <Translate value="patient_view.notes.examination.ecg_pending" />
                  )}
                </div>
              ) : null}
              <div className="column no-padding">
                <h3>
                  <Translate value={`patient_view.notes.examination.comment${isAssessment ? '_assessment' : ''}`} />
                </h3>
                <span>
                  {cardiopulmonaryExamination?.comment || I18n.t('patient_view.notes.examination.no_comment')}
                </span>
              </div>
            </div>
            <div className="columns mt-30">
              <div className="column no-padding">
                <h3>
                  <Translate
                    value={`patient_view.notes.examination.performed_by_header${isAssessment ? '_assessment' : ''}`}
                  />
                </h3>
                {cardiopulmonaryExamination?.performedBy && cardiopulmonaryExamination?.performedAt ? (
                  <Translate
                    value="patient_view.notes.examination.performed_by"
                    name={cardiopulmonaryExamination.performedBy}
                    facility={cardiopulmonaryExamination.performedAt}
                  />
                ) : (
                  '-'
                )}
              </div>
            </div>
            {cardiopulmonaryExamination.completedAt ? (
              <div>
                <small>
                  <Translate
                    value={`patient_view.notes.examination.performed_at${isAssessment ? '_assessment' : ''}`}
                    date={moment(cardiopulmonaryExamination.completedAt, DATE_FORMAT).format('YYYY-MM-DD HH:mm')}
                  />
                </small>
              </div>
            ) : null}
          </>
        )}
      </div>
      <audio ref={audioElement} src={audioUrl}></audio>
    </>
  );
};
