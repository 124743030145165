import React, { Component, Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import ModalDialog from '../../ModalDialog';
import HistoricalNote from '../../NotesView/HistoricalNote';
import './DayNotesSinceFollowUp.scss';

class DayNotesSinceFollowUp extends Component {
  constructor(props) {
    super(props);

    this.showNotes = this.showNotes.bind(this);
    this.hideNotes = this.hideNotes.bind(this);

    this.state = {
      notesVisible: false
    };
  }

  showNotes() {
    this.setState({
      notesVisible: true
    });
  }

  hideNotes() {
    this.setState({
      notesVisible: false
    });
  }

  render() {
    const { notes } = this.props;

    if (!notes || !notes.length) return null;

    return (
      <Fragment>
        <div className="columns">
          <div className="column day-notes-since-followup">
            <span onClick={this.showNotes}>
              {I18n.t(
                `decision_support_view.notes_section.notes_since_last_followup_${
                  notes.length > 1 ? 'plural' : 'singular'
                }`,
                { numNotes: notes.length }
              )}
            </span>
          </div>
        </div>
        <ModalDialog
          headerI18nKey="decision_support_view.notes_section.note_history_header"
          actionI18nKey="global.buttons.done"
          visible={this.state.notesVisible}
          onClose={this.hideNotes}
          onActionCompleted={this.hideNotes}
          actionCompletable={true}
          actionCompleting={false}
        >
          {notes.map((note, i) => {
            return <HistoricalNote key={i} note={note} dateTimeFormat="YYYY-MM-DD" />;
          })}
        </ModalDialog>
      </Fragment>
    );
  }
}

export default DayNotesSinceFollowUp;
