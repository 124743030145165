import moment from 'moment';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import ReactPaginate from 'react-paginate';
import { DATE_FORMAT, COMMUNICATION_ROWS_PER_PAGE, COMMUNICATION_ROW_LIMIT } from '../../../constants';
import {
  getMemberCommunicationEvents,
  getMemberFullCommunicationEvent,
  showCommunicationEventsContentModal,
  hideCommunicationEventsContentModal,
  setPatientCommunicationViewCurrentPage
} from '../../../actions';
import ContentLoader from '../../ContentLoader';
import ModalDialog from '../../ModalDialog';
import './PatientCommunication.scss';
import LoadingSpinner from '../../LoadingSpinner';

/**
 * Displays one patient communication row
 * @param {*} props
 * @returns PatientCommunicationRow
 */
const PatientCommunicationRow = ({ communicationEvent, onClick }) => {
  return (
    <div className="columns pb-4 pt-4 patient-communication-row vertical-align" onClick={() => onClick()}>
      <div className="column is-2 no-padding">
        {moment(communicationEvent.timestamp, DATE_FORMAT).format('YYYY-MM-DD')}
      </div>
      <div className="column is-2 no-padding">
        <Translate
          value={`patient_communication_view.communication_type.${communicationEvent.communicationEventType}`}
        />
      </div>
      <div className="column no-padding pr-15">{communicationEvent.description}</div>
      <div className="column no-padding pr-15 is-2 has-text-centered">
        <span className="ml-5 icon text-button chevron-right"></span>
      </div>
    </div>
  );
};

/**
 * Separate function for emails because we want to render the original email
 * document in an iframe.
 * @param {*} props
 * @returns EmailModalContent
 */
const EmailModalContent = ({ content }) => {
  return (
    <div className="patient-communication email">
      <iframe className="patient-communication" srcDoc={content}></iframe>
    </div>
  );
};

/**
 * Used to display modal content for all memberEventTypes !== email.
 * @param {*} props
 * @returns DefaultModalContent
 */
const DefaultModalContent = ({ content }) => {
  return <div>{content}</div>;
};

const ModalContent = ({ communicationEvent, content }) => {
  const isEmail = communicationEvent?.communicationEventType === 'email';
  if (!content) {
    return (
      <span className="field__empty">
        <Translate value="patient_communication_view.empty_content" />
      </span>
    );
  }
  if (isEmail) {
    return <EmailModalContent content={content} />;
  }

  return <DefaultModalContent content={content} />;
};

const PatientCommunication = ({
  patientCommunication,
  authToken,
  member,
  getMemberCommunicationEvents,
  getMemberFullCommunicationEvent,
  showCommunicationEventsContentModal,
  hideCommunicationEventsContentModal,
  setPatientCommunicationViewCurrentPage
}) => {
  useEffect(() => {
    const offset = COMMUNICATION_ROWS_PER_PAGE * patientCommunication.currentPage;
    if (member.guid) {
      getMemberCommunicationEvents(authToken, member.guid, offset, COMMUNICATION_ROW_LIMIT);
    }
  }, [authToken, member, patientCommunication.currentPage]);

  const viewContent = (id) => {
    showCommunicationEventsContentModal(id);
    const communicationEvent = patientCommunication.communicationEvents.find((e) => e.uuid === id);
    if (!communicationEvent?.content || !communicationEvent.content.length) {
      getMemberFullCommunicationEvent(authToken, member.guid, id);
    }
  };

  const handlePageClick = (data) => {
    setPatientCommunicationViewCurrentPage(data.selected);
  };

  return (
    <>
      <div className="rounded-container mw-900">
        <div className="flex space-between">
          <h2>
            <Translate value="patient_communication_view.header" />
          </h2>
          {patientCommunication.isLoading && patientCommunication.communicationEvents.length ? (
            <LoadingSpinner size="smaller" />
          ) : null}
        </div>
        <ContentLoader
          isLoading={patientCommunication.isLoading && !patientCommunication.communicationEvents.length}
          error={patientCommunication.error}
          errorTitleI18n="patient_communication_view.error"
        >
          {patientCommunication.communicationEvents.length ? (
            <>
              <div className="columns pt-20">
                <div className="column is-2 no-padding">
                  <h4>
                    <Translate value="patient_communication_view.column_header.date" />
                  </h4>
                </div>
                <div className="column is-2 no-padding">
                  <h4>
                    <Translate value="patient_communication_view.column_header.type" />
                  </h4>
                </div>
                <div className="column no-padding pr-10">
                  <h4>
                    <Translate value="patient_communication_view.column_header.description" />
                  </h4>
                </div>
                <div className="column is-2 no-padding has-text-centered"></div>
              </div>
              {patientCommunication.communicationEvents?.map((event) => (
                <PatientCommunicationRow
                  key={event.uuid}
                  communicationEvent={event}
                  onClick={() => viewContent(event.uuid)}
                />
              ))}
            </>
          ) : (
            <span className="field__empty">
              <Translate value="patient_communication_view.empty" />
            </span>
          )}
        </ContentLoader>
        {patientCommunication.paginationDetails.totalNumberOfItems > COMMUNICATION_ROWS_PER_PAGE ? (
          <div className="m-0-auto mb-20">
            <ReactPaginate
              previousLabel={I18n.t('global.previous')}
              nextLabel={I18n.t('global.next')}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={Math.ceil(
                patientCommunication.paginationDetails.totalNumberOfItems / COMMUNICATION_ROWS_PER_PAGE
              )}
              disableInitialCallback={true} // Because of known bug in ReactPaginate where onPageChange is loaded on component mount https://github.com/AdeleD/react-paginate/issues/152
              initialPage={patientCommunication.currentPage}
              forcePage={patientCommunication.currentPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={7}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          </div>
        ) : null}
      </div>
      <ModalDialog
        headerI18nKey="patient_communication_view.modal_header"
        actionI18nKey="patient_communication_view.modal_action"
        visible={patientCommunication.modalVisible}
        onActionCompleted={hideCommunicationEventsContentModal}
        actionCompletable={true}
        actionCompleting={false}
        onClose={hideCommunicationEventsContentModal}
      >
        <ContentLoader
          isLoading={patientCommunication.isLoadingContent}
          error={patientCommunication.error}
          errorTitleI18n="patient_communication_view.error"
        >
          {!patientCommunication.fullCommunicationEvent ? (
            <ModalContent
              communicationEvent={patientCommunication.selectedCommunicationEvent}
              content={patientCommunication.selectedCommunicationEvent?.content}
            />
          ) : (
            <ModalContent
              communicationEvent={patientCommunication.fullCommunicationEvent?.communicationEvent}
              content={patientCommunication.fullCommunicationEvent?.fullContent}
            />
          )}
        </ContentLoader>
      </ModalDialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    member: state.currentMember,
    patientCommunication: state.patientCommunication
  };
};

const mapActionsToProps = {
  getMemberCommunicationEvents,
  getMemberFullCommunicationEvent,
  showCommunicationEventsContentModal,
  hideCommunicationEventsContentModal,
  setPatientCommunicationViewCurrentPage
};

export default connect(mapStateToProps, mapActionsToProps)(PatientCommunication);
