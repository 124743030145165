import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'chartjs-plugin-annotation';
import { Line } from 'react-chartjs-2';
import { I18n, Translate } from 'react-redux-i18n';
import {
  defaultOptions as options,
  legendOptions,
  commonDataSetOptions,
  systolicDataOptions,
  diastolicDataOptions,
  heartRateDataOptions
} from '../../utils/graph.js';
import { DATE_FORMAT } from '../../constants/index.js';

options.annotation = {
  annotations: [
    {
      drawTime: 'afterDatasetsDraw',
      type: 'line',
      mode: 'horizontal',
      scaleID: 'y-axis-0',
      borderColor: 'rgba(216, 56, 74, 0.5)',
      borderWidth: 2,
      borderDash: [4, 2]
    },
    {
      drawTime: 'afterDatasetsDraw',
      type: 'line',
      mode: 'horizontal',
      scaleID: 'y-axis-0',
      borderColor: 'rgba(62, 95, 203, 0.5)',
      borderWidth: 2,
      borderDash: [4, 2]
    }
  ]
};

const chartData = {
  labels: [],
  datasets: [
    { ...commonDataSetOptions, ...systolicDataOptions },
    { ...commonDataSetOptions, ...diastolicDataOptions },
    { ...commonDataSetOptions, ...heartRateDataOptions }
  ]
};

const getAverageBloodPressure = (bpmeasurements) => {
  return {
    systolic: Math.floor(bpmeasurements.reduce((prev, curr) => (prev += curr.systolic), 0) / bpmeasurements.length),
    diastolic: Math.floor(bpmeasurements.reduce((prev, curr) => (prev += curr.diastolic), 0) / bpmeasurements.length),
    heartrate: Math.floor(bpmeasurements.reduce((prev, curr) => (prev += curr.heartrate), 0) / bpmeasurements.length)
  };
};

const BloodPressureLineGraph = ({ bpMeasurements, isLoading, goalSystolic, goalDiastolic }) => {
  const [shouldRedraw, setShouldRedraw] = useState(false);
  const [oldSystolicGoal, setOldSystolicGoal] = useState();

  useEffect(() => {
    if (goalSystolic === oldSystolicGoal) {
      setShouldRedraw(false);
    } else {
      setShouldRedraw(true);
      setOldSystolicGoal(goalSystolic);
    }
  });

  if (!bpMeasurements || !bpMeasurements.length) return null;

  options.annotation.annotations[0].value = goalSystolic;
  options.annotation.annotations[1].value = goalDiastolic;

  chartData.labels = [];

  const systolicData = bpMeasurements.map((measurement) => {
    chartData.labels.push(
      moment(measurement.bpmeasurements[0].timestamp, DATE_FORMAT).format('YYYY-MM-DD HH:mm') +
        '\n' +
        I18n.t(`patient_view.graph.measurement_mode.${measurement.measurementMode}`)
    );
    return {
      x: measurement.bpmeasurements[0].timestamp,
      y: getAverageBloodPressure(measurement.bpmeasurements).systolic
    };
  });

  const diastolicData = bpMeasurements.map((measurement) => {
    return {
      x: measurement.bpmeasurements[0].timestamp,
      y: getAverageBloodPressure(measurement.bpmeasurements).diastolic
    };
  });

  const heartRateData = bpMeasurements.map((measurement) => {
    return {
      x: measurement.bpmeasurements[0].timestamp,
      y: getAverageBloodPressure(measurement.bpmeasurements).heartrate
    };
  });

  chartData.datasets[0].data = systolicData;
  chartData.datasets[1].data = diastolicData;
  chartData.datasets[2].data = heartRateData;

  return (
    <>
      <div>
        <div className={`line-graph ${isLoading ? 'is-loading' : ''}`}>
          <Line data={chartData} legend={legendOptions} options={options} redraw={shouldRedraw} />
        </div>
        <div className="line-graph-legend small">
          <Translate className="legend-systolic" value="decision_support_view.legend_systolic" />
          <Translate className="legend-diastolic" value="decision_support_view.legend_diastolic" />
          <Translate className="legend-heartrate" value="decision_support_view.legend_heartrate" />
          <Translate className="legend-systolic-goal" value="decision_support_view.legend_systolic_goal" />
          <Translate className="legend-diastolic-goal" value="decision_support_view.legend_diastolic_goal" />
        </div>
      </div>
    </>
  );
};

export default React.memo(BloodPressureLineGraph);
