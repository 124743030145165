import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { Translate } from 'react-redux-i18n';
import './MedicationRow.scss';
import { DATE_FORMAT } from '../../../../../constants';
import DosageWithFallback from '../../../DosageWithFallback';

class MedicationRow extends Component {
  render() {
    const { item, onClick, changes, current } = this.props;

    if (!changes.length) {
      return null;
    }

    const created = changes.filter((change) => change.changeType === 'new')[0];
    const createdDate = created?.timestamp;
    const prescribedBy = created?.changedBy;
    const prescribedByAccumbo = created?.prescribed;

    const signed = changes.filter((change) => change.changeType === 'sign')[0];
    const signedDate = signed?.timestamp;
    const signedBy = signed?.changedBy;

    return (
      <div
        className={`row-container current-medication-row ${onClick ? 'pointer' : ''}`}
        onClick={onClick && onClick(item)}
      >
        <div className="table-row">
          <div className="columns">
            <div className="column is-2">
              {prescribedBy && prescribedByAccumbo ? (
                <Fragment>
                  <div>
                    {prescribedBy.givenName} {prescribedBy.familyName}
                  </div>
                  <div>{moment(createdDate, DATE_FORMAT).format('YYYY-MM-DD')}</div>
                </Fragment>
              ) : (
                <Fragment>
                  <div>
                    <Translate value="decision_support_view.medication_section.other_doctor" />
                  </div>
                  <div>{moment(createdDate).format('YYYY-MM-DD')}</div>
                </Fragment>
              )}
            </div>
            <div className="column is-2 overflow-ellipsis" title={current.name || ''}>
              {current.name || '-'}
            </div>
            <div className="column is-2">{current.strength || '-'}</div>
            <div className="column is-2 overflow-ellipsis" title={current.form || ''}>
              {current.form || '-'}
            </div>
            <div className="column is-2">
              <DosageWithFallback medication={current} />
            </div>
            <div className="column is-2">
              {prescribedBy && prescribedByAccumbo ? (
                <Fragment>
                  <div>
                    {prescribedBy.givenName} {prescribedBy.familyName}
                  </div>
                  <div>{moment(createdDate, DATE_FORMAT).format('YYYY-MM-DD')}</div>
                </Fragment>
              ) : (
                '-'
              )}
            </div>
          </div>
          <div className="medication-status">
            {signedBy ? (
              <Fragment>
                <Translate value="patient_view.medications.signed" />{' '}
                <span>{moment(signedDate, DATE_FORMAT).format('YYYY-MM-DD')}</span> <Translate value="global.by" />{' '}
                <span>
                  {signedBy.givenName} {signedBy.familyName}
                </span>
              </Fragment>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default MedicationRow;
