import * as types from '../constants/actionTypes';
import moment from 'moment';
import { updateObjectInArray, removeItemFromArray, toggleItem, getCheckupHighlights } from '../utils';
import { DATE_FORMAT, HTTP_STATUS_CODES } from '../constants';

const initialState = {
  checkupList: [],
  cancellationReasonModalVisible: false,
  editCheckupModalVisible: false,
  selectedCheckup: { type: '' },
  cancellationReason: '',
  deletingIds: [],
  nextCheckup: [],
  checkupReasons: [],
  selectedCheckupReasons: [],
  freeTextReason: '',
  selectedCheckupDate: moment(),
  isBookingCheckup: false,
  selectedCaregiverBookingType: null,
  selectedCheckupCategory: null,
  selectedCheckupType: null,
  newCheckupBookingDate: null,
  newReminderReasons: [],
  newReminderFreeTextReason: '',
  includeFreeTextReason: false,
  paymentHistory: [],
  disabledDates: []
};

const hideModalState = {
  cancellationReasonModalVisible: false,
  editCheckupModalVisible: false,
  selectedCheckup: { type: '' },
  selectedCheckupDate: moment(),
  selectedCheckupReasons: [],
  freeTextReason: ''
};

const resetBookingState = {
  isBookingCheckup: false,
  selectedCaregiverBookingType: null,
  selectedCheckupCategory: null,
  selectedCheckupType: null,
  newCheckupBookingDate: null,
  newReminderReasons: [],
  newReminderFreeTextReason: '',
  includeFreeTextReason: false
};

const getNextCheckup = (checkupList) => {
  let nextCheckup = [];

  if (checkupList) {
    const futureCheckups = checkupList.filter(
      (checkup) =>
        !checkup.cancellationReason &&
        !checkup.type.toLowerCase().includes('reminder') &&
        moment(checkup.checkupDate, DATE_FORMAT).isSameOrAfter(moment())
    );
    const futureReminders = checkupList.filter(
      (checkup) =>
        checkup.type.toLowerCase().includes('reminder') &&
        moment(checkup.checkupDate, DATE_FORMAT).isSameOrAfter(moment())
    );

    if (futureCheckups.length) {
      nextCheckup.push({
        attributeI18n: 'decision_support_view.notes_section.next_checkup',
        checkup: futureCheckups[0],
        id: futureCheckups[0].id
      });
    }

    if (futureReminders.length) {
      nextCheckup.push({
        attributeI18n: 'decision_support_view.notes_section.next_reminder',
        checkup: futureReminders[0],
        id: futureReminders[0].id
      });
    }
  }

  return nextCheckup.sort((a, b) => {
    if (a.checkup.checkupDate === b.checkup.checkupDate) {
      return 0;
    }

    if (a.checkup.checkupDate < b.checkup.checkupDate) {
      return -1;
    }

    return 1;
  });
};

const sortByDate = (a, b) => {
  if (a.checkupDate === b.checkupDate) {
    return 0;
  }

  if (a.checkupDate < b.checkupDate) {
    return -1;
  }

  return 1;
};

const getDisabledDates = (checkups) => {
  return checkups
    .filter(
      (checkup) =>
        !checkup.type.toLowerCase().includes('reminder') &&
        !checkup.cancellationReason &&
        moment(checkup.checkupDate, DATE_FORMAT).isAfter(moment())
    )
    .map((checkup) => moment(checkup.checkupDate, DATE_FORMAT).toDate());
};

const checkupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DSS_STATUS_REQUEST:
      return { ...state, isLoadingCheckups: true };
    case types.GET_DSS_STATUS_SUCCESS:
      return {
        ...state,
        isLoadingCheckups: false,
        nextCheckup: getNextCheckup(action.payload.checkups),
        checkupReasons: [...action.payload.checkupReasons, { freeTextPlaceholder: true }],
        checkupList: action.payload.checkups,
        hasPastCheckups: action.payload.checkups.some((checkup) =>
          moment(checkup.checkupDate, DATE_FORMAT).isBefore(moment(), 'day')
        ),
        hasPastReminders: action.payload.checkups.some(
          (checkup) =>
            moment(checkup.checkupDate, DATE_FORMAT).isBefore(moment(), 'day') &&
            checkup.type.toLowerCase().includes('reminder')
        ),
        disabledDates: [...state.disabledDates, ...getDisabledDates(action.payload.checkups)]
      };
    case types.GET_DSS_STATUS_ERROR:
      return { ...state, isLoadingCheckups: false };
    case types.SHOW_CANCELLATION_REASON_MODAL:
      return { ...state, cancellationReasonModalVisible: true, selectedCheckup: action.selectedCheckup };
    case types.HIDE_CANCELLATION_REASON_MODAL:
      return { ...state, ...hideModalState };
    case types.SET_SELECTED_CHECKUP:
      return {
        ...state,
        selectedCheckup: action.payload
      };
    case types.DELETE_MEMBER_CHECKUP_REQUEST:
      return { ...state, deletingIds: toggleItem(state.deletingIds, action.id) };
    case types.DELETE_MEMBER_CHECKUP_SUCCESS: {
      let deletedItemIndex = state.checkupList.map((checkup) => checkup.id).indexOf(action.id);
      const checkupListAfterDeletion = !action.checkup.type.toLowerCase().includes('reminder')
        ? updateObjectInArray(state.checkupList, {
            index: deletedItemIndex,
            item: {
              ...state.checkupList[deletedItemIndex],
              cancellationReason: action.cancellationReason
            }
          })
        : removeItemFromArray(state.checkupList, { index: deletedItemIndex });

      return {
        ...state,
        deletingIds: toggleItem(state.deletingIds, action.id),
        checkupList: checkupListAfterDeletion,
        nextCheckup: getNextCheckup(checkupListAfterDeletion),
        disabledDates: getDisabledDates(checkupListAfterDeletion),
        ...hideModalState
      };
    }
    case types.DELETE_MEMBER_CHECKUP_ERROR:
      return {
        ...state,
        deletingIds: toggleItem(state.deletingIds, action.id),
        cancellationReasonModalVisible: action.payload.status && action.payload.status !== HTTP_STATUS_CODES.CONFLICT
      };
    case types.SHOW_EDIT_CHECKUP_MODAL:
      return {
        ...state,
        editCheckupModalVisible: true,
        selectedCheckup: action.selectedCheckup,
        selectedCheckupDate: moment(action.selectedCheckup.checkupDate, DATE_FORMAT),
        selectedCheckupReasons: action.selectedCheckup.checkupReasons
          ? action.selectedCheckup.checkupReasons.map((reason) => reason.id)
          : [],
        freeTextReason: action.selectedCheckup.checkupReasonFreeText
      };
    case types.HIDE_EDIT_CHECKUP_MODAL:
      return { ...state, ...hideModalState };
    case types.SET_EDITED_CHECKUP_DATE:
      return { ...state, selectedCheckupDate: moment(action.payload) };
    case types.TOGGLE_REMINDER_REASON:
      return { ...state, selectedCheckupReasons: toggleItem(state.selectedCheckupReasons, action.payload) };
    case types.SET_REMINDER_REASON_FREE_TEXT:
      return { ...state, freeTextReason: action.payload };
    case types.EDIT_MEMBER_CHECKUP_REQUEST:
      return { ...state, isSavingEditedCheckup: true };
    case types.EDIT_MEMBER_CHECKUP_SUCCESS: {
      let editedItemIndex = state.checkupList.map((checkup) => checkup.id).indexOf(action.payload.id);
      let newCheckupListAfterEditing = state.checkupList;
      if (editedItemIndex > -1) {
        newCheckupListAfterEditing = updateObjectInArray(state.checkupList, {
          index: editedItemIndex,
          item: action.payload
        });
      }

      newCheckupListAfterEditing.sort(sortByDate);

      return {
        ...state,
        isSavingEditedCheckup: false,
        checkupList: newCheckupListAfterEditing,
        nextCheckup: getNextCheckup(newCheckupListAfterEditing),
        disabledDates: getDisabledDates(newCheckupListAfterEditing),
        ...hideModalState
      };
    }
    case types.EDIT_MEMBER_CHECKUP_ERROR:
      return { ...state, isSavingEditedCheckup: false, editCheckupError: action.payload };
    case types.GET_CHECKUP_CALENDAR_REQUEST:
      return { ...state, loadingCheckupCalendar: true };
    case types.GET_CHECKUP_CALENDAR_SUCCESS:
      return {
        ...state,
        isCleared: false,
        loadingCheckupCalendar: false,
        checkupCalendar: action.payload,
        checkupHighlights: getCheckupHighlights(action.payload),
        checkupCalendarError: undefined
      };
    case types.GET_CHECKUP_CALENDAR_ERROR:
      return { ...state, loadingCheckupCalendar: false, checkupCalendarError: action.payload };
    case types.SHOW_NEW_CHECKUP_BOOKING:
      return { ...state, isBookingCheckup: true };
    case types.SELECT_NEW_CHECKUP_CAREGIVER_TYPE:
      return {
        ...state,
        selectedCaregiverBookingType: action.payload,
        selectedCheckupType: null,
        selectedCheckupCategory: null
      };
    case types.SELECT_NEW_CHECKUP_CATEGORY:
      return { ...state, selectedCheckupCategory: action.payload, selectedCheckupType: null };
    case types.SELECT_NEW_CHECKUP_TYPE:
      return { ...state, selectedCheckupType: action.payload };
    case types.TOGGLE_NEW_REMINDER_REASON:
      return { ...state, newReminderReasons: toggleItem(state.newReminderReasons, action.payload) };
    case types.SET_NEW_REMINDER_FREE_TEXT_REASON:
      return { ...state, newReminderFreeTextReason: action.payload };
    case types.SELECT_NEW_CHECKUP_DATE:
      return { ...state, newCheckupBookingDate: moment(action.payload) };
    case types.POST_MEMBER_CHECKUP_REQUEST:
      return { ...state, savingNewCheckup: true };
    case types.POST_MEMBER_CHECKUP_SUCCESS: {
      const newCheckupListAfterSavingCheckup = [
        ...state.checkupList.map((checkup) => ({ ...checkup, new: false })),
        { ...action.payload, new: true }
      ];

      newCheckupListAfterSavingCheckup.sort(sortByDate);

      return {
        ...state,
        checkupList: newCheckupListAfterSavingCheckup,
        nextCheckup: getNextCheckup(newCheckupListAfterSavingCheckup),
        disabledDates: getDisabledDates(newCheckupListAfterSavingCheckup),
        savingNewCheckup: false,
        ...resetBookingState
      };
    }
    case types.POST_MEMBER_CHECKUP_ERROR:
      return { ...state, savingNewCheckup: false };
    case types.TOGGLE_INCLUDE_FREE_TEXT_REASON:
      return { ...state, includeFreeTextReason: !state.includeFreeTextReason };
    case types.GET_PAYMENT_HISTORY_REQUEST:
      return { ...state, loadingPaymentHistory: true };
    case types.GET_PAYMENT_HISTORY_SUCCESS:
      return { ...state, loadingPaymentHistory: false, paymentHistory: action.payload, paymentHistoryError: undefined };
    case types.GET_PAYMENT_HISTORY_ERROR:
      return { ...state, loadingPaymentHistory: false, paymentHistoryError: action.payload };
    case types.CLEAR_CURRENT_MEMBER:
      return { ...initialState };
    default:
      return state;
  }
};

export default checkupsReducer;
