import React from 'react';
import { Translate } from 'react-redux-i18n';

const RadioButton = ({ id, value, name, onChange, checked, labelI18nKey, ...rest }) => {
  return (
    <span {...rest}>
      <input type="radio" id={id} value={value} name={name} onChange={onChange} checked={checked} />
      <label htmlFor={id}>
        <Translate value={labelI18nKey} />
      </label>
    </span>
  );
};

export default RadioButton;
