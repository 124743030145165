import React, { Component, Fragment } from 'react';
import { Translate } from 'react-redux-i18n';
import RiskFactorStatus from '../RiskFactorStatus';
import RiskFactorWeight from '../RiskFactorWeight';
import RiskFactorLowPhysicalActivity from '../RiskFactorLowPhysicalActivity';
import { RISK_FACTOR_LOW_PHYSICAL_ACTIVITY_ID, RISK_FACTOR_WEIGHT_ID } from '../../../../constants';
import { makeDisableable } from '../../../../utils/hoc';

class RiskFactorList extends Component {
  render() {
    const { riskFactors, ...rest } = this.props;

    return (
      <Fragment>
        <h2>
          <Translate value="decision_support_view.risk_profile_factors_header" />
        </h2>
        {!riskFactors || !riskFactors.length ? (
          <div>
            <span className="field__empty">
              <Translate value="decision_support_view.risk_profile_section.no_risk_factors" />
            </span>
          </div>
        ) : (
          riskFactors.map((riskFactor) => {
            switch (riskFactor.recommendation ? riskFactor.recommendation.id : 99999) {
              case RISK_FACTOR_LOW_PHYSICAL_ACTIVITY_ID:
                return <RiskFactorLowPhysicalActivity key={riskFactor.id} {...riskFactor} {...rest} />;
              case RISK_FACTOR_WEIGHT_ID:
                return <RiskFactorWeight key={riskFactor.id} {...riskFactor} {...rest} />;
              default:
                return <RiskFactorStatus key={riskFactor.id} {...riskFactor} {...rest} />;
            }
          })
        )}
      </Fragment>
    );
  }
}

export default makeDisableable(RiskFactorList);
