import { HTTP_STATUS_CODES } from '../constants';
import * as types from '../constants/actionTypes';

const sortByDate = (a, b) => {
  if (a.timestamp === b.timestamp) {
    return 0;
  }

  if (a.timestamp > b.timestamp) {
    return -1;
  }

  return 1;
};

const initialState = {
  communicationEvents: [],
  modalVisible: false,
  selectedCommunicationEvent: null,
  fullCommunicationEvent: null,
  paginationDetails: {
    offset: -1,
    limit: -1,
    totalNumberOfItems: -1
  },
  currentPage: 0
};

const patientCommunicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MEMBER_COMMUNICATION_EVENTS_REQUEST:
      return { ...state, isLoading: true };
    case types.GET_MEMBER_COMMUNICATION_EVENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        communicationEvents: action.payload.communicationEvents.sort(sortByDate),
        paginationDetails: action.payload.pagination,
        communicationEventsError: undefined
      };
    case types.SET_PATIENT_COMMUNICATION_VIEW_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload
      };
    case types.GET_MEMBER_COMMUNICATION_EVENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        communicationEventsError:
          action.payload && action.payload.status !== HTTP_STATUS_CODES.NOT_FOUND ? action.payload : undefined
      };
    case types.SHOW_COMMUNICATION_EVENTS_CONTENT_MODAL: {
      const selectedCommunicationEvent = state.communicationEvents.find((event) => event.uuid === action.payload);
      return {
        ...state,
        modalVisible: true,
        selectedCommunicationEvent: selectedCommunicationEvent
      };
    }
    case types.HIDE_COMMUNICATION_EVENTS_CONTENT_MODAL:
      return {
        ...state,
        selectedCommunicationEvent: null,
        fullCommunicationEvent: null,
        modalVisible: false
      };
    case types.GET_MEMBER_FULL_COMMUNICATION_EVENT_REQUEST:
      return { ...state, isLoadingContent: true };
    case types.GET_MEMBER_FULL_COMMUNICATION_EVENT_SUCCESS:
      return {
        ...state,
        isLoadingContent: false,
        communicationEventsError: undefined,
        fullCommunicationEvent: action.payload
      };
    case types.GET_MEMBER_FULL_COMMUNICATION_EVENT_ERROR:
      return {
        ...state,
        isLoadingContent: false,
        communicationEventsError:
          action.payload && action.payload.status !== HTTP_STATUS_CODES.NOT_FOUND ? action.payload : undefined
      };
    default:
      return state;
  }
};

export default patientCommunicationReducer;
