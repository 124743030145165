import React, { Fragment } from 'react';
import { Translate } from 'react-redux-i18n';

const CurrentMedicationsColumnHeaders = () => {
  return (
    <Fragment>
      <div className="column is-2">
        <Translate value="patient_view.medications.table_header.issued_by" />
      </div>
      <div className="column is-2">
        <Translate value="patient_view.medications.table_header.name" />
      </div>
      <div className="column is-2">
        <Translate value="patient_view.medications.table_header.strength" />
      </div>
      <div className="column is-2">
        <Translate value="patient_view.medications.table_header.type" />
      </div>
      <div className="column is-2">
        <Translate value="patient_view.medications.table_header.dose" />
      </div>
      <div className="column is-2">
        <Translate value="patient_view.medications.table_header.prescribed_by" />
      </div>
    </Fragment>
  );
};

export default CurrentMedicationsColumnHeaders;
