import * as types from '../constants/actionTypes';
import flatten from 'lodash.flattendeep';
import moment from 'moment';
import {
  NATURAL_REMEDIES_ID,
  DIETARY_SUPPLEMENTS_ID,
  HEIGHT_ID,
  WEIGHT_ID,
  WAIST_SIZE_ID,
  PERSONAL_GOAL_ID,
  ALLERGY_QUESTION_ID,
  OVERSENSITIVITY_QUESTION_ID,
  MEDICATION_QUESTION_ID,
  DATE_FORMAT
} from '../constants';

const getQuestionById = (id) => (question) => question.id === id;

const morningMeasurementFilter = (measurement) => {
  const date = new Date(measurement.date);
  return date.getHours() >= 2 && date.getHours() < 14;
};
const eveningMeasurementFilter = (measurement) => {
  const date = new Date(measurement.date);
  return date.getHours() >= 14 || date.getHours() < 2;
};

const initialState = {
  profileData: { questions: [] },
  memberMeasurements: [],
  originalMeasurements: [],
  naturalRemedies: null,
  dietarySupplements: null,
  height: null,
  weight: null,
  waistSize: null,
  memberStatistics: null,
  personalGoal: null,
  allergy: null,
  oversensitivity: null,
  morningMeasurements: [],
  eveningMeasurements: [],
  medicationAnswer: null,
  lastPeriod: null,
  currentPeriods: null
};

const extractAnswer = (questionId, payload, parseAsInt) => {
  const question = payload.questions.filter(getQuestionById(questionId));

  const answer =
    question[0] &&
    question[0].userAnswer &&
    question[0].userAnswer.answers.length &&
    (parseAsInt
      ? parseInt(question[0].range[0], 10) + parseInt(question[0].userAnswer.answers[0], 10)
      : question[0].userAnswer.answers[0]);

  return answer;
};

const patientDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PATIENT_DATA_REQUEST:
      return { ...state, isFetching: true };
    case types.PATIENT_DATA_SUCCESS: {
      const naturalRemedies = extractAnswer(NATURAL_REMEDIES_ID, action.payload);
      const dietarySupplements = extractAnswer(DIETARY_SUPPLEMENTS_ID, action.payload);
      const remediesSupplementsTableData = [];

      remediesSupplementsTableData[0] = {
        attributeI18n: 'patient_view.medications.natural_remedies',
        value: naturalRemedies || '-'
      };

      remediesSupplementsTableData[1] = {
        attributeI18n: 'patient_view.medications.dietary_supplements',
        value: dietarySupplements || '-'
      };

      return {
        ...state,
        profileData: action.payload,
        naturalRemedies,
        dietarySupplements,
        height: extractAnswer(HEIGHT_ID, action.payload, true),
        weight: extractAnswer(WEIGHT_ID, action.payload, true),
        waistSize: extractAnswer(WAIST_SIZE_ID, action.payload, true),
        personalGoal: extractAnswer(PERSONAL_GOAL_ID, action.payload),
        allergy: extractAnswer(ALLERGY_QUESTION_ID, action.payload),
        oversensitivity: extractAnswer(OVERSENSITIVITY_QUESTION_ID, action.payload),
        medicationAnswer: extractAnswer(MEDICATION_QUESTION_ID, action.payload),
        remediesSupplementsTableData,
        isFetching: false,
        error: undefined
      };
    }
    case types.PATIENT_DATA_ERROR:
      return { ...state, error: action.payload, isFetching: false };
    case types.CLEAR_PATIENT_DATA:
      return initialState;
    case types.MEMBER_MEASUREMENTS_REQUEST:
      return { ...state, isFetching: true, loadingMeasurements: true };
    case types.MEMBER_MEASUREMENTS_SUCCESS: {
      const bloodPressureMeasurements = action.payload.map((measurement) => {
        var measurements = [];
        measurement.bpmeasurements.forEach((bp) => {
          measurements.push({
            timestamp: bp.timestamp,
            date: new Date(bp.timestamp).getTime(),
            systolic: bp.systolic,
            diastolic: bp.diastolic,
            measurementMode: measurement.measurementMode,
            heartrate: bp.heartrate,
            bodyPosition: measurement.bodyPosition,
            comment: measurement.comment
          });
        });

        return measurements;
      });

      const bloodPressureAverages = action.payload
        .filter((measurement) => {
          return measurement.measurementMode !== 'standing' && measurement.measurementMode !== 'symptoms';
        })
        .map((measurement) => {
          var measurements = [];
          let totalSystolic = 0;
          let totalDiastolic = 0;
          let totalHeartrate = 0;

          measurement.bpmeasurements.forEach((bp) => {
            totalSystolic += bp.systolic;
            totalDiastolic += bp.diastolic;
            totalHeartrate += bp.heartrate;
          });

          measurements.push({
            date: moment(measurement.bpmeasurements[0].timestamp, DATE_FORMAT).valueOf(),
            calendarDate: moment(measurement.bpmeasurements[0].timestamp, DATE_FORMAT).format('YYYY-MM-DD'),
            systolic: Math.round(totalSystolic / measurement.bpmeasurements.length),
            diastolic: Math.round(totalDiastolic / measurement.bpmeasurements.length),
            heartrate: Math.round(totalHeartrate / measurement.bpmeasurements.length),
            measurementMode: measurement.measurementMode,
            bodyPosition: measurement.bodyPosition,
            comment: measurement.comment
          });

          return measurements;
        });

      const flattenedMeasurements = flatten(bloodPressureAverages);

      return {
        ...state,
        isFetching: false,
        loadingMeasurements: false,
        originalMeasurements: action.payload,
        memberMeasurements: flatten(bloodPressureMeasurements),
        totalMeasurements: flattenedMeasurements.length,
        morningMeasurements: flattenedMeasurements.filter(morningMeasurementFilter),
        eveningMeasurements: flattenedMeasurements.filter(eveningMeasurementFilter),
        error: undefined
      };
    }
    case types.MEMBER_MEASUREMENTS_ERROR:
      return { ...state, isFetching: false, loadingMeasurements: false, error: action.payload };
    case types.MEMBER_STATS_REQUEST:
      return { ...state, isFetchingStatistics: true };
    case types.MEMBER_STATS_SUCCESS:
      return {
        ...state,
        isFetchingStatistics: false,
        memberStatistics: action.payload,
        lastPeriod: action.payload.length
          ? !state.lastPeriod || action.payload[action.payload.length - 1].periodId > state.lastPeriod
            ? action.payload[action.payload.length - 1].periodId
            : state.lastPeriod
          : 0,
        currentPeriods: action.payload.map((period) => period.periodId),
        periodSize: state.periodSize || 14
      };
    case types.MEMBER_STATS_ERROR:
      return { ...state, isFetchingStatistics: false, memberStatisticsError: action.payload };
    case types.SET_STATS_PERIOD_SIZE:
      return { ...state, periodSize: action.payload };
    case types.GET_MEMBER_EVENT_HISTORY_REQUEST:
      return { ...state, loadingEventHistory: true };
    case types.GET_MEMBER_EVENT_HISTORY_SUCCESS:
      return { ...state, loadingEventHistory: false, eventHistory: action.payload };
    case types.GET_MEMBER_EVENT_HISTORY_ERROR:
      return { ...state, loadingEventHistory: false, eventHistoryError: action.payload };
    default:
      return state;
  }
};

export default patientDataReducer;
