import api from '../api/apiClient';
import { isTokenNearingExpiry } from '../utils';
import { refreshTokenRequest, refreshTokenSuccess } from '../actions';
import { loadSessionState } from '../services/sessionStorage';
import * as types from '../constants/actionTypes';

const excludedActions = [
  types.LOGOUT,
  types.DISABLE_INSTANCE,
  types.LOGIN_REQUEST,
  types.LOGIN_SUCCESS,
  types.GET_CHAT_MESSAGES_REQUEST,
  types.GET_CHAT_MESSAGES_SUCCESS,
  types.GET_CHAT_MESSAGES_ERROR,
  types.UPDATE_DRAFT_COUNTDOWN,
  types.CHECK_TOKEN_VALIDITY,
  types.MANUAL_TOKEN_REFRESH_REQUEST,
  types.MANUAL_TOKEN_REFRESH_SUCCESS,
  '@@' // 3rd party libraries
];

const tokenRefreshMiddleware = (store) => (next) => (action) => {
  const token = store.getState().auth.token;
  const isRefreshing = store.getState().auth.refreshingToken;
  const isExcluded = excludedActions.includes(action.type);

  if (token && isTokenNearingExpiry(token) && !isRefreshing && !isExcluded) {
    next(refreshTokenRequest());

    const requestId = loadSessionState('requestId');

    api
      .refreshToken(token.jwt, requestId)
      .then((response) => {
        store.dispatch(refreshTokenSuccess(response));
        return next(action);
      })
      .catch((error) => {
        // Todo: Handle token refresh error
        console.log(error);
        return next(action);
      });
  } else {
    return next(action);
  }
};

export default tokenRefreshMiddleware;
