import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import App from './containers/App';
import LoginView from './components/LoginView';
import { connect } from 'react-redux';
import { APPLICATION_BASE_PATH, APP_TITLE, CURRENT_ENV } from './constants';
import PrivateRoute from './components/PrivateRoute';
import BasePathRedirector from './components/BasePathRedirector';
import { loadSessionState } from './services/sessionStorage';
import api from '../src/api/apiClient';
import metadata from './metadata.json';

const authState = loadSessionState('auth', true) || {};
api.setClientIdHeader(authState.clientId);

const env = process.env.REACT_APP_API_ENV || CURRENT_ENV;
const displayEnv = metadata.environments[env].apiUrl.match(/https?:\/\/(\w+)/);

class Root extends Component {
  render() {
    return (
      <Router>
        <div className="root-container">
          {env !== 'production' && displayEnv.length > 1 ? (
            <Helmet>
              <title>{`${APP_TITLE} (${displayEnv[1].toUpperCase()})`}</title>
            </Helmet>
          ) : null}
          <Route exact path="/" component={BasePathRedirector} />
          <Route path="/login" component={LoginView} />
          <PrivateRoute path={`/${APPLICATION_BASE_PATH}`} component={App} />
        </div>
      </Router>
    );
  }
}

export default connect()(Root);
