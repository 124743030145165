import React, { Component } from 'react';
import { Translate } from 'react-redux-i18n';
import './TopBar.scss';

class TopBar extends Component {
  render() {
    return (
      <nav className="top-bar level">
        <div className="level-left">
          <Translate value={this.props.header} />
        </div>
      </nav>
    );
  }
}

export default TopBar;
