import { useState, Fragment, useEffect } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { decamelize, toggleItem } from '../../../utils';
import SkeletonComponent from '../../SkeletonComponent';
import WaitTime from '../../WaitTime';
import './CaregiverPriorityList.scss';

const PriorityListHeader = ({ i18nKey, numItems, fullPageLinkUrl }) => {
  const header = (
    <h1>
      <Translate value={i18nKey} />{' '}
      {numItems > 0 && <span className="list-count">({`${numItems} ${I18n.t('global.count_suffix')}`.trim()})</span>}
    </h1>
  );

  return fullPageLinkUrl ? <Link to={fullPageLinkUrl}>{header}</Link> : header;
};

const PriorityRow = ({ member, labels, isRead, onClick, timestamp, isSnoozed }) => {
  let additionalLabels = new Set(labels);
  additionalLabels.delete(labels[0]);
  additionalLabels = [...additionalLabels];

  return (
    <div className="priority-row" onClick={onClick}>
      <div className={!isRead ? 'unread' : undefined}>
        <span className="member-name" data-sensitive>{`${member.givenName} ${member.familyName}`}</span>
        <WaitTime sinceTimestamp={timestamp} />
      </div>
      <div className="vertical-align">
        {isSnoozed ? <span className="icon snooze mr-5"></span> : null}
        <span className={`errand-label ${decamelize(labels[0], '-')}`}>
          <Translate value={`global.event_type.label.${labels[0]}`} />
        </span>
        {additionalLabels.length ? (
          <span
            className="additional-labels-count"
            data-multiline={true}
            data-tip={`${additionalLabels.map((label) => I18n.t(`global.event_type.label.${label}`)).join('<br>')}`}
          >
            +{additionalLabels.length}
          </span>
        ) : null}
      </div>
    </div>
  );
};

const PriorityList = ({ allItems, filteredItems, isLoading, visitedPatients, onRowClick, itemLimit, i18nKey }) => {
  const NUM_SKELETON_ROWS = 3;

  if (!allItems.length && isLoading) {
    return (
      <>
        {new Array(NUM_SKELETON_ROWS).fill(0).map((_, i) => (
          <SkeletonComponent key={i} width="100%" height="47px" />
        ))}
      </>
    );
  }

  if (allItems.length && filteredItems.length) {
    return (itemLimit ? filteredItems.slice(0, itemLimit) : filteredItems).map((item) => {
      const storedEvents = visitedPatients[item.member.guid];
      const eventIds = item.events.map((event) => event.id);
      return (
        <PriorityRow
          key={item.member.guid}
          member={item.member}
          onClick={onRowClick(item.member)}
          labels={item.events.map((event) => event.eventType)}
          timestamp={item.events[0].timestamp}
          isRead={storedEvents && eventIds.every((id) => storedEvents.includes(id))}
          isSnoozed={item.events.some((event) => event.snoozedUntilDate)}
        />
      );
    });
  }

  return (
    <div className="no-table-rows">
      {!allItems.length ? <Translate value={`${i18nKey}.empty`} /> : <Translate value={`${i18nKey}.filtered_empty`} />}
    </div>
  );
};

const FilterControls = ({ filterProperties, filters, onToggleFilter }) => {
  if (!filterProperties) {
    return null;
  }

  return (
    <div className="vertical-align mb-10">
      {filterProperties.map((filterProperty) => (
        <Fragment key={filterProperty}>
          <input
            type="checkbox"
            id={`filter-${filterProperty}`}
            checked={filters.includes(filterProperty)}
            onChange={() => onToggleFilter(filterProperty)}
          />
          <label htmlFor={`filter-${filterProperty}`} className="fs-14 ml-5 mr-15">
            <Translate value={`global.event_type.label.${filterProperty}`} />
          </label>
        </Fragment>
      ))}
    </div>
  );
};

const CaregiverPriorityList = ({
  onRowClick,
  items,
  fullPageLinkUrl,
  isLoading,
  filterProperties,
  totalNumItems,
  visitedPatients,
  itemLimit,
  i18nKey,
  showAnimations = true
}) => {
  const [filters, setFilters] = useState(filterProperties ? filterProperties : []);
  let filteredItems = [...items];

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  if (filterProperties) {
    filteredItems = items.filter((item) => item.events.some((event) => filters.includes(event.eventType)));
  }

  return (
    <>
      <div className={`caregiver-prio ${showAnimations ? 'animations-enabled' : ''}`}>
        <PriorityListHeader
          numItems={totalNumItems}
          i18nKey={`${i18nKey}.list_header`}
          fullPageLinkUrl={fullPageLinkUrl}
        />
        <FilterControls
          filterProperties={filterProperties}
          filters={filters}
          onToggleFilter={(e) => setFilters(toggleItem(filters, e))}
        />
        <div className="rounded-container">
          <PriorityList
            allItems={items}
            filteredItems={filteredItems}
            isLoading={isLoading}
            visitedPatients={visitedPatients}
            onRowClick={onRowClick}
            itemLimit={itemLimit}
            i18nKey={i18nKey}
          />
          {fullPageLinkUrl && totalNumItems > itemLimit ? (
            <Link to={fullPageLinkUrl} className="full-width-link">
              <Translate value={`${i18nKey}.show_all`} />
            </Link>
          ) : null}
        </div>
      </div>
      <ReactTooltip multiline={true} effect="solid" className="custom-tooltip" />
    </>
  );
};

export default CaregiverPriorityList;
