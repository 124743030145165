import React, { Fragment } from 'react';
import TopBar from '../TopBar';
import UnauthorizedAccessWarning from '../UnauthorizedAccessWarning';

const Page = ({ children, titleI18nKey, allowedPatients }) => {
  return (
    <Fragment>
      <UnauthorizedAccessWarning ownPatients={allowedPatients} />
      <div className="column no-padding">
        <TopBar header={titleI18nKey} />
        {children}
      </div>
    </Fragment>
  );
};

export default Page;
