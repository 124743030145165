import React, { Component, Fragment } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import ModalDialog from '../../../../ModalDialog';
import './SimpleRow.scss';

class SimpleRow extends Component {
  constructor(props) {
    super(props);

    this.showHistory = this.showHistory.bind(this);
    this.hideHistory = this.hideHistory.bind(this);

    this.state = {
      historyVisible: false
    };
  }

  showHistory() {
    this.setState({
      historyVisible: true
    });
  }

  hideHistory() {
    this.setState({
      historyVisible: false
    });
  }

  render() {
    const { attributeI18n, value, displayValue } = this.props;

    if (!attributeI18n && !displayValue) return null;

    const hasHistory = Array.isArray(value) && value.length > 1;

    return (
      <Fragment>
        <div className="simple-row row-container">
          <div className="columns table-row">
            <div className="column row-category is-4">
              {attributeI18n ? <Translate value={attributeI18n} /> : displayValue}
            </div>
            <div className="column row-response is-7">{Array.isArray(value) ? value[0] || '-' : value || '-'}</div>
            <div className="column is-1">
              <div className="row-actions">
                {hasHistory ? (
                  <span
                    title={I18n.t('decision_support_view.show_history')}
                    className="pointer open-history"
                    onClick={this.showHistory}
                  >
                    <span className="icons history"></span>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <ModalDialog
          header={`${I18n.t('decision_support_view.history_header')}: ${
            attributeI18n ? I18n.t(attributeI18n) : displayValue
          }`}
          actionI18nKey="global.buttons.done"
          visible={this.state.historyVisible}
          onClose={this.hideHistory}
          onActionCompleted={this.hideHistory}
          actionCompletable={true}
          actionCompleting={false}
        >
          <div className="simple-history-container">
            {hasHistory
              ? value.map((historicalValue, i) => {
                  return (
                    <div className="simple-history-row" key={i}>
                      {historicalValue}
                    </div>
                  );
                })
              : null}
          </div>
        </ModalDialog>
      </Fragment>
    );
  }
}

export default SimpleRow;
