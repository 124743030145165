import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { Translate } from 'react-redux-i18n';
import { DATE_FORMAT } from '../../../../../constants';
import DosageWithFallback from '../../../DosageWithFallback';
import './InvalidatedMedicationRow.scss';

class InvalidatedMedicationRow extends Component {
  render() {
    const { changes, current } = this.props;

    const created = changes.filter((change) => change.changeType === 'new')[0];
    const createdDate = created?.timestamp;
    const prescribedBy = created?.changedBy;
    const prescribedByAccumbo = created?.prescribed;

    const invalidated = changes.filter((change) => change.changeType === 'invalidate')[0];
    const invalidatedDate = invalidated?.timestamp;
    const invalidatedBy = invalidated?.changedBy;

    return (
      <div className="dss-medication-row row-container">
        <div className="columns table-row previous-medication">
          <div className="column is-2 overflow-ellipsis" title={current.name || ''}>
            {current.name}
          </div>
          <div className="column is-2">{current.strength}</div>
          <div className="column is-2 overflow-ellipsis" title={current.form || ''}>
            {current.form}
          </div>
          <div className="column is-2">
            <DosageWithFallback medication={current} />
          </div>
          <div className="column is-2">
            {prescribedBy && prescribedByAccumbo ? (
              <Fragment>
                <div>
                  {prescribedBy.givenName} {prescribedBy.familyName}
                </div>
                <div>{moment(createdDate, DATE_FORMAT).format('YYYY-MM-DD')}</div>
              </Fragment>
            ) : (
              <Fragment>
                <div>
                  <Translate value="decision_support_view.medication_section.other_doctor" />
                </div>
                <div>{moment(createdDate, DATE_FORMAT).format('YYYY-MM-DD')}</div>
              </Fragment>
            )}
          </div>
          <div className="column is-2">
            {invalidatedBy ? (
              <Fragment>
                <div>
                  {invalidatedBy.givenName} {invalidatedBy.familyName}
                </div>
                <div>{moment(invalidatedDate, DATE_FORMAT).format('YYYY-MM-DD')}</div>
              </Fragment>
            ) : (
              '-'
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default InvalidatedMedicationRow;
