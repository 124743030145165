import React, { Fragment } from 'react';
import { Translate } from 'react-redux-i18n';
import moment from 'moment';
import MedicationDataWithFallback from '../MedicationDataWithFallback';
import { DATE_FORMAT } from '../../../constants';

const MedicationBase = ({ medication, changes, hideSignDate, hideAddedDate }) => {
  const created = changes.filter((change) => change.changeType === 'new')[0];
  const createdDate = created && created.timestamp;

  const signed = changes.filter((change) => change.changeType === 'sign')[0];
  const signedDate = signed && signed.timestamp;
  const signedBy = signed && signed.changedBy;

  return (
    <Fragment>
      <div className="columns mb-25">
        <div className="column no-padding">
          <MedicationDataWithFallback
            medication={medication}
            dataProperty="nplPackId"
            headerI18nKey="patient_view.medications.medication_modal.medication_npl_pack_id"
          />
        </div>
      </div>

      <div className="columns mb-25">
        <div className="column no-padding">
          <MedicationDataWithFallback
            medication={medication}
            dataProperty="name"
            headerI18nKey="patient_view.medications.medication_modal.medication_name"
          />
        </div>
      </div>

      <div className="columns mb-25">
        <div className="column no-padding pad-right">
          <MedicationDataWithFallback
            medication={medication}
            dataProperty="form"
            headerI18nKey="patient_view.medications.medication_modal.medication_type"
          />
        </div>
        <div className="column no-padding">
          <MedicationDataWithFallback
            medication={medication}
            dataProperty="strength"
            headerI18nKey="patient_view.medications.medication_modal.medication_strength"
          />
        </div>
      </div>

      <div className="columns mb-25">
        <div className="column no-padding">
          <MedicationDataWithFallback
            medication={medication}
            dataProperty="dosageRegimen"
            headerI18nKey="patient_view.medications.medication_modal.medication_dose"
          />
        </div>
      </div>

      <div className="columns mb-25">
        <div className="column no-padding">
          <MedicationDataWithFallback
            medication={medication}
            dataProperty="reason"
            headerI18nKey="patient_view.medications.medication_modal.medication_reason"
          />
        </div>
      </div>

      <div className="columns mb-25">
        <div className="column no-padding">
          <MedicationDataWithFallback
            medication={medication}
            dataProperty="whenNeeded"
            headerI18nKey="patient_view.medications.medication_modal.medication_when_needed"
          />
        </div>
      </div>

      {hideSignDate && hideAddedDate ? null : (
        <div className="columns medication-information__footer">
          <div className="column is-6 no-padding">
            {!hideAddedDate ? (
              <Fragment>
                <h4>
                  <Translate value="patient_view.medications.medication_modal.added_at" />
                </h4>
                <div>{createdDate ? moment(createdDate, DATE_FORMAT).format('YYYY-MM-DD') : '-'}</div>
              </Fragment>
            ) : null}
          </div>
          <div className="column is-6 no-padding">
            {!hideSignDate ? (
              <Fragment>
                <h4>
                  <Translate value="patient_view.medications.medication_modal.signed_at" />
                </h4>
                <div>
                  {signedDate ? moment(signedDate, DATE_FORMAT).format('YYYY-MM-DD') : '-'}
                  {signedBy && `, ${signedBy.givenName} ${signedBy.familyName}`}
                </div>
              </Fragment>
            ) : null}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default MedicationBase;
