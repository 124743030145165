import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Chrono } from 'react-chrono';
import { Translate } from 'react-redux-i18n';
import { DATE_FORMAT } from '../../constants';
import api from '../../api/apiClient';
import './ProgramTimelineView.scss';
import ContentLoader from '../ContentLoader';

const ProgramTimelineView = ({ authToken, patientGuid }) => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [programs, setPrograms] = useState([]);

  useEffect(() => {
    if (authToken && patientGuid) {
      api
        .getProgramsForPatient(authToken, patientGuid)
        .then((response) => {
          setPrograms(response);
          setIsLoading(false);
        })
        .catch((error) => {
          setError(error);
          setIsLoading(false);
        });
    }
  }, [authToken, patientGuid]);

  return (
    <div className="rounded-container mb-30">
      <h1>
        <Translate value="program_timeline_view.header" />
      </h1>
      <ContentLoader isLoading={isLoading} error={error} errorTitleI18n="program_timeline_view.get_programs_error">
        {programs?.length ? (
          programs.map((program) => <ProgramTimeline key={program.programName} program={program} />)
        ) : (
          <Translate value="program_timeline_view.no_active_program" />
        )}
      </ContentLoader>
    </div>
  );
};

const ProgramTimeline = ({ program }) => {
  const getActiveStepIndex = (steps) => {
    return steps.findIndex((step) => step.startedAt && !step.completedAt);
  };

  const stepTitles = program.steps.map((step) => {
    return { title: step.stepName };
  });

  return (
    <>
      <h2>
        <Translate value="program_timeline_view.program" /> - {program.programName}
      </h2>
      <div className="program-timeline-container">
        <Chrono
          items={stepTitles}
          mode="VERTICAL"
          activeItemIndex={getActiveStepIndex(program.steps)}
          cardHeight={0}
          cardWidth={300}
          borderLessCards="true"
          hideControls="true"
          theme={{
            primary: '#72053a',
            secondary: '#fee4ed',
            titleColor: '#72053a'
          }}
          fontSizes={{
            cardTitle: '1rem'
          }}
        >
          {program.steps.map((step) => (
            <ProgramStep key={step.stepName} step={step} />
          ))}
        </Chrono>
      </div>
    </>
  );
};

const FutureProgramStep = () => {
  return (
    <div className="program-step">
      <Translate value="program_timeline_view.step.not_started" />
    </div>
  );
};

const CurrentProgramStep = ({ step }) => {
  return (
    <div className="program-step">
      <h4>
        <Translate value="program_timeline_view.step.ongoing" />
      </h4>
      <Translate value="program_timeline_view.step.started" />: {moment(step.startedAt, DATE_FORMAT).format('LL')}{' '}
    </div>
  );
};

const CompletedProgramStep = ({ step }) => {
  return (
    <div className="program-step">
      <Translate value="program_timeline_view.step.completed" />: {moment(step.completedAt, DATE_FORMAT).format('LL')}
    </div>
  );
};

const ProgramStep = ({ step }) => {
  if (step.completedAt) {
    return <CompletedProgramStep step={step} />;
  }

  if (!step.startedAt) {
    return <FutureProgramStep />;
  }

  if (step.startedAt && !step.completedAt) {
    return <CurrentProgramStep step={step} />;
  }
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    patientGuid: state.currentMember.guid
  };
};

export default connect(mapStateToProps)(ProgramTimelineView);
