import { Translate } from 'react-redux-i18n';
import SkeletonComponent from '../../../SkeletonComponent';
import AuscultationRecording from '../AuscultationRecording';
import { HEART_RECORDING_LOCATIONS, LUNG_RECORDING_LOCATIONS } from '../../../../constants';

const AuscultationRecordingList = ({ isLoading, type, activeRecording, recordings, onPlay, onStop, recordingDate }) => {
  const recordingLocations = type === 'heart' ? HEART_RECORDING_LOCATIONS : LUNG_RECORDING_LOCATIONS;

  return (
    <>
      <h3>
        <Translate value={`patient_view.notes.examination.auscultation.${type}.header`} />
      </h3>
      {isLoading
        ? recordingLocations.map((_, i) => <SkeletonComponent key={i} width="100%" height="86px" />)
        : recordingLocations.map((location, i) => (
            <AuscultationRecording
              key={location}
              isPlaying={activeRecording === `${type}${location}`}
              order={i + 1}
              type={type}
              location={location}
              base64Data={recordings.find((r) => r.key === `${type}${location}`)?.value}
              onPlay={() => onPlay(`${type}${location}`)}
              onStop={onStop}
              recordingDate={recordingDate}
            />
          ))}
    </>
  );
};

export default AuscultationRecordingList;
