import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { changeTab, getAllReferrals, getAllLabResults } from '../../actions';
import PrivateRoute from '../../components/PrivateRoute';
import InfoSolutionsLabResults from './InfoSolutionsLabResults';
import InfoSolutionsReferrals from './InfoSolutionsReferrals';
import './ReferralsView.scss';
import { Mixpanel } from '../../utils/mixpanel';

class ReferralsView extends Component {
  componentDidMount() {
    Mixpanel.resetSuperProperties();
    this.props.getAllReferrals(this.props.authToken);
    this.props.getAllLabResults(this.props.authToken);
  }

  render() {
    return (
      <div className="columns no-padding">
        <div className="columns">
          <div className="column no-padding tab-content-container full-width">
            <div className="tabs">
              <div className="columns">
                <div className="column no-padding is-9 tab-column">
                  <ul>
                    <li>
                      <NavLink to={`${this.props.match.url}/all-referrals`} activeClassName="is-active">
                        <Translate value="referrals_view.referrals" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink exact to={`${this.props.match.url}/all-lab-results`} activeClassName="is-active">
                        <Translate value="referrals_view.lab_results" />
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="referrals-container">
              <PrivateRoute exact path={`${this.props.match.url}/all-referrals`} component={InfoSolutionsReferrals} />
              <PrivateRoute path={`${this.props.match.url}/all-lab-results`} component={InfoSolutionsLabResults} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt
  };
};

const mapActionsToProps = {
  changeTab,
  getAllReferrals,
  getAllLabResults
};

export default connect(mapStateToProps, mapActionsToProps)(ReferralsView);
