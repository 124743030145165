import React, { Component, Fragment } from 'react';
import { Translate } from 'react-redux-i18n';
import './WeeklyResultsRow.scss';

class WeeklyResultsRow extends Component {
  render() {
    const { typeI18nKey, goal, results } = this.props;

    if (!goal) return null;

    return (
      <div className="weekly-results-row row-container">
        <div className="columns table-row">
          <div className="column is-4">{<Translate value={typeI18nKey} />}</div>
          <div className="column is-4">
            <div>
              <Translate value="decision_support_view.risk_profile_section.members_goal" />:
            </div>
            <div>
              {goal} <Translate value="decision_support_view.risk_profile_section.minutes_per_week" />
            </div>
          </div>
          <div className="column is-4">
            <WeeklyResultsList results={results} />
          </div>
        </div>
      </div>
    );
  }
}

export default WeeklyResultsRow;

class WeeklyResultsList extends Component {
  render() {
    const { results } = this.props;

    if (!results || !results.length) return null;

    return (
      <Fragment>
        <div className="mb-5">
          <Translate value="decision_support_view.risk_profile_section.results_week_by_week" />
        </div>
        <div>
          {results.map((result, i) => {
            return <span key={i} className={`weekly-result ${result}`}></span>;
          })}
        </div>
      </Fragment>
    );
  }
}
