import React, { useState, Fragment } from 'react';
import moment from 'moment';
import { Translate, I18n } from 'react-redux-i18n';
import ContentLoader from '../ContentLoader';
import LoadingSpinner from '../LoadingSpinner';
import FutureCheckupsGraph from './FutureCheckupsGraph';
import './CaregiverDashboard.scss';

const CaregiverDashboard = ({ caregiver, metrics, isLoading, error, onPeriodChange, isNurse, bookedCheckups }) => {
  const [hidden, setHidden] = useState(true);
  const [currentPeriod, setCurrentPeriod] = useState(moment().format('YYYY-MM-01'));

  const parseMetric = (metricValue, valueType) => {
    if (valueType === 'integer') {
      return parseInt(metricValue, 10).toLocaleString();
    }

    if (valueType === 'float') {
      return parseFloat(metricValue).toLocaleString();
    }

    return metricValue;
  };

  const changePeriod = (e) => {
    setCurrentPeriod(e.target.value);
    onPeriodChange(e.target.value);
  };

  const months = [];
  const startYear = 2019;
  const nowYear = moment().year() + 1;

  for (let i = nowYear; i >= startYear; i--) {
    for (let j = 12; j >= 1; j--) {
      months.push({
        label: `${I18n.t(`global.months.${j}`)} ${i}`,
        value: `${i}-${j < 10 ? `0${j}` : j}-01`
      });
    }
  }

  return (
    <div className="dashboard-container">
      <div className="dashboard-wrapper">
        <ContentLoader
          isLoading={isLoading && metrics.numberOfPatients === -1}
          error={error}
          errorTitleI18n="inbox_view.dashboard.error"
        >
          <div className="columns mb-0">
            <div className="column is-8 no-padding">
              <h1>
                <Translate value="inbox_view.dashboard.greeting" name={`${caregiver.givenName}`} />
              </h1>
            </div>
            <div className="column is-3 no-padding text-right">
              {!hidden ? (
                <Fragment>
                  {isLoading ? (
                    <span className="loading-container">
                      <LoadingSpinner size="smaller" />
                    </span>
                  ) : null}
                  <div className="select">
                    <select value={currentPeriod} onChange={changePeriod}>
                      {months.map((month) => (
                        <option key={month.value} value={month.value}>
                          {month.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </Fragment>
              ) : null}
            </div>
            <div className="column is-1 no-padding text-right">
              <span className={`fs-14 ${hidden ? 'show-all-data' : 'hide-rows'}`} onClick={() => setHidden(!hidden)}>
                <Translate value={`inbox_view.dashboard.${hidden ? 'show' : 'hide'}`} />
              </span>
            </div>
          </div>
          <div className="patient-count">
            {metrics.numberOfPatients > 0 ? (
              <Fragment>
                <Translate value="inbox_view.dashboard.you_have" />
                <Translate
                  value="inbox_view.dashboard.total_patients"
                  numTotal={metrics.numberOfPatients.toLocaleString()}
                />
                <strong>
                  <Translate
                    value="inbox_view.dashboard.active_patients"
                    numActive={metrics.numberOfActivePatients.toLocaleString()}
                  />
                </strong>
              </Fragment>
            ) : (
              <Translate value={`inbox_view.dashboard.no_patients_${isNurse ? 'pas' : 'pal'}`} />
            )}
          </div>
          {!hidden ? (
            <Fragment>
              <div className="metric-items">
                {metrics.metrics?.map((metric, i) => {
                  return (
                    <div key={i}>
                      <div className="metric-value">
                        {metric.valueType ? parseMetric(metric.value, metric.valueType) : metric.value}
                      </div>
                      {metric.description ? <div className="metric-description">{metric.description}</div> : null}
                    </div>
                  );
                })}
              </div>
              <FutureCheckupsGraph bookedCheckups={bookedCheckups} currentPeriod={currentPeriod} />
            </Fragment>
          ) : null}
        </ContentLoader>
      </div>
    </div>
  );
};

export default CaregiverDashboard;
