import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { decamelize, groupBy } from '../../utils';
import ChapterChevron from '../../assets/icons/chapter-chevron.svg';
import './MemberEventSummary.scss';
import LoadingSpinner from '../LoadingSpinner';

const MemberEventSummary = React.memo((props) => {
  const groupedByEventType = groupBy(props.activeEvents, 'eventType');

  return (
    <div className="columns member-events" onClick={props.onClick}>
      <div className="column no-padding is-11">
        <div className="events-header">
          <Translate value="patient_view.events.header" />
        </div>
        <div className="event-icons-wrapper">
          {props.isLoading ? (
            <LoadingSpinner size="smaller" />
          ) : props.activeEvents.length ? (
            Object.getOwnPropertyNames(groupedByEventType).map((eventType, i) => {
              return (
                <span
                  key={i}
                  className={`icons small ${decamelize(eventType, '-')}`}
                  title={I18n.t(`global.event_type.${eventType}`)}
                >
                  {groupedByEventType[eventType].length > 1 &&
                  Object.getOwnPropertyNames(groupedByEventType).length < 5 ? (
                    <span className="event-count">{groupedByEventType[eventType].length}</span>
                  ) : (
                    ''
                  )}
                </span>
              );
            })
          ) : props.error ? (
            <span className="error-message">
              <Translate value="patient_view.events.get_active_events_error" />
            </span>
          ) : (
            <Translate value="patient_view.events.no_active_events_header" />
          )}
        </div>
      </div>
      <div className="column no-padding center-align">
        <img src={ChapterChevron} alt="Chevron" className={`open-indicator ${props.toggled ? 'rotated' : ''}`} />
      </div>
    </div>
  );
});

export default MemberEventSummary;
