import React, { Fragment, useState } from 'react';
import moment from 'moment';
import { NavLink, withRouter } from 'react-router-dom';
import { Translate, I18n } from 'react-redux-i18n';
import { DATE_FORMAT } from '../../../constants';

const PaymentMessage = ({ message, match, source }) => {
  const [expanded, setExpanded] = useState(false);
  const paymentState = message.payment && message.payment.state ? message.payment.state.toLowerCase() : '';
  const historyLinkId = `payment-history-icon-${message.id}`;

  return (
    <div className={`system-message-wrapper`} onClick={(e) => setExpanded(e.target.id === historyLinkId || !expanded)}>
      <div className={`system-message-container ${expanded ? 'expanded' : ''}`}>
        {expanded ? (
          <div className="system-message">
            <div className="system-message__content">
              <blockquote>{message.text}</blockquote>
            </div>
            <div className="flex vertical-align">
              <div className={`payment-status payment-status__${paymentState}`}>
                <Translate
                  value={`patient_view.chat.payment_message.${
                    source === 'EuroAccident' ? 'exemption_ea' : paymentState
                  }`}
                  date={moment(message.payment.stateTimestamp, DATE_FORMAT).format('YYYY-MM-DD')}
                />
              </div>
              <NavLink className="vertical-align" to={`${match.url}/notes/payment-history`}>
                <span
                  id={historyLinkId}
                  className="ml-10 text-button"
                  title={I18n.t('patient_view.chat.payment_history_icon_title')}
                >
                  Historik
                </span>
              </NavLink>
            </div>
          </div>
        ) : (
          <Fragment>
            <Translate value={`patient_view.chat.payment_message_header.${paymentState}`} />
            <span className={`payment-badge payment-badge__${paymentState}`}></span>
            <span className="chevron"></span>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default withRouter(PaymentMessage);
