import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Tab, TabPanel } from 'react-tabs';
import debounce from 'debounce';
import ContentLoader from '../ContentLoader';
import ModalDialog from '../ModalDialog';
import MedicationTable from './MedicationTable';
import MedicationRow from './MedicationTable/RowTypes/MedicationRow';
import UnsignedMedicationRow from './MedicationTable/RowTypes/UnsignedMedicationRow';
import CurrentMedicationsColumnHeaders from './CurrentMedicationsColumnHeaders';
import UnsignedMedicationsColumnHeaders from './UnsignedMedicationsColumnHeaders';
import PreviousMedicationsColumnHeaders from './PreviousMedicationsColumnHeaders';
import SignMedicationModalContent from './SignMedicationModalContent';
import MedicationSearch from './MedicationSearch';
import MedicationSearchResultSelection from './MedicationSearchResultSelection';
import PreviousMedicationRow from './MedicationTable/RowTypes/PreviousMedicationRow';
import DecisionSupportTable from '../DecisionSupportView/DecisionSupportTable';
import DssItemRow from '../DecisionSupportView/DecisionSupportTable/RowTypes/DssItemRow';
import {
  openAlfaEReceptModal,
  closeAlfaEReceptModal,
  getPrescription,
  postPrescription,
  showSignMedicationModal,
  hideSignMedicationModal,
  closeSignMedicationModal,
  selectMedication,
  updateMedicationProperty,
  updateCurrentMedicationProperty,
  signMedication,
  dismissSigningErrorMessage,
  searchMedicationAlternatives,
  selectMedicationConflictOption,
  showVaraSearchModal,
  hideVaraSearchModal,
  hideVaraResultSelectionModal,
  varaSearch,
  selectVaraSearchResult,
  selectVaraImportType,
  importVaraSearchResult,
  addMedicationManually,
  addMedication,
  viewMedication,
  hideMedicationModal,
  dismissCancellationErrorMessage,
  cancelMedication,
  changeMedicationProperty,
  showChangeMedicationPropertyModal,
  hideChangeMedicationPropertyModal,
  clearDssItemEditValues,
  setDssItemComment,
  addDssItemComment,
  toggleVaraBypassInfo,
  continueWithoutVaraImport,
  setVaraBypassReasonType,
  setVaraBypassFreeTextReason
} from '../../actions';
import './MedicationView.scss';
import { getEmptyMedicationListFallbackI18nKey } from '../../utils';
import MedicationInformation from './MedicationInformation';
import MedicationCopy from './MedicationCopy';
import TabbedModalDialog from '../TabbedModalDialog';
import ChangeMedicationPropertyModalContent from './ChangeMedicationPropertyModalContent';
import RevisionList from './RevisionList';
import HasAuthority from '../HasAuthority';
import { AUTHORITY } from '../../constants';
import InvalidatedMedicationsColumnHeaders from './InvalidatedMedicationsColumnHeaders';
import InvalidatedMedicationRow from './MedicationTable/RowTypes/InvalidatedMedicationRow';
import RequestedPrescription from './RequestedPrescription';

class MedicationView extends Component {
  constructor(props) {
    super(props);

    this.getPrescriptionSession = this.getPrescriptionSession.bind(this);
    this.postPrescriptionSession = this.postPrescriptionSession.bind(this);
    this.closeEReceptModal = this.closeEReceptModal.bind(this);
    this.signMedication = this.signMedication.bind(this);
    this.searchMedicationAlternatives = this.searchMedicationAlternatives.bind(this);
    this.varaNameSearch = this.varaNameSearch.bind(this);
    this.varaStrengthSearch = this.varaStrengthSearch.bind(this);
    this.initialVaraSearch = this.initialVaraSearch.bind(this);
    this.enterMedicationSearchFlow = this.enterMedicationSearchFlow.bind(this);
    this.cancelMedication = this.cancelMedication.bind(this);
    this.editMedicationProperty = this.editMedicationProperty.bind(this);
    this.saveComment = this.saveComment.bind(this);
  }

  getPrescriptionSession() {
    this.props.getPrescription(this.props.authToken, this.props.memberGuid);
  }

  postPrescriptionSession() {
    this.props.postPrescription(this.props.authToken, this.props.memberGuid, {
      redirectUrl: this.props.medications.redirectUrl,
      sessionToken: this.props.medications.sessionToken
    });
  }

  closeEReceptModal() {
    if (window.confirm(I18n.t('patient_view.medications.prescription_confirm_close'))) {
      this.props.closeAlfaEReceptModal();
    }
  }

  signMedication() {
    const { authToken, memberGuid, medications, user } = this.props;
    if (!medications.manualAdd) {
      if (medications.selectedMedicationOptionIndex > 0) {
        if (
          window.confirm(I18n.t('patient_view.medications.medication_modal.sign_with_alternative_option_confirmation'))
        ) {
          this.props.signMedication(authToken, memberGuid, medications.selectedMedication, user);
        }
      } else {
        this.props.signMedication(
          authToken,
          memberGuid,
          medications.selectedMedication,
          user,
          medications.varaBypassReasonType
            ? medications.varaBypassReasonType === 'other'
              ? medications.varaBypassFreeTextReason
              : 'Utländskt preparat'
            : undefined
        );
      }
    } else {
      this.props.addMedication(authToken, memberGuid, medications.selectedMedication.current);
    }
  }

  searchMedicationAlternatives(barcode) {
    this.props.searchMedicationAlternatives(this.props.authToken, barcode);
  }

  varaNameSearch(e) {
    this.props.varaSearch(this.props.authToken, e.target.value, this.props.medications.varaStrengthSearchTerm);
  }

  varaStrengthSearch(e) {
    this.props.varaSearch(this.props.authToken, this.props.medications.varaNameSearchTerm, e.target.value);
  }

  initialVaraSearch(name, strength) {
    this.props.varaSearch(this.props.authToken, name, strength);
  }

  enterMedicationSearchFlow() {
    const { medications } = this.props;
    this.props.showVaraSearchModal();
    this.props.hideSignMedicationModal();
    this.initialVaraSearch(
      medications.selectedMedication.current.name,
      medications.selectedMedication.current.strength
    );
  }

  cancelMedication() {
    const { authToken, memberGuid, medications, user } = this.props;
    if (window.confirm(I18n.t('patient_view.medications.medication_modal.delete_confirmation'))) {
      this.props.cancelMedication(authToken, memberGuid, medications.viewedMedication, user);
    }
  }

  editMedicationProperty(changeType) {
    return () => {
      const { authToken, memberGuid, medications, user } = this.props;
      this.props.changeMedicationProperty(
        authToken,
        memberGuid,
        medications.viewedMedication.id,
        medications.medicationUpdates,
        changeType,
        medications.medicationChangeProperty,
        medications.currentMedicationPropertyValue,
        medications.currentMedicationWhenNeeded,
        user
      );
    };
  }

  saveComment(itemNumber, cb) {
    if (this.props.decisionSupport.commentModified) {
      this.props.addDssItemComment(
        this.props.authToken,
        this.props.memberGuid,
        itemNumber,
        this.props.decisionSupport.itemComment,
        cb
      );
    }
  }

  render() {
    const {
      medications,
      showSignMedicationModal,
      closeSignMedicationModal,
      selectMedication,
      updateMedicationProperty,
      updateCurrentMedicationProperty,
      dismissSigningErrorMessage,
      selectMedicationConflictOption,
      showVaraSearchModal,
      hideVaraSearchModal,
      hideVaraResultSelectionModal,
      selectVaraSearchResult,
      selectVaraImportType,
      importVaraSearchResult,
      addMedicationManually,
      healthProfile,
      decisionSupport,
      viewMedication,
      hideMedicationModal,
      dismissCancellationErrorMessage,
      showChangeMedicationPropertyModal,
      hideChangeMedicationPropertyModal,
      clearDssItemEditValues,
      setDssItemComment
    } = this.props;

    const changeDosageRegimenConfig = {
      headerI18nKey: 'patient_view.medications.medication_modal.change_dose',
      actionI18nKey: 'patient_view.medications.medication_modal.update_dosage',
      currentHeaderI18nKey: 'patient_view.medications.change_dose_modal.current_dose',
      newHeaderI18nKey: 'patient_view.medications.change_dose_modal.new_dose',
      property: 'dosageRegimen',
      changeType: 'doseChange'
    };

    const changeReasonConfig = {
      headerI18nKey: 'patient_view.medications.medication_modal.change_reason',
      actionI18nKey: 'patient_view.medications.medication_modal.update_reason',
      currentHeaderI18nKey: 'patient_view.medications.change_reason_modal.current_reason',
      newHeaderI18nKey: 'patient_view.medications.change_reason_modal.new_reason',
      property: 'reason',
      changeType: 'textChange'
    };

    return (
      <div className="medication-view">
        <div className="rounded-container mb-30">
          <h1>
            <Translate value="patient_view.medications.medication_list" />
          </h1>

          <ContentLoader
            isLoading={medications.loadingMemberMedications}
            error={medications.memberMedicationsError}
            errorTitleI18n="patient_view.medications.errors.loading_medications"
          >
            <section className="mb-40">
              <h2>
                <Translate value="patient_view.medications.section.unsigned_medications" />
              </h2>
              <MedicationTable
                data={medications.memberMedications && medications.unsignedMedications}
                error={medications.memberMedicationsError}
                emptyI18n="patient_view.medications.empty.unsigned_medications"
                rowType={UnsignedMedicationRow}
                ColumnHeaderComponent={UnsignedMedicationsColumnHeaders}
                onClick={this.props.authorities.includes(AUTHORITY.CAREGIVER) ? selectMedication : undefined}
                scopeName="unsigned-medications"
              />
            </section>

            <section className="mb-40">
              <h2>
                <Translate value="patient_view.medications.section.current_medications" />
              </h2>
              <MedicationTable
                data={medications.memberMedications && medications.currentMedications}
                error={medications.memberMedicationsError}
                emptyI18n={getEmptyMedicationListFallbackI18nKey(healthProfile, medications)}
                rowType={MedicationRow}
                ColumnHeaderComponent={CurrentMedicationsColumnHeaders}
                onClick={this.props.authorities.includes(AUTHORITY.CAREGIVER) ? viewMedication : undefined}
              />
              <div className="mt-15">
                <div className="columns">
                  <div className="column no-padding">
                    <HasAuthority authority={AUTHORITY.CAREGIVER} disable={true}>
                      <button className="button is-primary mr-10" onClick={addMedicationManually}>
                        <Translate value="patient_view.medications.add_medication" />
                      </button>
                    </HasAuthority>
                    <HasAuthority authority={AUTHORITY.CAREGIVER} disable={true}>
                      <button className="button is-external" onClick={this.getPrescriptionSession}>
                        <Translate value="patient_view.medications.prescribe_medication" />
                      </button>
                    </HasAuthority>
                  </div>
                </div>
                <div className="mt-25">
                  <RequestedPrescription
                    memberHasRequestedPrescription={medications.memberHasRequestedPrescription}
                    memberPrescriptionRequestAnswer={medications.memberPrescriptionRequestAnswer}
                  />
                </div>
              </div>
            </section>

            <ContentLoader
              isLoading={medications.loadingMedicineExtras}
              error={medications.medicineExtrasError}
              errorTitleI18n="patient_view.medications.errors.loading_medications"
            >
              <section className="mb-40">
                <DecisionSupportTable
                  titleI18n="patient_view.medications.section.supplements"
                  emptyI18n="patient_view.medications.medicine_extras.supplements"
                  data={decisionSupport.medicineExtras && decisionSupport.medicineExtras.supplements}
                  error={decisionSupport.medicineExtrasError}
                  errorTitleI18n="patient_view.medications.errors.loading_medication_supplements"
                  setComment={debounce(setDssItemComment, 200)}
                  saveEditedValues={this.saveComment}
                  rowType={DssItemRow}
                  onCloseEdit={clearDssItemEditValues}
                />
              </section>

              <section className="mb-40">
                <DecisionSupportTable
                  titleI18n="patient_view.medications.section.anamnesis"
                  emptyI18n="patient_view.medications.medicine_extras.medicine_changes"
                  data={decisionSupport.medicineExtras && decisionSupport.medicineExtras.medicineChanges}
                  error={decisionSupport.medicineExtrasError}
                  errorTitleI18n="decision_support_view.loading_medication_extras"
                  setComment={debounce(setDssItemComment, 200)}
                  saveEditedValues={this.saveComment}
                  rowType={DssItemRow}
                  onCloseEdit={clearDssItemEditValues}
                />
              </section>
            </ContentLoader>

            <section className="mb-40">
              <h2>
                <Translate value="patient_view.medications.section.previous_medications" />
              </h2>
              <MedicationTable
                data={medications.memberMedications && medications.previousMedications}
                error={medications.memberMedicationsError}
                emptyI18n="patient_view.medications.empty.previous_medications"
                rowType={PreviousMedicationRow}
                ColumnHeaderComponent={PreviousMedicationsColumnHeaders}
                onClick={this.props.authorities.includes(AUTHORITY.CAREGIVER) ? viewMedication : undefined}
              />
            </section>

            <section className="mb-40">
              <h2>
                <Translate value="patient_view.medications.section.invalidated_medications" />
              </h2>
              <MedicationTable
                data={medications.memberMedications && medications.invalidatedMedications}
                error={medications.memberMedicationsError}
                emptyI18n="patient_view.medications.empty.invalidated_medications"
                rowType={InvalidatedMedicationRow}
                ColumnHeaderComponent={InvalidatedMedicationsColumnHeaders}
              />
            </section>
          </ContentLoader>
        </div>

        <ModalDialog
          headerI18nKey="patient_view.medications.prescribe_medication"
          actionI18nKey="global.buttons.save"
          visible={medications.alfaEReceptModalVisible}
          onClose={this.closeEReceptModal}
          onActionCompleted={this.postPrescriptionSession}
          actionCompletable={true}
          actionCompleting={medications.gettingPrescription}
          size="large"
        >
          <iframe title="Alfa e-recept" src={medications.iframeUrl} frameBorder="0" />
        </ModalDialog>
        <ModalDialog
          headerI18nKey={medications.signMedicationModalHeaderI18nKey}
          actionI18nKey={medications.signMedicationModalActionI18nKey}
          visible={medications.signMedicationModalActive}
          onClose={closeSignMedicationModal}
          onActionCompleted={this.signMedication}
          actionCompletable={
            !!(
              medications.selectedMedication.current &&
              (medications.varaImportBypassed ? true : medications.selectedMedication.current.atcCode) &&
              (medications.selectedMedication.current.dosageRegimen ||
                medications.selectedMedication.current.whenNeeded)
            )
          }
          actionCompleting={medications.isSigningMedication}
          size={medications.signMedicationModalSize}
          contentClass="pt-10"
        >
          <SignMedicationModalContent
            isSavingMedicationEdit={medications.isSigningMedication}
            selectedMedication={medications.selectedMedication}
            updateMedicationProperty={updateMedicationProperty}
            hasConflict={medications.hasConflict}
            isScanned={medications.isScanned}
            editingDisabled={medications.editingDisabled}
            subHeaderI18nKey={medications.signMedicationModalSubHeaderI18nKey}
            onsearchMedicationAlternatives={this.searchMedicationAlternatives}
            alternativesSearchResults={medications.alternativesSearchResults}
            selectedOption={medications.selectedMedicationOptionIndex}
            loadingSearchResults={medications.loadingSearchResults}
            searchError={medications.searchMedicationAlternativesError}
            onOptionChanged={selectMedicationConflictOption}
            onEnterMedicationSearchFlow={this.enterMedicationSearchFlow}
            varaImportCompleted={medications.varaImportCompleted}
            showMedicationNameNotice={medications.showMedicationNameNotice}
            varaImportBypassed={medications.varaImportBypassed}
          />
        </ModalDialog>
        <ModalDialog
          headerI18nKey={medications.signMedicationModalHeaderI18nKey}
          actionI18nKey="patient_view.medications.sign_without_vara_import"
          visible={medications.varaSearchModalVisible}
          onClose={hideVaraSearchModal}
          onGoBack={() => {
            showSignMedicationModal();
            hideVaraSearchModal();
          }}
          onActionCompleted={medications.varaBypassReasonType ? this.props.continueWithoutVaraImport : undefined}
          actionCompletable={true}
          size="wide"
          contentClass="pt-10"
        >
          <MedicationSearch
            isLoading={medications.loadingVaraMedications}
            medicationName={medications.varaNameSearchTerm}
            medicationStrength={medications.varaStrengthSearchTerm}
            onChangeNameSearchValue={this.varaNameSearch}
            onChangeStrengthSearchValue={this.varaStrengthSearch}
            onResultSelected={selectVaraSearchResult}
            searchResults={medications.varaSearchResults}
            originalMedication={medications.selectedMedication.original}
            varaBypassInfoExpanded={medications.varaBypassInfoExpanded}
            toggleExpandVaraBypass={this.props.toggleVaraBypassInfo}
            varaBypassReasonType={medications.varaBypassReasonType}
            varaBypassFreeTextReason={medications.varaBypassFreeTextReason}
            setVaraBypassReasonType={this.props.setVaraBypassReasonType}
            setVaraBypassFreeTextReason={this.props.setVaraBypassFreeTextReason}
            error={medications.varaSearchError}
          />
        </ModalDialog>
        <ModalDialog
          headerI18nKey={medications.signMedicationModalHeaderI18nKey}
          actionI18nKey="global.complete_data"
          onActionCompleted={importVaraSearchResult}
          actionCompletable={!!medications.varaImportType}
          visible={medications.varaResultSelectionModalVisible}
          onClose={hideVaraResultSelectionModal}
          onGoBack={() => {
            hideVaraResultSelectionModal();
            showVaraSearchModal();
          }}
          size="wide"
          contentClass="pt-10"
        >
          <MedicationSearchResultSelection
            selectedSearchResult={medications.varaSearchSelection}
            medicationOriginal={medications.selectedMedication.original}
            importOptionSelected={medications.varaImportType}
            onImportOptionSelection={selectVaraImportType}
          />
        </ModalDialog>
        <ModalDialog
          headerI18nKey="patient_view.medications.error_modal.sign.header"
          actionI18nKey="global.buttons.ok"
          visible={medications.signErrorModalActive}
          actionCompletable={true}
          onClose={dismissSigningErrorMessage}
          onActionCompleted={dismissSigningErrorMessage}
          size="small"
        >
          <Translate value="patient_view.medications.error_modal.sign.body" />
        </ModalDialog>
        <ModalDialog
          headerI18nKey="patient_view.medications.error_modal.cancel.header"
          actionI18nKey="global.buttons.ok"
          visible={medications.cancelErrorModalActive}
          actionCompletable={true}
          onClose={dismissCancellationErrorMessage}
          onActionCompleted={dismissCancellationErrorMessage}
          size="small"
        >
          <Translate value="patient_view.medications.error_modal.cancel.body" />
        </ModalDialog>
        <ModalDialog
          headerI18nKey={medications.changeMedicationPropertyHeaderI18nKey}
          actionI18nKey={medications.changeMedicationPropertyActionI18nKey}
          visible={medications.changeMedicationPropertyModalActive}
          onClose={hideChangeMedicationPropertyModal}
          onActionCompleted={this.editMedicationProperty(medications.changeMedicationPropertyChangeType)}
          actionCompletable={true}
          actionCompleting={medications.changingMedicationProperty}
        >
          <ChangeMedicationPropertyModalContent
            currentValue={medications.currentMedicationPropertyValue}
            updateMedicationProperty={updateCurrentMedicationProperty}
            medicationUpdates={medications.medicationUpdates}
            propertyName={medications.medicationChangeProperty}
            currentHeaderI18nKey={medications.changeMedicationPropertyCurrentHeaderI18nKey}
            newHeaderI18nKey={medications.changeMedicationPropertyNewHeaderI18nKey}
          />
        </ModalDialog>
        <TabbedModalDialog
          visible={medications.medicationModalActive}
          onClose={hideMedicationModal}
          tabList={
            <Fragment>
              <Tab>
                <Translate value="patient_view.medications.tabs.medication_information" />
              </Tab>
              <Tab>
                <Translate
                  value="patient_view.medications.tabs.revisions"
                  revisionCount={medications.revisionCount.toLocaleString()}
                />
              </Tab>
            </Fragment>
          }
          tabContent={
            <Fragment>
              <TabPanel>
                <MedicationInformation
                  medication={medications.viewedMedication.current}
                  changes={medications.viewedMedication.changes}
                  onCancelMedication={this.cancelMedication}
                  onChangeDosageRegimen={() => {
                    showChangeMedicationPropertyModal(changeDosageRegimenConfig);
                  }}
                  onChangeReason={() => {
                    showChangeMedicationPropertyModal(changeReasonConfig);
                  }}
                />
              </TabPanel>
              <TabPanel>
                <Fragment>
                  <RevisionList revisions={medications.revisions} />
                  <h3 className="mb-20">
                    <Translate value={medications.originalVersionI18nKey} /> ({medications.viewedMedicationAddedDate})
                  </h3>
                  <MedicationCopy
                    medication={medications.viewedMedication.original}
                    changes={medications.viewedMedication.changes}
                  />
                </Fragment>
              </TabPanel>
            </Fragment>
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    user: state.auth.token.user,
    medications: state.medications,
    healthProfile: state.patientHealthProfile,
    decisionSupport: state.decisionSupport,
    memberGuid: state.currentMember.guid,
    authorities: state.auth.authorities,
    i18n: state.i18n
  };
};

const mapActionsToProps = {
  openAlfaEReceptModal,
  closeAlfaEReceptModal,
  getPrescription,
  postPrescription,
  showSignMedicationModal,
  hideSignMedicationModal,
  closeSignMedicationModal,
  selectMedication,
  updateMedicationProperty,
  updateCurrentMedicationProperty,
  signMedication,
  dismissSigningErrorMessage,
  searchMedicationAlternatives,
  selectMedicationConflictOption,
  showVaraSearchModal,
  hideVaraSearchModal,
  hideVaraResultSelectionModal,
  varaSearch,
  selectVaraSearchResult,
  selectVaraImportType,
  importVaraSearchResult,
  addMedicationManually,
  addMedication,
  viewMedication,
  hideMedicationModal,
  dismissCancellationErrorMessage,
  cancelMedication,
  changeMedicationProperty,
  showChangeMedicationPropertyModal,
  hideChangeMedicationPropertyModal,
  clearDssItemEditValues,
  setDssItemComment,
  addDssItemComment,
  toggleVaraBypassInfo,
  continueWithoutVaraImport,
  setVaraBypassReasonType,
  setVaraBypassFreeTextReason
};

export default connect(mapStateToProps, mapActionsToProps)(MedicationView);
