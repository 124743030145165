import React, { Component } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import moment from 'moment';
import './UnsignedMedicationRow.scss';
import DosageWithFallback from '../../../DosageWithFallback';
import { invalidateMedication } from '../../../../../actions';
import { AUTHORITY } from '../../../../../constants';
import LoadingSpinner from '../../../../LoadingSpinner';

class UnsignedMedicationRow extends Component {
  constructor(props) {
    super(props);

    this.invalidateMedication = this.invalidateMedication.bind(this);
  }

  invalidateMedication(e) {
    e.stopPropagation();
    const { authToken, memberGuid, user, item } = this.props;
    if (window.confirm(I18n.t('patient_view.medications.medication_modal.invalidate_confirmation'))) {
      this.props.invalidateMedication(authToken, memberGuid, item.id, user);
    }
  }

  render() {
    const { onClick, item, changes, current, original, medications } = this.props;

    const createdDate = changes.filter((change) => change.changeType === 'new')[0]?.timestamp;
    const method = original.barcode ? 'scanned' : 'manual';

    return (
      <div
        className={`dss-medication-row row-container ${onClick ? 'pointer' : ''}`}
        onClick={onClick && onClick(item)}
      >
        <div className="columns table-row unsigned-medication">
          <div className="column is-2 overflow-ellipsis" title={current.name || ''}>
            {current.name || '-'}
          </div>
          <div className="column is-2 overflow-ellipsis" title={current.form || ''}>
            {current.form || '-'}
          </div>
          <div className="column is-2">{current.strength || '-'}</div>
          <div className="column is-2">
            <DosageWithFallback medication={current} />
          </div>
          <div className="column is-2">{moment(createdDate).format('YYYY-MM-DD')}</div>
          <div className="column is-1">
            <Translate value={`patient_view.medications.method.${method}`} />
          </div>
          <div className="column is-1 text-right">
            {method === 'manual' && this.props.authorities.includes(AUTHORITY.CAREGIVER) ? (
              medications.invalidatingIds.includes(item.id) ? (
                <LoadingSpinner size="smaller" />
              ) : (
                <span className="icons cross hover-effect" onClick={this.invalidateMedication}></span>
              )
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    user: state.auth.token.user,
    medications: state.medications,
    memberGuid: state.currentMember.guid,
    authorities: state.auth.authorities
  };
};

const mapActionsToProps = {
  invalidateMedication
};

export default connect(mapStateToProps, mapActionsToProps)(UnsignedMedicationRow);
