import { useState, useEffect } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { Translate } from 'react-redux-i18n';
import LoaderButton from '../../LoaderButton';

const PatientComments = ({ onToggle, comment, commentText, onSave, onUpdateComment, isSaving }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      onToggle();
    }
  }, [isOpen]);

  const save = () => {
    onSave(() => {
      setIsOpen(false);
    });
  };

  return (
    <div className={`patient-comments ${isOpen ? 'open' : ''}`}>
      <div className="comments-header" onClick={() => setIsOpen(!isOpen)}>
        <span>
          {commentText && commentText !== comment.text ? <span>*</span> : null}
          <Translate value="patient_view.comments.header" />
        </span>
        <span className={`icon chevron ${isOpen ? '' : 'rotated'}`}></span>
      </div>
      <DebounceInput
        element="textarea"
        value={commentText}
        onChange={(e) => onUpdateComment(e.target.value)}
        data-sensitive
      />
      <div className="text-right">
        <LoaderButton
          buttonI18nKey="global.buttons.save"
          isLoading={isSaving}
          disabled={commentText === comment.text || isSaving}
          onClick={save}
        />
      </div>
    </div>
  );
};

export default PatientComments;
