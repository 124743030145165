import React, { Fragment } from 'react';
import { Translate } from 'react-redux-i18n';
import './MedicationSearchResultSelection.scss';

const MedicationSearchResultSelection = ({
  selectedSearchResult,
  medicationOriginal,
  importOptionSelected,
  onImportOptionSelection
}) => {
  if (!selectedSearchResult) {
    return null;
  }

  return (
    <div className="medication-search-result-selection">
      <h2>
        <Translate value="patient_view.medications.vara_search.vara_match" />
      </h2>
      <div className="vara-selection mb-20">
        <MedicationSelectionInformation medication={selectedSearchResult} />
      </div>
      {medicationOriginal ? (
        <Fragment>
          <h2>
            <Translate value="patient_view.medications.vara_search.patient_information" />
          </h2>
          <div className="medication-original mb-20">
            <MedicationSelectionInformation medication={medicationOriginal} />
          </div>
        </Fragment>
      ) : null}
      <h2>
        <Translate value="patient_view.medications.vara_search.which_information" />
      </h2>
      <div className="columns">
        <div className="column is-1 no-padding">
          <input
            type="radio"
            value="manufacturerKnown"
            id="selection-manufacturer-known"
            name="selection-manufacturer-known"
            checked={importOptionSelected === 'manufacturerKnown'}
            onChange={onImportOptionSelection}
          />
          <label htmlFor="selection-manufacturer-known"></label>
        </div>
        <div className="column no-padding">
          <label htmlFor="selection-manufacturer-known">
            <div>
              <strong>
                <Translate value="patient_view.medications.vara_search.manufacturer_known" />
              </strong>
              : <Translate value="patient_view.medications.vara_search.fill_in_all_information" />
            </div>
            <div>
              <small>
                <Translate
                  value={`patient_view.medications.vara_search.fill_in_all_information_note${
                    medicationOriginal ? '' : '_caregiver'
                  }`}
                />
              </small>
            </div>
          </label>
        </div>
      </div>
      <div className="columns">
        <div className="column is-1 no-padding">
          <input
            type="radio"
            value="manufacturerUnknown"
            id="selection-manufacturer-unknown"
            name="selection-manufacturer-unknown"
            checked={importOptionSelected === 'manufacturerUnknown'}
            onChange={onImportOptionSelection}
          />
          <label htmlFor="selection-manufacturer-unknown"></label>
        </div>
        <div className="column no-padding">
          <label htmlFor="selection-manufacturer-unknown">
            <div>
              <strong>
                <Translate value="patient_view.medications.vara_search.manufacturer_unknown" />
              </strong>
              : <Translate value="patient_view.medications.vara_search.fill_in_atc_code_only" />
            </div>
            <div>
              <small>
                <Translate value="patient_view.medications.vara_search.fill_in_atc_code_only_note" />
              </small>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default MedicationSearchResultSelection;

const MedicationSelectionInformation = ({ medication }) => {
  return (
    <Fragment>
      <div className="columns mb-5">
        <div className="column is-4 no-padding">
          <Translate value="patient_view.medications.medication_modal.medication_npl_pack_id" />
        </div>
        <div className="column no-padding">{medication.nplPackId || ''}</div>
      </div>
      <div className="columns mb-5">
        <div className="column is-4 no-padding">
          <Translate value="patient_view.medications.medication_modal.medication_atc_code" />
        </div>
        <div className="column no-padding">{medication.atcCode || ''}</div>
      </div>
      <div className="columns mb-5">
        <div className="column is-4 no-padding">
          <Translate value="patient_view.medications.table_header.name" />
        </div>
        <div className="column no-padding">{medication.name || ''}</div>
      </div>
      <div className="columns mb-5">
        <div className="column is-4 no-padding">
          <Translate value="patient_view.medications.medication_modal.medication_type" />
        </div>
        <div className="column no-padding">{medication.form || ''}</div>
      </div>
      <div className="columns mb-5">
        <div className="column is-4 no-padding">
          <Translate value="patient_view.medications.medication_modal.medication_strength" />
        </div>
        <div className="column no-padding">{medication.strength || ''}</div>
      </div>
    </Fragment>
  );
};
