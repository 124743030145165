import * as types from '../constants/actionTypes';

const initialState = {
  modalActive: false,
  currentFile: {},
  numPages: -1,
  fileBlob: null,
  options: {},
  fullSizeModalVisible: false,
  fullSizeImageSrc: '',
  isSigned: false,
  previewOptions: {
    headerI18nKey: 'patient_view.chat.fullsize_image_header',
    size: 'large'
  }
};

const previewReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PREVIEW_FILE_REQUEST:
      return { ...state, loading: true, modalActive: true, currentFile: action.payload };
    case types.PREVIEW_FILE_SUCCESS: {
      const fileBlob = new Blob([action.payload.response.response.body], {
        type: 'application/pdf'
      });
      return { ...state, loading: false, fileBlob, previewFileError: undefined };
    }
    case types.PREVIEW_FILE_ERROR:
      return { ...state, loading: false, previewFileError: action.payload };
    case types.CLOSE_FILE_PREVIEW:
      return { ...state, modalActive: false, fileBlob: null, currentFile: {}, numPages: -1, isSigned: false };
    case types.PREVIEW_FILE_SET_NUM_PAGES:
      return { ...state, numPages: action.payload };
    case types.SET_FILE_PREVIEW_PROPS:
      return { ...state, options: action.payload };
    case types.SHOW_FULLSIZE_IMAGE:
      return {
        ...state,
        fullSizeModalVisible: true,
        fullSizeImageSrc: action.payload.imageSrc,
        previewOptions: { ...state.previewOptions, ...action.payload.previewOptions }
      };
    case types.HIDE_FULLSIZE_IMAGE:
      return { ...state, fullSizeModalVisible: false, fullSizeImageSrc: '' };
    case types.TOGGLE_DOCUMENT_SIGNED:
      return { ...state, isSigned: !state.isSigned };
    case types.CLEAR_PATIENT_DATA:
      return initialState;
    default:
      return state;
  }
};

export default previewReducer;
