import React, { Component } from 'react';
import MedicationModalContent from '../MedicationModalContent';
import MedicationOptions from '../MedicationOptions';
import './SignMedicationModalContent.scss';

class SignMedicationModalContent extends Component {
  componentDidUpdate(prevProps) {
    const { hasConflict, selectedMedication, onsearchMedicationAlternatives } = this.props;

    if (hasConflict && prevProps.selectedMedication.id !== selectedMedication.id && !!selectedMedication.id) {
      onsearchMedicationAlternatives(selectedMedication.original.barcode);
    }
  }

  render() {
    const {
      isSavingMedicationEdit,
      selectedMedication,
      updateMedicationProperty,
      alternativesSearchResults,
      hasConflict,
      isScanned,
      editingDisabled,
      loadingSearchResults,
      searchError,
      selectedOption,
      onOptionChanged,
      subHeaderI18nKey,
      onEnterMedicationSearchFlow,
      varaImportCompleted,
      showMedicationNameNotice,
      varaImportBypassed
    } = this.props;

    return (
      <div className="sign-medication-modal-content">
        <div className="columns">
          <div className="column no-padding">
            <MedicationModalContent
              isSavingMedicationEdit={isSavingMedicationEdit}
              medication={selectedMedication}
              isScanned={isScanned}
              editingDisabled={editingDisabled}
              varaImportCompleted={varaImportCompleted}
              updateMedicationProperty={updateMedicationProperty}
              headerI18nKey={subHeaderI18nKey}
              onEnterMedicationSearchFlow={onEnterMedicationSearchFlow}
              showMedicationNameNotice={showMedicationNameNotice}
              varaImportBypassed={varaImportBypassed}
            />
          </div>
          {hasConflict ? (
            <div className="column no-padding ml-20">
              <MedicationOptions
                options={alternativesSearchResults && alternativesSearchResults.medicines}
                selectedOption={selectedOption}
                onOptionChanged={onOptionChanged}
                isLoading={loadingSearchResults}
                error={searchError}
                errorTitleI18n="patient_view.medications.errors.loading_search_results"
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default SignMedicationModalContent;
