import React, { Component } from 'react';
import { Translate } from 'react-redux-i18n';
import './EditRowContent.scss';

class EditRowContent extends Component {
  constructor(props) {
    super(props);

    this.handleCheckedChange = this.handleCheckedChange.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);

    this.state = {
      selectedValues: this.props.values && this.props.values.filter((value) => value.relevant).map((value) => value.id),
      comment: this.props.comments && this.props.comments.length ? this.props.comments[0].comment : ''
    };
  }

  handleCheckedChange(event) {
    const value = parseInt(event.target.value, 10);

    if (!this.state.selectedValues.includes(value)) {
      this.setState({
        selectedValues: [...this.state.selectedValues, value]
      });
      this.props.onRelevantValuesChange([...this.state.selectedValues, value]);
    } else {
      let newselectedValues = [...this.state.selectedValues];
      const index = newselectedValues.indexOf(value);
      newselectedValues.splice(index, 1);
      this.setState({
        selectedValues: newselectedValues
      });
      this.props.onRelevantValuesChange(newselectedValues);
    }
  }

  handleCommentChange(event) {
    this.setState({
      comment: event.target.value
    });

    this.props.onCommentChange(event.target.value);
  }

  render() {
    const { itemId, values } = this.props;

    return (
      <div className="row-edit-container">
        <div className="values-container">
          {values &&
            values.map((value, i) => {
              return (
                <div className="columns" key={i}>
                  <div className="column checkbox-column">
                    <input
                      disabled={!this.props.onRelevantValuesChange}
                      type="checkbox"
                      className="styled-checkbox"
                      value={value.id}
                      id={`checkbox-${itemId}-${value.id}`}
                      name={`checkbox-${itemId}-${value.id}`}
                      checked={this.state.selectedValues.includes(value.id)}
                      onChange={this.handleCheckedChange}
                    />
                    <label htmlFor={`checkbox-${itemId}-${value.id}`}></label>
                  </div>
                  <div className="column">
                    <label htmlFor={`checkbox-${itemId}-${value.id}`}>
                      <div>
                        <strong>{value.question}</strong>
                      </div>
                      <div>{value.answer || <Translate value="patient_view.anamnesis.no_answer" />}</div>
                    </label>
                  </div>
                </div>
              );
            })}
        </div>

        <div className="row-comment-container">
          <h3>
            <Translate value="decision_support_view.doctors_comment" />
          </h3>
          <textarea name="row-comment" onChange={this.handleCommentChange} value={this.state.comment}></textarea>
        </div>
      </div>
    );
  }
}

export default EditRowContent;
