import React, { Component, Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import ModalDialog from '../../../../ModalDialog';
import RowHistory from './RowHistory';
import EditRowContent from './EditRowContent';
import AttributeResponse from '../../AttributeResponse';
import ComponentLoader from '../../../../ComponentLoader';
import './DssItemRow.scss';
import HasAuthority from '../../../../HasAuthority';
import { AUTHORITY } from '../../../../../constants';

class DssItemRow extends Component {
  constructor(props) {
    super(props);

    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.showHistory = this.showHistory.bind(this);
    this.hideHistory = this.hideHistory.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);

    this.state = {
      historyVisible: false,
      editModalVisible: false
    };
  }

  showHistory() {
    this.setState({
      historyVisible: true
    });

    this.props.fetchHistory(this.props.itemId);
  }

  hideHistory() {
    this.setState({
      historyVisible: false
    });
  }

  toggleEditModal() {
    this.props.onCloseEdit();
    this.setState({
      editModalVisible: !this.state.editModalVisible
    });
  }

  closeEditModal() {
    this.setState({
      editModalVisible: false
    });
  }

  render() {
    const {
      itemId,
      attribute,
      commentable,
      hasHistory,
      value,
      recentlyUpdated,
      includedValues,
      saveEditedValues,
      setComment,
      changeRelevantValues,
      currentItemHistory,
      isLoadingItemHistory,
      isSettingRelevantValues,
      severity
    } = this.props;

    return (
      <Fragment>
        <div className={`dss-item-row row-container ${recentlyUpdated ? 'has-updated-value' : ''}`}>
          <div className="columns table-row">
            <div className="column row-category is-4">{attribute}</div>
            <div className="column row-response is-7">
              <AttributeResponse value={value} severity={severity} />
            </div>
            <div className="column is-1">
              <div className="row-actions">
                {hasHistory && this.props.fetchHistory ? (
                  <span
                    title={I18n.t('decision_support_view.show_history')}
                    className="pointer open-history"
                    onClick={this.showHistory}
                  >
                    <span className="icons history"></span>
                  </span>
                ) : null}
                {commentable && this.props.onCloseEdit ? (
                  <HasAuthority authority={AUTHORITY.CAREGIVER}>
                    <span
                      title={I18n.t('decision_support_view.add_comment')}
                      className="pointer edit-row"
                      onClick={this.toggleEditModal}
                    >
                      <span className="icons edit"></span>
                    </span>
                  </HasAuthority>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <ModalDialog
          header={`${I18n.t('decision_support_view.history_header')}: ${attribute}`}
          actionI18nKey="global.buttons.done"
          visible={this.state.historyVisible}
          onClose={this.hideHistory}
          onActionCompleted={this.hideHistory}
          actionCompletable={true}
          actionCompleting={false}
        >
          <ComponentLoader
            isLoading={isLoadingItemHistory}
            ComponentClass={RowHistory}
            childProps={{ history: currentItemHistory, isOverviewItems: true }}
          />
        </ModalDialog>
        <ModalDialog
          header={attribute}
          actionI18nKey="global.buttons.save"
          visible={this.state.editModalVisible}
          onClose={this.toggleEditModal}
          onActionCompleted={() => {
            saveEditedValues(itemId, this.closeEditModal);
          }}
          actionCompletable={true}
          actionCompleting={isSettingRelevantValues}
        >
          <EditRowContent
            values={includedValues}
            itemId={itemId}
            comments={value && value.comments}
            onCommentChange={setComment}
            onRelevantValuesChange={changeRelevantValues}
          />
        </ModalDialog>
      </Fragment>
    );
  }
}

export default DssItemRow;
