import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Translate } from 'react-redux-i18n';
import useClickOutside from '../../hooks/useClickOutside';
import './DropdownMenu.scss';

const DropdownMenu = ({ actions, userAuthorities = [], icon = 'more', padIcon = false }) => {
  const [dropdownActive, setDropdownActive] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const scrollHandler = () => setDropdownActive(false);
    document.addEventListener('scroll', scrollHandler, true);

    return () => document.removeEventListener('scroll', scrollHandler, true);
  }, []);

  const toggle = (e) => {
    e.stopPropagation();
    setDropdownActive(!dropdownActive);
  };

  return (
    <>
      <div ref={ref} className={`dropdown dropdown-menu-actions ${dropdownActive ? 'is-active' : ''}`}>
        <span onClick={toggle} className={`dropdown-trigger icon ${icon} ${padIcon ? 'pad-icon' : ''}`}></span>
      </div>
      {dropdownActive ? (
        <DropdownContent parent={ref.current} onClickOutside={() => setDropdownActive(false)}>
          <div className="dropdown-menu" style={{ display: 'block' }}>
            <div className="dropdown-content">
              {actions.map((action, i) => {
                return (
                  <div
                    key={i}
                    className={`dropdown-item ${!userAuthorities.includes(action.authority) ? 'disabled' : ''}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      action.onClick();
                      setDropdownActive(false);
                    }}
                  >
                    <Translate value={action.i18nKey} />
                  </div>
                );
              })}
            </div>
          </div>
        </DropdownContent>
      ) : null}
    </>
  );
};

export default DropdownMenu;

const DropdownContent = ({ children, parent, onClickOutside }) => {
  const ref = useClickOutside(onClickOutside);
  const rect = parent.getBoundingClientRect();
  const style = {
    position: 'absolute',
    left: rect.x + 15,
    top: rect.y + 25 + window.scrollY
  };

  return ReactDOM.createPortal(
    <div ref={ref} style={style}>
      {children}
    </div>,
    document.body
  );
};
