import * as types from '../constants/actionTypes';
import { removeItemFromArray, extractProperties, extractCaregivers } from '../utils';

const initialState = {
  selfAssigned: [],
  assigningIds: [],
  unassigned: []
};

const newMembersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_NEW_MEMBERS_REQUEST:
      return { ...state, isLoading: true };
    case types.GET_NEW_MEMBERS_SUCCESS: {
      const unassigned =
        action.payload &&
        action.payload
          .map((member) => ({
            ...member.user,
            assignmentReadyReason: member.assignmentReadyReason,
            waitTimeStart: member.waitTimeStart
          }))
          .map((member) => extractProperties(member))
          .map((member) => extractCaregivers(member));

      return {
        ...state,
        isLoading: false,
        unassigned,
        selfAssigned: [],
        error: undefined
      };
    }
    case types.GET_NEW_MEMBERS_ERROR:
      return { ...state, isLoading: false, error: action.payload };
    case types.ASSIGN_PAL_REQUEST:
      return { ...state, isAssigning: true, assigningIds: [...state.assigningIds, action.payload] };
    case types.ASSIGN_PAL_SUCCESS:
      return {
        ...state,
        isAssigning: false,
        selfAssigned: [...state.selfAssigned, action.payload.guid],
        assigningIds: removeItemFromArray(state.assigningIds, {
          index: state.assigningIds.indexOf(action.payload.guid)
        }),
        assignError: undefined
      };
    case types.ASSIGN_PAL_ERROR:
      return {
        ...state,
        isAssigning: false,
        assigningIds: [],
        assignError: action.payload
      };
    case types.CLEAR_ASSIGNED_MEMBERS:
      return { ...state, selfAssigned: [] };
    default:
      return state;
  }
};

export default newMembersReducer;
