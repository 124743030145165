import React, { Fragment } from 'react';
import { Translate } from 'react-redux-i18n';
import ErrorMessage from '../../../ErrorMessage';
import './MedicationSearchResults.scss';

const MedicationSearchResults = ({ medications, medicationName, isLoading, error, onResultSelected, collapsed }) => {
  if (!medications) {
    return null;
  }

  return (
    <div className={`medication-search-results ${collapsed ? 'collapsed' : ''}`}>
      {error ? (
        <ErrorMessage error={error} errorI18nKey="patient_view.medications.errors.loading_search_results" />
      ) : (
        <Fragment>
          {!medicationName || !medicationName.length ? (
            <div className="medication-search-results__empty vertical-align">
              <Translate value="patient_view.medications.medication_modal.enter_name_to_start_search" />
            </div>
          ) : !medications.length && !isLoading ? (
            <div className="medication-search-results__empty vertical-align">
              <Translate value="patient_view.medications.medication_modal.no_search_results" />
            </div>
          ) : (
            <Fragment>
              <div className="columns mb-0">
                <div className="column is-6 no-padding">
                  <h4>
                    <Translate value="global.name" />
                  </h4>
                </div>
                <div className="column no-padding">
                  <h4>
                    <Translate value="global.form" />
                  </h4>
                </div>
                <div className="column no-padding">
                  <h4>
                    <Translate value="global.strength" />
                  </h4>
                </div>
              </div>
              {
                <div className={isLoading ? 'loading' : ''}>
                  {medications.map((m, i) => (
                    <div
                      key={i}
                      className={`columns result-row ${isLoading ? 'loading' : ''}`}
                      onClick={() => onResultSelected(i)}
                    >
                      <div className="column is-6 no-padding">
                        <div>{m.name}</div>
                        <div>
                          <small>{m.packageContent}</small>
                        </div>
                      </div>
                      <div className="column no-padding">{m.form}</div>
                      <div className="column no-padding">{m.strength}</div>
                    </div>
                  ))}
                </div>
              }
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default MedicationSearchResults;
