export const en = {
  global: {
    title: 'Accumbo Webapp',
    loading: 'Loading...',
    buttons: {
      save: 'Save',
      update: 'Update',
      switch_language: 'Switch language',
      done: 'Done',
      cancel: 'Cancel',
      ok: 'OK',
      next: 'Next',
      delete: 'Delete',
      sign: 'Sign',
      retry: 'Try again'
    },
    undo: 'Undo',
    sort: 'Sort by',
    name: 'Name',
    years: 'years',
    labresult: 'Lab result',
    referral: 'Referral',
    certificate: 'Certificate',
    instruction: 'Instruction',
    document: 'Document',
    diagnosis: 'Diagnosis',
    show: 'Show',
    attach_file: 'Attach file',
    select_file: 'Select file',
    no_file_selected: 'No file selected',
    error_loading_data: 'Error loading data.',
    error_loading_file: 'Error loading file.',
    error_uploading_file: 'Error uploading file.',
    error_saving_note: 'Could not save the note. Please try again.',
    personalNumber: 'Personal number',
    email: 'Email address',
    epicrisis: 'Epicrisis',
    day_note: 'Day note',
    note: 'Note',
    diagnosis_code: 'Diagnosis code (ICD)',
    male: 'Male',
    female: 'Female',
    days: 'days',
    days_ago: 'days ago',
    today: 'Today',
    yesterday: 'Yesterday',
    other: 'Other',
    file: 'File',
    week: 'Week',
    weeks: 'weeks',
    two_weeks: '2 weeks',
    month: 'Month',
    ninety_days: '90 days',
    day: 'Day',
    by: 'by',
    to: 'to',
    date: 'Date',
    systolic: 'systolic',
    diastolic: 'diastolic',
    heartrate: 'heartrate',
    reason: 'Reason',
    show_error_info: 'Show technical information',
    no_data: 'No data',
    yes: 'Yes',
    no: 'No',
    source: 'Source',
    claim_number: 'Claim no.',
    freetext: 'Free text',
    change_date: 'Change date',
    feature_disabled: "This feature is not supported by the member's app.",
    new: 'New',
    status: 'Status',
    clear: 'Clear',
    non_pal_warning:
      'Warning! You are attempting to open the journal for a patient you are not responsible for. This access will be logged. Click OK to accept.',
    non_pas_warning:
      'Warning! You are attempting to open the journal for a patient you are not responsible for. This access will be logged. Click OK to accept.',
    back: 'Back',
    unknown_date: 'Unknown',
    complete_data: 'Complete data',
    form: 'Form',
    strength: 'Strength',
    hits: 'results',
    print: 'Print',
    preview: 'Preview',
    documents: 'Documents',
    images: 'Images',
    phone: 'Phone',
    phone_number: 'Mobile number',
    address: 'Address',
    create: 'Create',
    document_is_signed: 'The document is signed',
    profile_image: 'Profile image',
    contact_details: 'Contact details',
    minimize: 'Minimize',
    show_all: 'Show all',
    minimize_all: 'Minimize all',
    hide_all: 'Hide all',
    months: {
      1: 'January',
      2: 'February',
      3: 'March',
      4: 'April',
      5: 'May',
      6: 'June',
      7: 'July',
      8: 'August',
      9: 'September',
      10: 'October',
      11: 'November',
      12: 'December'
    },
    previous: 'Previous',
    next: 'Next',
    remaining_characters: 'Remaining characters: %{remaining}',
    unknown_caregiver: 'Unknown caregiver',
    caregiver_role: {
      doctor: 'Doctor',
      nurse: 'Nurse'
    },
    checkup_type: {
      checkup: 'Checkup',
      new_member_checkup: 'New patient visit',
      three_month_checkup: '3-month appointment',
      yearly_checkup: 'Yearly appointment',
      extra_checkup: 'Extra checkup',
      six_week_checkup: '6-week checkup',
      initial_checkup: 'Initial checkup',
      nurse_treatment: 'Nurse treatment, hypertension',
      nurse_new_member: 'New patient visit',
      nurse_two_week: '2-week checkup',
      nurse_six_month: 'Before 6-month appointment',
      nurse_yearly: 'Before yearly appointment',
      nurse_extra: 'Extra checkup',
      reminder: 'Reminder',
      nurse_reminder: 'Reminder',
      diagnosis_screening: 'Diagnosis screening',
      examination_follow_up: 'Physical examination follow-up',
      kiosk_assessment: 'Kiosk assessment'
    },
    no_final_note_reason: 'Reason missing',
    event_type: {
      newMessage: 'New message',
      profileUpdate: 'Profile update',
      labResult: 'Lab result',
      referralFailed: 'Referral failed',
      lowBloodpressure: 'Low blood pressure',
      highBloodpressure: 'High blood pressure',
      symptom: 'Symptom',
      checkup: 'Checkup',
      standing: 'Standing measurement',
      titrationComplete: 'Titration complete',
      titrationIncomplete: 'Titration incomplete',
      memberMissing: 'Member inactive',
      reminder: 'Reminder',
      threeMonthCheckup: 'Appointment (3 month)',
      yearlyCheckup: 'Appointment (yearly)',
      followupMissing: 'Follow-up missing',
      newMedication: 'New medication',
      noRegion: 'No testing region is suitable',
      missingLabResult: 'Lab samples not taken yet',
      measurementStart: 'Start of blood pressure measurement',
      patientNotReady: 'Pat. not ready for doctor',
      patientFeeNotPaid: 'Patient fee not paid',
      nurseReminder: 'Nurse reminder',
      lifestyleReminder: 'Nurse reminder - lifestyle',
      nurseCheckup: 'Nurse checkup',
      slowOnboarding: 'Patient not ready to receive BP cuff',
      onboardingComplete: 'Pat. completed onboarding',
      screeningResult: 'Physical examination result',
      alarmValue: 'Alert',
      label: {
        slowOnboarding: 'Stuck in onboarding',
        followupMissing: 'Missing booking',
        titrationIncomplete: 'Onboarding almost done',
        titrationComplete: 'Pat. completed onboarding',
        newMessage: 'New message',
        lowBloodpressure: 'Alert (low BP)',
        highBloodpressure: 'Alert (high BP)',
        patientNotReady: 'Not ready for doctor',
        standing: 'Standing measurement',
        symptom: 'Symptomatic measurement',
        reminder: 'Reminder',
        nurseReminder: 'Reminder',
        lifestyleReminder: 'Nurse reminder - lifestyle',
        patientFeeNotPaid: 'Patient fee not paid',
        threeMonthCheckup: 'Ongoing consultation',
        newMemberCheckup: 'Ongoing consultation',
        sixWeekCheckup: 'Ongoing consultation',
        yearlyCheckup: 'Ongoing consultation',
        extraCheckup: 'Ongoing consultation',
        checkup: 'Ongoing consultation',
        nurseCheckup: 'Ongoing consultation',
        onboardingComplete: 'Pat. completed onboarding',
        alarmValue: 'Alert (generic)'
      }
    },
    back_to_inbox: 'Back to inbox',
    count_suffix: ' ',
    anonymous_mode_notice: "You're using Clinic in anonymous mode. Press <kbd>Ctrl</kbd>+<kbd>Y</kbd> to deactivate.",
    generic_error: 'Something went wrong. Information about the error has been forwarded to the development team.',
    nurse: 'nurse',
    added: 'Added',
    undiagnosed: 'Undiagnosed patient',
    referred: 'Referred for physical examination',
    monitor: {
      ihealth: 'iHealth',
      truly: 'Truly',
      otherManual: 'Manual/own monitor'
    },
    ecg: 'ECG',
    please_note: 'Note!',
    updated: 'Updated',
    blood_pressure: 'Blood pressure',
    unknown: 'Unknown',
    certificate_patient: 'Certificate patient',
    language: {
      swedish: 'Swedish',
      english: 'English'
    }
  },
  about_clinic: {
    header: 'About Clinic',
    accumbo_medical_system: 'Clinic is a part of Accumbo Medical System.',
    address: 'Varvsholmen, Bredbandet 1, 392 30 Kalmar'
  },
  instance_disabled: {
    header: 'Tab disabled',
    body: 'Clinic has been opened in another tab or browser. This tab can no longer be used.'
  },
  service_status: {
    active: 'Active',
    hidden: 'Hidden',
    blocked: 'Blocked',
    doctor: 'Handled by doctor',
    nurse: 'Handled by nurse',
    deactivating: 'Deactivating'
  },
  payment_state: {
    created: 'Payment initiated',
    pending: 'Payment pending',
    exemption: 'Exempt',
    complete: 'Paid',
    failed: 'Payment failed',
    error: 'Payment error',
    cancelled: 'Payment cancelled'
  },
  notification: {
    generic: {
      error: 'Something went wrong. Try again or contact support.'
    },
    assign_responsible_doctor: {
      success: 'Assigned as responsible doctor',
      error: 'Could not assign responsible doctor. Please try again.'
    },
    assign_responsible_nurse: {
      success: 'Assigned as responsible nurse',
      error: 'Could not assign responsible nurse. Please try again.'
    },
    medication_signed: {
      success: 'Medication signed',
      error: 'The medication could not be signed'
    },
    medication_added: {
      success: 'Medication added',
      error: 'The medication could not be added'
    },
    medication_paused: {
      success: 'Medication paused',
      error: 'The medication could not be paused'
    },
    medication_updated: {
      success: 'Medication updated',
      error: 'The medication could not be updated'
    },
    medication_canceled: {
      success: 'Medication canceled',
      error: 'The medication could not be canceled'
    },
    medication_invalidated: {
      success: 'Medication removed',
      error: 'The medication could not be removed'
    },
    medication_dosageRegimen_change: {
      error: 'Could not update dosage.'
    },
    medication_reason_change: {
      error: 'Could not update reason.'
    },
    upload_file: {
      error: 'The file could not be uploaded.'
    },
    post_file_to_chat: {
      error: 'Could not add the file to the chat.'
    },
    document_created: {
      success: 'Document created',
      error: 'Could not create the document. Please try again.'
    },
    final_note_created: {
      success: 'Epicrisis created.',
      error: 'Could not create an epicrisis.'
    },
    create_jira_issue: {
      success: 'Support issue created',
      error: 'Could not create support issue.'
    },
    create_referral: {
      success: 'Referral sent.',
      error: 'Could not send the referral. Please try again or contact support.'
    },
    prepare_referral: {
      success: 'Referral saved.',
      error: 'Could not save the referral. Please try again or contact support.'
    },
    delete_checkup: {
      error: 'Could not remove the booking. Please try again or contact support.'
    },
    delete_checkup_conflict: {
      error: 'Could not remove the checkup since the patient fee is already paid.'
    },
    save_checkup: {
      error: 'The checkup could not be updated. Please try again or contact support.'
    },
    checkup_saved: {
      success: 'Checkup booked at %{date}.',
      error: 'The checkup could not be booked. Please try again or contact support.'
    },
    reminder_saved: {
      success: 'Reminder saved for %{date}.',
      error: 'The reminder could not be saved. Please try again or contact support.'
    },
    bypass_actions: {
      success: 'Patient ready for doctor checkup.',
      error: 'Could not mark the patient as ready.'
    },
    upload_profile_picture: {
      success: 'Your profile picture was saved.',
      error: 'Could not save the profile picture.'
    },
    snooze_errand: {
      error: 'Could not snooze the errand. Please try again or contact support.'
    },
    fetch_patient_comment: {
      error: 'Could not fetch patient comments.'
    },
    save_patient_comment: {
      error: 'Could not save the comment. Please try again or contact support.'
    },
    post_physical_examination_referral: {
      success: 'Physical examination referral created and sent in the chat.',
      error: 'Could not create the physical examination referral. Please try again or contact support.'
    },
    physical_examination_referral_created: {
      success: 'Physical examination referral sent',
      error: 'Could not create the physical examination referral. Please try again or contact support.'
    },
    post_auscultation_assessment: {
      error: 'Could not save auscultation assessment. Please try again or contact support.'
    },
    convert_draft_to_message: {
      error: 'Could not send the message immediately.'
    }
  },
  patient_view: {
    header: 'Patient',
    unsaved_changes_prompt: 'There are unsaved changes. Leave anyway?',
    error_loading_patient:
      'Could not fetch patient data. Try again later or contact technical support. If you got here after clicking a link, check that the link is correct.',
    tabs: {
      overview: 'Overview',
      health_profile: 'Healthcare profile',
      referrals: 'Referrals and results',
      medicines: 'Medicines',
      notes: 'Notes and files',
      decision_support: 'Decision Support',
      timeline: 'Timeline'
    },
    anamnesis: {
      no_answer: 'Not answered.',
      skipped_question: 'Question was skipped.',
      empty_category: 'No questions in this category.',
      see_medicine_list: 'See medicine tab',
      personal_goal_header: "Patient's personal goal",
      no_personal_goal: 'No personal goal entered.',
      risk_profile_header: 'Risk profile',
      member_goal_header: 'Goal',
      risk_profile_history_link: 'History',
      risk_profile_history_header: 'Risk profile history',
      error: 'Could not fetch anamnesis answers.'
    },
    chat: {
      chat_message_placeholder: 'Type a message...',
      chat_search_placeholder: 'Search chat message',
      send: 'Send',
      create_note: 'Create note',
      create_day_note: 'Create day note',
      create_epicrisis: 'Create epicrisis',
      polling_error: 'Could not update chat. Check internet connectivity.',
      generic_post_error: 'Could not send message.',
      conflict_post_error: 'Could not send message. There is already an active nudge message.',
      chat_modal_header: 'Message to {0}',
      automatic_banner_message: 'Out of office notification',
      fullsize_image_header: 'Image from patient',
      image_thumbnail_error: 'Could not load thumbnail. Click for full version.',
      refer_to_support: 'Refer to support',
      nudge: 'Nudge',
      nudge_tooltip:
        '<p>This message triggers reminders via email and SMS.</p><p>Continued inactivity generates a support issue.</p>',
      active_nudge:
        "The patient is currently getting reminders via e-mail and SMS. A support issue is created automatically if the patient does not read your message. You don't have to create an extra support issue.",
      draft: {
        delete: 'Delete',
        edit: 'Edit',
        sending_in: 'Sending in %{time}',
        sending: 'Sending...',
        stopped: 'Stopped',
        sending_blocked_active_draft: "Can't send a new message when there is an active draft.",
        edit_header: 'Edit draft',
        send_edit: 'Edit draft',
        countdown_tooltip:
          "You can leave this page if you don't want to make more changes. This message will be sent automatically anyway.",
        countdown_stopped:
          'The countdown has stopped. You will need to send the message again for it to be received by the patient.',
        confirm_deletion: 'Are you sure you want to delete your draft?',
        send_now: 'Send now',
        confirm_send_now: 'Are you sure you want to send the message immediately?'
      },
      filters: {
        pal_message: 'PAL messages',
        pas_message: 'PAS messages',
        system_message: 'System messages',
        patient: 'Patient messages',
        file: 'Files',
        image: 'Images'
      },
      chat_filter_button: 'Chat filter',
      chat_filtered_notice: 'Note: the chat is filtered',
      canned_response: {
        support:
          'Hej!\n\nVid ickemedicinska frågor är vår support bättre lämpad att hjälpa dig, så jag ber dig ta kontakt med dem antingen via telefon eller e-post.\n\nRing oss på 0480-797 960 mellan 09.00-12.00 eller 13.00-15.00 på vardagar, alternativt skicka e-post till support@accumbo.se.\n\nDu kan även se vanliga frågor och svar på https://accumbo.se/faq/',
        nudge:
          'Hej %{name}!\n\nJag hoppas att allt är bra med dig. Jag ser att du inte har varit så aktiv i din behandling hos oss på sistone. Återkoppla gärna så att vi kan gå vidare.\n\nVänliga hälsningar,'
      },
      payment_message_header: {
        created: 'Patient fee message',
        pending: 'Patient fee message',
        exemption: 'Patient fee paid',
        complete: 'Patient fee paid',
        failed: 'Patient fee unpaid',
        error: 'Patient fee unpaid',
        cancelled: 'Patient fee cancelled'
      },
      payment_message: {
        created: 'The patient has been asked to pay the patient fee (%{date})',
        pending: 'Patient fee payment pending (%{date})',
        exemption: 'Patient fee paid via exemption %{date}',
        exemption_ea: 'Patient fee paid by EuroAccident',
        complete: 'Patient fee paid %{date}',
        failed: 'Patient fee payment failed (%{date})',
        error: 'Patient fee payment failed, technical error (%{date})',
        cancelled: 'The payment was cancelled, due to e.g. a cancelled checkup.'
      },
      payment_history_icon_title: 'Go to payment history',
      consent_message: {
        available_options: 'Available payment methods',
        consented: 'The patient has chosen payment method',
        option_billing: 'Invoice',
        option_exemption: 'Exemption card',
        option_not_chosen: 'The patient has been asked to choose payment method (exemption card or invoice)',
        option_not_chosen_short: 'The patient has been asked to choose payment method',
        billing: 'The patient has chosen payment method (invoice)',
        exemption: 'The patient has chosen payment method (exemption card)'
      },
      error: {
        title: 'The chat could not be rendered.',
        body: 'Information about the error has been forwarded to the development team.'
      }
    },
    graph: {
      relaxation_time: 'Relaxation time',
      systolic_pressure: 'Systolic pressure',
      diastolic_pressure: 'Diastolic pressure',
      heartrate: 'Heartrate',
      no_measurements: 'No measurements for this member.',
      measurements_header: 'Blood pressure measurements',
      measurement_mode: {
        relaxation: 'Our relaxation',
        symptoms: 'Symptomatic measurement',
        alreadyRelaxed: 'Already relaxed',
        standing: 'Standing measurement',
        test: 'Test measurement'
      },
      measurement_times: {
        morning: 'Morning (05-11)',
        day: 'Day (11-17)',
        evening: 'Evening (17-00)',
        night: 'Night (00-05)'
      }
    },
    averages: {
      average_values_header: 'Average values',
      highest_systolic: 'Highest SYS',
      lowest_systolic: 'Lowest SYS',
      highest_diastolic: 'Highest DIA',
      lowest_diastolic: 'Lowest DIA',
      average_morning: 'Average morning',
      average_day: 'Average day',
      average_evening: 'Average evening',
      average_night: 'Average night',
      number_of_measurements: 'Measurements',
      period_header: 'Averages week'
    },
    medications: {
      medication_list: 'Medicine list',
      accumbo_medications: 'Medication prescribed by Accumbo',
      member_medications: 'Member specified medications',
      natural_remedies: 'Natural remedies',
      dietary_supplements: 'Dietary supplements',
      when_needed: 'When needed',
      no_medications: 'No medications prescribed',
      no_member_medications: 'No medications specified',
      no_medication_history: 'No medication history',
      no_natural_remedies: 'The member is not using any natural remedies',
      no_dietary_supplements: 'The member is not taking any dietary supplements',
      prescribe_medication: 'Prescribe medication',
      edit_medication_error: 'Could not save updates. Please try again.',
      open_prescription_service: 'Open Alfa e-recept',
      prescription_service_description: 'All our medications are prescribed via Alfa e-Recept in a separate system.',
      prescription_confirm_close: 'Are you sure you want to close Alfa e-recept without saving?',
      get_prescription_session_error: 'Something went wrong when loading Alfa e-recept. Please try again.',
      post_prescription_error: 'Something went wrong when saving the prescription. Please try again.',
      add_medication: 'Add medication',
      sign_medications: 'Sign medication list',
      accumbo_medication_history: 'History medication prescribed by Accumbo',
      member_medication_history: 'History medication specified by member',
      add_medication_error: 'Could not add the medication. Please try again.',
      sign_medication_error: 'Could not sign the medication list. Please try again.',
      get_member_medications_error: 'Could not fetch medication information.',
      signed: 'Signed off',
      medication_signed: 'Medication signed',
      paused: 'Paused',
      renew_n_medications: 'Renew {0} medications',
      renew_medications: 'Renew medication',
      medication_updated: 'Medication updated',
      medication_added_notification: 'Medication added ({0})',
      medication_prescribed_notification: 'Medication prescribed ({0})',
      medications_prescribed_notification: '{0} medications prescribed',
      medication_reason_saved: 'Medication reason saved',
      medication_paused: 'Medication paused',
      medication_canceled: 'Medication canceled',
      sign_without_vara_import: 'Continue without VARA import',
      section: {
        unsigned_medications: 'Unsigned medications',
        current_medications: 'Current medications',
        previous_medications: 'Previous medications',
        supplements: 'Natural remedies and dietary supplements',
        anamnesis: 'Anamnesis questions about medications',
        invalidated_medications: 'Removed without being signed'
      },
      method: {
        scanned: 'Scanned',
        manual: 'Manual'
      },
      medication_history: {
        header: 'History of medication adjustments for patient',
        dose_change: 'Dose change',
        new_medication: 'New medication',
        cancellation: 'Canceled medication',
        new_dose: 'New dose',
        previous_dose: 'Previous dose',
        empty: 'No medication history'
      },
      header: {
        sign_manual_medication: 'Sign manually entered medication',
        sign_scanned_medication: 'Sign scanned medication',
        sign_scanned_medication_with_conflict: 'Sign medication (multiple results found)',
        patient_scanned_info: 'Information scanned by patient',
        patient_manual_info: 'Information entered by patient',
        medication_suggestions:
          "The patient scanned the medication package's barcode. We found multiple possible options in the VARA registry. Choose the best match.",
        add_medication_manually: 'Add medication manually',
        add_medication_manually_sub_header: 'Add medication manually'
      },
      medication_modal: {
        header: 'Update medication',
        medication_name: 'Name of medication',
        medication_type: 'Type of medication',
        medication_strength: 'Strength',
        medication_dose: 'Dosage',
        medication_reason: 'Reason',
        medication_when_needed: 'Only when needed',
        medication_npl_pack_id: 'NPLpack ID',
        medication_atc_code: 'ATC code',
        delete_medication: 'Delete medication',
        delete_confirmation: 'Are you sure you want to cancel this medication?',
        invalidate_confirmation:
          'Are you sure you want to remove this medication without signing it? You will be able to see the medication later at the bottom of this page.',
        header_add: 'Add medication',
        added_at: 'Added',
        signed_at: 'Signed',
        correct_text: 'Correct text',
        change_dose: 'Change dosage',
        change_reason: 'Change reason',
        update_reason: 'Update reason',
        pause_medication: 'Pause medication',
        cancel_medication: 'Cancel medication',
        resume_medication: 'Resume medication',
        paused_until: 'Paused until',
        paused_indefinitely: 'Paused indefinitely',
        update_dosage: 'Update dosage',
        dose_update_reason: 'Reason for doseage change',
        reason_textarea_placeholder: 'Free text',
        cancel_medication_reason: 'Reason for canceling the medication',
        add_medication_reason: 'Reason for prescribing medication',
        recommended_medication_option: 'Recommended option',
        recommended_option_reason: 'This medication was the last one added to the VARA registry.',
        sign_with_alternative_option_confirmation: 'Are you sure you want to select this medication?',
        no_medication_options_found: 'Could not find any medications.',
        no_search_results:
          'No search results found for this combination of name and strength. Please change one of the search parameters.',
        nplpackid_atc_code_missing: 'NPLPack ID or ATC code is missing',
        dosage_regimen_or_when_needed_missing: 'Dosage or "When needed" is required.',
        enter_name_to_start_search: 'Enter a medication name to start searching.',
        enter_vara_search: 'Complete through VARA',
        vara_disabled_reason: 'Fill out name and type',
        original_version_by_patient: 'Original version created by patient',
        original_version: 'Original version',
        name_notice:
          "Please check the name and spelling since the the name has not been imported. This is also shown in the patient's medicine list."
      },
      change_dose_modal: {
        current_dose: 'Current dose',
        new_dose: 'New dose'
      },
      change_reason_modal: {
        current_reason: 'Current reason',
        new_reason: 'New reason'
      },
      pause_medication_modal: {
        pause_indefinitely: 'Pause medication indefinitely',
        pause_until_date: 'Pause until date',
        register_pause_between_dates: 'Register break between two dates'
      },
      sign_medication_modal: {
        header: 'Sign medication',
        sign_button: 'Sign medication',
        replace_and_sign: 'Replace and sign'
      },
      add_medication_modal: {
        header: 'Add medication',
        add_button: 'Add medication'
      },
      table_header: {
        name: 'Name',
        dose: 'Dosage',
        strength: 'Strength',
        type: 'Medication type',
        interval: 'Dosage',
        date_signature: 'Date and signature',
        issued_by: 'Issued by',
        canceled_by: 'Canceled by',
        added: 'Added',
        method: 'Method',
        prescribed_by: 'Prescribed by',
        invalidated_by: 'Removed by'
      },
      empty: {
        unsigned_medications: 'No unsigned medications.',
        current_medications_maybe_empty: 'The patient has not yet answered the question about current medications.',
        current_medications_definitely_empty:
          'The patient has answered that they do not currently take any medications.',
        current_medications_empty_for_now:
          'The patient has answered that they take medications but has not added any yet.',
        current_medications_empty_but_has_unsigned: 'The patient has unsigned medications.',
        current_medications_empty: 'The patient does not currently take any medications.',
        previous_medications: 'No previous medications.',
        invalidated_medications: 'No medications removed before signing.'
      },
      errors: {
        loading_medications: 'Could not fetch medication data.',
        loading_search_results: 'Could not get search results from the VARA registry.',
        loading_medication_supplements: 'Could not fetch supplements and natural remedies.',
        loading_medication_extras: 'Could not fetch anamnesis information about medications.'
      },
      tabs: {
        medication_information: 'Medication information',
        revisions: 'Revisions (%{revisionCount})',
        original_copy: 'Original version'
      },
      error_modal: {
        sign: {
          header: 'The medication could not be signed',
          body: 'The patient has recently canceled adding this medication. Contact the patient if you have questions.'
        },
        cancel: {
          header: 'Medication already canceled',
          body: 'The medication you tried to cancel has already been canceled.'
        }
      },
      medication_option: {
        name: 'Name',
        form: 'Type',
        strength: 'Strength',
        atc_code: 'ATC code',
        package_content: 'Package content',
        approval_date: 'Approval date',
        unregistered_date: 'Unregistration date',
        sales_stop: 'Sales stop'
      },
      vara_search: {
        search_vara: 'Search in VARA',
        atc_note:
          'If the manufacturer is not known, choose the corresponding generic medication and select "Manufacturer unknown" in the next step.',
        vara_match: 'VARA matched medication',
        patient_information: 'Patient medication',
        which_information: 'Which information do you want to use?',
        manufacturer_known: 'Manufacturer known',
        manufacturer_unknown: 'Manufacturer unknown',
        fill_in_all_information: 'Use all information from the preview above',
        fill_in_all_information_note:
          'This replaces all the information that the patient has entered manually. ATC code is included.',
        fill_in_all_information_note_caregiver:
          'This replaces all the information that you have entered manually. ATC code is included.',
        fill_in_atc_code_only: 'Only the ATC code, strength and medication type is used',
        fill_in_atc_code_only_note:
          'ATC codes are used in order to known exactly which active substance the patient is being prescribed and to do data processing.',
        cannot_find_header: "Can't find the medication?",
        google_search_1: 'Try making a ',
        google_search_2: 'Google search',
        google_search_3: 'The most common reason is that the substance is spelled incorrectly.',
        not_in_vara_header: 'Does the substance not exist in VARA?',
        not_in_vara_body:
          'Note that natural remedies and dietary supplements should not be added to the medication list. You can add the medication manually after selecting a reason below.',
        reason_foreign_medicine: 'This is a foreign medication',
        reason_other: 'Other, please enter:'
      },
      base: {
        atc_code: 'ATC code',
        npl_pack_id: 'NPLpack ID',
        name: 'Name',
        dosage_regimen: 'Dosage',
        form: 'Medication type',
        strength: 'Strength',
        when_needed: 'When needed',
        reason: 'Reason'
      },
      medicine_extras: {
        supplements: 'No natural remedies or dietary supplements.',
        medicine_changes: 'No answers to the anamnesis questions about medications.'
      },
      patient_requested_prescription: 'The patient has requested medication renewal: "%{medicineName}"',
      patient_requested_prescription_warning:
        'The patient has requested medication renewal. However, no medication has been prescribed via Alfa e-recept. Sign anyway?',
      generic_error: 'Something went wrong.'
    },
    overview: {
      no_phone_number: 'No phone number provided',
      no_email: 'No email address provided',
      height: 'Height',
      weight: 'Weight',
      waist_size: 'Waist size',
      measurement_average: 'Average blood pressure',
      bp_goal: 'Goal blood pressure',
      risk_profile_header: 'Risk profile',
      goal_header: 'Goal',
      values_header: 'Measurements and values',
      risk_profile_empty: 'No risk profile given.',
      goal_empty: 'No goal stated.',
      allergy_header: 'Other allergies/oversensitivity',
      oversensitivity_header: 'Drug allergies',
      allergy_comments_header: 'Doctor comments',
      total_measurements: 'Total measurements',
      morning_measurements: 'Morning measurements',
      evening_measurements: 'Evening measurements',
      measurements_plural: 'measurements',
      measurements_singular: 'measurement',
      average_measurements_header: 'Averages',
      mark_inactive: 'Mark patient as inactive',
      marked_inactive_success: 'Patient marked as inactive.',
      marked_inactive_error: 'Could not mark patient as inactive. Please try again.',
      mark_inactive_title: 'The patient will not show up in your errands.',
      marked_inactive_already_title: 'The patient will be marked as active on next login.',
      blocked_alert: 'The patient is blocked and can no longer log in.',
      migrated_alert:
        'Note! The patient has been migrated to the next version of Clinic and can no longer be managed in this version.'
    },
    events: {
      header: 'Event handling',
      active_events_header: 'All errands',
      handled_events_header: 'Handled errands',
      no_active_events_header: 'No active errands to handle',
      no_active_events: 'No errands to handle.',
      no_handled_events: 'No handled errands.',
      handle_event_error: 'Could not mark the errand as done. Please try again.',
      get_active_events_error: 'Could not load active errands.',
      snooze: {
        zero_days: 'Snooze 0 days (test)',
        one_day: 'Snooze one day',
        three_days: 'Snooze three days',
        one_week: 'Snooze one week'
      },
      snoozed_until: 'Snoozed until %{date}',
      was_snoozed_until: 'Was snoozed until %{date}',
      confirm_handle_snoozed_errand:
        'This is a snoozed errand that will appear in your errand list %{date}. Are you sure you want to mark it as done already?',
      confirm_handle_without_final_note:
        "Looks like you're trying to close the errand without writing a final note. Click OK to close it anyway, or Cancel if you'd rather snooze it instead."
    },
    referrals: {
      error: 'Could not load Info Solutions. Please try again.',
      modal_header: 'Instructions for referrals',
      show_info: 'Show instructions for referrals',
      unilabs_stockholm: 'Lab sampling at Unilabs in Stockholm/Södertälje',
      unilabs_stockholm_body:
        'If you need additional lab samples from here, pick Unilabs Stockholm in LabPortalen. For the patient it is drop-in.',
      unilabs_goteborg: 'Lab sampling at Unilabs in Göteborg, Lidköping, Falköping, and Uddevalla',
      unilabs_goteborg_body:
        'If you need additional lab samples from here, pick Unilabs Eskilstuna/Skövde in LabPortalen. For the patient it is drop-in.',
      encia_aleris: 'Lab sampling with Encia/SYNLAB Medilab',
      encia_aleris_body:
        'No matter which Encia location the patient should go to they will need to contact our support too book an appointment. If you need additional lab samples for a patient who took their samples at Encia, pick <strong>SYNLAB Medilab</strong> in LabPortalen and then click <strong>"Spara"</strong>. That means you should <strong>not send referral</strong>. <em>(By saving a referral for SYNLAB Medilab the staff at Encia can go and read it in LabPortalen.)</em>',
      skane: 'Lab sampling within Region Skåne',
      skane_body:
        'We send paper referrals to all patients within Region Skåne. If you need to order additional samples you do this manually via LabPortalen and <strong>"Spara"</strong> the referral. Afterwards our support will send out a referral. If it is urgent then notify our support via the Slack channel #customersupport.',
      wetterhalsans: 'Provtagning hos Wetterhälsans Vårdcentral, A6',
      wetterhalsans_body:
        'För remiss gäller följande: <strong>Välj Unilabs Eskilstuna/Skövde</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.',
      cordinator: 'Provtagning hos Cordinator Linköping',
      cordinator_body:
        'För remiss gäller följande: <strong>Välj SYNLAB Medilab</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.',
      uddevalla: 'Provtagning hos Hälsobolaget i Uddevalla',
      uddevalla_body:
        'För remiss gäller följande: <strong>Välj Unilabs Eskilstuna/Skövde</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.',
      hudiksvall: 'Provtagning hos Valmea Vård i Hudiksvall',
      hudiksvall_body:
        'För remiss gäller följande: <strong>Välj SYNLAB Medilab</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.',
      ostersund: 'Provtagning hos Veronicare AB i Östersund',
      ostersund_body:
        'För remiss gäller följande: <strong>Välj Unilabs Sörmland</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.',
      rattvik: 'Provtagning hos LenaGo Health',
      rattvik_body:
        'För remiss gäller följande: <strong>Välj Unilabs Eskilstuna/Skövde</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.',
      vaxjo: 'Provtagning hos VaccinF i Växjö',
      vaxjo_body:
        'För remiss gäller följande: <strong>Välj Unilabs Eskilstuna/Skövde</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.',
      umea: 'Provtagning hos Aleris Specialistvård Renmarkstorget',
      umea_body:
        'För remiss gäller följande: <strong>Välj SYNLAB Medilab</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.',
      kiruna: "Provtagning hos AK's Estetik & Hälsa i Kiruna",
      kiruna_body:
        'För remiss gäller följande: <strong>Välj SYNLAB Medilab</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.',
      create_referral_header: 'Create referral',
      referral_region_missing:
        'The patient has not yet selected a region, which is required in order to be able to create a referral.',
      referral_is_sent_to: "The referral will be sent to the patient's chosen region (%{region})",
      referral_is_sent_to_region_se:
        'The patient has chosen ”Alternativen passar inte mig”. A physical referral needs to be printed and brought to the lab.',
      create_referral: 'Create referral',
      send_to_patient: 'Send referral to patient',
      prepare_referral: 'Prepare referral',
      select_package: 'Select analysis package',
      custom_package: 'Create custom package',
      historic_referrals_header: 'Created referrals',
      custom_historic_package: 'Custom package',
      historic_referrals_button: 'View created referrals',
      package_missing: 'Samples list missing.',
      region: {
        encia:
          'OBS! Denna patient har valt provtagning hos Encia i %{regionName}.<br />För remiss gäller följande: <strong>Välj SYNLAB Medilab</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.<br />Du behöver göra detta val manuellt om du beställer uppföljningsprover via LabPortalen.',
        unilabs:
          'OBS! Denna patient har valt provtagning hos Unilabs i %{regionName}.<br />För remiss gäller följande: <strong>Välj Unilabs Eskilstuna/Skövde</strong> och välj sedan <strong>"Spara och skicka"</strong>.',
        stockholm:
          'OBS! Denna patient har valt provtagning hos Unilabs i Stockholm.<br />För remiss gäller följande: <strong>Välj Unilabs Stockholm</strong> och välj sedan <strong>"Spara och skicka"</strong>.<br />Du behöver göra detta val manuellt om du beställer uppföljningsprover via LabPortalen.',
        skane:
          'OBS! Denna patient har valt provtagning i Region Skåne.<br />För remiss gäller följande: <strong>Välj Region Skåne</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.<br />Du behöver göra detta val manuellt om du beställer uppföljningsprover via LabPortalen.',
        jonkoping:
          'OBS! Denna patient har valt provtagning hos Wetterhälsans Vårdcentral, A6.<br />För remiss gäller följande: <strong>Välj Unilabs Eskilstuna/Skövde</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.<br />Du behöver göra detta val manuellt om du beställer uppföljningsprover via LabPortalen.',
        linkoping:
          'OBS! Denna patient har valt provtagning hos Cordinator i Linköping.<br />För remiss gäller följande: <strong>Välj SYNLAB Medilab</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.<br />Du behöver göra detta val manuellt om du beställer uppföljningsprover via LabPortalen.',
        hudiksvall:
          'OBS! Denna patient har valt provtagning hos Valmea Vård i Hudiksvall.<br />För remiss gäller följande: <strong>Välj SYNLAB Medilab</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.<br />Du behöver göra detta val manuellt om du beställer uppföljningsprover via LabPortalen.',
        ostersund:
          'OBS! Denna patient har valt provtagning hos Veronicare AB i Östersund.<br />För remiss gäller följande: <strong>Välj Unilabs Sörmland</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.<br />Du behöver göra detta val manuellt om du beställer uppföljningsprover via LabPortalen.',
        rattvik:
          'OBS! Denna patient har valt provtagning hos LenaGo Health i Rättvik.<br />För remiss gäller följande: <strong>Välj Unilabs Eskilstuna/Skövde</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.<br />Du behöver göra detta val manuellt om du beställer uppföljningsprover via LabPortalen.',
        vaxjo:
          'OBS! Denna patient har valt provtagning hos VaccinF i Växjö.<br />För remiss gäller följande: <strong>Välj Unilabs Eskilstuna/Skövde</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.<br />Du behöver göra detta val manuellt om du beställer uppföljningsprover via LabPortalen.',
        umea: 'OBS! Denna patient har valt provtagning hos Aleris Specialistvård Renmarkstorget i Umeå.<br />För remiss gäller följande: <strong>Välj SYNLAB Medilab</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.<br />Du behöver göra detta val manuellt om du beställer uppföljningsprover via LabPortalen.',
        sweden:
          'Den här patienten har valt övrig provtagning i Sverige. Patienten behöver skriva ut en fysisk remiss och ta med till en av regionens provtagningsenheter.<br /><strong>För att skapa en remiss måste du använda funktionen i beslutsstödet, du kan inte skapa en remiss i LabPortalen</strong>.',
        kiruna:
          "OBS! Denna patient har valt provtagning hos AK's Estetik & Hälsa i Kiruna.<br />För remiss gäller följande: <strong>Välj SYNLAB Medilab</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.<br />Du behöver göra detta val manuellt om du beställer uppföljningsprover via LabPortalen."
      }
    },
    notes: {
      notes_tab: 'Notes',
      documents_tab: 'Documents and images',
      day_notes_header: 'Day notes',
      final_notes_header: 'Final notes',
      no_day_notes: 'No day notes',
      no_final_notes: 'No final notes',
      no_documents: 'No documents',
      no_images: 'The patient has not uploaded any images.',
      patient_communication_tab: 'Patient communication',
      show_in_chat: 'Show preceding chat communication',
      show_full_note: 'Show full note',
      hide_full_note: 'Hide note',
      show_my_notes: 'Show only my notes',
      send_to_chat: 'Send to chat',
      upload_file: 'Upload',
      preview_error: 'Could not preview the document.',
      unsupported_filetype: 'The selected file type is unsupported. Only PDF files can be uploaded.',
      filename_notice: 'Note that the filename will be visible to the patient if you send the document in the chat.',
      edit_filename: 'Edit document name',
      content_type: 'Document type',
      no_file_selected: 'No file selected',
      member_files_error: "Could not fetch the patient's files.",
      page_of_num_pages: 'Page %{currentPage} of %{numPages}',
      document_type: 'Document type',
      uploaded_by: 'Uploaded by %{uploaderName}',
      create_document: 'Create PDF',
      referring_doctor: 'Referring doctor',
      patient_details: 'Patient details',
      document_field: {
        referral_recipient: 'Referral recipient',
        recipient: 'Recipient',
        requested_examination: 'Requested examination',
        diagnosis_and_query: 'Diagnosis and query',
        diagnosis: 'Diagnosis',
        anamnesis: 'Anamnesis',
        anamnesis_summary: 'Summary of health history',
        current_medications: 'Current medications',
        verdict: 'Verdict'
      },
      has_unsigned_medications_warning: 'There are unsigned medications.',
      examination: {
        header: 'Examinations',
        volume_control: 'Audio volume',
        performed_at: 'The examination was concluded %{date}',
        performed_at_assessment: 'The test recording was concluded %{date}',
        performed_by_header: 'Examination performed by',
        performed_by_header_assessment: 'Test recording performed by',
        performed_by: '%{name} at %{facility}',
        comment: 'Examination comment',
        comment_assessment: 'Partner user comment',
        no_comment: 'No comment',
        no_examination: 'No examinations performed.',
        auscultation: {
          error: 'Could not fetch auscultation recordings',
          no_recording: 'No recording',
          heart: {
            header: 'Heart auscultation',
            top_left: 'Top left',
            top_right: 'Top right',
            bottom_left: 'Bottom left',
            bottom_right: 'Bottom right'
          },
          lungs: {
            header: 'Lung auscultation',
            top_left: 'Apikalt vänster',
            top_right: 'Apikalt höger',
            mid_left: 'Midskapulärt vänster',
            mid_right: 'Midskapulärt höger',
            bottom_left: 'Basalt vänster',
            bottom_right: 'Basalt höger'
          }
        },
        ecg_pending: 'EKG has not yet been uploaded by the partner.'
      }
    },
    confirm: {
      risk_profile: 'Are you sure you want to save a new risk profile?',
      member_goal: 'Are you sure you want to save a new goal?'
    },
    comments: {
      header: 'PAS notes'
    }
  },
  inbox_view: {
    header: 'Inbox',
    action_required: 'All errands',
    handled_events_header: 'Handled errands',
    sort_option: {
      latest: 'Latest',
      age: 'Age'
    },
    wait_time: 'Wait time',
    events_error: 'Could not load errands.',
    events_empty: 'There are no errands to display yet.',
    handled_events_error: 'Could not get handled errands.',
    handled_events_empty: 'There are no handled errands to display.',
    own_events_empty: 'You have no errands assigned.',
    own_handled_events_empty: 'You have no handled errands.',
    handled_date: 'Handling date',
    handled_reason: 'Reason',
    new_patient: 'New pat.',
    responsible_doctor: 'PAL',
    responsible_nurse: 'PAS',
    show_only_own_errands: 'Show only my errands',
    show_all_errands: 'Show all errands',
    hide_missing_members: 'Hide inactive',
    new_members_error: 'Could not fetch new members.',
    new_members_empty: 'There are no more new and unassigned members.',
    self_assign: 'Assign to me',
    self_assign_and_navigate: 'Assign and respond',
    new_members_header: 'New members',
    registration_date: 'Registration date',
    responsible_doctor_column: 'Assign as PAL',
    responsible_nurse_column: 'Assign as PAS',
    hide_new_members: 'Hide',
    show_all_new_members: 'Show all new patients',
    fetch_handled_events: 'Fetch handled errands',
    dashboard: {
      greeting: 'Hi %{name}!',
      you_have: 'You have ',
      total_patients: '%{numTotal} patients, of which ',
      active_patients: '%{numActive} are active.',
      hide: 'Hide',
      show: 'Show',
      error: 'Could not fetch statistics.',
      no_patients_pal: 'You are not yet responsible doctor for any patients.',
      no_patients_pas: 'You are not yet responsible doctor for any patients.',
      upcoming_checkups: 'Booked checkups',
      upcoming_checkups_label: 'Checkups',
      note: 'These numbers are preliminary and may be affected by new patient visits or cancellations.'
    },
    errand_action: {
      mark_unread: 'Mark as unread'
    },
    n_new_patients: 'new patients waiting to be assigned.'
  },
  unhandled_errands_view: {
    header: 'Unhandled errands',
    empty: 'No unhandled errands',
    error: 'Could not fetch unhandled errands.'
  },
  member_list_view: {
    header_active: 'Active members',
    header_inactive: 'Inactive members',
    filter_placeholder: 'Filter on name/personal number',
    error: 'Could not fetch member list.',
    no_results: 'No members matched the filter.',
    showing_x_of_y: 'Showing %{currentPage} of %{totalCount}',
    include_non_pal: 'Include patients for whom I am not %{caregiverAbbr}',
    not_active_yet: 'Not yet active',
    column_headers: {
      name: 'Name',
      personal_number: 'Personal number',
      caregiver: '%{caregiverAbbr}',
      status: 'Status',
      source: 'Source'
    }
  },
  unassigned_patients_view: {
    header: 'New patients',
    empty: 'There are no new patients at the moment.',
    undiagnosed_title: 'Undiagnosed',
    status: {
      new: 'New patient',
      new_undiagnosed: 'New pat. (UD)',
      old: 'From PAL'
    }
  },
  prioritized_errands_view: {
    header: 'My prioritized errands',
    list_header: 'Prioritized errands',
    empty: 'There are no prioritized errands right now.',
    filtered_empty: 'No errands matched the filter.',
    show_all: 'Show all prioritized errands'
  },
  active_consultations_view: {
    header: 'My active consultations',
    list_header: 'Active consultations',
    empty: 'You have no active consultations right now.',
    filtered_empty: 'No consultations matched the filter.',
    show_all: 'Show all active consultations'
  },
  patient_search_view: {
    header: 'Patient search'
  },
  login_view: {
    login_with: 'Log in with Mobile BankID',
    login_failed: 'Login failed',
    login: 'Log in',
    instruction1:
      'Start the BankID app on your phone and press <strong>Skanna QR-kod</strong>. Point the camera at the QR code above.',
    instruction2: 'Enter your security code in the BankID app and press <strong>Identifiera</strong>.',
    cancel_login: 'Cancel login'
  },
  referrals_view: {
    referrals: 'Referrals',
    lab_results: 'Lab results',
    region_reminder: 'Note! This patient has previously had their tests taken at:',
    region_reminder_manually:
      'You need to make the same selection manually if you order follow-up tests via LabPortalen.'
  },
  decision_support_view: {
    onboarding: {
      header: 'Onboarding'
    },
    patient_not_ready: {
      header: 'The patient is not ready for their planned doctor checkup',
      nudge_and_snooze: 'Nudge patient and snooze errand',
      snooze: 'Snooze errand',
      snooze_and_send_message: 'Send message and snooze errand',
      measurements: {
        none: "The patient hasn't made any measurements in the past two weeks",
        single: 'The patient has made 1 measurement in the past two weeks',
        multiple: 'The patient has made %{numMeasurements} measurements in the past two weeks'
      },
      anamnesis: {
        none: "The patient hasn't answered the anamnesis questions",
        partial: 'The patient has answered some of the anamnesis questions',
        all: 'The patient has answered the anamnesis questions'
      },
      mark_as_ready_for_doctor: 'Mark patient as ready for doctor checkup',
      chat_input_header: 'Send message to chat',
      chat_message_notice:
        'Note that if the message box is left empty, the errand will be snoozed without a message being sent to the patient.',
      event_info: 'The errand will reappear in 4 days if the patient is still not ready.',
      prefilled_chat_message: {
        not_prepared_at_all:
          'Hej! Inför din läkarkontroll behöver du uppdatera svaren på de utvalda hälso- och sjukvårdsfrågorna samt mäta ditt blodtryck. Du behöver mäta ditt blodtryck efter avslappning, både på morgonen och på kvällen, helst under flera dagar. Jag skjuter på din läkarkontroll några dagar så att du hinner förbereda dig.\n\nVänliga hälsningar,',
        no_measurements:
          'Hej! Inför din läkarkontroll behöver du mäta ditt blodtryck. Du behöver mäta ditt blodtryck efter avslappning, både på morgonen och på kvällen, helst under flera dagar. Jag skjuter på din läkarkontroll några dagar så att du hinner förbereda dig.\n\nVänliga hälsningar,',
        unanswered_questions:
          'Hej! Inför din läkarkontroll behöver du uppdatera svaren på de utvalda hälso- och sjukvårdsfrågorna. Jag skjuter på din läkarkontroll några dagar så att du hinner förbereda dig.\n\nVänliga hälsningar,'
      },
      existing_draft_notice:
        'There is an active draft in the chat. Please wait until it has been sent, or erase the text above if you only want to snooze the errand without sending a message.'
    },
    physical_examination_referral: {
      header: 'Physical examination',
      recommendation: 'The patient is recommended physical examination at:',
      aleris: 'Aleris Hälsa',
      other_clinic: 'Listed clinic',
      partner_button: 'Send physical examination instructions',
      other_clinic_button: 'Send referral and physical examination instructions',
      referred_at: 'Referred %{date}.',
      referred_partner: 'Awaiting results from partner.',
      referred_other_clinic: 'Awaiting results from clinic.',
      create_referral: 'Create referral'
    },
    summary_header: '1. Summary',
    summary_error: 'Could not fetch summary.',
    measurement_status_error: 'Could not fetch measurement info.',
    blood_pressure_summary_error: 'Could not fetch blood pressure data.',
    lab_results_error: 'Could not fetch lab results.',
    status_header: '2. Status',
    risk_profile_section_header: '3. Risk profile and treatment goals',
    risk_profile_error: 'Could not fetch risk profile.',
    risk_profile_factors_header: 'Affectable factors',
    risk_profile_factors_empty: 'There are no affectable factors.',
    show_history: 'Show history',
    hide_history: 'Hide history',
    no_history: 'No history.',
    new_patient_response: 'New patient responses',
    removed_patient_response: 'Responses removed by patient',
    modified_patient_response: 'Responses modified by patient',
    new_response: 'New response',
    previous_response: 'Previous response',
    show_full_list: 'Show complete list',
    hide_full_list: 'Hide complete list',
    no_changes_since_signature: 'No changes since previous signing.',
    signed_by: 'signed by',
    edit_row_header: 'Edit',
    doctors_comment: "Caregiver's comment",
    add_comment: 'Add comment',
    history_header: 'History',
    day_notes_header: 'Relevant day notes',
    period_header: 'Showing period',
    show_all_lab_data: 'Show all lab data',
    hide_lab_data: 'Hide lab data',
    lab_data_header: 'Lab tests',
    lab_test_type: 'Test type',
    show_lab_evaluation: 'Show evaluation',
    goal_value: 'Goal',
    recommendation: 'Recommendation',
    show_recommendation: 'Show recommendation',
    create_final_note_and_sign_report: 'Write epicrisis and sign',
    sign_report: 'Sign report',
    create_final_note: 'Write epicrisis',
    sign_report_error: 'Could not sign.',
    cannot_create_final_note: 'Cannot write epicrisis for this patient right now.',
    not_all_required_questions_answered: 'Not all mandatory anamnesis questions have been answered.',
    sign_report_success: 'Report signed!',
    goal_value_footnote: 'Goal value based on an individual SCORE judgment.',
    not_signable_header: 'Information missing:',
    sign_with_unsigned_medications_warning:
      'Warning! You are attempting to sign the decision support system despite there being unsigned medicines.',
    signing_disabled_member_source:
      'Final notes generate an invoice for Region Sörmland and are not created for members from',
    required_field_missing: {
      measure_frequency: 'Measurement frequency must be selected before signing.',
      checkup: 'A checkup must be booked.'
    },
    final_note_content: {
      treatment: 'Behandling av hypertoni',
      patient: '%{gender}, %{age} år, sysselsättning: %{occupation}',
      goal_bp: 'Målblodtryck: < %{systolic}/%{diastolic} mmHg',
      current_average_bp: 'Aktuellt medelblodtryck: %{average}',
      average_at_last_final_note: 'Medelblodtryck vid föregående slutanteckning %{date} (%{reason}): %{average}',
      heredity: 'Ärftlighet: %{heredity}',
      previous_diseases: 'Tidigare sjukdomar: %{diseases}',
      current_diseases: 'Nuvarande sjukdomar: %{diseases}',
      risk_factors: 'Aktuella påverkningsbara riskfaktorer: %{riskFactors}',
      recommended_lifestyle_changes: 'Rekommenderade livsstilsråd: %{recommendations}',
      medications: 'Nuvarande medicinering: %{medications}',
      allergies: 'Allergier: %{allergies}',
      planned_checkup: 'Planerad extra kontroll: %{date}',
      planned_checkups: 'Planerade extra kontroller: %{dates}',
      planned_three_month: 'Planerat 3-månadersbesök: %{date}',
      planned_yearly: 'Planerat årsbesök: %{date}',
      no_planned_checkup: 'Ingen bokning',
      next_nurse_checkup_header: 'Nästa sjuksköterskebesök',
      next_doctor_checkup_header: 'Nästa läkarbesök',
      assessment: 'Bedömning: %{assessment}'
    },
    body_position: {
      unknown: 'Unknown',
      lyingDown: 'Lying down',
      semiRecumbent: 'Semi recumbent',
      sitting: 'Sitting',
      standing: 'Standing'
    },
    anamnesis_updates: {
      header: 'Anamnesis changes',
      header_threeMonthCheckup: 'Updated anamnesis before three-month-checkup',
      header_yearlyCheckup: 'Updated anamnesis before yearly checkup',
      changed_answers: 'changed answers',
      changed_answer: 'changed answer',
      new_info: 'New information',
      new_answer: 'New answer',
      previous_answer: 'Previous answer',
      current_answer: 'Current answer',
      show_unchanged_answers: 'Show unchanged answers',
      hide_unchanged_answers: 'Hide unchanged answers',
      question_never_answered: 'Question has never been answered.',
      unanswered: 'Unanswered',
      unanswered_questions: 'unanswered questions',
      unanswered_question: 'unanswered question',
      no_changes_all_answered: 'All questions answered',
      show_older: 'Show older anamnesis changes',
      hide_older: 'Hide older anamnesis changes'
    },
    current_status_section: {
      measurement_type: 'Measurement type',
      latest_result: 'Last result',
      standing_measurement: 'Standing measurement',
      symptomatic_measurement: 'Symptomatic measurement',
      show_all_standing_measurements: 'Show all standing measurements',
      show_all_symptomatic_measurements: 'Show all symptomatic measurements',
      no_standing_measurements: 'No standing measurements.',
      no_symptomatic_measurements: 'No symptomatic measurements.',
      hide_standing_measurements: 'Hide standing measurements',
      hide_symptomatic_measurements: 'Hide symptomatic measurements'
    },
    risk_profile_section: {
      column_headers: {
        assessment: 'Assessment',
        based_on_answers: "Based on the member's answers",
        current_status: 'Current status',
        status_at_previous_recommendation: 'Status at last recommendation',
        previous_recommendation: 'Previous recommendation'
      },
      results_week_by_week: 'Results week by week',
      answered_at: 'Answered',
      no_risk_factor: 'Not currently a risk factor',
      minutes_per_week: 'min/week',
      high_intensity_training: 'High-intensity training',
      low_intensity_training: 'Medium-intensity training',
      members_goal: "Patient's goal",
      lose: 'Lose',
      starting_weight: 'Starting weight',
      end_weight: 'End weight',
      total_weight_loss: 'Total weight change',
      results_week_by_week_in_kg: 'Results week by week (in kg)',
      show_results_in_graph: 'Show results in a graph',
      not_updated: 'Not updated since last appointment',
      weight_label: 'Weight, kg',
      no_risk_profile: 'No risk profile available',
      no_risk_factors: 'No adjustable risk factors',
      answered: 'Answered',
      recommended: 'Recommended',
      no_reported_weight_change: 'No reported weight change.',
      blood_pressure_goals_saved: 'Blood pressure goal and measurement frequency saved.',
      blood_pressure_goals_save_error: 'Blood pressure goal and measurement frequency could not be saved.'
    },
    treatment_goals_section: {
      header: '4. Treatment goals',
      individual_goals_header: 'Individual treatment goals',
      treatment_goals: 'Treatment goals',
      results: 'Recommended goal',
      copy_goal: 'Copy goal',
      error: 'Could not fetch treatment goals.',
      empty: 'There are no treatment goals to select.',
      edit_bp_goal: 'Edit blood pressure goal',
      bp_goal_lead_text: 'Individual goal blood pressure for next checkup',
      measurement_frequency_lead_text: 'Measurement frequency',
      measurement_frequency_1: 'At least once per week',
      measurement_frequency_1_explanation: 'Values are good (<135/85), continued monitoring.',
      measurement_frequency_1_generated_response:
        'Ditt blodtryck ligger på en bra nivå. Forsätt mäta minst en gång i veckan, gärna oftare om du vill. Växla gärna mellan olika tider på dagen.',
      measurement_frequency_2: '2-3 times per week',
      measurement_frequency_2_explanation:
        'Patienten ligger något utanför målvärdet eller stor variation i blodtrycket.',
      measurement_frequency_2_generated_response:
        'Ditt blodtryck ligger en bit ifrån målblodtrycket. Fortsätt mäta 2-3 gånger i veckan, gärna oftare om du vill. Växla gärna mellan olika tider på dagen.',
      measurement_frequency_4: 'At least 4 times per week',
      measurement_frequency_4_explanation:
        'The member is outside goal values and should measure more often until next checkup.',
      measurement_frequency_4_generated_response:
        'Ditt blodtryck ligger ganska långt ifrån målblodtrycket. Jag vill att du fortsätter att mäta ditt blodtryck minst 4 gånger per vecka. Variera gärna dina mätningar mellan morgon och kväll samt mellan vardag och helgdag.',
      measurement_frequency_7: 'Daily after medicine adjustment',
      measurement_frequency_7_explanation:
        'The member should measure daily until next checkup, e.g. after medicine adjustment.',
      measurement_frequency_7_generated_response:
        'Eftersom din medicinering har justerats är det bra om du mäter ditt blodtryck dagligen närmaste tiden. Variera gärna mellan morgon och kväll, gör gärna även en mätning mitt på dagen och en mätning i stående.',
      bp_goals_header: 'Blood pressure goal and measurement frequency',
      member_accepted_challenge: 'Member accepted challenge',
      member_completed_challenge: 'Member completed challenge',
      member_not_accepted_challenge: 'Member has not accepted challenge.',
      generated_response_header: 'Generated goal for patient:',
      generated_response_info:
        'När mindre än 3 veckor återstår till en kommande läkarkontroll ombeds patienten att registrera minst en mätning på morgonen och minst en mätning på kvällen, men gärna fler mätningar för bättre behandlingsunderlag.',
      disabled: "This feature is not supported by the member's app. Inform of measurement frequency via a chat message."
    },
    notes_section: {
      next_checkup: 'Next checkup',
      next_reminder: 'Next reminder',
      upcoming_follow_up: 'Upcoming follow-up',
      last_signed: 'Last signed',
      change_follow_up_date: 'Change follow-up date',
      notes_since_last_followup_singular: 'There is %{numNotes} day note since last signing.',
      notes_since_last_followup_plural: 'There are %{numNotes} day notes since last signing.',
      note_history_header: 'Note history',
      error: 'Could not fetch status.',
      no_checkups: 'No upcoming follow-ups scheduled.',
      edit_checkup_date: 'Edit checkup date',
      edit_checkup_title: 'Edit checkup',
      edit_checkup: 'Edit reminder',
      delete_checkup_title: 'Remove follow-up',
      confirm_deletion: 'Are you sure you want to remove the follow-up?',
      save_new_checkup_date: 'Save new date',
      save_checkup: 'Save reminder',
      selected_checkup_date: 'Selected follow-up date'
    },
    medication_section: {
      header: '4. Medication treatment',
      error: 'Could not fetch current medication list.',
      empty: 'There are no current medications.',
      current_medication_header: 'Current medications',
      medication_link: 'Manage medications',
      unsigned_medication_list: 'Unsigned medication list',
      other_doctor: 'Other doctor',
      column_headers: {
        issued_by: 'Issued by',
        medication_name: 'Name',
        strength: 'Strength',
        medication_type: 'Type',
        medication_dose: 'Dosage',
        prescription: 'Prescription'
      }
    },
    checkup_section: {
      header: '5. Follow-up',
      error: 'Could not fetch reminder reasons.',
      checkup_reasons_empty: 'There are no reminder reasons.',
      choose_date: 'Choose date',
      checkup_period_two_weeks: '2 weeks',
      checkup_period_three_weeks: '3 weeks',
      checkup_period_one_month: '1 month',
      checkup_period_six_weeks: '6 weeks',
      checkup_period_three_months: '3 month',
      checkup_period_six_months: '6 months',
      checkup_period_year: 'Yearly',
      checkup_period_sub_header: 'Checkup',
      return_visit: 'Appointment',
      return_visit_approaching: "The patient's yearly appointment is approaching. The last yearly appointment was on",
      checkup_reason: 'Reason for reminder',
      epicrisis_header: 'Epicrisis',
      confirm_epicrisis_header: 'Confirm epicrisis',
      sign_button_text: 'Sign',
      book_return_visit: 'Book appointment',
      previous_bookings: 'Previous bookings',
      checkup_will_be_booked: 'Checkup will be booked',
      three_month_visit_will_be_booked: '3 month appointment will be booked',
      yearly_visit_will_be_booked: 'Yearly appointment will be booked',
      planned_checkup: 'Planned checkup',
      planned_visit: 'Planned appointment',
      reason_header: 'Reason',
      treatment_reason: 'Treatment reason',
      diagnosis_code: 'Diagnosis code',
      treatment_code: 'Treatment code',
      checkup_explanation:
        "E.g. if you've made a medication change or are waiting for more information. The patient doesn't get any feedback in the app unless you've changed the measurement frequency under",
      visit_explanation:
        'When less than 3 weeks remain until the appointment, the patient is asked to make 7 morning and evening measurements. If a yearly appointment is booked they will also be asked to take new routine tests.',
      disabled_dates_explanation: 'Note that you cannot book a checkup on the same date as an already-booked checkup.',
      same_day_warning:
        'OBS - du håller på att boka en kontroll på ett datum där det redan finns en bokning. Detta ska bara göra i särskilda fall, så som vid medicinskt brådskande ärenden. Överväg att lägga din bokning nästkommande dag om möjligt.',
      yearly_checkup_already_booked: 'A yearly appointment is already booked.',
      three_month_checkup_already_booked: 'A 3 month appointment is already booked.',
      treatment_reason_default: 'Treatment of hypertension.',
      first_visit_sub_header:
        'No appointment needs to be booked at this first sign-off - an errand about titration being complete will be created automatically.',
      num_bookings_in_week: 'appointments or checkups planned during the chosen week.',
      bookings_empty: 'No',
      search_diagnosis_code: 'Search diagnosis code on internetmedicin.se',
      opens_new_tab: 'opens in a new tab',
      icd_selection: 'Select ICD',
      checkup_timeline: {
        header: 'Checkup bookings',
        empty: 'The patient has no previous or future checkups.',
        caregiver_type: {
          doctor: 'Doctor',
          nurse: 'Nurse'
        },
        delete_header: 'Enter reason for deletion',
        exemption_card_expiration_warning: "Note: The patient's exemption card expires %{validUntil}.",
        patient_checkup_notification_warning:
          'Note: The patient has already been notified that the checkup will take place soon.',
        confirm_delete_reminder: 'Are you sure you want to delete the reminder?',
        edit_checkup: 'Edit date',
        edit_reminder: 'Edit date/reason',
        total_checkups_in_selected_week: 'Total bookings during the chosen week: %{numCheckups}',
        member_actions_requested:
          'The patient has been asked to prepare for the checkup via e-mail, push notifications, and on the app start page. An SMS is sent a week before the checkup if the patient is not preparing.'
      },
      new_checkup_booking: {
        add_booking: 'Add checkup/reminder',
        caregiver_type_header: 'For whom should the checkup be booked?',
        checkup_or_reminder: 'Is it a checkup or a reminder?',
        checkup_type_header: 'Which type of checkup?',
        reminder_header: 'What do you want to be reminded of?',
        checkup_category: {
          checkup: 'Checkup',
          reminder: 'Reminder'
        },
        patient_fee_notice: 'Note: The checkup may require a patient fee.',
        recent_payment_warning: 'Also note that the patient recently paid another patient fee.',
        close_booking_warning: 'Note that the chosen date is close to an existing checkup.'
      },
      treatment_year_overview: {
        header: 'Recommended treatment year',
        year_1: {
          header: 'Year 1',
          checkup_0: 'Nybesök <strong>sjuksköterska</strong>',
          checkup_1: 'Nybesök <strong>läkare</strong>',
          checkup_2: '2-veckorsbesök <strong>sjuksköterska</strong>',
          checkup_3: '6-veckorsbesök <strong>läkare</strong>',
          checkup_4: '3-månadersbesök <strong>läkare</strong>',
          checkup_5: 'Sjuksköterskebesök inför halvårsbesöket',
          checkup_6: '6-månadersbesök <strong>läkare</strong>',
          checkup_7: '9-månadersbesök <strong>läkare</strong>',
          checkup_8: 'Sjuksköterskebesök inför årsbesöket',
          checkup_9: 'Årsbesök <strong>läkare</strong>'
        },
        year_2: {
          header: 'Year 2',
          checkup_0: '3-månadersbesök <strong>läkare</strong>',
          checkup_1: 'Sjuksköterskebesök inför halvårsbesöket',
          checkup_2: '6-månadersbesök <strong>läkare</strong>',
          checkup_3: '9-månadersbesök <strong>läkare</strong>',
          checkup_4: 'Sjuksköterskebesök inför årsbesöket',
          checkup_5: 'Årsbesök <strong>läkare</strong>'
        }
      }
    },
    final_note: {
      role_transfer_header: 'Handover to nurse',
      role_transfer_default_option: 'Select when the patient should be handed over',
      role_transfer_delay: {
        delay_0: 'Immediately',
        delay_7: 'In 1 week',
        delay_14: 'In 2 weeks'
      },
      patient_will_be_transferred_to_nurse: 'The patient will be handed over to the nurse %{delay}.',
      patient_fee_header: 'Was this an extra checkup for medicine prescription?',
      patient_fee_not_required: 'Yes, this checkup does not require a patient fee.',
      patient_fee_required: 'No, the patient will be charged a 100 SEK patient fee.',
      patient_fee_required_confirmation_yes: 'The patient will pay 100 SEK in patient fee.',
      patient_fee_required_confirmation_no: 'The patient will not have to pay a patient fee.',
      standard_assessment_disabled_title:
        "You've made changes to the text above. Standard assessment can no longer be selected.",
      select_standard_assessment: 'Select standard assessment',
      bp_normal: 'Good blood pressure',
      bp_elevated_medication_unchanged: 'Elevated average blood pressure - medication unchanged',
      bp_elevated_medication_changed: 'Elevated average blood pressure - medication adjusted',
      other_treatment_codes: 'Other diagnosis/treatment code'
    },
    overview_section: {
      no_measurements: 'No measurements',
      no_logins: 'No logins',
      last_measurement: 'Last measurement',
      last_login: 'Last login',
      payment_exemption_header: 'Payment exemption',
      payment_exemption: '%{cardNumber} (valid until %{validUntil})',
      payment_exemption_empty: 'None',
      active_caregiver: 'Active caregiver',
      account_created: 'Account created',
      last_n_measurements_header: 'Last %{n} measurements (of %{total})',
      last_measurement_header: 'Only 1 measurement made',
      current_monitor_header: 'Blood pressure monitor',
      current_monitor_tooltip: 'Indicates which monitor was used for the last measurement.',
      patient_status: 'Patient status',
      final_notes_in_last_year_header: 'Checkups last 12 months',
      doctor_notes: '%{count} doctor checkups',
      nurse_notes: '%{count} nurse checkups'
    },
    show_detailed_graph: 'Show detailed graph',
    last_three_months_header: 'Measurements last 3 months',
    variation_systolic: 'Variation systolic',
    variation_diastolic: 'Variation diastolic',
    average_last_three_months: 'Average last 3 months',
    special_measurements_header: 'Specialmätningar',
    orthostatic_hypotension: 'Presence of orthostatic hypotension',
    no_lab_results: 'There are no lab results.',
    no_trend_data: 'No trend data available.',
    legend_systolic: 'Systolic, mmHg',
    legend_diastolic: 'Diastolic, mmHg',
    legend_heartrate: 'Puls, BPM',
    legend_systolic_goal: 'Systolic goal',
    legend_diastolic_goal: 'Diastolic goal',
    legend_weight: 'Weight',
    legend_weight_goal: 'Goal weight',
    weight_graph_header: 'Weight change',
    trend_period: {
      average: 'Average',
      measurements: 'measurements',
      number_of_measurements: 'Number of measurements',
      average_morning: 'Morning average',
      average_evening: 'Evening average',
      average_day: 'Day average',
      average_night: 'Night average',
      median: 'Median',
      heartrate: 'Heartrate',
      trend_since_previous_period: 'Trend since previous period',
      important_event: 'Important event'
    },
    severities: {
      extralow: 'Extremely low',
      verylow: 'Very low',
      low: 'Low',
      slightlylow: 'Slightly low',
      decreased: 'Decreased',
      normal: 'Normal',
      increased: 'Increased',
      slightlyhigh: 'Slightly high',
      high: 'High',
      veryhigh: 'Very high',
      extrahigh: 'Extremely high'
    },
    support: {
      header: 'Support issue',
      button: 'Create support issue',
      history_header: 'Previous support cases for this patient',
      history_error: 'Could not fetch history.',
      history_empty: 'No previous support cases.',
      history_column: {
        issue: 'Issue',
        created: 'Created',
        status: 'Status'
      },
      select_issue_type: 'Select issue type',
      status_closed: 'Closed',
      status_open: 'Open',
      comments_empty: 'No updates.',
      issue: 'Issue',
      updates: 'Updates'
    }
  },
  time: {
    chat_timestamp_format: 'YYYY-MM-DD \\a\\t HH:mm'
  },
  sidebar: {
    profile: 'Profile',
    logout: 'Log out',
    release_notes: 'Release notes',
    tutorial: 'Tutorial',
    faq_clinic: 'FAQ Clinic',
    faq_medical: 'FAQ Medical',
    faq_support: 'FAQ Patient Support',
    role: {
      caregiver: 'MD',
      nurse: 'Reg. nurse',
      healthscientist: 'Health scientist'
    },
    change_profile_picture: 'Change profile picture',
    menu_option: {
      inbox: 'Errands',
      unhandled: 'Unhandled errands',
      active_patients_list: 'Patients',
      inactive_patients_list: 'Inactive',
      referrals: 'Referrals',
      kiosk_review: 'Kiosk review',
      auscultation_assessments: 'Auscultation assessments',
      search: 'Search',
      my_patients: 'My patients'
    }
  },
  profile_image_upload: {
    header: 'Update profile picture',
    header_new: 'Select profile picture',
    header_confirm: 'Confirm profile picture',
    action: 'Save profile picture',
    select_file: 'Select file',
    drag_here: 'or drag an image file here',
    preview: 'Preview',
    app_preview_header: "What your picture will look like in your patients' chat:",
    webcam_action: 'Take photo',
    webcam_header: 'Webcam',
    or: 'or',
    take_webcam_photo: 'take a photo with your webcam',
    webcam_retry: 'Click here',
    camera_access_denied: 'You must allow Clinic access to the webcam.',
    camera_access_try_again: "when you've allowed access."
  },
  checkup: {
    reason: {
      choose_reason: 'Choose checkup reason',
      medication_adjustment: 'Medication adjustment',
      lifestyle_changes: 'Lifestyle changes',
      awaiting_more_measurements: 'Awaiting more measurements',
      awaiting_member_reply: 'Awaiting reply from patient',
      other: 'Other',
      doctor_reminder: 'Reminder'
    },
    checkup_date: 'Checkup date',
    no_checkup_date: 'No date selected',
    checkup: 'Checkup',
    checkup_booked: 'Checkup booked',
    save_checkup: 'Save checkup'
  },
  release_notes: {
    header: 'New version released',
    general_info: "A software update has been made since you last logged in. Click the link below to see what's new.",
    link_text: 'Release notes'
  },
  canned_response: {
    button: 'Canned response',
    back: 'Back',
    free_text_alert: 'You must fill out %{tag} before the message can be sent.',
    search_placeholder: 'Search for response',
    no_search_results: 'No canned responses matched the search term.'
  },
  maintenance_mode: {
    banner: 'The system will enter maintenance mode in %{remainingTime}. Finish any unsaved work.',
    deploy_in_progress_header: 'Maintenance in progress',
    deploy_in_progress_body:
      "This dialog will close automatically when the maintenance is complete. After that you'll be able to resume working.",
    maintenance_warning_header: 'Upcoming maintenance',
    maintenance_warning_body:
      'The system will soon be put into maintenance mode. During this time you will not be able to work in Clinic so finish any ongoing work now.',
    maintenance_complete_header: 'Maintenance completed',
    maintenance_complete_body: 'You can now resume working in Clinic.'
  },
  session: {
    token_expiration_warning_header: 'Your session is about to expire',
    token_expiration_warning_body: 'You will soon be logged out. Close this dialog to remain logged in.'
  },
  patient_communication_view: {
    header: 'Patient communication',
    error: 'Could not fetch patient communication.',
    empty: 'No patient communication.',
    empty_content: 'Content was empty.',
    modal_action: 'OK',
    modal_header: 'Content',
    view_content: 'Show content',
    column_header: {
      date: 'Date',
      description: 'Description',
      type: 'Contact type',
      content: 'Content'
    },
    communication_type: {
      chat: 'Chat',
      email: 'Email',
      jira: 'Jira',
      push: 'Push',
      sms: 'SMS'
    }
  },
  payments_view: {
    header: 'Patient fee history',
    error: 'Could not fetch patient fee history.',
    empty: 'No payment history.',
    column_header: {
      checkup_type: 'Checkup type',
      payment_status: 'Payment status',
      date: 'Date'
    }
  },
  program_timeline_view: {
    header: 'Timeline',
    get_programs_error: 'Could not fetch programs',
    no_active_program: 'Patient has no ongoing programs',
    program: 'Program',
    step: {
      completed: 'Completed',
      not_started: 'Not started',
      ongoing: 'Ongoing',
      started: 'Started'
    }
  },
  kiosk_assessment: {
    page_title: 'Kiosk patient review',
    fetch_assessment_count_error: 'Could not fetch assessments.',
    fetch_assessment_error: 'Could not fetch the assessment. Try again or contact support.',
    leave_unhandled_warning: 'The assessment has not been handled. You\'ll find it under "%{section}".',
    open_dss: 'Open in decision support',
    summary: {
      header: 'Kiosk assessments from partners',
      waiting: 'new patients awaiting assessment',
      empty: 'No assessments to review right now.',
      start_assessment: 'Start review',
      start_error: 'Could not start review.'
    },
    assessment_list: {
      caregiver_paused: {
        header: 'My paused assessments',
        header_all: 'All paused assessments',
        empty: 'No paused assessments',
        error: 'Could not fetch paused assessments'
      },
      caregiver_rejected: {
        header: 'My rejected assessments',
        header_all: 'All rejected assessments',
        empty: 'No rejected assessments',
        error: 'Could not fetch rejected assessments'
      },
      completed: {
        header: 'My approved assessments',
        header_all: 'All approved assessments',
        empty: 'No approved assessments',
        error: 'Could not fetch approved assessments'
      },
      assessment_in_progress: {
        header: 'My ongoing assessments',
        empty: 'No ongoing assessments',
        error: 'Could not fetch ongoing assessments'
      }
    },
    actions: {
      cancel_assessment: 'Release patient and go back',
      cancel_assessment_paused: 'Let someone else handle this assessment',
      write_final_note: 'Write final note and go back',
      write_final_note_and_get_next: 'Write final note and review next',
      pause_assessment: 'Pause this review',
      reject_assessment: 'Assessment incorrect',
      error: {
        cancel: 'Could not cancel the assessment.',
        pause: 'Could not pause the assessment.',
        reject: 'Could not reject the assessment.',
        write_final_note: 'Could not write final note. Try again or contact support.',
        get_next: 'Could not get the next assessment.'
      },
      success: {
        pause: 'The assessment was paused.',
        reject: 'The assessment was rejected.'
      }
    },
    all_assessments_completed: 'All patients reviewed! Returning to start page...',
    assessment_meta:
      'This measurement session was performed at <strong>%{location}</strong> on <strong>%{date}</strong>.',
    assessment_language: 'Anamnesis completed in:',
    unknown_location: 'unknown pharmacy',
    anamnesis_summary: 'Anamnesis summary',
    medical_assessment: 'Medical assessment',
    recommendation: 'Recommendation',
    kiosk_reason: 'Kiosk measurement',
    status: {
      completed: 'Reviewed by %{name}',
      caregiver_paused: 'Paused by %{name}',
      caregiver_rejected: 'Rejected by %{name}',
      assessment_in_progress: 'Under review by %{name}'
    },
    btd_invite_type: {
      diagnosed_onboarding: 'Patient received information regarding starting treatment at Blodtrycksdoktorn',
      excluded: 'Patient did not receive an invitation to Blodtrycksdoktorn',
      existing_patient: 'Existing patient at Blodtrycksdoktorn',
      undiagnosed_onboarding:
        'Patient received information regarding starting hypertension investigation at Blodtrycksdoktorn'
    },
    non_nurse_info: 'Only nurses can handle kiosk assessments'
  },
  auscultation_assessment: {
    page_title: 'Auscultations assessments',
    assessment_header: 'Recordings to assess',
    list_header: 'Pending auscultation assessments',
    column: {
      facility: 'Partner facility',
      received: 'Submitted'
    },
    error: {
      list_assessments: 'Could not fetch pending assessments.'
    },
    no_assessments: 'No pending assessments',
    status: {
      accepted: 'Test recording approved',
      rejected: 'Test recording rejected'
    },
    rejection_modal: {
      header: 'Reject test recording',
      action: 'Reject',
      comment_header: 'Why is the test recording getting rejected?'
    },
    accept_modal: {
      header: 'Approve test recording',
      action: 'Approve',
      comment_header: 'Optional feedback to the partner user'
    }
  },
  journal: {
    caregiver_role: {
      doctor: 'Läkare',
      nurse: 'Sjuksköterska',
      dietician: 'Dietist',
      support: 'Support'
    },
    treatment_area: {
      hypertension: 'Hypertoni',
      obesity: 'Obesitas'
    },
    treatment_area_state: {
      investigation: 'Utredning',
      treatment: 'Behandling',
      titration: 'Titrering',
      not_started: 'Inte påbörjad',
      aborting: 'Begärt avslut',
      aborted: 'Avslutad',
      lifestyle_only: 'Endast livsstilsbehandling'
    },
    temporary_caregiver: {
      notice: 'Du är tillfällig vårdgivare för denna patient under perioden %{from} – %{to}',
      introduction_reminder:
        'OBS! Detta är ditt första möte med patienten. Kom ihåg att presentera dig i början av meddelandet.'
    },
    migration_status: {
      notice: 'Patienten har migrerats från tidigare version av Clinic %{from}'
    },
    treatment_panel: {
      message_type: {
        doctor: 'PAL-meddelande',
        nurse: 'PAS-meddelande',
        patient: 'Patientmeddelande'
      },
      program_state: {
        paused: 'Pausat',
        aborted: 'Avbrutet',
        ongoing: 'Pågående'
      },
      program_state_reason: {
        preparations_not_done: 'Förberedelser ej genomförda i tid',
        optional_preparations_not_done: 'Valfria förberedelser ej genomförda',
        other: 'Orsak ej specificerad',
        aborted_by_patient: 'Avbrutet av patient',
        aborted_by_admin: 'Avbrutet av support',
        aborted_by_caregiver: 'Avbrutet av vårdgivare'
      },
      preparation_reasons: {
        other: 'Annat',
        no_time: 'Har inte tid',
        no_available_time: 'Finns inga tider',
        too_long_distance: 'För långt avstånd',
        cannot_leave_home: 'Har ej möjlighet att ta mig hemifrån',
        not_relevant: 'Ej relevant',
        no_comment: 'Ingen kommentar',
        recently_done: 'Nyligen genomförd',
        more_time: 'Behöver med tid',
        preparations_not_done: 'Förberedelser ej genomförda'
      }
    },
    assessment_status: {
      unknown: 'Okänd',
      verified: 'Signerad',
      ready_for_verification: 'Osignerad',
      findings_available: 'Utredning avslutad',
      investigation_ongoing: 'Utredning pågår',
      signed: 'Signerad',
      rejected: 'Avvisad',
      waiting_for_conclusion_from_caregiver: 'Inväntar bedömning'
    },
    appointment: {
      edit_header: 'Redigera kontroll',
      cancel: 'Avboka',
      save_new_date: 'Boka om',
      state: {
        new: 'Redo att starta',
        ongoing_waiting_for_patient: 'Pågående',
        ongoing_waiting_for_caregiver: 'Pågående',
        signed: 'Signerad',
        cancelled: 'Avbruten'
      }
    },
    work_item_type: {
      appointment: 'Kontroll',
      errand: 'Ärende',
      monitoring: 'Bevakning',
      program: 'Uppföljning'
    },
    patient_feedback: {
      header: 'Patientåterkoppling',
      send: 'Skicka',
      regenerate: 'Generera om 🤖',
      cancel: 'Avbryt',
      placeholder: 'Skriv din text här',
      is_generating: ' genereras...',
      error: 'Kunde inte generera patientåterkoppling.'
    },
    careplan_modal: {
      header: 'Välj behandlingsplan',
      save: 'Starta behandlingsplan'
    },
    recommended_actions: {
      modal_header: 'Lägg till åtgärder',
      modal_button: 'Spara',
      error: 'Kunde inte hämta rekommenderade åtgärder.'
    },
    encounter_summary: {
      error: 'Kunde inte hämta summering av vårdinsats.'
    },
    medical_record_category: {
      current_diseases: 'Aktuella sjukdomar',
      previous_diseases: 'Tidigare sjukdomar',
      allergies: 'Allergier',
      medicines: 'Mediciner',
      other_medical_information: 'Övrig läkemedelsinformation',
      nurse_notes: 'PAS-anteckningar',
      patient_anamnesis: 'Patientanamnes'
    },
    data_type: {
      frequency: {
        very_often: 'Mycket ofta',
        often: 'Ofta',
        sometimes: 'Ibland',
        rarely: 'Sällan',
        never: 'Aldrig',
        daily: 'Dagligen',
        monthly: 'Månatligen',
        yearly: 'Årligen',
        weekly: 'Varje vecka'
      }
    },
    assessment: {
      smoking_habits: {
        occasional_smoker: 'Röker då och då',
        smokes_cigarettes_daily: 'Röker cigaretter dagligen',
        smokes_cigarettes_daily_above_10: 'Röker cigaretter dagligen (fler än 10)',
        smokes_cigarettes_daily_above_20: 'Röker cigaretter dagligen (fler än 20)',
        smokes_hookah_daily: 'Röker vattenpipa dagligen',
        smokes_hookah_daily_30_min: 'Röker vattenpipa dagligen (30 min.)',
        smokes_hookah_daily_1_hour: 'Röker vattenpipa dagligen (1 timme)',
        smokes_cigars_daily_one: 'Röker 1 cigarr dagligen',
        smokes_cigars_daily_two: 'Röker 2 cigarrer dagligen',
        smokes_cigars_daily_above_two: 'Röker fler än 2 cigarrer dagligen',
        smokes_pipes_daily: 'Röker pipa dagligen',
        smokes_pipes_daily_above_5: 'Röker pipa dagligen (fler än 5)',
        smokes_pipes_daily_above_10: 'Röker pipa dagligen (fler än 10)',
        former_smoker: 'Före detta rökare',
        smoker: 'Rökare',
        non_smoker: 'Ickerökare'
      },
      snuff_consumption: {
        snuff_weekly_above_1: '1 – 3 dosor/vecka',
        snuff_weekly_above_3: '4 – 6 dosor/vecka',
        snuff_weekly_above_6: '7 eller fler dosor/vecka',
        228492003: 'Har aldrig varit snusare',
        228503001: 'Före detta snusare',
        449867007: 'Snusar, men inte dagligen',
        228504007: 'Snusar dagligen'
      }
    },
    state_reason: {
      new_message: 'Meddelande',
      appointment_feedback_timed_out: 'Uteblivet svar på återkoppling',
      appointment_feedback_acknowledged: 'Godkänt återkoppling',
      appointment_feedback_questioned: 'Frågor på återkoppling',
      conversation_timed_out: 'Uteblivet svar',
      send_appointment_feedback: 'Skicka återkoppling',
      waiting_for_chat_message: 'Väntar på meddelande',
      waiting_for_response_appointment_feedback: 'Väntar på återkoppling'
    },
    timeline: {
      no_results_generic: 'Ingen historik.',
      no_results_errand: 'Ingen historik för ärenden.',
      no_results_appointment: 'Ingen historik för kontroller.',
      no_results_journal_signing: 'Övrig historik saknas.'
    },
    care_team: {
      responsible_doctor: 'PAL',
      responsible_nurse: 'PAS',
      temporary_doctor: 'Tillfällig PAL',
      temporary_nurse: 'Tillfällig PAS'
    }
  },
  my_patients: {
    upcoming: {
      ongoing: 'Förberedelser pågår',
      paused: 'Programmet pausat, inväntar patient'
    }
  },
  error_boundary: {
    header: 'Something went wrong :(',
    summary: 'View technical details'
  },
  server_error: {
    db_access_error: 'Database access error',
    internal: 'Internal server error',
    unknown_user: 'Unknown user',
    insufficient_privileges: 'Insufficient privileges',
    authentication_error: 'Authentication error',
    user_canceled_login: 'User canceled login',
    login_already_in_process: 'Login already in progress',
    external_service_timeout: 'External service timeout',
    not_implemented: 'Not implemented',
    invalid_data_posted: 'Invalid data was posted',
    missing_data: 'Missing data',
    id_already_exists: 'ID already exists',
    fallback: 'Unknown error - contact technical support',
    connectivity: 'Could not reach backend server'
  },
  network_error: {
    400: 'Bad request',
    401: 'Unauthorized',
    404: 'Resource not found',
    405: 'Method not allowed',
    409: 'Conflict',
    500: 'Internal server error',
    502: 'Bad gateway',
    503: 'Service not available'
  }
};
