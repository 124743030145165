import React from 'react';
import moment from 'moment';
import { sv } from '../../i18n/sv';
import { en } from '../../i18n/en';
import { DEFAULT_LAB_RESULT_COLUMN_WIDTH } from '../../constants';
import CustomLabResultValue from '../../components/DecisionSupportView/LabResultsDataTable/CustomLabResultValue';
import CustomLabResultType from '../../components/DecisionSupportView/LabResultsDataTable/CustomLabResultType';
import CustomGoalValue from '../../components/DecisionSupportView/LabResultsDataTable/CustomGoalValue';

export const transformLabResultsData = (labResults, locale) => {
  let transformed = {};
  let dates = [];
  let fixedColumns = [];
  let labResultColumns = [];
  let transformedResults = [...labResults];

  fixedColumns.push({
    Header: (locale === 'sv' ? sv : en).decision_support_view.lab_test_type,
    accessor: 'name',
    Cell: (props) => CustomLabResultType(props),
    width: 200,
    sortable: true,
    resizable: false
  });

  fixedColumns.push({
    Header: (locale === 'sv' ? sv : en).decision_support_view.goal_value,
    accessor: 'goalValue',
    Cell: (props) => CustomGoalValue(props),
    width: 130,
    sortable: false,
    resizable: false
  });

  transformed.fixedColumns = fixedColumns;

  labResults.forEach((result) => {
    if (result.values.length) {
      result.values.forEach((value) => {
        let date = moment(value.timestamp).format('YYYY-MM-DD');
        if (dates.indexOf(date) === -1) {
          dates.push(date);
        }
      });
    }
  });

  dates.sort((a, b) => {
    if (a === b) return 0;

    if (a > b) return -1;

    return 1;
  });

  dates.forEach((date) => {
    let accessor = `${moment(date).format('YYYYMMDD')}`;
    labResultColumns.push({
      Header: date,
      accessor: `results.${accessor}.value`,
      Cell: (props) => <CustomLabResultValue original={props.original} value={props.value} accessor={accessor} />,
      maxWidth: DEFAULT_LAB_RESULT_COLUMN_WIDTH,
      sortable: false,
      resizable: false
    });
  });

  transformed.labResultColumns = labResultColumns;

  transformedResults.forEach((result) => {
    result.results = {};
    dates.forEach((date) => {
      let formattedDate = moment(date).format('YYYYMMDD');
      result.results[formattedDate] = { value: '-' };

      if (result.values.length) {
        result.values.forEach((value) => {
          let valueTimestamp = moment(value.timestamp).format('YYYYMMDD');
          result.results[valueTimestamp] = value;
        });
      }
    });
  });

  transformed.labResults = transformedResults;

  return transformed;
};
