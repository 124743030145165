import React, { Component, Fragment } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import LoadingSpinner from '../../../LoadingSpinner';
import moment from 'moment';
import './MeasurementTrendsControl.scss';

class MeasurementTrendsControl extends Component {
  render() {
    const {
      trendData,
      isLoading,
      getPreviousPeriods,
      getNextPeriods,
      currentPeriods,
      lastPeriod,
      periodSize,
      setPeriod
    } = this.props;

    return (
      <Fragment>
        {isLoading && !trendData ? (
          <div className="section-loader">
            <LoadingSpinner size="small" />
          </div>
        ) : (
          <div className="columns">
            <div className="column is-2 no-padding">
              <span
                onClick={getPreviousPeriods}
                className={`current-period-control-arrow left ${
                  currentPeriods && currentPeriods[0] > 0 ? 'enabled' : 'disabled'
                }`}
              >
                &lsaquo;
              </span>
              <span
                onClick={getNextPeriods}
                className={`current-period-control-arrow right ${
                  currentPeriods && lastPeriod !== undefined && currentPeriods[currentPeriods.length - 1] === lastPeriod
                    ? 'disabled'
                    : 'enabled'
                }`}
              >
                &rsaquo;
              </span>
            </div>
            <div className="column is-3 no-padding measurement-trends-current-period">
              <div className="current-period-header">
                <Translate value="decision_support_view.period_header" />
              </div>
              <div>
                {trendData && trendData.length
                  ? `${moment(trendData[0].startTimestamp).format('YYYY-MM-DD')} ${I18n.t('global.to')} ${moment(
                      trendData[trendData.length - 1].endTimestamp
                    ).format('YYYY-MM-DD')}`
                  : null}
              </div>
            </div>
            <div className="column no-padding right-align-text">
              <div
                onClick={setPeriod(90)}
                className={`measurement-trends-control-period-size left center-align ${
                  periodSize === 90 ? 'selected' : ''
                }`}
              >
                <Translate className="center-align" value="global.ninety_days" />
              </div>
              <div
                onClick={setPeriod(30)}
                className={`measurement-trends-control-period-size center-align ${periodSize === 30 ? 'selected' : ''}`}
              >
                <Translate className="center-align" value="global.month" />
              </div>
              <div
                onClick={setPeriod(14)}
                className={`measurement-trends-control-period-size center-align ${periodSize === 14 ? 'selected' : ''}`}
              >
                <Translate className="center-align" value="global.two_weeks" />
              </div>
              <div
                onClick={setPeriod(1)}
                className={`measurement-trends-control-period-size right center-align ${
                  periodSize === 1 ? 'selected' : ''
                }`}
              >
                <Translate className="center-align" value="global.day" />
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default MeasurementTrendsControl;
