import moment from 'moment';
import React, { useState, Fragment } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import api from '../../api/apiClient';
import MemberEventRow from './MemberEventRow';
import { removeItemFromArray } from '../../utils';
import { handleEventSuccess, showNotification, snoozeEvent } from '../../actions';
import { DATE_FORMAT, INBOX_VISITED_PATIENTS_KEY } from '../../constants';
import { saveState, loadState } from '../../services/localStorage';
import './MemberEventList.scss';

const MemberEventList = ({
  open,
  active,
  history,
  member,
  authToken,
  handleEventSuccess,
  dateTimeFormat,
  onClickOutside,
  onAnamnesisLink,
  url,
  handledEventIds,
  showNotification,
  snoozeEvent,
  hasWrittenFinalNote
}) => {
  const [handlingEventIds, setHandlingEventIds] = useState([]);
  const eventTypesRequiringFinalNote = [
    'checkup',
    'threeMonthCheckup',
    'yearlyCheckup',
    'nurseCheckup',
    'nurseNewMember',
    'nurseTwoWeek',
    'nurseSixMonth',
    'nurseYearly',
    'nurseExtra'
  ];

  const sortByDate = (a, b) => {
    if (a.timestamp === b.timestamp) {
      return 0;
    }

    if (a.timestamp > b.timestamp) {
      return -1;
    }

    return 1;
  };

  const handleEvent = (event) => {
    setHandlingEventIds([...handlingEventIds, event.id]);

    api
      .handleEvent(authToken, event.id, {
        timestamp: event.timestamp,
        eventType: event.eventType,
        handledTimestamp: moment().format(DATE_FORMAT)
      })
      .then((response) => {
        handleEventSuccess(response);
        setHandlingEventIds(
          removeItemFromArray(handlingEventIds, {
            index: handlingEventIds.indexOf(event.id)
          })
        );

        const storedEvents = loadState(INBOX_VISITED_PATIENTS_KEY, true) || {};
        let memberEventsIds = storedEvents[member.guid];
        if (memberEventsIds) {
          const eventIndex = memberEventsIds.findIndex((eventId) => eventId === event.id);
          memberEventsIds = removeItemFromArray(memberEventsIds, { index: eventIndex });
          // Set to undefined if no events remain. This causes the key to be removed when the object is serialized in saveState.
          storedEvents[member.guid] = memberEventsIds.length ? memberEventsIds : undefined;
          saveState(storedEvents, INBOX_VISITED_PATIENTS_KEY);
        }
      })
      .catch((error) => {
        console.log(error);
        showNotification(I18n.t('patient_view.events.handle_event_error'), 'error');
        setHandlingEventIds(
          removeItemFromArray(handlingEventIds, {
            index: handlingEventIds.indexOf(event.id)
          })
        );
      });
  };

  const markEventHandled = (event) => {
    return () => {
      if (eventTypesRequiringFinalNote.includes(event.eventType) && !hasWrittenFinalNote) {
        if (window.confirm(I18n.t('patient_view.events.confirm_handle_without_final_note'))) {
          handleEvent(event);
        }
      } else if (event.snoozedUntilDate && moment(event.snoozedUntilDate).isAfter(moment())) {
        if (
          window.confirm(I18n.t('patient_view.events.confirm_handle_snoozed_errand', { date: event.snoozedUntilDate }))
        ) {
          handleEvent(event);
        }
      } else {
        handleEvent(event);
      }
    };
  };

  const snooze = (event) => {
    return (snoozeDuration, unit) => {
      snoozeEvent(authToken, event.id, {
        timestamp: event.timestamp,
        eventType: event.eventType,
        snoozedUntilDate: moment().add(snoozeDuration, unit).format('YYYY-MM-DD')
      });
    };
  };

  return (
    <Fragment>
      <div className={`events-dropdown-overlay ${!open ? 'hidden' : ''}`} onClick={onClickOutside}></div>
      <div className={`events-dropdown-container ${!open ? 'hidden' : ''}`}>
        <div className="active-events">
          <h4>
            <Translate value="patient_view.events.active_events_header" />
          </h4>
          {active.length ? (
            active.map((event, i) => {
              return (
                <MemberEventRow
                  event={event}
                  key={i}
                  dateTimeFormat={dateTimeFormat}
                  doneHandler={markEventHandled(event)}
                  handled={handledEventIds.indexOf(event.id) > -1}
                  beingHandled={handlingEventIds.indexOf(event.id) > -1}
                  url={url}
                  onAnamnesisLink={onAnamnesisLink}
                  onSnooze={snooze(event)}
                />
              );
            })
          ) : (
            <div className="no-events">
              <Translate value="patient_view.events.no_active_events" />
            </div>
          )}
        </div>
        <div className="handled-events">
          <h4>
            <Translate value="patient_view.events.handled_events_header" />
          </h4>
          {history.length ? (
            history.sort(sortByDate).map((event, i) => {
              return (
                <MemberEventRow event={event} key={i} dateTimeFormat={dateTimeFormat} historical={true} url={url} />
              );
            })
          ) : (
            <div className="no-events">
              <Translate value="patient_view.events.no_handled_events" />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    member: state.currentMember,
    hasWrittenFinalNote: state.decisionSupport.hasWrittenFinalNote
  };
};

const mapActionsToProps = {
  handleEventSuccess,
  showNotification,
  snoozeEvent
};

export default connect(mapStateToProps, mapActionsToProps)(MemberEventList);
