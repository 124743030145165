import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { setRemainingKioskAssessmentCount } from '../../../actions';
import api from '../../../api/apiClient';
import { decamelize, getAgeFromPersonalNumber } from '../../../utils';
import WaitTime from '../../WaitTime';
import AssessmentRowHeadings from './AssessmentRowHeadings';
import SkeletonComponent from '../../SkeletonComponent';
import { DATE_FORMAT, HTTP_STATUS_CODES } from '../../../constants';
import ErrorMessage from '../../ErrorMessage';

const AssessmentList = ({ authToken, user, status, onRowClick, allAssessmentsToggle = true }) => {
  const [assessments, setAssessments] = useState([]);
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [showAll, setShowAll] = useState(false);
  const ROWS_PER_PAGE = 5;

  useEffect(() => {
    setIsLoading(true);
    api
      .getKioskAssessments(
        authToken,
        status,
        !showAll ? user.guid : undefined,
        ROWS_PER_PAGE * currentPage,
        ROWS_PER_PAGE
      )
      .then((response) => {
        setAssessments(response.assessments);
        setPagination(response.pagination);
        setError(undefined);
      })
      .catch((err) => {
        if (err?.status === HTTP_STATUS_CODES.NOT_FOUND) {
          setAssessments([]);
          setPagination({ totalNumberOfItems: 0 });
        } else {
          setError(err);
        }
      })
      .finally(() => setIsLoading(false));
  }, [currentPage, showAll]);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
    setCurrentPage(0);
  };

  return (
    <>
      <header className="mt-100 flex space-between align-center">
        <h1>
          <Translate value={`kiosk_assessment.assessment_list.${decamelize(status)}.header${showAll ? '_all' : ''}`} />
        </h1>
        {allAssessmentsToggle ? (
          <div>
            <input type="checkbox" id={`show-all-checkbox-${status}`} checked={showAll} onChange={toggleShowAll} />
            <label htmlFor={`show-all-checkbox-${status}`} className="mukta ml-5 fs-14">
              <Translate value="global.show_all" />
            </label>
          </div>
        ) : null}
      </header>
      <div className="member-row-container">
        <AssessmentRowHeadings />
        <div className="errand-row-container mb-20">
          {assessments.length && !isLoading ? (
            assessments.map((assessment) => (
              <div className="member-row" key={assessment.sessionId} onClick={() => onRowClick(assessment.sessionId)}>
                <div className="columns errand-item">
                  <div className="column">
                    <strong data-sensitive="true">{`${assessment.givenName} ${assessment.familyName}`}</strong>,{' '}
                    {getAgeFromPersonalNumber(assessment.personalNumber)}{' '}
                    <span>
                      <Translate value="global.years" />
                    </span>
                  </div>
                  <div className="column errand-item__wait-time">
                    {`${moment(assessment.lastModified, DATE_FORMAT).format('YYYY-MM-DD HH:mm')}`} (
                    <WaitTime sinceTimestamp={assessment.lastModified} withSuffix />)
                  </div>
                  <div className="column mukta">
                    <span>{assessment.kioskLocation}</span>
                  </div>
                  <div className="column mukta">
                    <Translate value="kiosk_assessment.kiosk_reason" />
                  </div>
                </div>
              </div>
            ))
          ) : isLoading ? (
            <>
              {new Array(assessments.length || 3).fill(0).map((_, i) => (
                <SkeletonComponent key={i} width="100%" height="70px" margin="8px" />
              ))}
            </>
          ) : error ? (
            <ErrorMessage error={error} errorI18nKey={`kiosk_assessment.assessment_list.${decamelize(status)}.error`} />
          ) : (
            <div className="no-table-rows">
              <Translate value={`kiosk_assessment.assessment_list.${decamelize(status)}.empty`} />
            </div>
          )}
        </div>
        {pagination?.totalNumberOfItems > ROWS_PER_PAGE ? (
          <div className="pagination-wrapper">
            <ReactPaginate
              previousLabel={I18n.t('global.previous')}
              nextLabel={I18n.t('global.next')}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={Math.ceil(pagination.totalNumberOfItems / ROWS_PER_PAGE)}
              initialPage={currentPage}
              forcePage={currentPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={7}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    user: state.auth.token.user
  };
};

const mapActionsToProps = {
  setRemainingKioskAssessmentCount
};

export default connect(mapStateToProps, mapActionsToProps)(AssessmentList);
