import React, { Component, Fragment } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { DebounceInput } from 'react-debounce-input';
import './MedicationModalContent.scss';

class MedicationModalContent extends Component {
  render() {
    const {
      isSavingMedicationEdit,
      medication,
      updateMedicationProperty,
      headerI18nKey,
      isScanned,
      editingDisabled,
      onEnterMedicationSearchFlow,
      varaImportCompleted,
      showMedicationNameNotice,
      varaImportBypassed
    } = this.props;

    const currentMedication = (medication && medication.current) || {};

    return (
      <Fragment>
        <div className="medication-modal-content">
          <h3>
            <Translate value={headerI18nKey} />
          </h3>
          <div className="columns">
            <div className="column no-padding">
              <h4>
                <Translate value="patient_view.medications.medication_modal.medication_name" />
              </h4>
              <DebounceInput
                type="text"
                maxLength="100"
                disabled={showMedicationNameNotice ? false : isSavingMedicationEdit || editingDisabled}
                value={currentMedication.name || ''}
                debounceTimeout={300}
                onChange={(e) => {
                  updateMedicationProperty('name', e);
                }}
              />
              {showMedicationNameNotice && (
                <div className="mt-10 notice">
                  <Translate value="patient_view.medications.medication_modal.name_notice" />
                </div>
              )}
            </div>
          </div>

          <div className="columns">
            <div className="column no-padding pad-right">
              <h4>
                <Translate value="patient_view.medications.medication_modal.medication_type" />
              </h4>
              <DebounceInput
                type="text"
                maxLength="100"
                disabled={isSavingMedicationEdit || editingDisabled}
                value={currentMedication.form || ''}
                debounceTimeout={300}
                onChange={(e) => {
                  updateMedicationProperty('form', e);
                }}
              />
            </div>
            <div className="column no-padding">
              <h4>
                <Translate value="patient_view.medications.medication_modal.medication_strength" />
              </h4>
              <DebounceInput
                type="text"
                maxLength="100"
                disabled={isSavingMedicationEdit || editingDisabled}
                value={currentMedication.strength || ''}
                debounceTimeout={300}
                onChange={(e) => {
                  updateMedicationProperty('strength', e);
                }}
              />
            </div>
          </div>

          <div className="columns">
            <div className="column no-padding pad-right">
              <h4>
                <Translate value="patient_view.medications.medication_modal.medication_npl_pack_id" />
              </h4>
              <input type="text" maxLength="100" disabled={true} value={currentMedication.nplPackId || ''} />
            </div>
            <div className="column no-padding">
              <h4>
                <Translate value="patient_view.medications.medication_modal.medication_atc_code" />
              </h4>
              <DebounceInput
                type="text"
                maxLength="100"
                disabled={!varaImportBypassed}
                value={currentMedication.atcCode || ''}
                debounceTimeout={300}
                onChange={(e) => {
                  updateMedicationProperty('atcCode', e);
                }}
              />
            </div>
          </div>

          {!isScanned && !varaImportCompleted && !varaImportBypassed ? (
            <div className="info-missing">
              <div className="columns">
                <div className="column is-7 vertical-align no-padding">
                  <Translate value="patient_view.medications.medication_modal.nplpackid_atc_code_missing" />
                </div>
                <div className="column right-align no-padding">
                  <button
                    className="button is-primary"
                    disabled={!currentMedication.name || !currentMedication.form}
                    title={
                      !currentMedication.name || !currentMedication.form
                        ? I18n.t('patient_view.medications.medication_modal.vara_disabled_reason')
                        : ''
                    }
                    onClick={onEnterMedicationSearchFlow}
                  >
                    <Translate value="patient_view.medications.medication_modal.enter_vara_search" />
                  </button>
                </div>
              </div>
            </div>
          ) : null}

          <div className="columns">
            <div className="column no-padding">
              <h4>
                <Translate value="patient_view.medications.medication_modal.medication_dose" />
              </h4>
              <DebounceInput
                maxLength={254}
                value={currentMedication.dosageRegimen || ''}
                debounceTimeout={300}
                onChange={(e) => {
                  updateMedicationProperty('dosageRegimen', e);
                }}
              />
            </div>
          </div>

          <div className="columns">
            <div className="column no-padding">
              <h4>
                <Translate value="patient_view.medications.medication_modal.medication_reason" />
              </h4>
              <DebounceInput
                element="textarea"
                maxLength="2000"
                value={currentMedication.reason || ''}
                debounceTimeout={300}
                disabled={isSavingMedicationEdit}
                onChange={(e) => {
                  updateMedicationProperty('reason', e);
                }}
              />
            </div>
          </div>

          <div className="columns">
            <div className="column no-padding">
              <input
                type="checkbox"
                className="styled-checkbox"
                id="medication-when-needed"
                checked={currentMedication.whenNeeded || false}
                disabled={isSavingMedicationEdit}
                onChange={(e) => {
                  updateMedicationProperty('whenNeeded', e);
                }}
              />
              <label htmlFor="medication-when-needed">
                <Translate value="patient_view.medications.medication_modal.medication_when_needed" />
              </label>
            </div>
          </div>

          {varaImportCompleted && !currentMedication.dosageRegimen && !currentMedication.whenNeeded ? (
            <div className="info-missing">
              <Translate value="patient_view.medications.medication_modal.dosage_regimen_or_when_needed_missing" />
            </div>
          ) : null}
        </div>
      </Fragment>
    );
  }
}

export default MedicationModalContent;
