import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { Translate, I18n } from 'react-redux-i18n';
import AttributeResponse from '../../../../AttributeResponse';

class ComplexHistory extends Component {
  render() {
    const { history } = this.props;

    if (!history) return null;

    if (history.length === 0) {
      return (
        <div>
          <Translate value="decision_support_view.no_history" />
        </div>
      );
    }

    return (
      history &&
      history.map((item, i) => {
        return <HistoryItemRow item={item} key={i} />;
      })
    );
  }
}

export default ComplexHistory;

class HistoryItemRow extends Component {
  constructor(props) {
    super(props);

    this.toggleExpandItems = this.toggleExpandItems.bind(this);

    this.state = {
      itemsExpanded: false,
      showToggle:
        (this.props.item.added.length && this.props.item.removed.length && this.props.item.modified.length) ||
        (this.props.item.added.length && this.props.item.removed.length) ||
        (this.props.item.removed.length && this.props.item.modified.length) ||
        (this.props.item.added.length && this.props.item.modified.length)
    };
  }

  toggleExpandItems() {
    this.setState({
      itemsExpanded: !this.state.itemsExpanded,
      showToggle: false
    });
  }

  render() {
    const { item } = this.props;

    return (
      <div className="history-row">
        <div className="history-timestamp">
          <span>{item.signedTimestamp ? moment(item.signedTimestamp).format('YYYY-MM-DD HH:mm') : null}</span>
          <span>
            {item.signedBy
              ? `, ${I18n.t('decision_support_view.signed_by')} ${item.signedBy.givenName} ${item.signedBy.familyName}`
              : null}
          </span>
        </div>
        <Fragment>
          {item.added &&
          item.removed &&
          item.modified &&
          !item.added.length &&
          !item.removed.length &&
          !item.modified.length ? (
            item.valueHistory && item.valueHistory.length ? (
              item.valueHistory.map((value, i) => {
                return (
                  <div className="history-values" key={i}>
                    <AttributeResponse value={value} showTimestamp={true} />
                  </div>
                );
              })
            ) : (
              <Translate value="decision_support_view.no_changes_since_signature" />
            )
          ) : (
            <Fragment>
              <ItemHistoryAdditionRemoval
                change={item.added}
                headerI18nKey="decision_support_view.new_patient_response"
                className="mb-20 response-modification response-added"
              />
              <ItemHistoryAdditionRemoval
                change={item.removed}
                headerI18nKey="decision_support_view.removed_patient_response"
                className="mb-20 response-modification response-removed"
              />
              <ItemHistoryModification
                modifications={item.modified}
                headerI18nKey="decision_support_view.modified_patient_response"
                className="mb-20 response-modification response-modified"
              />
            </Fragment>
          )}
        </Fragment>
        <div className="mt-10">
          {!this.state.itemsExpanded ? (
            <div className="show-all-data">
              <span onClick={this.toggleExpandItems}>
                <span>
                  <Translate value="decision_support_view.show_full_list" />{' '}
                  {item.signedTimestamp ? moment(item.signedTimestamp).format('YYYY-MM-DD') : ''}
                </span>
              </span>
            </div>
          ) : (
            <div className="hide-rows">
              <span onClick={this.toggleExpandItems}>
                <span>
                  <Translate value="decision_support_view.hide_full_list" />{' '}
                  {item.signedTimestamp ? moment(item.signedTimestamp).format('YYYY-MM-DD') : ''}
                </span>
              </span>
            </div>
          )}
        </div>
        <div className={`${this.state.itemsExpanded ? '' : 'hidden'}`}>
          <AttributeResponse value={item.valueAtSigning} />
        </div>
      </div>
    );
  }
}

class ItemHistoryAdditionRemoval extends Component {
  render() {
    const { change, headerI18nKey, ...rest } = this.props;

    if (!change || !change.length) return null;

    return (
      <div {...rest}>
        <h3>
          <Translate value={headerI18nKey} />
        </h3>
        {change.map((value, i) => {
          return (
            <div className="history-values" key={i}>
              <AttributeResponse value={value} showTimestamp={true} />
            </div>
          );
        })}
      </div>
    );
  }
}

class ItemHistoryModification extends Component {
  render() {
    const { modifications, headerI18nKey, ...rest } = this.props;

    if (!modifications || !modifications.length) return null;

    return (
      <div {...rest}>
        <h3>
          <Translate value={headerI18nKey} />
        </h3>
        {modifications.map((modification, i) => {
          return (
            <div className="item-modification mt-10" key={i}>
              <h4>
                <Translate value="decision_support_view.new_response" />
              </h4>
              <div className="history-values mb-10">
                <AttributeResponse value={modification.newItem} showTimestamp={true} />
              </div>
              <h4>
                <Translate value="decision_support_view.previous_response" />
              </h4>
              <div className="history-values mb-10">
                <AttributeResponse value={modification.oldItem} showTimestamp={true} />
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
