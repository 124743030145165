import React from 'react';
import ChatMessage from '../ChatMessage';
import ChatAttachment from '../ChatAttachment';
import AutomaticMessage from '../AutomaticMessage';
import PaymentMessage from '../PaymentMessage';
import ConsentMessage from '../ConsentMessage';
import './ChatItem.scss';

const ChatItem = React.memo(
  ({ message, member, dateTimeFormat, onPreview, locale, onThumbnailClick, onDelete, activeFilters, ...rest }) => {
    if (
      ['message', 'draft', 'nudge'].includes(message.type) ||
      (message.type === 'file' && message.fileinfo.contentType === 'image')
    ) {
      return (
        <ChatMessage
          message={message}
          member={member}
          dateTimeFormat={dateTimeFormat}
          locale={locale}
          onThumbnailClick={onThumbnailClick}
          onDelete={onDelete}
          activeFilters={activeFilters}
          {...rest}
        />
      );
    }

    if (message.type === 'file' && !activeFilters.includes('file')) {
      return <ChatAttachment message={message} member={member} dateTimeFormat={dateTimeFormat} onPreview={onPreview} />;
    }

    if (message.type === 'banner' && !activeFilters.includes('system')) {
      return <AutomaticMessage message={message} />;
    }

    if (message.type === 'payment' && message.payment && !activeFilters.includes('system')) {
      return <PaymentMessage message={message} source={member.source} />;
    }

    if (message.type === 'checkupConsent' && !activeFilters.includes('system')) {
      return <ConsentMessage message={message} />;
    }

    return null;
  }
);

export default ChatItem;
