import React, { Component, Fragment } from 'react';
import { Translate } from 'react-redux-i18n';
import { DebounceInput } from 'react-debounce-input';

class ChangeMedicationPropertyModalContent extends Component {
  render() {
    const {
      currentValue,
      updateMedicationProperty,
      propertyName,
      currentHeaderI18nKey,
      newHeaderI18nKey,
      medicationUpdates
    } = this.props;

    return (
      <Fragment>
        <div className="fs-18">
          <div className="mb-30">
            <h4>
              <Translate value={currentHeaderI18nKey} />
            </h4>
            <div>{currentValue || '-'}</div>
          </div>
          <div className="mb-30">
            <h4>
              <Translate value={newHeaderI18nKey} />
            </h4>
            <div className="fw-input">
              <DebounceInput
                type="text"
                value={medicationUpdates[propertyName] === currentValue ? '' : medicationUpdates[propertyName]}
                onChange={(e) => {
                  updateMedicationProperty(propertyName, e);
                }}
              />
            </div>
          </div>
          {propertyName === 'dosageRegimen' ? (
            <div className="mb-30">
              <input
                type="checkbox"
                className="styled-checkbox mr-5"
                id="update-medication-when-needed"
                checked={medicationUpdates['whenNeeded']}
                onChange={(e) => {
                  updateMedicationProperty('whenNeeded', e);
                }}
              />
              <label htmlFor="update-medication-when-needed">
                <Translate value="patient_view.medications.medication_modal.medication_when_needed" />
              </label>
            </div>
          ) : null}
        </div>
      </Fragment>
    );
  }
}

export default ChangeMedicationPropertyModalContent;
