import React from 'react';
import MedicationBase from '../MedicationBase';

const MedicationCopy = ({ medication, changes }) => {
  return (
    <div className="medication-information">
      <div className="columns">
        <div className="column is-9 no-padding pr-40">
          <MedicationBase medication={medication} changes={changes} hideSignDate={true} hideAddedDate={true} />
        </div>
      </div>
    </div>
  );
};

export default MedicationCopy;
