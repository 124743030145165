import React, { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { withRouter } from 'react-router-dom';
import { DebounceInput } from 'react-debounce-input';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import Page from '../../Page';
import MemberListRow from '../MemberListRow';
import LoadingSpinner from '../../LoadingSpinner';
import { APPLICATION_BASE_PATH, MEMBERS_PER_PAGE } from '../../../constants';
import { getMembers, setCurrentPage, setSearchQuery, clearDssData, notifyClearDss } from '../../../actions';
import './PaginatedMemberList.scss';
import '../../../styles/index.scss';
import { Mixpanel } from '../../../utils/mixpanel';

const PaginatedMemberList = ({
  authToken,
  isNurse,
  currentUser,
  getMembers,
  setSearchQuery,
  setCurrentPage,
  ...props
}) => {
  const namespace = props.namespace;
  const queryParams = props.membersList[`queryParams${namespace}`];
  const ownPatients = props.membersList[`ownPatients${namespace}`];
  const members = props.membersList[`members${namespace}`];
  const loadingMembers = props.membersList[`loadingMembers${namespace}`];
  const currentPage = props.membersList[`currentPage${namespace}`];
  const searchQuery = props.membersList[`searchQuery${namespace}`];
  const currentSearchTerm = props.membersList[`currentSearchTerm${namespace}`];
  const pagination = props.membersList[`pagination${namespace}`];

  useEffect(() => {
    Mixpanel.resetSuperProperties();
  }, []);

  useEffect(() => {
    getMembers(
      namespace,
      authToken,
      MEMBERS_PER_PAGE * currentPage,
      MEMBERS_PER_PAGE,
      searchQuery,
      currentUser,
      isNurse
    );
  }, [namespace, getMembers, authToken, currentPage, searchQuery, currentUser, isNurse]);

  const handlePageClick = (data) => {
    window.scrollTo({
      top: 0
    });
    setCurrentPage(namespace, data.selected);
  };

  const handleSearch = (event) => {
    let queryParam = {};
    let regex = /\d/g;
    let trimmedSearchTerm = event.target.value.trim();

    if (trimmedSearchTerm.length) {
      if (regex.test(trimmedSearchTerm)) {
        let personalNumber = trimmedSearchTerm;
        personalNumber = personalNumber.replace('-', '');
        personalNumber = personalNumber.replace(/\D/g, '');
        queryParam.personalNumber = {
          value: personalNumber.length ? `*${personalNumber}*` : null,
          comparator: '=='
        };
        queryParam.concatenatedNames = {
          value: null,
          comparator: '=='
        };
      } else {
        queryParam.concatenatedNames = {
          value: trimmedSearchTerm.length ? `"*${trimmedSearchTerm}*"` : null,
          comparator: '=='
        };

        queryParam.personalNumber = {
          value: null,
          comparator: '=='
        };
      }
    } else {
      queryParam.personalNumber = {
        value: null,
        comparator: '=='
      };

      queryParam.concatenatedNames = {
        value: null,
        comparator: '=='
      };
    }

    setSearchQuery(namespace, queryParam, event.target.value);
  };

  const navigateToMember = (member) => {
    return () => {
      if (!props.decisionSupport.isCleared || props.decisionSupport.lastTwoWeekAverage !== null) {
        props.clearDssData();
        props.notifyClearDss();
        return;
      }
      props.history.push(`/${APPLICATION_BASE_PATH}/member/${member.guid}`);
    };
  };

  const handlePalCheckboxChange = () => {
    setSearchQuery(namespace, {
      caregiver: {
        value: queryParams.caregiver.value ? null : currentUser.guid,
        comparator: '=='
      }
    });
  };

  return (
    <Page titleI18nKey={`member_list_view.header_${namespace.toLowerCase()}`} allowedPatients={ownPatients}>
      <div className="paginated-member-list">
        <div className="columns mb-0">
          <div className="column is-4 no-padding">
            <DebounceInput
              debounceTimeout={200}
              spellCheck={false}
              value={currentSearchTerm}
              placeholder={I18n.t('member_list_view.filter_placeholder')}
              onChange={handleSearch}
            />
          </div>
          <div className="column is-1 no-padding vertical-align">
            {loadingMembers ? (
              <div className="ml-5 vertical-align">
                <LoadingSpinner size="small" />
              </div>
            ) : null}
          </div>
        </div>
        <div className="columns">
          <div className="column no-padding">
            <input
              type="checkbox"
              name="include-all-patients"
              id="include-all-patients"
              checked={!queryParams.caregiver.value}
              onChange={handlePalCheckboxChange}
            />
            <label htmlFor="include-all-patients">
              <Translate value="member_list_view.include_non_pal" caregiverAbbr={!isNurse ? 'PAL' : 'PAS'} />
            </label>
          </div>
          <div className="column no-padding text-right total-result-count">
            {pagination.totalNumberOfItems > 0 ? (
              <Translate
                value="member_list_view.showing_x_of_y"
                currentPage={`${currentPage * MEMBERS_PER_PAGE + 1}–${Math.min(
                  (currentPage + 1) * MEMBERS_PER_PAGE,
                  pagination.totalNumberOfItems
                )}`}
                totalCount={pagination.totalNumberOfItems}
              />
            ) : null}
          </div>
        </div>
        <div className="columns member-list-headings">
          <div className="column">
            <Translate value="member_list_view.column_headers.namn" />
          </div>
          <div className="column is-2">
            <Translate value="member_list_view.column_headers.personal_number" />
          </div>
          <div className="column is-1">
            <Translate value="member_list_view.column_headers.caregiver" caregiverAbbr="PAL" />
          </div>
          <div className="column is-1">
            <Translate value="member_list_view.column_headers.caregiver" caregiverAbbr="PAS" />
          </div>
          <div className="column is-2">
            <Translate value="member_list_view.column_headers.status" />
          </div>
          <div className="column is-2">
            <Translate value="member_list_view.column_headers.source" />
          </div>
        </div>
        <div>
          {members.length ? (
            members.map((member) => (
              <MemberListRow
                key={member.guid}
                member={member}
                currentUser={currentUser}
                onClick={navigateToMember(member)}
              />
            ))
          ) : !members.length && !loadingMembers ? (
            <div className="list__empty">
              <Translate value="member_list_view.no_results" />
            </div>
          ) : null}
        </div>
        {pagination.totalNumberOfItems > MEMBERS_PER_PAGE ? (
          <div className="pagination-wrapper">
            <ReactPaginate
              previousLabel={I18n.t('global.previous')}
              nextLabel={I18n.t('global.next')}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={Math.ceil(pagination.totalNumberOfItems / MEMBERS_PER_PAGE)}
              initialPage={currentPage}
              forcePage={currentPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={7}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          </div>
        ) : null}
      </div>
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    currentUser: state.auth.token.user,
    isNurse: state.auth.isNurse,
    membersList: state.membersList,
    decisionSupport: state.decisionSupport
  };
};

const mapActionsToProps = {
  getMembers,
  setCurrentPage,
  setSearchQuery,
  clearDssData,
  notifyClearDss
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(PaginatedMemberList));
