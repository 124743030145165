import React, { Component } from 'react';
import LoadingSpinner from '../LoadingSpinner';

class ComponentLoader extends Component {
  render() {
    const { isLoading, ComponentClass, childProps } = this.props;

    return isLoading ? (
      <div className="section-loader">
        <LoadingSpinner size="small" />
      </div>
    ) : (
      <ComponentClass {...childProps} />
    );
  }
}

export default ComponentLoader;
