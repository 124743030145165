import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import { DebounceInput } from 'react-debounce-input';
import moment from 'moment';
import './CheckboxRow.scss';

class CheckboxRow extends Component {
  render() {
    const {
      scopeName,
      id,
      selected,
      leadText,
      content,
      onCheckboxChange,
      selectedCheckboxes,
      memberAccepted,
      memberCompleted,
      isMemberChallenge,
      freeTextCheckboxOption,
      onFreeTextChanged
    } = this.props;

    const isChecked = id
      ? selectedCheckboxes.indexOf(id) > -1
      : freeTextCheckboxOption && freeTextCheckboxOption.length;

    return (
      <div className="row-container checkbox-row">
        <div className="columns table-row">
          <div className={`column row-category ${content ? 'is-4' : 'is-12'}`}>
            <input
              type="checkbox"
              className="styled-checkbox"
              id={`${scopeName}-${id || 'freetext'}`}
              checked={isChecked}
              onChange={onCheckboxChange(id)}
            />
            <label htmlFor={`${scopeName}-${id || 'freetext'}`}>
              {leadText ? (
                leadText
              ) : (
                <DebounceInput
                  type="text"
                  placeholder={I18n.t('global.freetext')}
                  value={freeTextCheckboxOption}
                  onChange={onFreeTextChanged}
                  maxLength={500}
                />
              )}
            </label>
          </div>
          {content ? (
            <div className="column row-response is-8">
              <div>
                {Array.isArray(content)
                  ? content.map((c, i) => {
                      return <p key={i}>{c}</p>;
                    })
                  : content.split('\n').length > 1
                  ? content.split('\n').map((line, i) => {
                      if (!line.length) return <br key={i} />;

                      return <p key={i}>{line}</p>;
                    })
                  : content}
              </div>
            </div>
          ) : null}
          {isMemberChallenge ? (
            <div
              className={`challenge-status ${memberAccepted && !memberCompleted ? 'challenge-accepted' : ''} ${
                memberCompleted ? 'challenge-completed' : ''
              } ${selected && !memberAccepted ? 'not-accepted' : ''}`}
            >
              {memberAccepted && !memberCompleted
                ? `${I18n.t('decision_support_view.treatment_goals_section.member_accepted_challenge')} ${moment(
                    memberAccepted
                  ).format('YYYY-MM-DD')}.`
                : null}
              {memberCompleted
                ? `${I18n.t('decision_support_view.treatment_goals_section.member_completed_challenge')} ${moment(
                    memberCompleted
                  ).format('YYYY-MM-DD')}.`
                : null}
              {selected && !memberAccepted
                ? I18n.t('decision_support_view.treatment_goals_section.member_not_accepted_challenge')
                : null}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default CheckboxRow;
