import React, { Component } from 'react';
import './LoadingSpinner.scss';

class LoadingSpinner extends Component {
  render() {
    return <span className={`loading-spinner ${this.props.size || ''}`}></span>;
  }
}

export default LoadingSpinner;
