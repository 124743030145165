import { useEffect, useRef, useState } from 'react';

export default function useAudioFileVisualization(blob) {
  const canvasRef = useRef();
  const [audioFile, setAudioFile] = useState(blob);

  useEffect(() => {
    if (canvasRef.current && audioFile) {
      const canvasCtx = canvasRef.current.getContext('2d');

      if (!canvasCtx) {
        throw new Error('Could not create 2D context');
      }

      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const fileReader = new FileReader();

      fileReader.onloadend = () => {
        const arrayBuffer = fileReader.result;

        audioContext.decodeAudioData(arrayBuffer, (audioBuffer) => {
          const channelData = audioBuffer.getChannelData(0);
          const samples = WIDTH;
          const blockSize = Math.floor(channelData.length / samples);
          let blockAverages = [];
          let xPos = 0;
          let yPos = HEIGHT / 2;

          const normalizeData = (data) => {
            const multiplier = Math.pow(Math.max(...data), -1);
            return data.map((n) => n * multiplier);
          };

          canvasCtx.beginPath();
          canvasCtx.moveTo(xPos, yPos);

          for (let i = 0; i < samples; i++) {
            let blockStart = blockSize * i;
            let sum = 0;

            for (let j = 0; j < blockSize; j++) {
              sum = sum + channelData[blockStart + j];
            }

            blockAverages.push(sum / blockSize);
          }

          const normalizedData = normalizeData(blockAverages);

          for (let i = 0; i < samples; i++) {
            yPos = normalizedData[i];
            xPos++;

            // Multiply by half the height to fill up the canvas
            // Add half the height to center the graph
            canvasCtx.lineTo(xPos, (yPos * HEIGHT) / 2 + HEIGHT / 2);
          }

          canvasCtx.stroke();
        });
      };

      const cssWidth = parseInt(getComputedStyle(canvasRef.current).getPropertyValue('width').slice(0, -2));

      const scale = window.devicePixelRatio;
      canvasRef.current.width = Math.floor(cssWidth * scale);

      const WIDTH = canvasRef.current.width;
      const HEIGHT = canvasRef.current.height;

      canvasCtx.fillStyle = 'rgb(249, 249, 249)';
      canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);
      canvasCtx.lineWidth = 2 * scale;
      canvasCtx.strokeStyle = 'rgb(18, 74, 159)';

      //Load blob
      fileReader.readAsArrayBuffer(audioFile);
    }
  }, [audioFile]);

  return {
    canvasRef,
    setAudioFile
  };
}
