import React, { useState } from 'react';
import { Translate } from 'react-redux-i18n';
import './AutomaticMessage.scss';

const AutomaticMessage = React.memo(({ message }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="automatic-message-container" onClick={() => setExpanded(!expanded)}>
      <span>{expanded ? message.text : <Translate value="patient_view.chat.automatic_banner_message" />}</span>
    </div>
  );
});

export default AutomaticMessage;
