import { useEffect } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { getKioskAssessmentCount } from '../../actions';
import { APPLICATION_BASE_PATH, ASSESSMENT_STATUS } from '../../constants';
import TopBar from '../TopBar';
import AssessmentList from './AssessmentList';
import AssessmentSummary from './AssessmentSummary';
import './KioskIndexView.scss';
import { Mixpanel } from '../../utils/mixpanel';

const KioskIndexView = ({ authToken, assessments, history, getKioskAssessmentCount }) => {
  useEffect(() => {
    Mixpanel.resetSuperProperties();
    getKioskAssessmentCount(authToken);
  }, []);

  const openKioskSession = (sessionId) => {
    history.push(`/${APPLICATION_BASE_PATH}/kiosk/${sessionId}`);
  };

  return (
    <>
      <TopBar header={I18n.t('kiosk_assessment.page_title')} />
      <div className="wrapper pb-40">
        <AssessmentSummary assessmentCount={assessments.totalRemainingKioskAssessments} error={assessments.error} />

        <AssessmentList
          status={ASSESSMENT_STATUS.AssessmentInProgress}
          onRowClick={openKioskSession}
          allAssessmentsToggle={false}
        />

        <AssessmentList status={ASSESSMENT_STATUS.CaregiverPaused} onRowClick={openKioskSession} />

        <AssessmentList status={ASSESSMENT_STATUS.CaregiverRejected} onRowClick={openKioskSession} />

        <AssessmentList status={ASSESSMENT_STATUS.Completed} onRowClick={openKioskSession} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    assessments: state.assessments
  };
};

const mapActionsToProps = {
  getKioskAssessmentCount
};

export default connect(mapStateToProps, mapActionsToProps)(KioskIndexView);
