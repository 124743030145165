import { useState } from 'react';
import { Translate } from 'react-redux-i18n';
import styles from './SortControl.module.scss';

export default function SortControl({ onSort, currentlySortedBy }) {
  const [isOpen, setIsOpen] = useState(false);
  const sortOptions = [
    'newMessage',
    'checkup',
    'referralFailed',
    'lowBloodpressure',
    'highBloodpressure',
    'alarmValue',
    'labResult',
    'profileUpdate',
    'standing',
    'symptom',
    'threeMonthCheckup',
    'yearlyCheckup',
    'followupMissing',
    'newMedication',
    'patientNotReady',
    'nurseReminder',
    'nurseCheckup',
    'onboardingComplete',
    'screeningResult'
  ];

  const handleSelectSortOption = (newSortOption) => {
    setIsOpen(false);

    onSort(newSortOption);
  };

  return (
    <div className={`sort-control ${styles.wrapper}`}>
      <em>
        <Translate value="global.sort" />
      </em>
      :{' '}
      <span className={styles.changer} onClick={() => setIsOpen(!isOpen)}>
        <Translate
          value={
            currentlySortedBy === 'age' || currentlySortedBy === 'latest'
              ? `inbox_view.sort_option.${currentlySortedBy || 'age'}`
              : currentlySortedBy === 'responsibleDoctor'
              ? 'inbox_view.responsible_doctor'
              : `global.event_type.${currentlySortedBy}`
          }
        />{' '}
        <span className="icon filter"></span>
      </span>
      <div className={`dropdown ${isOpen ? 'is-active' : ''}`}>
        <div className="dropdown-menu" role="menu">
          <div className="dropdown-content">
            <div
              onClick={() => handleSelectSortOption('latest')}
              className={`dropdown-item ${currentlySortedBy === 'latest' ? 'is-active' : ''}`}
            >
              <Translate value="inbox_view.sort_option.latest" />
            </div>
            <div
              onClick={() => handleSelectSortOption('age')}
              className={`dropdown-item ${currentlySortedBy === 'age' ? 'is-active' : ''}`}
            >
              <Translate value="inbox_view.sort_option.age" />
            </div>
            <div
              onClick={() => handleSelectSortOption('responsibleDoctor')}
              className={`dropdown-item ${currentlySortedBy === 'responsibleDoctor' ? 'is-active' : ''}`}
            >
              <Translate value="inbox_view.responsible_doctor" />
            </div>
            {sortOptions.map((sortOption, i) => {
              return (
                <div
                  onClick={() => handleSelectSortOption(sortOption)}
                  className={`dropdown-item ${sortOption === currentlySortedBy ? 'is-active' : ''}`}
                  key={i}
                >
                  <Translate value={`global.event_type.${sortOption}`} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
