import { MAIN_MENU_COLLAPSE_STATE_KEY } from '../constants';
import * as types from '../constants/actionTypes';
import { loadState } from '../services/localStorage';

const initialState = {
  currentTab: '',
  dssSectionsCollapsed: {
    summary: false,
    currentStatus: false,
    riskProfile: false,
    medications: false
  },
  allSectionsCollapsed: false,
  allSectionsExpanded: true,
  chatCollapsed: false,
  instanceDisabled: false,
  ceModalVisible: false,
  maintenanceNoticeModalVisible: false,
  deployModalVisible: false,
  maintenanceCompleteModalVisible: false,
  healthPingSuccesses: 0,
  mainMenuCollapsed: false,
  maintenanceStartsAt: undefined
};

initialState.mainMenuCollapsed = loadState(MAIN_MENU_COLLAPSE_STATE_KEY, true);

let allCollapsed = false;
let allExpanded = true;
let collapsedStates = {};
let newDssSectionsCollapsed;

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_DSS_SECTION:
      collapsedStates = {
        ...state.dssSectionsCollapsed,
        [action.payload]: !state.dssSectionsCollapsed[action.payload]
      };

      allExpanded = Object.keys(collapsedStates).every((s) => collapsedStates[s] === false);
      allCollapsed = Object.keys(collapsedStates).every((s) => collapsedStates[s] === true);

      return {
        ...state,
        allSectionsCollapsed: allCollapsed,
        allSectionsExpanded: allExpanded,
        dssSectionsCollapsed: collapsedStates
      };
    case types.TOGGLE_COLLAPSE_ALL_DSS_SECTIONS:
      for (const section in state.dssSectionsCollapsed) {
        if (allExpanded) {
          collapsedStates[section] = true;
        } else if (allCollapsed) {
          collapsedStates[section] = false;
        } else {
          collapsedStates[section] = true;
        }
      }

      allExpanded = Object.keys(collapsedStates).every((s) => collapsedStates[s] === false);
      allCollapsed = Object.keys(collapsedStates).every((s) => collapsedStates[s] === true);

      return {
        ...state,
        allSectionsCollapsed: allCollapsed,
        allSectionsExpanded: allExpanded,
        dssSectionsCollapsed: collapsedStates
      };
    case types.GET_DSS_STATUS_SUCCESS:
      newDssSectionsCollapsed = state.dssSectionsCollapsed;
      if (action.payload.finalNoteReasonsAllowed === 'initialCheckup') {
        newDssSectionsCollapsed.checkup = true;
      }

      return { ...state, dssSectionsCollapsed: newDssSectionsCollapsed };
    case types.COLLAPSE_CHAT:
      return { ...state, chatCollapsed: true };
    case types.EXPAND_CHAT:
      return { ...state, chatCollapsed: false };
    case types.DISABLE_INSTANCE:
      return { ...state, instanceDisabled: true };
    case types.LOGOUT:
      return { ...state, instanceDisabled: false };
    case types.SHOW_CE_MODAL:
      return { ...state, ceModalVisible: true };
    case types.HIDE_CE_MODAL:
      return { ...state, ceModalVisible: false };
    case types.CLEAR_CURRENT_MEMBER:
      newDssSectionsCollapsed = state.dssSectionsCollapsed;
      delete newDssSectionsCollapsed.checkup;
      return { ...state, dssSectionsCollapsed: newDssSectionsCollapsed, currentTab: '' };
    case types.CHANGE_TAB:
      return { ...state, currentTab: action.payload };
    case types.SHOW_MAINTENTANCE_MODAL:
      return { ...state, maintenanceNoticeModalVisible: true };
    case types.HIDE_MAINTENTANCE_MODAL:
      return { ...state, maintenanceNoticeModalVisible: false };
    case types.SHOW_DEPLOY_IN_PROGRESS_MODAL:
      return { ...state, deployModalVisible: true };
    case types.HIDE_DEPLOY_IN_PROGRESS_MODAL:
      return { ...state, deployModalVisible: false, maintenanceNoticeModalVisible: false };
    case types.HEALTH_PING_SUCCESS:
      return { ...state, healthPingSuccesses: state.healthPingSuccesses + 1 };
    case types.RESET_HEALH_PING_SUCCESSES:
      return { ...state, healthPingSuccesses: 0 };
    case types.SHOW_MAINTENANCE_COMPLETE_MODAL:
      return { ...state, maintenanceCompleteModalVisible: true };
    case types.HIDE_MAINTENANCE_COMPLETE_MODAL:
      return { ...state, maintenanceCompleteModalVisible: false };
    case types.GET_CAREGIVER_PROFILE_IMAGE_REQUEST:
      return { ...state, loadingProfileImage: true };
    case types.GET_CAREGIVER_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        loadingProfileImage: false,
        profileImageUrl: window.URL.createObjectURL(action.payload),
        profileImageError: undefined
      };
    case types.GET_CAREGIVER_PROFILE_IMAGE_ERROR:
      return { ...state, loadingProfileImage: false, profileImageError: action.payload };
    case types.TOGGLE_MAIN_MENU_COLLAPSE:
      return { ...state, mainMenuCollapsed: !state.mainMenuCollapsed };
    case types.SET_MAINTENANCE_STARTS_AT:
      return { ...state, maintenanceStartsAt: action.payload };
    default:
      return state;
  }
};

export default uiReducer;
