import React, { Fragment } from 'react';
import { Translate, I18n } from 'react-redux-i18n';

const MedicationDataWithFallback = ({ medication, dataProperty, headerI18nKey }) => {
  return (
    <Fragment>
      <h4>
        <Translate value={headerI18nKey} />
      </h4>
      {medication ? (
        typeof medication[dataProperty] === 'boolean' ? (
          <div>
            <Translate value={`global.${medication[dataProperty] ? 'yes' : 'no'}`} />
          </div>
        ) : (
          <div className={`${medication[dataProperty] ? '' : 'no-data'}`}>
            {medication[dataProperty] || I18n.t('global.no_data')}
          </div>
        )
      ) : (
        <div className="no-data">
          <Translate value="global.no_data" />
        </div>
      )}
    </Fragment>
  );
};

export default MedicationDataWithFallback;
