import React, { Component } from 'react';
import SimpleHistory from './SimpleHistory';
import ComplexHistory from './ComplexHistory';
import './RowHistory.scss';

class RowHistory extends Component {
  render() {
    const { history, isOverviewItems } = this.props;

    return (
      <div className="row-history-container">
        {isOverviewItems ? <ComplexHistory history={history} /> : <SimpleHistory history={history} />}
      </div>
    );
  }
}

export default RowHistory;
