import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { sv } from '../../i18n/sv';
import { NavLink } from 'react-router-dom';
import debounce from 'debounce';
import moment from 'moment';
import DecisionSupportSection from './DecisionSupportSection';
import DecisionSupportTable from './DecisionSupportTable';
import SimpleRow from './DecisionSupportTable/RowTypes/SimpleRow';
import DssItemRow from './DecisionSupportTable/RowTypes/DssItemRow';
import BloodPressureGoalRow from './DecisionSupportTable/RowTypes/BloodPressureGoalRow';
import BloodPressureMeasureFrequencyRow from './DecisionSupportTable/RowTypes/BloodPressureMeasureFrequencyRow';
import MedicationRow from '../MedicationView/MedicationTable/RowTypes/MedicationRow';
import CheckupRow from './DecisionSupportTable/RowTypes/CheckupRow';
import MeasurementTrends from './MeasurementTrends';
import MeasurementsHistoryTable from './MeasurementsHistoryTable';
import ComponentLoader from '../ComponentLoader';
import LabResultsDataTable from './LabResultsDataTable';
import DayNotesSinceFollowUp from './DayNotesSinceFollowUp';
import ErrorMessage from '../ErrorMessage';
import LoaderButton from '../LoaderButton';
import ModalDialog from '../ModalDialog';
import FinalNoteModalContent from '../FinalNoteModalContent';
import {
  SOURCES_EXCLUDED_FROM_CREATING_FINAL_NOTES,
  DATE_FORMAT,
  AUTHORITY,
  SERVICE_STATUS,
  PATIENT_FEE_EXEMPTED_SOURCES,
  LAST_N_MEASUREMENTS_LIMIT,
  FINAL_NOTE_DIAGNOSIS_CODES,
  FINAL_NOTE_STANDARD_ASSESSMENTS,
  DIAGNOSED_ICD_CODE
} from '../../constants';
import RiskFactorList from './RiskFactor/RiskFactorList';
import AnamnesisUpdateList from './AnamnesisUpdateList';
import CurrentMedicationsColumnHeaders from '../MedicationView/CurrentMedicationsColumnHeaders';
import {
  getEmptyMedicationListFallbackI18nKey,
  getAgeFromPersonalNumber,
  getGenderFromPersonalNumber,
  decamelize,
  getBloodPressureAverageAtLastFinalNote
} from '../../utils';
import MedicationTable from '../MedicationView/MedicationTable';
import HasAuthority from '../HasAuthority';
import JiraSupportModalContent from './JiraSupportModalContent';
import CheckupTimeline from './CheckupTimeline';
import CheckupBookingSelector from './CheckupBookingSelector';
import {
  getDssItemHistory,
  setDssRelevantValuesToUpdate,
  addDssItemComment,
  setDssRelevantValues,
  setDssItemComment,
  getBloodPressureTrends,
  toggleTreatment,
  setDssMeasurementFrequency,
  setDssGoalSystolic,
  setDssGoalDiastolic,
  signDss,
  clearDssItemEditValues,
  editCheckup,
  deleteCheckup,
  showFinalNoteModal,
  hideFinalNoteModal,
  showFinalNoteConfirmationModal,
  hideFinalNoteConfirmationModal,
  saveDss,
  viewNewerLabResults,
  viewOlderLabResults,
  toggleShowLabEvaluation,
  windowResized,
  toggleRiskFactorRecommendation,
  postBloodPressureGoals,
  postMemberCheckup,
  showDayNoteModal,
  markMemberAsInactive,
  toggleCollapseAllDssSections,
  saveFinalNote,
  showJiraModal,
  hideJiraModal,
  getMemberJiraIssues,
  setJiraIssueType,
  setJiraIssueDescription,
  postMemberJiraIssue,
  createFinalNote,
  postPhysicalExaminationReferral,
  updateFinalNoteProperty,
  getClinics,
  updateAdditionalDiagnosisCodes
} from '../../actions';
import './DecisionSupportView.scss';
import OnboardingStatus from './OnboardingStatus';
import PatientNotReady from './PatientNotReady';
import RequestedPrescription from '../MedicationView/RequestedPrescription';
import WaitTime from '../WaitTime';
import BloodPressureLineGraph from '../BloodPressureLineGraph';
import PhysicalExaminationReferral from './PhysicalExaminationReferral';
import BasicPatientInfo from '../BasicPatientInfo';

class DecisionSupportView extends Component {
  constructor(props) {
    super(props);
    this.getItemHistory = this.getItemHistory.bind(this);
    this.saveEditedRelevantValuesAndComment = this.saveEditedRelevantValuesAndComment.bind(this);
    this.getTrends = this.getTrends.bind(this);
    this.setMeasurementFrequency = this.setMeasurementFrequency.bind(this);
    this.setSystolicGoal = this.setSystolicGoal.bind(this);
    this.setDiastolicGoal = this.setDiastolicGoal.bind(this);
    this.signDecisionSupport = this.signDecisionSupport.bind(this);
    this.handleFinalNoteValueChange = this.handleFinalNoteValueChange.bind(this);
    this.handleResize = debounce(this.handleResize.bind(this), 400);
    this.riskFactorRecommendationCheckedChanged = this.riskFactorRecommendationCheckedChanged.bind(this);
    this.saveBloodPressureGoals = this.saveBloodPressureGoals.bind(this);
    this.confirmAndSignDss = this.confirmAndSignDss.bind(this);
    this.markInactive = this.markInactive.bind(this);
    this.confirmAndSaveFinalNote = this.confirmAndSaveFinalNote.bind(this);
    this.viewSupportOptions = this.viewSupportOptions.bind(this);
    this.createJiraIssue = this.createJiraIssue.bind(this);
    this.createFinalNote = this.createFinalNote.bind(this);
    this.getFinalNoteModalAction = this.getFinalNoteModalAction.bind(this);
    this.getFinalNoteModalActionKey = this.getFinalNoteModalActionKey.bind(this);
    this.showFinalNoteModal = this.showFinalNoteModal.bind(this);
    this.handleDiagnosisCodeChange = this.handleDiagnosisCodeChange.bind(this);
    this.handleAdditionalDiagnosisCodeChange = this.handleAdditionalDiagnosisCodeChange.bind(this);
    this.setNurseTransferDelay = this.setNurseTransferDelay.bind(this);
    this.isFinalNoteComplete = this.isFinalNoteComplete.bind(this);
    this.patientFeeRequirementChange = this.patientFeeRequirementChange.bind(this);
    this.selectStandardAssessment = this.selectStandardAssessment.bind(this);
    this.warn = this.warn.bind(this);
    this.toggleLastMeasurementsModal = this.toggleLastMeasurementsModal.bind(this);
    this.createPhysicalExaminationReferral = this.createPhysicalExaminationReferral.bind(this);
    this.toggleDiagnosisCode = this.toggleDiagnosisCode.bind(this);
    this.getFullDiagnosisCodeList = this.getFullDiagnosisCodeList.bind(this);

    this.state = {
      lastMeasurementsModalVisible: false
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.decisionSupport.status.finalNoteReasonsAllowed.length &&
      this.props.decisionSupport.status.finalNoteReasonsAllowed.length === 1 &&
      !prevProps.decisionSupport.finalNote.reason
    ) {
      this.props.updateFinalNoteProperty({
        property: 'reason',
        value: this.props.decisionSupport.status.finalNoteReasonsAllowed[0]
      });
    }

    // Don't set any default diagnosis code for undiagnosed patients.
    if (prevProps.member.loadingMember && !this.props.member.loadingMember) {
      if (this.props.decisionSupport.finalNote.diagnosisCode === '' && !this.props.member.pendingDiagnosis) {
        this.props.updateFinalNoteProperty({
          property: 'diagnosisCode',
          value: DIAGNOSED_ICD_CODE
        });
      }
    }
  }

  handleResize() {
    this.props.windowResized(window.innerWidth);
  }

  handleFinalNoteValueChange(property) {
    return (e) => {
      this.props.updateFinalNoteProperty({
        property: property,
        value: e.target.value,
        isManualTextChange: property === 'text'
      });
    };
  }

  setNurseTransferDelay(e) {
    this.props.updateFinalNoteProperty({
      property: 'nurseTransferDelay',
      value: parseInt(e.target.value, 10)
    });
  }

  handleDiagnosisCodeChange(e) {
    const currentDiagnosisCode = this.props.decisionSupport.finalNote.diagnosisCode;

    const updatedDiagnosisCode = currentDiagnosisCode.length
      ? !currentDiagnosisCode.includes(e.target.value)
        ? currentDiagnosisCode + ', ' + e.target.value
        : currentDiagnosisCode
      : e.target.value;

    this.props.updateFinalNoteProperty({
      property: 'diagnosisCode',
      value: updatedDiagnosisCode
    });
  }

  toggleDiagnosisCode(newDiagnosisCode) {
    this.props.updateFinalNoteProperty({
      property: 'diagnosisCode',
      value: newDiagnosisCode
    });
  }

  handleAdditionalDiagnosisCodeChange(e) {
    this.props.updateAdditionalDiagnosisCodes(e.target.value);
  }

  getItemHistory(itemId) {
    this.props.getDssItemHistory(this.props.authToken, this.props.member.guid, itemId);
  }

  saveEditedRelevantValuesAndComment(itemNumber, cb) {
    if (this.props.decisionSupport.relevantValuesToUpdate) {
      this.props.setDssRelevantValues(
        this.props.authToken,
        this.props.member.guid,
        itemNumber,
        this.props.decisionSupport.relevantValuesToUpdate,
        cb
      );
    }

    if (this.props.decisionSupport.commentModified) {
      this.props.addDssItemComment(
        this.props.authToken,
        this.props.member.guid,
        itemNumber,
        this.props.decisionSupport.itemComment,
        cb
      );
    }
  }

  getTrends(startPeriod, periodSize) {
    this.props.getBloodPressureTrends(this.props.authToken, this.props.member.guid, startPeriod, periodSize);
  }

  riskFactorRecommendationCheckedChanged(riskFactorId) {
    return () => {
      this.props.toggleRiskFactorRecommendation(riskFactorId);
    };
  }

  setMeasurementFrequency(e) {
    this.props.setDssMeasurementFrequency(parseInt(e.target.value, 10));
  }

  setSystolicGoal(newGoal) {
    this.props.setDssGoalSystolic(newGoal);
  }

  setDiastolicGoal(newGoal) {
    this.props.setDssGoalDiastolic(newGoal);
  }

  saveBloodPressureGoals() {
    const { decisionSupport } = this.props;
    this.props.postBloodPressureGoals(
      this.props.authToken,
      this.props.member.guid,
      decisionSupport.goalSystolic,
      decisionSupport.goalDiastolic,
      decisionSupport.measureFrequencyWeekly
    );
  }

  getFullDiagnosisCodeList() {
    return (
      this.props.decisionSupport.finalNote.diagnosisCode +
      `${
        this.props.decisionSupport.additionalDiagnosisCodes.length && this.props.isNurse
          ? ',' + this.props.decisionSupport.additionalDiagnosisCodes.join(',')
          : ''
      }`
    );
  }

  signDecisionSupport(includeFinalNote) {
    const { decisionSupport } = this.props;
    let riskFactorRecommendations;

    const riskFactors = JSON.parse(JSON.stringify(decisionSupport.riskProfile.adjustableFactors));
    const selectedRiskFactorRecommendations = decisionSupport.selectedRiskFactors;

    if (riskFactors) {
      riskFactors.forEach((rf) => {
        if (rf.recommendation) {
          rf.recommendation.selected = selectedRiskFactorRecommendations.indexOf(rf.id) > -1;
        }
      });
      riskFactorRecommendations = riskFactors.filter((rf) => !!rf.recommendation).map((rf) => rf.recommendation);
    }

    const finalNote = {
      noteType: 'finalnote',
      text: decisionSupport.finalNote.text,
      icd: this.getFullDiagnosisCodeList(),
      reason: decisionSupport.finalNote.reason,
      patientFeeRequired: !decisionSupport.finalNote.hasPrescribedMedicationDuringCheckup
    };

    this.props.saveDss(
      this.props.authToken,
      this.props.member.guid,
      decisionSupport.goalSystolic,
      decisionSupport.goalDiastolic,
      decisionSupport.measureFrequencyWeekly,
      riskFactorRecommendations,
      includeFinalNote ? finalNote : undefined,
      decisionSupport.finalNote.nurseTransferDelay
    );
  }

  confirmAndSignDss(includeFinalNote) {
    return () => {
      this.warn(this.getFinalNoteWarningConditions(), () => {
        this.signDecisionSupport(includeFinalNote);
        this.props.updateFinalNoteProperty({
          property: 'saveable',
          value: false
        });
      });
    };
  }

  markInactive() {
    this.props.markMemberAsInactive(this.props.authToken, this.props.member.guid);
  }

  saveFinalNote() {
    const finalNote = {
      noteType: 'finalnote',
      text: this.props.decisionSupport.finalNote.text,
      icd: this.getFullDiagnosisCodeList(),
      reason: this.props.decisionSupport.finalNote.reason,
      patientFeeRequired: !this.props.decisionSupport.finalNote.hasPrescribedMedicationDuringCheckup
    };

    this.props.saveFinalNote(this.props.authToken, this.props.member.guid, finalNote);
  }

  confirmAndSaveFinalNote() {
    this.warn(this.getFinalNoteWarningConditions(), () => {
      this.saveFinalNote();
      this.props.updateFinalNoteProperty({
        property: 'saveable',
        value: false
      });
    });
  }

  createFinalNote() {
    this.props.updateFinalNoteProperty({
      property: 'saveable',
      value: false
    });
    const finalNote = {
      noteType: 'finalnote',
      text: this.props.decisionSupport.finalNote.text,
      icd: this.getFullDiagnosisCodeList(),
      reason: this.props.decisionSupport.finalNote.reason
    };

    this.props.saveFinalNote(this.props.authToken, this.props.member.guid, finalNote);
  }

  getFinalNoteModalAction() {
    if (this.props.authorities.includes(AUTHORITY.CAREGIVER)) {
      return !this.props.decisionSupport.status.isInitialCheckup
        ? this.confirmAndSignDss(true)
        : this.confirmAndSaveFinalNote;
    }

    if (this.props.authorities.includes(AUTHORITY.NURSE)) {
      return this.createFinalNote;
    }

    return () => {};
  }

  getFinalNoteModalActionKey() {
    if (this.props.authorities.includes(AUTHORITY.CAREGIVER)) {
      return !this.props.decisionSupport.status.isInitialCheckup
        ? 'decision_support_view.checkup_section.sign_button_text'
        : 'decision_support_view.create_final_note';
    }

    return 'decision_support_view.create_final_note';
  }

  getFinalNoteWarningConditions() {
    const { medications } = this.props;

    const warningConditions = [
      {
        value: medications.unsignedMedications.length > 0,
        i18nKey: 'decision_support_view.sign_with_unsigned_medications_warning'
      },
      {
        value: !medications.doctorHasPrescribedMedication && medications.memberHasRequestedPrescription,
        i18nKey: 'patient_view.medications.patient_requested_prescription_warning'
      }
    ];

    return warningConditions;
  }

  warn(conditions, callback) {
    const warnConditions = conditions.filter((condition) => condition.value);

    if (!warnConditions.length) {
      callback();
    } else {
      warnConditions.forEach((condition, i) => {
        if (window.confirm(I18n.t(condition.i18nKey))) {
          if (i === warnConditions.length - 1) {
            callback();
          }
        }
      });
    }
  }

  viewSupportOptions() {
    this.props.showJiraModal();
    this.props.getMemberJiraIssues(this.props.authToken, this.props.member.guid);
  }

  createJiraIssue() {
    const { jira } = this.props;
    const jiraIssue = {
      project: process.env.REACT_APP_JIRA_PROJECT_KEY || 'ST',
      type: jira.selectedSupportType.type,
      summary: jira.selectedSupportType.summary,
      description: jira.issueDescription,
      accumboAdminLink: `https://admin.accumbo.se/app/member/${this.props.member.guid}`,
      accumboMemberGuid: this.props.member.guid
    };
    this.props.postMemberJiraIssue(this.props.authToken, jiraIssue);
  }

  getUpComingAppointments() {
    const { checkupList } = this.props.checkups;
    const futureCheckups = checkupList.filter(
      (checkup) =>
        !checkup.cancellationReason &&
        !checkup.type.toLowerCase().includes('reminder') &&
        moment(checkup.checkupDate, DATE_FORMAT).isAfter(moment())
    );
    const nextNurseCheckup = futureCheckups.find((checkup) => checkup.type.includes('nurse'));
    const nextDoctorCheckup = futureCheckups.find((checkup) => !checkup.type.includes('nurse'));

    let upcoming = '';
    upcoming +=
      I18n.t('decision_support_view.final_note_content.next_nurse_checkup_header') +
      ': ' +
      (nextNurseCheckup
        ? `${I18n.t(`global.checkup_type.${decamelize(nextNurseCheckup.type)}`)}, ${nextNurseCheckup.checkupDate}`
        : I18n.t('decision_support_view.final_note_content.no_planned_checkup')) +
      '\n';
    upcoming +=
      I18n.t('decision_support_view.final_note_content.next_doctor_checkup_header') +
      ': ' +
      (nextDoctorCheckup
        ? `${I18n.t(`global.checkup_type.${decamelize(nextDoctorCheckup.type)}`)}, ${nextDoctorCheckup.checkupDate}`
        : I18n.t('decision_support_view.final_note_content.no_planned_checkup'));

    return upcoming;
  }

  /**
   * Generates a final note text based on existing parameters that can
   * serve as a basis for the final note to be edited by the caregiver.
   *
   * @returns string
   */
  getFinalNoteText(assessment) {
    const trimWhitespace =
      (wrapInQuotes = true) =>
      (val) => {
        val = val.replace(/\n+/g, ' ');
        return wrapInQuotes ? `"${val}"` : val;
      };

    const {
      finalNoteContent,
      riskProfile,
      selectedRiskFactors,
      allergyDssItemComments,
      allergyItem,
      lastTwoWeekAverage
    } = this.props.decisionSupport;
    const averageBloodPressureAtLastFinalNote = getBloodPressureAverageAtLastFinalNote(
      this.props.notes.notes,
      this.props.healthProfile.originalMeasurements
    );
    const currentAverage = lastTwoWeekAverage
      ? `${lastTwoWeekAverage.mean.systolic}/${lastTwoWeekAverage.mean.diastolic} mmHg`
      : null;

    const memberCurrentMedications = this.props.medications.currentMedications
      .map((medication, index) => {
        return `${index + 1}) ${medication.current.name.trim()}, ${
          medication.current.strength ? medication.current.strength.trim() : ''
        } ${medication.current.dosageRegimen ? medication.current.dosageRegimen.trim() : ''}${
          medication.current.whenNeeded ? ' (vid behov)' : ''
        }`;
      })
      .join('\n');

    const lifestyleRecommendations = riskProfile.adjustableFactors
      .filter((factor) => selectedRiskFactors.includes(factor.id))
      .map((factor) =>
        factor.recommendation.leadText ? factor.recommendation.leadText.replace('Motivera till', '') : ''
      )
      .join(', ');

    const allergyComments = allergyDssItemComments && allergyDssItemComments.length;
    let combinedAllergyData = '';

    if (allergyItem && allergyItem.value && allergyItem.value.values && allergyItem.value.values.length) {
      combinedAllergyData += allergyItem.value.values.map(trimWhitespace()).join(', ') + '. ';
    }

    if (allergyComments) {
      combinedAllergyData += allergyDssItemComments.join(', ');
    }

    const riskFactors = riskProfile.adjustableFactors
      .filter((factor) => (factor.currentStatus.severity ? factor.currentStatus.severity !== 'normal' : false))
      .map((factor) => factor.name.toLowerCase());

    return [
      I18n.t('decision_support_view.final_note_content.treatment'),
      I18n.t('decision_support_view.final_note_content.patient', {
        gender: sv.global[`${getGenderFromPersonalNumber(this.props.member.personalNumber)}`],
        age: getAgeFromPersonalNumber(this.props.member.personalNumber),
        occupation:
          (finalNoteContent.occupation &&
            finalNoteContent.occupation.value &&
            finalNoteContent.occupation.value.values &&
            finalNoteContent.occupation.value.values.length &&
            finalNoteContent.occupation.value.values.map(trimWhitespace()).join(', ')) ||
          'Ingen uppgift'
      }),
      I18n.t('decision_support_view.final_note_content.previous_diseases', {
        diseases:
          (finalNoteContent.previousDiseases &&
            finalNoteContent.previousDiseases.value &&
            finalNoteContent.previousDiseases.value.values &&
            finalNoteContent.previousDiseases.value.values.length &&
            finalNoteContent.previousDiseases.value.values.map(trimWhitespace()).join(', ')) ||
          'Inga angivna'
      }),
      I18n.t('decision_support_view.final_note_content.current_diseases', {
        diseases:
          (finalNoteContent.currentDiseases &&
            finalNoteContent.currentDiseases.value &&
            finalNoteContent.currentDiseases.value.values &&
            finalNoteContent.currentDiseases.value.values.length &&
            finalNoteContent.currentDiseases.value.values.map(trimWhitespace()).join(', ')) ||
          'Inga angivna'
      }),
      I18n.t('decision_support_view.final_note_content.heredity', {
        heredity:
          (finalNoteContent.heredity &&
            finalNoteContent.heredity.value &&
            finalNoteContent.heredity.value.values &&
            finalNoteContent.heredity.value.values.length &&
            finalNoteContent.heredity.value.values.map(trimWhitespace(false)).join(', ')) ||
          'Ingen känd ärftlighet'
      }),
      I18n.t('decision_support_view.final_note_content.goal_bp', {
        systolic: this.props.decisionSupport.goalSystolic || '-',
        diastolic: this.props.decisionSupport.goalDiastolic || '-'
      }),
      I18n.t('decision_support_view.final_note_content.current_average_bp', {
        average: currentAverage || 'Inga data tillgängliga'
      }),
      averageBloodPressureAtLastFinalNote.systolic > 0
        ? I18n.t('decision_support_view.final_note_content.average_at_last_final_note', {
            date: moment(averageBloodPressureAtLastFinalNote.finalNote.timestamp, DATE_FORMAT).format('YYYY-MM-DD'),
            reason: averageBloodPressureAtLastFinalNote.finalNote.reason
              ? sv.global.checkup_type[`${decamelize(averageBloodPressureAtLastFinalNote.finalNote.reason)}`]
              : sv.global.no_final_note_reason,
            average: `${averageBloodPressureAtLastFinalNote.systolic}/${averageBloodPressureAtLastFinalNote.diastolic} mmHg`
          })
        : null,
      I18n.t('decision_support_view.final_note_content.risk_factors', {
        riskFactors: riskFactors.length ? riskFactors.join(', ') : 'Inga'
      }),
      I18n.t('decision_support_view.final_note_content.recommended_lifestyle_changes', {
        recommendations: (lifestyleRecommendations && lifestyleRecommendations.toLowerCase()) || 'Inga'
      }),
      I18n.t('decision_support_view.final_note_content.allergies', {
        allergies: combinedAllergyData.length ? combinedAllergyData : 'Inga angivna'
      }),
      I18n.t('decision_support_view.final_note_content.medications', {
        medications: this.props.medications.currentMedications.length ? '\n' + memberCurrentMedications : 'Ingen'
      }),
      I18n.t('decision_support_view.final_note_content.assessment', {
        assessment: assessment
      }),
      this.getUpComingAppointments() || null
    ]
      .filter((r) => r !== null)
      .join('\n');
  }

  showFinalNoteModal() {
    if (!this.props.decisionSupport.finalNote.textTouched) {
      this.props.updateFinalNoteProperty({
        property: 'text',
        value: this.getFinalNoteText(
          FINAL_NOTE_STANDARD_ASSESSMENTS[this.props.decisionSupport.finalNote.standardAssessmentOptionState] || ''
        )
      });

      this.props.showFinalNoteModal();
    } else {
      this.props.showFinalNoteModal();
    }
  }

  isFinalNoteComplete() {
    if (!this.props.decisionSupport.finalNote.reason) {
      return false;
    }

    if (!this.props.decisionSupport.finalNote.diagnosisCode.length) {
      return false;
    }

    if (!this.props.isNurse && this.props.decisionSupport.finalNote.nurseTransferDelay < 0) {
      return false;
    }

    return true;
  }

  patientFeeRequirementChange(e) {
    this.props.updateFinalNoteProperty({
      property: 'hasPrescribedMedicationDuringCheckup',
      value: e.target.value !== 'fee'
    });
  }

  selectStandardAssessment(value) {
    if (value !== 'unselected') {
      this.props.updateFinalNoteProperty({
        property: 'text',
        value: this.getFinalNoteText(FINAL_NOTE_STANDARD_ASSESSMENTS[value])
      });
      this.props.updateFinalNoteProperty({
        property: 'standardAssessmentOptionState',
        value: value
      });
    }
  }

  toggleLastMeasurementsModal() {
    this.setState({
      lastMeasurementsModalVisible: !this.state.lastMeasurementsModalVisible
    });
  }

  createPhysicalExaminationReferral(recipient) {
    this.props.postPhysicalExaminationReferral(this.props.authToken, this.props.member.guid, recipient);
  }

  // pendingDiagnosis user property will eventually be changed, for now we're making the assumption
  // that referred and results = diagnosed, since patient is not referred unless doctor decides that
  // during screening appointment (and having diagnosed the patient)
  getPendingDiagnosisBadgeText(pendingDiagnosisUserProperty) {
    switch (pendingDiagnosisUserProperty) {
      case 'referred':
      case 'results':
        return 'referred';
      default:
        return 'undiagnosed';
    }
  }

  render() {
    const {
      decisionSupport,
      setDssRelevantValuesToUpdate,
      setDssItemComment,
      clearDssItemEditValues,
      medications,
      member,
      viewNewerLabResults,
      viewOlderLabResults,
      toggleShowLabEvaluation,
      healthProfile,
      showDayNoteModal,
      memberMeasurements,
      notes,
      jira,
      referrals,
      checkups,
      rawMeasurements
    } = this.props;

    const memberMedications = medications.membersOwnMedications || [];
    const prescribedMedications = medications.membersPrescribedMedications || [];
    const currentMedications = [...memberMedications, ...prescribedMedications];
    const isSignable = !!decisionSupport.measureFrequencyWeekly;
    const lastMeasurementDate =
      memberMeasurements && memberMeasurements.length && memberMeasurements[memberMeasurements.length - 1].timestamp;

    return (
      <Fragment>
        <DecisionSupportSection isLoading={member.loadingMember}>
          <div className="member-overview">
            <div className="columns mb-50">
              <div className="column no-padding">
                <BasicPatientInfo member={member} getPendingDiagnosisBadgeText={this.getPendingDiagnosisBadgeText} />
              </div>
              <div className="column no-padding right-align">
                <div className="block text-right">
                  <div>
                    <LoaderButton
                      title={
                        member.serviceStatus === 'hidden'
                          ? I18n.t('patient_view.overview.marked_inactive_already_title')
                          : I18n.t('patient_view.overview.mark_inactive_title')
                      }
                      buttonI18nKey="patient_view.overview.mark_inactive"
                      isLoading={member.markingMemberAsInactive}
                      onClick={this.markInactive}
                      disabled={
                        member.serviceStatus === SERVICE_STATUS.HIDDEN ||
                        member.serviceStatus === SERVICE_STATUS.BLOCKED
                      }
                    />
                  </div>
                  <div>
                    <button className="button is-tertiary mt-10 w-100" onClick={this.viewSupportOptions}>
                      <Translate value="decision_support_view.support.button" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-3 no-padding">
                <h4>
                  <Translate value="global.contact_details" />
                </h4>
                <div data-sensitive>{member.streetaddress ? member.streetaddress : null}</div>
                <div data-sensitive>
                  {member.city && member.postalCode ? `${member.postalCode} ${member.city}` : null}
                </div>
                <div data-sensitive>
                  {member.email ? (
                    <span className="fs-16">
                      <a href={`mailto:${member.email}`}>{member.email}</a>
                    </span>
                  ) : (
                    <small>
                      <Translate value="patient_view.overview.no_email" />
                    </small>
                  )}
                </div>
                <div data-sensitive>
                  {member.phoneNumber ? (
                    <span className="fs-16">{member.phoneNumber}</span>
                  ) : (
                    <small>
                      <Translate value="patient_view.overview.no_phone_number" />
                    </small>
                  )}
                </div>
              </div>
              <div className="column is-6 no-padding">
                <div className="columns">
                  <div className="column no-padding">
                    <div className="mb-20">
                      <h4>
                        <Translate value="inbox_view.responsible_doctor" />
                        {member.responsibleDoctor && member.serviceStatus === 'doctor' ? (
                          <span className="active-caregiver">
                            <Translate value="decision_support_view.overview_section.active_caregiver" />
                          </span>
                        ) : null}
                      </h4>
                      {member.responsibleDoctor
                        ? `${member.responsibleDoctor.givenName} ${member.responsibleDoctor.familyName}`
                        : '-'}
                    </div>
                    <div className="mb-20">
                      <h4>
                        <Translate value="inbox_view.responsible_nurse" />
                        {member.responsibleNurse && member.serviceStatus === 'nurse' ? (
                          <span className="active-caregiver">
                            <Translate value="decision_support_view.overview_section.active_caregiver" />
                          </span>
                        ) : null}
                      </h4>
                      {member.responsibleNurse
                        ? `${member.responsibleNurse.givenName} ${member.responsibleNurse.familyName}`
                        : '-'}
                    </div>
                    <div>
                      <h4>
                        <Translate value="decision_support_view.overview_section.patient_status" />
                      </h4>
                      <Translate value={`service_status.${member.serviceStatus}`} />
                    </div>
                  </div>
                  <div className="column no-padding">
                    <div className="mb-20">
                      <h4>
                        <Translate value="decision_support_view.overview_section.last_measurement" />
                      </h4>
                      {lastMeasurementDate ? (
                        <>
                          {moment(lastMeasurementDate, DATE_FORMAT).format('YYYY-MM-DD')}
                          <div>
                            <span className="fs-16 text-button" onClick={this.toggleLastMeasurementsModal}>
                              {'('}
                              <WaitTime sinceTimestamp={lastMeasurementDate} withSuffix />
                              {')'}
                            </span>
                          </div>
                        </>
                      ) : (
                        <Translate value="decision_support_view.overview_section.no_measurements" />
                      )}
                    </div>
                    <div className="mb-20">
                      <h4>
                        <Translate value="decision_support_view.overview_section.last_login" />
                      </h4>
                      {member.lastLoginDate ? (
                        <>
                          {moment(member.lastLoginDate, DATE_FORMAT).format('YYYY-MM-DD')}
                          <div className="fs-16">
                            {'('}
                            <WaitTime sinceTimestamp={member.lastLoginDate} withSuffix />
                            {')'}
                          </div>
                        </>
                      ) : (
                        <Translate value="decision_support_view.overview_section.no_logins" />
                      )}
                    </div>
                    <div>
                      <h4>
                        <Translate value="decision_support_view.overview_section.account_created" />
                      </h4>
                      <>
                        {moment(member.createdDate, DATE_FORMAT).format('YYYY-MM-DD')}
                        <div className="fs-16">
                          {'('}
                          <WaitTime sinceTimestamp={member.createdDate} withSuffix />
                          {')'}
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-3 no-padding">
                <div className="mb-20">
                  <h4>
                    <Translate value="decision_support_view.overview_section.final_notes_in_last_year_header" />
                  </h4>
                  {typeof notes.doctorNotesCount !== 'undefined' ? (
                    <div>
                      <div>
                        <Translate
                          value="decision_support_view.overview_section.doctor_notes"
                          count={notes.doctorNotesCount}
                        />
                      </div>
                      <div>
                        <Translate
                          value="decision_support_view.overview_section.nurse_notes"
                          count={notes.nurseNotesCount}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>...</div>
                  )}
                </div>
                <div className="mb-20">
                  <h4>
                    <Translate value="decision_support_view.overview_section.payment_exemption_header" />
                  </h4>
                  <div>
                    {member.paymentExemption ? (
                      <Translate
                        value="decision_support_view.overview_section.payment_exemption"
                        cardNumber={member.paymentExemption.cardNumber}
                        validUntil={member.paymentExemption.validUntil}
                      />
                    ) : (
                      <Translate value="decision_support_view.overview_section.payment_exemption_empty" />
                    )}
                  </div>
                </div>
                <div>
                  <h4>
                    <Translate value="decision_support_view.overview_section.current_monitor_header" />{' '}
                    <span
                      className="question-mark"
                      data-tip={I18n.t('decision_support_view.overview_section.current_monitor_tooltip')}
                    ></span>
                  </h4>
                  <div>
                    {member.currentMonitor ? (
                      <Translate value={`global.monitor.${member.currentMonitor.device?.deviceType}`} />
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DecisionSupportSection>
        <HasAuthority authority={AUTHORITY.CAREGIVER}>
          <PhysicalExaminationReferral
            isLoading={member.loadingMember || referrals.isLoading}
            member={member}
            isPosting={referrals.postingPhysicalExaminationReferral}
            onCreateReferral={this.createPhysicalExaminationReferral}
            existingReferral={this.props.files.memberFiles.find((file) => file.contentType === 'instruction')}
            clinics={referrals.clinics ?? [{ id: 'VC', name: 'VC' }]}
          />
        </HasAuthority>
        <PatientNotReady
          isLoading={member.loadingMember}
          isNurse={this.props.isNurse}
          latestAnamnesisUpdate={decisionSupport.anamnesisUpdateSummary[0]}
          activeEvents={this.props.events.memberActiveEvents}
          measurements={rawMeasurements}
        />
        <OnboardingStatus member={member} />
        <DecisionSupportSection isLoading={decisionSupport.isLoadingStatus}>
          <DecisionSupportTable
            data={checkups.nextCheckup}
            error={decisionSupport.statusError}
            errorTitleI18n="decision_support_view.notes_section.error"
            emptyI18n="decision_support_view.notes_section.no_checkups"
            rowType={CheckupRow}
          />

          <DecisionSupportTable
            data={decisionSupport.status && decisionSupport.statusTableData}
            error={decisionSupport.statusError}
            errorTitleI18n="decision_support_view.notes_section.error"
            rowType={SimpleRow}
          />

          <AnamnesisUpdateList summariesList={decisionSupport.anamnesisUpdateSummary} />

          <DayNotesSinceFollowUp notes={decisionSupport.status && decisionSupport.status.daynotes} />

          <div className="columns">
            <div className="column no-padding right-align">
              <button className="button is-primary create-note-button" onClick={showDayNoteModal}>
                <Translate value="patient_view.chat.create_note" />
              </button>
            </div>
          </div>
        </DecisionSupportSection>
        <div className="columns">
          <div className="column no-padding right-align">
            <span
              className={`text-button chevron ${!this.props.ui.allSectionsCollapsed ? 'up' : 'down'}`}
              onClick={this.props.toggleCollapseAllDssSections}
            >
              <Translate value={`global.${this.props.ui.allSectionsCollapsed ? 'show' : 'minimize'}_all`} />
            </span>
          </div>
        </div>
        <DecisionSupportSection
          name="summary"
          headerI18nKey="decision_support_view.summary_header"
          isLoading={decisionSupport.isLoadingSummary}
        >
          <DecisionSupportTable
            data={decisionSupport.summary && decisionSupport.summary.items}
            error={decisionSupport.summaryError}
            errorTitleI18n="decision_support_view.summary_error"
            fetchHistory={this.getItemHistory}
            changeRelevantValues={setDssRelevantValuesToUpdate}
            setComment={debounce(setDssItemComment, 200)}
            saveEditedValues={this.saveEditedRelevantValuesAndComment}
            currentItemHistory={decisionSupport.currentItemHistory}
            isLoadingItemHistory={decisionSupport.loadingItemHistory}
            isSettingRelevantValues={decisionSupport.settingRelevantValues || decisionSupport.postingComment}
            rowType={DssItemRow}
            onCloseEdit={clearDssItemEditValues}
          />
        </DecisionSupportSection>
        <DecisionSupportSection name="currentStatus" headerI18nKey="decision_support_view.status_header">
          <ComponentLoader
            isLoading={decisionSupport.isLoadingMeasurementStatus}
            ComponentClass={DecisionSupportTable}
            childProps={{
              data: decisionSupport.measurementStatus,
              error: decisionSupport.measurementStatusError,
              errorTitleI18n: 'decision_support_view.measurement_status_error',
              fetchHistory: this.getItemHistory,
              changeRelevantValues: setDssRelevantValuesToUpdate,
              setComment: setDssItemComment,
              saveEditedValues: this.saveEditedRelevantValuesAndComment,
              currentItemHistory: decisionSupport.currentItemHistory,
              isLoadingItemHistory: decisionSupport.loadingItemHistory,
              isSettingRelevantValues: decisionSupport.settingRelevantValues || decisionSupport.postingComment,
              rowType: DssItemRow,
              onCloseEdit: clearDssItemEditValues
            }}
          />

          <ComponentLoader
            isLoading={decisionSupport.isLoadingBloodPressureSummary}
            ComponentClass={DecisionSupportTable}
            childProps={{
              data: decisionSupport.bloodPressureSummary && decisionSupport.bloodPressureSummary.variationData,
              error: decisionSupport.bloodPressureSummaryError,
              errorTitleI18n: 'decision_support_view.blood_pressure_summary_error',
              titleI18n: 'decision_support_view.last_three_months_header',
              rowType: SimpleRow
            }}
          />

          <ComponentLoader
            isLoading={decisionSupport.isLoadingBloodPressureSummary}
            ComponentClass={DecisionSupportTable}
            childProps={{
              data: decisionSupport.bloodPressureSummary && decisionSupport.bloodPressureSummary.specialMeasurements,
              error: decisionSupport.bloodPressureSummaryError,
              errorTitleI18n: 'decision_support_view.blood_pressure_summary_error',
              titleI18n: 'decision_support_view.special_measurements_header',
              rowType: SimpleRow
            }}
          />

          <ComponentLoader
            isLoading={decisionSupport.isLoadingBloodPressureSummary}
            ComponentClass={MeasurementsHistoryTable}
            childProps={{
              error: decisionSupport.bloodPressureSummaryError,
              errorTitleI18n: 'decision_support_view.blood_pressure_summary_error',
              standingMeasurements:
                decisionSupport.bloodPressureSummary && decisionSupport.bloodPressureSummary.standingMeasures,
              symptomaticMeasurements:
                decisionSupport.bloodPressureSummary && decisionSupport.bloodPressureSummary.symptomMeasures
            }}
          />

          <MeasurementTrends
            trendData={decisionSupport.bloodPressureTrends}
            lastPeriod={decisionSupport.lastPeriod}
            currentPeriods={decisionSupport.currentPeriods}
            isLoading={decisionSupport.isLoadingTrends || decisionSupport.isLoadingTreatmentOptions}
            onGetTrends={this.getTrends}
            periodSize={decisionSupport.periodSize}
            goalSystolic={decisionSupport.goalSystolic}
            goalDiastolic={decisionSupport.goalDiastolic}
            memberMeasurements={memberMeasurements}
          />

          <ComponentLoader
            isLoading={decisionSupport.isLoadingLabResults}
            ComponentClass={LabResultsDataTable}
            childProps={{
              labResultData: decisionSupport.transformedLabResults,
              onViewOlder: viewOlderLabResults,
              onViewNewer: viewNewerLabResults,
              labResultsStartIndex: decisionSupport.labResultsStartIndex,
              labResultsEndIndex: decisionSupport.labResultsEndIndex,
              totalLabResultColumns: decisionSupport.totalLabResultColumns,
              onCheckboxToggle: toggleShowLabEvaluation,
              showLabEvaluation: decisionSupport.showLabEvaluation,
              referrals: referrals.historicReferrals,
              riskProfile: decisionSupport.riskProfile
            }}
          />
        </DecisionSupportSection>
        <DecisionSupportSection
          name="riskProfile"
          headerI18nKey="decision_support_view.risk_profile_section_header"
          isLoading={decisionSupport.isLoadingRiskProfile}
        >
          <DecisionSupportTable
            data={decisionSupport.riskProfile && decisionSupport.riskProfile.risk}
            error={decisionSupport.riskProfileError}
            emptyI18n="decision_support_view.risk_profile_section.no_risk_profile"
            errorTitleI18n="decision_support_view.risk_profile_error"
            fetchHistory={this.getItemHistory}
            setComment={debounce(setDssItemComment, 200)}
            saveEditedValues={this.saveEditedRelevantValuesAndComment}
            currentItemHistory={decisionSupport.currentItemHistory}
            isLoadingItemHistory={decisionSupport.loadingItemHistory}
            isSettingRelevantValues={decisionSupport.settingRelevantValues || decisionSupport.postingComment}
            rowType={DssItemRow}
            onCloseEdit={clearDssItemEditValues}
          />

          <DecisionSupportTable
            data={decisionSupport.treatmentGoal}
            error={decisionSupport.riskProfileError}
            errorTitleI18n="decision_support_view.treatment_goals_section.error"
            titleI18n="decision_support_view.treatment_goals_section.bp_goals_header"
            emptyI18n="decision_support_view.risk_profile_section.no_risk_profile"
            fetchHistory={this.getItemHistory}
            changeRelevantValues={setDssRelevantValuesToUpdate}
            setComment={debounce(setDssItemComment, 200)}
            saveEditedValues={this.saveEditedRelevantValuesAndComment}
            currentItemHistory={decisionSupport.currentItemHistory}
            isLoadingItemHistory={decisionSupport.loadingItemHistory}
            isSettingRelevantValues={decisionSupport.settingRelevantValues || decisionSupport.postingComment}
            rowType={DssItemRow}
            onCloseEdit={clearDssItemEditValues}
          />

          <DecisionSupportTable
            error={decisionSupport.riskProfileError}
            errorTitleI18n="decision_support_view.treatment_goals_section.error"
            emptyI18n="decision_support_view.treatment_goals_section.empty"
            onSaveChanges={this.saveBloodPressureGoals}
            isSavable={decisionSupport.hasUnsavedBloodPressureGoalChanges}
            isSaving={decisionSupport.isSavingBloodPressureGoals}
          >
            <BloodPressureGoalRow
              goalSystolic={decisionSupport.goalSystolic}
              goalDiastolic={decisionSupport.goalDiastolic}
              setSystolicGoal={this.setSystolicGoal}
              setDiastolicGoal={this.setDiastolicGoal}
            />

            <BloodPressureMeasureFrequencyRow
              onMeasurementFrequencyChanged={this.setMeasurementFrequency}
              measurementFrequency={decisionSupport.measureFrequencyWeekly}
              disabled={member.riskFactorAndGoalsDisabled}
              disabledTitleI18n="decision_support_view.treatment_goals_section.disabled"
              allowedToEdit={this.props.authorities.indexOf(AUTHORITY.CAREGIVER) > -1}
            />
          </DecisionSupportTable>

          <RiskFactorList
            riskFactors={decisionSupport.riskProfile && decisionSupport.riskProfile.adjustableFactors}
            selectedRiskFactors={decisionSupport.selectedRiskFactors}
            onCheckboxChange={this.riskFactorRecommendationCheckedChanged}
            lifestyleObjectives={
              decisionSupport.memberObjectives && decisionSupport.memberObjectives.lifestyleObjectives
            }
            lifestyleObjectivesProgress={
              decisionSupport.memberObjectives && decisionSupport.memberObjectives.lifestyleObjectivesProgress
            }
            weightObjective={decisionSupport.memberObjectives && decisionSupport.memberObjectives.weightObjective}
            weightObjectiveProgress={
              decisionSupport.memberObjectives && decisionSupport.memberObjectives.weightObjectiveProgress
            }
            locale={this.props.locale}
            disabled={member.riskFactorAndGoalsDisabled}
            disabledTitleI18n="global.feature_disabled"
            allowedToEdit={this.props.authorities.indexOf(AUTHORITY.CAREGIVER) > -1}
          />
        </DecisionSupportSection>
        <DecisionSupportSection
          name="medications"
          headerI18nKey="decision_support_view.medication_section.header"
          isLoading={medications.loadingMemberMedications}
          sectionNote={
            medications.unsignedMedications.length === 0
              ? false
              : I18n.t('decision_support_view.medication_section.unsigned_medication_list')
          }
        >
          <MedicationTable
            data={medications.memberMedications && medications.currentMedications}
            error={medications.memberMedicationsError}
            errorTitleI18n="patient_view.medications.errors.loading_medications"
            emptyI18n={getEmptyMedicationListFallbackI18nKey(healthProfile, medications)}
            rowType={MedicationRow}
            ColumnHeaderComponent={CurrentMedicationsColumnHeaders}
          />

          <div className="flex space-between mt-20">
            <RequestedPrescription
              memberHasRequestedPrescription={medications.memberHasRequestedPrescription}
              memberPrescriptionRequestAnswer={medications.memberPrescriptionRequestAnswer}
            />

            <NavLink className="button is-primary" to={`/app/member/${this.props.member.guid}/medications`}>
              <Translate value="decision_support_view.medication_section.medication_link" />
            </NavLink>
          </div>
        </DecisionSupportSection>
        <DecisionSupportSection
          name="checkup"
          headerI18nKey="decision_support_view.checkup_section.header"
          isLoading={checkups.isLoadingCheckups}
          minimizable={
            SOURCES_EXCLUDED_FROM_CREATING_FINAL_NOTES.indexOf(member.source) === -1 &&
            decisionSupport.status.finalNoteReasonsAllowed === 'initialCheckup'
          }
          minimizeOverride={SOURCES_EXCLUDED_FROM_CREATING_FINAL_NOTES.indexOf(member.source) > -1}
        >
          <CheckupTimeline checkups={checkups} member={member} />
          <HasAuthority authority={[AUTHORITY.CAREGIVER, AUTHORITY.NURSE]}>
            <CheckupBookingSelector checkups={checkups} member={member} caregiverGuid={this.props.user.guid} />
          </HasAuthority>
        </DecisionSupportSection>

        <div className="sign-container">
          <div className="center-text">
            <ErrorMessage
              error={decisionSupport.signingError || decisionSupport.treatmentPostError}
              errorI18nKey="decision_support_view.sign_report_error"
            />
          </div>
          {!isSignable ? (
            <div className="center-text mukta">
              <h3>
                <Translate value="decision_support_view.not_signable_header" />
              </h3>
              <div className="mb-20">
                <Translate value="decision_support_view.required_field_missing.measure_frequency" />
              </div>
            </div>
          ) : null}

          <HasAuthority authority={AUTHORITY.CAREGIVER}>
            <div className="center-text">
              {(decisionSupport.status.finalNoteReasonsAllowed.length !== 0 &&
                !decisionSupport.status.isInitialCheckup) ||
              SOURCES_EXCLUDED_FROM_CREATING_FINAL_NOTES.indexOf(member.source) > -1 ? (
                <Fragment>
                  <span className="mr-20">
                    <HasAuthority authority={AUTHORITY.CAREGIVER} disable={true}>
                      <LoaderButton
                        disabled={!isSignable}
                        onClick={this.confirmAndSignDss(false)}
                        isLoading={decisionSupport.isLoadingTreatmentPost || decisionSupport.isLoadingSigning}
                        isLarge={true}
                        isSecondary={true}
                        buttonI18nKey="decision_support_view.sign_report"
                      />
                    </HasAuthority>
                  </span>
                  <HasAuthority authority={AUTHORITY.CAREGIVER} disable={true}>
                    <LoaderButton
                      disabled={!isSignable || SOURCES_EXCLUDED_FROM_CREATING_FINAL_NOTES.indexOf(member.source) > -1}
                      title={
                        SOURCES_EXCLUDED_FROM_CREATING_FINAL_NOTES.indexOf(member.source) > -1
                          ? `${I18n.t('decision_support_view.signing_disabled_member_source')} ${member.source}.`
                          : undefined
                      }
                      onClick={this.showFinalNoteModal}
                      isLoading={false}
                      isLarge={true}
                      isSecondary={true}
                      buttonI18nKey="decision_support_view.create_final_note_and_sign_report"
                    />
                  </HasAuthority>
                </Fragment>
              ) : null}

              {decisionSupport.status.isInitialCheckup &&
              SOURCES_EXCLUDED_FROM_CREATING_FINAL_NOTES.indexOf(member.source) === -1 ? (
                <LoaderButton
                  onClick={this.showFinalNoteModal}
                  isLoading={false}
                  isLarge={true}
                  isSecondary={true}
                  buttonI18nKey="decision_support_view.create_final_note"
                />
              ) : null}
            </div>
          </HasAuthority>

          <div className="center-text">
            {decisionSupport.status.finalNoteReasonsAllowed.length === 0 &&
            SOURCES_EXCLUDED_FROM_CREATING_FINAL_NOTES.indexOf(member.source) === -1 ? (
              <div className="center-text mukta">
                <h3>
                  <Translate value="decision_support_view.cannot_create_final_note" />
                </h3>
              </div>
            ) : null}
          </div>

          {decisionSupport.status.finalNoteReasonsAllowed.length ? (
            <HasAuthority authority={AUTHORITY.NURSE}>
              <div className="center-text">
                <LoaderButton
                  onClick={this.showFinalNoteModal}
                  isLoading={decisionSupport.savingFinalNote}
                  disabled={SOURCES_EXCLUDED_FROM_CREATING_FINAL_NOTES.includes(member.source)}
                  isLarge={true}
                  isSecondary={true}
                  buttonI18nKey="decision_support_view.create_final_note"
                />
              </div>
            </HasAuthority>
          ) : null}
        </div>
        <ModalDialog
          headerI18nKey="decision_support_view.checkup_section.epicrisis_header"
          actionI18nKey="global.buttons.next"
          visible={decisionSupport.finalNoteModalActive}
          onClose={this.props.hideFinalNoteModal}
          onActionCompleted={() => {
            this.props.hideFinalNoteModal();
            this.props.showFinalNoteConfirmationModal();
          }}
          actionCompletable={this.isFinalNoteComplete()}
          actionCompleting={!decisionSupport.status.isInitialCheckup ? decisionSupport.isSavingDss : notes.addingNote}
          size="large"
        >
          <FinalNoteModalContent
            isNurse={this.props.isNurse}
            selectedReason={decisionSupport.finalNote.reason}
            onReasonChanged={this.handleFinalNoteValueChange('reason')}
            treatmentReason={decisionSupport.finalNote.text}
            onTreatmentReasonChanged={this.handleFinalNoteValueChange('text')}
            diagnosisCodes={FINAL_NOTE_DIAGNOSIS_CODES}
            diagnosisCode={decisionSupport.finalNote.diagnosisCode}
            additionalDiagnosisCodes={decisionSupport.additionalDiagnosisCodes}
            onDiagnosisCodeChanged={this.handleDiagnosisCodeChange}
            onConcatenatedCodeChange={this.handleFinalNoteValueChange('diagnosisCode')}
            onAdditionalDiagnosisCodeChanged={this.handleAdditionalDiagnosisCodeChange}
            onToggleDiagnosisCode={this.toggleDiagnosisCode}
            currentMedications={currentMedications}
            medications={medications}
            currentMedicationsEmptyI18nKey={getEmptyMedicationListFallbackI18nKey(healthProfile, medications)}
            availableReasons={decisionSupport.status.finalNoteReasonsAllowed}
            selectedNurseTransferDelay={decisionSupport.finalNote.nurseTransferDelay}
            onSelectNurseTransferDelay={this.setNurseTransferDelay}
            onPatientFeeSelection={this.patientFeeRequirementChange}
            standardAssessmentOptionState={decisionSupport.finalNote.standardAssessmentOptionState}
            onStandardAssessmentSelection={this.selectStandardAssessment}
            finalNoteTouched={decisionSupport.finalNote.textTouched}
            hasPrescribedMedicationDuringCheckup={decisionSupport.finalNote.hasPrescribedMedicationDuringCheckup}
            memberSource={member.source}
          />
        </ModalDialog>
        <ModalDialog
          headerI18nKey="decision_support_view.support.header"
          actionI18nKey="decision_support_view.support.button"
          visible={jira.modalVisible}
          onClose={this.props.hideJiraModal}
          onActionCompleted={this.createJiraIssue}
          actionCompletable={!!jira.selectedSupportType}
          actionCompleting={jira.creatingIssue}
          contentClass="pt-0"
        >
          <JiraSupportModalContent
            content={jira}
            onSelectIssueType={this.props.setJiraIssueType}
            onChangeDescription={this.props.setJiraIssueDescription}
          />
        </ModalDialog>
        <ModalDialog
          headerI18nKey="decision_support_view.checkup_section.confirm_epicrisis_header"
          actionI18nKey={this.getFinalNoteModalActionKey()}
          visible={decisionSupport.finalNoteConfirmationModalActive}
          onClose={this.props.hideFinalNoteConfirmationModal}
          onGoBack={() => {
            this.props.hideFinalNoteConfirmationModal();
            this.props.showFinalNoteModal();
          }}
          onActionCompleted={this.getFinalNoteModalAction()}
          actionCompletable={decisionSupport.finalNote.saveable}
          actionCompleting={!decisionSupport.status.isInitialCheckup ? decisionSupport.isSavingDss : notes.addingNote}
          size="wide"
        >
          <div>
            {this.props.decisionSupport.finalNote.text.split('\n').map((item, key) => {
              return (
                <span key={key}>
                  {item}
                  <br />
                </span>
              );
            })}
          </div>
          <div className="mt-10">ICD: {this.props.decisionSupport.finalNote.diagnosisCode}</div>
          {this.props.isNurse ? (
            <div className="mt-10">
              <Translate value="decision_support_view.checkup_section.treatment_code" />:{' '}
              {this.props.decisionSupport.additionalDiagnosisCodes.length
                ? this.props.decisionSupport.additionalDiagnosisCodes.join(', ')
                : '-'}
            </div>
          ) : null}
          {decisionSupport.finalNoteReason ? (
            <div className="mt-10">
              <Translate value="global.reason" />:{' '}
              <Translate value={`global.checkup_type.${decamelize(decisionSupport.finalNote.reason, '_')}`} />
            </div>
          ) : null}
          {!this.props.isNurse && !PATIENT_FEE_EXEMPTED_SOURCES.includes(member.source) ? (
            <>
              <div className="mt-10">
                <Translate
                  value="decision_support_view.final_note.patient_will_be_transferred_to_nurse"
                  delay={I18n.t(
                    `decision_support_view.final_note.role_transfer_delay.delay_${this.props.decisionSupport.finalNote.nurseTransferDelay}`
                  ).toLowerCase()}
                />
              </div>
              <div className="mt-10">
                <Translate
                  value={`decision_support_view.final_note.patient_fee_required_confirmation_${
                    !this.props.decisionSupport.finalNote.hasPrescribedMedicationDuringCheckup ? 'yes' : 'no'
                  }`}
                />
              </div>
            </>
          ) : null}
        </ModalDialog>
        <ModalDialog
          header={I18n.t(
            `decision_support_view.overview_section.${
              rawMeasurements.length === 1 ? 'last_measurement_header' : 'last_n_measurements_header'
            }`,
            {
              n:
                rawMeasurements.length > LAST_N_MEASUREMENTS_LIMIT ? LAST_N_MEASUREMENTS_LIMIT : rawMeasurements.length,
              total: rawMeasurements.length.toLocaleString()
            }
          )}
          actionI18nKey="global.buttons.ok"
          visible={this.state.lastMeasurementsModalVisible}
          onClose={this.toggleLastMeasurementsModal}
          onActionCompleted={this.toggleLastMeasurementsModal}
          actionCompletable={true}
        >
          <BloodPressureLineGraph
            bpMeasurements={rawMeasurements.slice(-LAST_N_MEASUREMENTS_LIMIT)}
            goalSystolic={decisionSupport.goalSystolic}
            goalDiastolic={decisionSupport.goalDiastolic}
          />
        </ModalDialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    isNurse: state.auth.isNurse,
    member: state.currentMember,
    decisionSupport: state.decisionSupport,
    medications: state.medications,
    healthProfile: state.patientHealthProfile,
    notes: state.notes,
    locale: state.i18n.locale,
    authorities: state.auth.authorities,
    memberMeasurements: state.patientHealthProfile.memberMeasurements,
    rawMeasurements: state.patientHealthProfile.originalMeasurements,
    ui: state.ui,
    jira: state.jira,
    referrals: state.referrals,
    checkups: state.checkups,
    anamnesisChapters: state.anamnesisChapters,
    events: state.events,
    user: state.auth.token.user,
    files: state.files
  };
};

const mapActionsToProps = {
  getDssItemHistory,
  setDssRelevantValuesToUpdate,
  addDssItemComment,
  setDssRelevantValues,
  setDssItemComment,
  getBloodPressureTrends,
  toggleTreatment,
  setDssMeasurementFrequency,
  setDssGoalSystolic,
  setDssGoalDiastolic,
  signDss,
  clearDssItemEditValues,
  editCheckup,
  deleteCheckup,
  showFinalNoteModal,
  hideFinalNoteModal,
  showFinalNoteConfirmationModal,
  hideFinalNoteConfirmationModal,
  saveDss,
  viewNewerLabResults,
  viewOlderLabResults,
  toggleShowLabEvaluation,
  windowResized,
  toggleRiskFactorRecommendation,
  postBloodPressureGoals,
  postMemberCheckup,
  showDayNoteModal,
  markMemberAsInactive,
  toggleCollapseAllDssSections,
  saveFinalNote,
  showJiraModal,
  hideJiraModal,
  getMemberJiraIssues,
  setJiraIssueType,
  setJiraIssueDescription,
  postMemberJiraIssue,
  createFinalNote,
  postPhysicalExaminationReferral,
  updateFinalNoteProperty,
  getClinics,
  updateAdditionalDiagnosisCodes
};

export default connect(mapStateToProps, mapActionsToProps)(DecisionSupportView);
