import React, { Component, Fragment } from 'react';
import { Translate } from 'react-redux-i18n';
import MeasurementsLineGraph from './MeasurementsLineGraph';
import MeasurementTrendsControl from './MeasurementTrendsControl';
import MeasurementPeriods from './MeasurementPeriods';

class MeasurementTrends extends Component {
  constructor(props) {
    super(props);

    this.getPreviousPeriods = this.getPreviousPeriods.bind(this);
    this.getNextPeriods = this.getNextPeriods.bind(this);
    this.setPeriod = this.setPeriod.bind(this);
  }

  getPreviousPeriods() {
    this.props.onGetTrends(this.props.currentPeriods[0] - 1, this.props.periodSize);
  }

  getNextPeriods() {
    this.props.onGetTrends(this.props.currentPeriods[0] + 1, this.props.periodSize);
  }

  setPeriod(periodSize) {
    return () => {
      this.props.onGetTrends(null, periodSize);
    };
  }

  render() {
    const {
      trendData,
      lastPeriod,
      currentPeriods,
      periodSize,
      isLoading,
      goalSystolic,
      goalDiastolic,
      memberMeasurements
    } = this.props;

    return !trendData || !trendData.length ? (
      <span className="field__empty">
        <Translate value="decision_support_view.no_trend_data" />
      </span>
    ) : (
      <Fragment>
        <MeasurementTrendsControl
          trendData={trendData}
          isLoading={isLoading}
          getPreviousPeriods={this.getPreviousPeriods}
          getNextPeriods={this.getNextPeriods}
          currentPeriods={currentPeriods}
          lastPeriod={lastPeriod}
          periodSize={periodSize}
          setPeriod={this.setPeriod}
        />
        <MeasurementsLineGraph
          trendData={trendData}
          isLoading={isLoading}
          currentPeriods={currentPeriods}
          periodSize={periodSize}
          goalSystolic={goalSystolic}
          goalDiastolic={goalDiastolic}
          memberMeasurements={memberMeasurements}
        />
        <MeasurementPeriods trendData={trendData} isLoading={isLoading} periodSize={periodSize} />
      </Fragment>
    );
  }
}

export default MeasurementTrends;
