import React, { Component } from 'react';
import { Translate } from 'react-redux-i18n';
import moment from 'moment';
import './MeasurementPeriod.scss';
import { DATE_FORMAT } from '../../../../../constants';

class MeasurementPeriod extends Component {
  render() {
    const { period, periodSize } = this.props;
    return (
      <div className="column no-padding measurement-period">
        <h1>
          {periodSize !== 1
            ? `${moment.utc(period.startTimestamp, DATE_FORMAT).format('DD MMM')} - ${moment
                .utc(period.endTimestamp, DATE_FORMAT)
                .format('DD MMM')}`
            : `${moment.utc(period.startTimestamp, DATE_FORMAT).format('DD MMM')}`}
        </h1>

        <h2>
          <Translate value="decision_support_view.trend_period.average" />
        </h2>
        <div>
          {period.mean ? period.mean.systolic : '-'}/{period.mean ? period.mean.diastolic : '-'} mmHg
        </div>

        <h2>
          <Translate value="decision_support_view.trend_period.number_of_measurements" />
        </h2>
        <div>{period.totalCount || '0'}</div>

        <h2>
          <Translate value="decision_support_view.trend_period.average_morning" /> ({period.countMorning || '0'})
        </h2>
        <div>
          {period.meanMorning ? period.meanMorning.systolic : '-'}/
          {period.meanMorning ? period.meanMorning.diastolic : '-'} mmHg
        </div>

        <h2>
          <Translate value="decision_support_view.trend_period.average_day" /> ({period.countDay || '0'})
        </h2>
        <div>
          {period.meanDay ? period.meanDay.systolic : '-'}/{period.meanDay ? period.meanDay.diastolic : '-'} mmHg
        </div>

        <h2>
          <Translate value="decision_support_view.trend_period.average_evening" /> ({period.countEvening || '0'})
        </h2>
        <div>
          {period.meanEvening ? period.meanEvening.systolic : '-'}/
          {period.meanEvening ? period.meanEvening.diastolic : '-'} mmHg
        </div>

        <h2>
          <Translate value="decision_support_view.trend_period.average_night" /> ({period.countNight || '0'})
        </h2>
        <div>
          {period.meanNight ? period.meanNight.systolic : '-'}/{period.meanNight ? period.meanNight.diastolic : '-'}{' '}
          mmHg
        </div>

        <h2>
          <Translate value="decision_support_view.trend_period.median" />
        </h2>
        <div>
          {period.systolicMedian ? period.systolicMedian.systolic : '-'}/
          {period.systolicMedian ? period.systolicMedian.diastolic : '-'} mmHg
        </div>

        <h2>
          <Translate value="decision_support_view.trend_period.heartrate" />
        </h2>
        <div>{period.mean ? Math.round(period.mean.heartrate) : '-'} BPM</div>

        {/* <h2>Trend sedan föreg.</h2>
        <div>-23 %</div> */}
      </div>
    );
  }
}

export default MeasurementPeriod;
