import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';

export const makeDisableable = (WrappedComponent) => {
  return class DisableableComponent extends Component {
    render() {
      const { disabled, disabledTitleI18n, ...otherProps } = this.props;

      if (disabled) {
        return (
          <div className="cursor-not-allowed" title={I18n.t(disabledTitleI18n)}>
            <div className="disabled">
              <WrappedComponent {...otherProps} />
            </div>
          </div>
        );
      }

      return <WrappedComponent {...otherProps} />;
    }
  };
};
