import * as types from '../constants/actionTypes';

const anamnesisChaptersReducer = (state = { chapters: [] }, action) => {
  switch (action.type) {
    case types.GET_CHAPTERS_REQUEST:
      return { ...state, loadingChapters: true };
    case types.GET_CHAPTERS_SUCCESS:
      return { ...state, chapters: action.payload, loadingChapters: false, error: undefined };
    case types.GET_CHAPTERS_ERROR:
      return { ...state, loadingChapters: false, error: action.payload };
    default:
      return state;
  }
};

export default anamnesisChaptersReducer;
