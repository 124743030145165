import React, { Component } from 'react';
import { Translate } from 'react-redux-i18n';
import moment from 'moment';
import ErrorMessage from '../../ErrorMessage';
import './MeasurementsHistoryTable.scss';
import '../DecisionSupportTable/DecisionSupportTable.scss';

class MeasurementsHistoryTable extends Component {
  constructor(props) {
    super(props);

    this.showAllStandingMeasurements = this.showAllStandingMeasurements.bind(this);
    this.showAllSymptomaticMeasurements = this.showAllSymptomaticMeasurements.bind(this);
    this.hideStandingMeasurements = this.hideStandingMeasurements.bind(this);
    this.hideSymptomaticMeasurements = this.hideSymptomaticMeasurements.bind(this);

    this.state = {
      showAllStandingMeasurements: false,
      showAllSymptomaticMeasurements: false
    };
  }

  showAllStandingMeasurements() {
    this.setState({
      showAllStandingMeasurements: true
    });
  }

  hideStandingMeasurements() {
    this.setState({
      showAllStandingMeasurements: false
    });
  }

  showAllSymptomaticMeasurements() {
    this.setState({
      showAllSymptomaticMeasurements: true
    });
  }

  hideSymptomaticMeasurements() {
    this.setState({
      showAllSymptomaticMeasurements: false
    });
  }

  render() {
    const { error, errorTitleI18n, standingMeasurements, symptomaticMeasurements } = this.props;

    if (error) {
      return <ErrorMessage error={error} errorI18nKey={errorTitleI18n} />;
    }

    return (
      <div className="measurement-history-table">
        <div className="columns column-headers">
          <div className="column is-3">
            <Translate value="decision_support_view.current_status_section.measurement_type" />
          </div>
          <div className="column is-6">
            <Translate value="decision_support_view.current_status_section.latest_result" />
          </div>
          <div className="column is-3">
            <Translate value="global.date" />
          </div>
        </div>

        <MeasurementsHistoryTableRow
          measurements={standingMeasurements}
          showAll={this.state.showAllStandingMeasurements}
          showAllFn={this.showAllStandingMeasurements}
          hideFn={this.hideStandingMeasurements}
          categoryTextI18n="decision_support_view.current_status_section.standing_measurement"
          showAllTextI18n="decision_support_view.current_status_section.show_all_standing_measurements"
          hideTextI18n="decision_support_view.current_status_section.hide_standing_measurements"
          noDataTextI18n="decision_support_view.current_status_section.no_standing_measurements"
          showBodyPosition={true}
        />

        <MeasurementsHistoryTableRow
          measurements={symptomaticMeasurements}
          showAll={this.state.showAllSymptomaticMeasurements}
          showAllFn={this.showAllSymptomaticMeasurements}
          hideFn={this.hideSymptomaticMeasurements}
          categoryTextI18n="decision_support_view.current_status_section.symptomatic_measurement"
          showAllTextI18n="decision_support_view.current_status_section.show_all_symptomatic_measurements"
          hideTextI18n="decision_support_view.current_status_section.hide_symptomatic_measurements"
          noDataTextI18n="decision_support_view.current_status_section.no_symptomatic_measurements"
          hideFirstBorder={true}
        />
      </div>
    );
  }
}

export default MeasurementsHistoryTable;

class MeasurementsHistoryTableRow extends Component {
  render() {
    const {
      measurements,
      showAll,
      showAllFn,
      categoryTextI18n,
      showAllTextI18n,
      noDataTextI18n,
      showBodyPosition,
      hideFirstBorder,
      hideFn,
      hideTextI18n
    } = this.props;

    return measurements && measurements.length ? (
      measurements.map((measurement, i) => {
        return (
          <div
            className={`columns measurement-history-row ${i === 0 || showAll ? '' : 'hidden'} ${
              hideFirstBorder && !showAll ? 'no-border-bottom' : ''
            }`}
            key={i}
          >
            <div className="column is-3">
              <Translate value={categoryTextI18n} />
            </div>
            <div className="column is-6">
              {measurement.bpmeasurements.map((m, i) => {
                return (
                  <p key={i}>
                    {measurement.bpmeasurements.length > 1 ? `${i + 1}. ` : ''}
                    {showBodyPosition ? (
                      <Translate
                        value={
                          i === 0
                            ? 'decision_support_view.body_position.sitting'
                            : 'decision_support_view.body_position.standing'
                        }
                      />
                    ) : null}{' '}
                    {m.systolic}/{m.diastolic} mmHg, <Translate value="global.heartrate" /> {m.heartrate}
                    {measurement.comment ? ` – ${measurement.comment}` : ''}
                  </p>
                );
              })}
              {i === 0 && measurements.length > 1 ? (
                <div className={`show-historical-measurements ${showAll ? 'hidden' : ''}`}>
                  <span className="text-button fs-16" onClick={showAllFn}>
                    <Translate value={showAllTextI18n} />
                  </span>
                </div>
              ) : i === measurements.length - 1 ? (
                <div className={`hide-historical-measurements ${showAll ? '' : 'hidden'}`}>
                  <span className="text-button fs-16" onClick={hideFn}>
                    <Translate value={hideTextI18n} />
                  </span>
                </div>
              ) : null}
            </div>
            <div className="column is-3">
              {moment(measurement.bpmeasurements[0].timestamp).format('YYYY-MM-DD HH:mm')}
            </div>
          </div>
        );
      })
    ) : (
      <div className="columns measurement-history-row">
        <div className="column is-4">
          <Translate value={categoryTextI18n} />
        </div>
        <div className="column is-6">
          <Translate value={noDataTextI18n} />
        </div>
      </div>
    );
  }
}
