import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { Link } from 'react-router-dom';
import TopBar from '../TopBar';
import ErrandRow from '../ErrandRow';
import ContentLoader from '../ContentLoader';
import HasAuthority from '../HasAuthority';
import CaregiverDashboard from '../CaregiverDashboard';
import { APPLICATION_BASE_PATH, AUTHORITY, INBOX_PRIORITY_LIMIT, INBOX_VISITED_PATIENTS_KEY } from '../../constants';
import {
  sortEvents,
  getEvents,
  getHandledEvents,
  toggleShowOwnEvents,
  getNewMembers,
  assignResponsibleDoctor,
  getCaregiverMetrics,
  clearDssData,
  notifyClearDss,
  getPrioritizedEvents,
  getActiveConsultations,
  getCheckupCalendar
} from '../../actions';
import './InboxView.scss';
import { loadState, saveState } from '../../services/localStorage';
import LoadingSpinner from '../LoadingSpinner';
import UnauthorizedAccessWarning from '../UnauthorizedAccessWarning';
import CaregiverPriorityList from './CaregiverPriorityList';
import SortControl from './SortControl';
import { Mixpanel } from '../../utils/mixpanel';

class InboxView extends Component {
  constructor(props) {
    super(props);

    this.handleSortControlClick = this.handleSortControlClick.bind(this);
    this.handleSelectSortOption = this.handleSelectSortOption.bind(this);
    this.handleShowOnlyOwnErrandsCheckedChange = this.handleShowOnlyOwnErrandsCheckedChange.bind(this);
    this.selfAssignResponsibleDoctor = this.selfAssignResponsibleDoctor.bind(this);
    this.navigateToMember = this.navigateToMember.bind(this);
    this.getHandledEvents = this.getHandledEvents.bind(this);
    this.getCaregiverMetrics = this.getCaregiverMetrics.bind(this);
    this.markAsUnread = this.markAsUnread.bind(this);

    this.state = {
      sortOptions: [
        'newMessage',
        'checkup',
        'referralFailed',
        'lowBloodpressure',
        'highBloodpressure',
        'alarmValue',
        'labResult',
        'profileUpdate',
        'standing',
        'symptom',
        'threeMonthCheckup',
        'yearlyCheckup',
        'followupMissing',
        'newMedication',
        'patientNotReady',
        'nurseReminder',
        'nurseCheckup',
        'onboardingComplete',
        'screeningResult'
      ],
      sortDropdownOpen: false,
      showHandledEvents: false,
      visitedPatients: {}
    };
  }

  componentDidMount() {
    Mixpanel.resetSuperProperties();
    if (!this.props.events.isLoading) {
      this.props.getEvents(
        this.props.authToken,
        this.props.user.guid,
        this.props.isNurse,
        this.props.events.viewingAllEvents
      );
    }

    this.props.getNewMembers(this.props.authToken, this.props.isNurse);
    this.props.getCaregiverMetrics(this.props.authToken);
    if (this.props.isNurse) {
      this.props.getPrioritizedEvents(this.props.authToken, this.props.user.guid, INBOX_PRIORITY_LIMIT);
      this.props.getActiveConsultations(this.props.authToken, this.props.user.guid, INBOX_PRIORITY_LIMIT);
    }

    this.setState({
      visitedPatients: loadState(INBOX_VISITED_PATIENTS_KEY, true) || {}
    });

    this.props.getCheckupCalendar(this.props.authToken);
  }

  handleSortControlClick() {
    this.setState({
      sortDropdownOpen: !this.state.sortDropdownOpen
    });
  }

  handleSelectSortOption(newSortOption) {
    this.setState({
      sortDropdownOpen: false
    });

    this.props.sortEvents(newSortOption);
  }

  handleShowOnlyOwnErrandsCheckedChange() {
    this.props.getEvents(
      this.props.authToken,
      this.props.user.guid,
      this.props.isNurse,
      !this.props.events.viewingAllEvents
    );

    if (this.state.showHandledEvents) {
      this.props.getHandledEvents(
        this.props.authToken,
        this.props.user.guid,
        this.props.isNurse,
        !this.props.events.viewingAllEvents
      );
    }
  }

  selfAssignResponsibleDoctor(memberGuid) {
    return (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.props.assignResponsibleDoctor(this.props.authToken, memberGuid, this.props.isNurse);
    };
  }

  navigateToMember(member) {
    return () => {
      if (!this.props.decisionSupport.isCleared || this.props.decisionSupport.lastTwoWeekAverage !== null) {
        this.props.clearDssData();
        this.props.notifyClearDss();
        return;
      }
      this.props.history.push(`/${APPLICATION_BASE_PATH}/member/${member.guid}`);
    };
  }

  getHandledEvents() {
    this.setState({
      showHandledEvents: true
    });
    this.props.getHandledEvents(
      this.props.authToken,
      this.props.user.guid,
      this.props.isNurse,
      this.props.events.viewingAllEvents
    );
  }

  getCaregiverMetrics(period) {
    this.props.getCaregiverMetrics(this.props.authToken, period);
  }

  markAsUnread(memberGuid) {
    this.setState(
      {
        visitedPatients: { ...this.state.visitedPatients, [memberGuid]: undefined }
      },
      () => {
        saveState(this.state.visitedPatients, INBOX_VISITED_PATIENTS_KEY);
      }
    );
  }

  render() {
    const { events, user, newMembers, dashboard, isNurse } = this.props;

    return (
      <Fragment>
        <UnauthorizedAccessWarning ownPatients={events.caregiverIsResponsibleFor} />
        <div className="column no-padding">
          <div
            className={`dropdown-overlay ${this.state.sortDropdownOpen ? 'dropdown-overlay__active' : ''}`}
            onClick={this.handleSortControlClick}
          ></div>
          <TopBar header="inbox_view.header" />
          <div className="wrapper">
            <HasAuthority authority={[AUTHORITY.CAREGIVER, AUTHORITY.NURSE]}>
              <CaregiverDashboard
                metrics={dashboard.caregiverMetrics}
                caregiver={user}
                isLoading={dashboard.loadingCaregiverMetrics}
                error={dashboard.caregiverMetricsError}
                onPeriodChange={this.getCaregiverMetrics}
                isNurse={isNurse}
                bookedCheckups={dashboard.bookedCheckups}
              />

              <ContentLoader
                isLoading={newMembers.isLoading}
                error={newMembers.error}
                errorTitleI18n="inbox_view.new_members_error"
                hasContent={newMembers.unassigned.length > 0}
                showEmptyContent={false}
              >
                <Link to={`${APPLICATION_BASE_PATH}/unassigned`} className="rounded-container unassigned-link">
                  <div className="unassigned-content">
                    <span className="icon triangle mr-15"></span>
                    <span className="num-unassigned">{newMembers.unassigned.length}</span>{' '}
                    <Translate value="inbox_view.n_new_patients" />
                  </div>
                  <div>
                    <span className="text-button chevron-right"></span>
                  </div>
                </Link>
              </ContentLoader>
            </HasAuthority>
            <HasAuthority authority={AUTHORITY.NURSE}>
              <div className="mt-50">
                <div className="columns">
                  <div className="column no-padding mr-20">
                    <CaregiverPriorityList
                      onRowClick={this.navigateToMember}
                      items={events.activeConsultations}
                      totalNumItems={events.totalNumActiveConsultations}
                      isLoading={events.loadingActiveConsultations}
                      fullPageLinkUrl={`${APPLICATION_BASE_PATH}/active-consultations`}
                      i18nKey="active_consultations_view"
                      visitedPatients={this.state.visitedPatients}
                      itemLimit={INBOX_PRIORITY_LIMIT}
                    />
                  </div>
                  <div className="column no-padding">
                    <CaregiverPriorityList
                      onRowClick={this.navigateToMember}
                      items={events.prioritizedEvents}
                      totalNumItems={events.totalNumPrioritizedEvents}
                      isLoading={events.loadingPrioritizedEvents}
                      fullPageLinkUrl={`${APPLICATION_BASE_PATH}/priority-errands`}
                      i18nKey="prioritized_errands_view"
                      visitedPatients={this.state.visitedPatients}
                      itemLimit={INBOX_PRIORITY_LIMIT}
                    />
                  </div>
                </div>
              </div>
            </HasAuthority>
            <div className="member-row-container mt-50">
              <div className="columns">
                <div className="column is-8 no-padding">
                  <h2>
                    <Translate value="inbox_view.action_required" />
                  </h2>
                </div>
                <div className="column is-4">
                  <SortControl currentlySortedBy={this.props.events.sortedBy} onSort={this.props.sortEvents} />
                </div>
              </div>

              <div className="event-list-actions">
                <div>
                  <span className="mr-5">{this.props.events.isLoading ? <LoadingSpinner size="smaller" /> : null}</span>
                  <input
                    type="checkbox"
                    checked={this.props.events.viewingAllEvents}
                    id="show-all-checkbox"
                    name="show-all-checkbox"
                    disabled={this.props.events.isLoading}
                    onChange={this.handleShowOnlyOwnErrandsCheckedChange}
                  />
                  <label htmlFor="show-all-checkbox">
                    <Translate value="inbox_view.show_all_errands" />
                  </label>
                </div>
              </div>

              <div className="columns errand-headings">
                <div className="column">
                  <Translate value="global.name" />
                </div>
                <div className="column is-3">
                  <Translate value="inbox_view.wait_time" />
                </div>
                <div className="column is-1">
                  <Translate value={`inbox_view.responsible_${isNurse ? 'nurse' : 'doctor'}`} />
                </div>
                <div className="column is-2">
                  <Translate value="global.source" />
                </div>
                <div className="column"></div>
              </div>

              <ContentLoader
                isLoading={events.isLoading}
                error={events.error}
                errorTitleI18n="inbox_view.events_error"
                hasContent={!!(events.userEvents && events.userEvents.length)}
              >
                <div className="errand-row-container">
                  <Fragment>
                    {events.userEvents && events.userEvents.length ? (
                      events.userEvents.map((userEvent) => {
                        const eventIds = userEvent.events.map((event) => event.id);
                        const storedEvents = this.state.visitedPatients[userEvent.member.guid];

                        return (
                          <ErrandRow
                            key={userEvent.member.guid}
                            visited={storedEvents && eventIds.every((id) => storedEvents.includes(id))}
                            userEvent={userEvent}
                            historical={false}
                            currentUser={user}
                            onClick={this.navigateToMember(userEvent.member)}
                            onMarkAsUnread={() => this.markAsUnread(userEvent.member.guid)}
                            authorities={this.props.authorities}
                            isNurse={isNurse}
                          />
                        );
                      })
                    ) : (
                      <div className="no-table-rows">
                        <Translate
                          value={`inbox_view.${!this.props.events.viewingAllEvents ? 'own_' : ''}events_empty`}
                        />
                      </div>
                    )}
                  </Fragment>
                </div>
              </ContentLoader>

              <h2>
                <Translate value="inbox_view.handled_events_header" />
              </h2>

              {this.state.showHandledEvents ? (
                <Fragment>
                  <div className="columns errand-headings">
                    <div className="column">
                      <Translate value="global.name" />
                    </div>
                    <div className="column is-3">
                      <Translate value="inbox_view.handled_date" />
                    </div>
                    <div className="column is-1">
                      <Translate value={`inbox_view.responsible_${isNurse ? 'nurse' : 'doctor'}`} />
                    </div>
                    <div className="column is-2">
                      <Translate value="global.source" />
                    </div>
                    <div className="column has-text-right">
                      <Translate value="inbox_view.handled_reason" />
                    </div>
                  </div>

                  <ContentLoader
                    isLoading={events.handledEventsIsLoading}
                    error={events.handledUserEventsError}
                    errorTitleI18n="inbox_view.handled_events_error"
                  >
                    <div className="errand-row-container">
                      <div>
                        {events.handledUserEvents && events.handledUserEvents.length ? (
                          events.handledUserEvents.map((handledUserEvent) => {
                            return (
                              <ErrandRow
                                userEvent={handledUserEvent}
                                key={handledUserEvent.member.guid}
                                historical={true}
                                currentUser={user}
                                onClick={this.navigateToMember(handledUserEvent.member)}
                                isNurse={isNurse}
                                visited={true}
                                authorities={this.props.authorities}
                              />
                            );
                          })
                        ) : (
                          <div className="no-table-rows">
                            <Translate
                              value={`inbox_view.${
                                !this.props.events.viewingAllEvents ? 'own_' : ''
                              }handled_events_empty`}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </ContentLoader>
                </Fragment>
              ) : (
                <button onClick={this.getHandledEvents} className="button is-primary">
                  <Translate value="inbox_view.fetch_handled_events" />
                </button>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    events: state.events,
    user: state.auth.token.user,
    newMembers: state.newMembers,
    ui: state.ui,
    authorities: state.auth.authorities,
    dashboard: state.dashboard,
    isNurse: state.auth.isNurse,
    decisionSupport: state.decisionSupport
  };
};

const mapActionsToProps = {
  sortEvents,
  getEvents,
  getHandledEvents,
  toggleShowOwnEvents,
  getNewMembers,
  assignResponsibleDoctor,
  getCaregiverMetrics,
  clearDssData,
  notifyClearDss,
  getPrioritizedEvents,
  getActiveConsultations,
  getCheckupCalendar
};

export default connect(mapStateToProps, mapActionsToProps)(InboxView);
