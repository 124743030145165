export const loadSessionState = (key, parse) => {
  try {
    const serializedState = sessionStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }

    return parse ? JSON.parse(serializedState) : serializedState;
  } catch (err) {
    return undefined;
  }
};

export const saveSessionState = (state, key) => {
  try {
    const serializedState = typeof state === 'string' ? state : JSON.stringify(state);
    sessionStorage.setItem(key, serializedState);
  } catch (err) {
    // Ignore
  }
};

export const clearStorage = () => {
  sessionStorage.clear();
};
