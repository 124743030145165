import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import LoadingSpinner from '../../LoadingSpinner';

class InfoSolutionsLabResults extends Component {
  render() {
    const { referrals } = this.props;

    return referrals.allLabResultsLoading ? (
      <LoadingSpinner size="small" />
    ) : (
      <section>
        {referrals &&
        !referrals.allLabResultsError &&
        referrals.allLabResults &&
        referrals.allLabResults.redirectUrl ? (
          <iframe src={referrals.allLabResults.redirectUrl} frameBorder="0" title="Info Solutions"></iframe>
        ) : (
          <Translate value="patient_view.referrals.error" />
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    referrals: state.referrals
  };
};

export default connect(mapStateToProps)(InfoSolutionsLabResults);
