import { combineReducers } from 'redux';
import patientDataReducer from './patientDataReducer';
import anamnesisChaptersReducer from './anamnesisChaptersReducer';
import authReducer from './authReducer';
import patientChatReducer from './patientChatReducer';
import currentMemberReducer from './currentMemberReducer';
import eventsReducer from './eventsReducer';
import referralsReducer from './referralsReducer';
import medicationReducer from './medicationReducer';
import notesReducer from './notesReducer';
import decisionSupportReducer from './decisionSupportReducer';
import notificationReducer from './notificationReducer';
import uiReducer from './uiReducer';
import newMembersReducer from './newMembersReducer';
import filesReducer from './filesReducer';
import previewReducer from './previewReducer';
import dashboardReducer from './dashboardReducer';
import jiraReducer from './jiraReducer';
import membersListReducer from './membersListReducer';
import checkupsReducer from './checkupsReducer';
import patientCommunicationReducer from './patientCommunicationReducer';
import examinationReducer from './examinationReducer';
import assessmentReducer from './assessmentReducer';
import { i18nReducer } from 'react-redux-i18n';

const reducers = combineReducers({
  patientHealthProfile: patientDataReducer,
  anamnesisChapters: anamnesisChaptersReducer,
  membersList: membersListReducer,
  patientChat: patientChatReducer,
  currentMember: currentMemberReducer,
  auth: authReducer,
  events: eventsReducer,
  referrals: referralsReducer,
  medications: medicationReducer,
  notes: notesReducer,
  decisionSupport: decisionSupportReducer,
  notifications: notificationReducer,
  ui: uiReducer,
  newMembers: newMembersReducer,
  files: filesReducer,
  preview: previewReducer,
  dashboard: dashboardReducer,
  jira: jiraReducer,
  checkups: checkupsReducer,
  patientCommunication: patientCommunicationReducer,
  examination: examinationReducer,
  assessments: assessmentReducer,
  i18n: i18nReducer // Needs to come last in this list
});

export default reducers;
