import React, { Component } from 'react';
import RiskFactorRecommendationRow from '../RiskFactorRecommendationRow';
import RiskFactorStatusRow from '../RiskFactorStatusRow';
import './RiskFactorStatus.scss';

class RiskFactorStatus extends Component {
  render() {
    return (
      <div className="risk-factor">
        <RiskFactorStatusRow {...this.props} showTimestamp={true} />
        <RiskFactorRecommendationRow {...this.props} />
      </div>
    );
  }
}

export default RiskFactorStatus;
