import mixpanel from 'mixpanel-browser';

const trackingEnabled = process.env.REACT_APP_MIXPANEL_TRACKING === 'enabled';

if (trackingEnabled) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { api_host: 'https://api-eu.mixpanel.com' }, '');
}

const Mixpanel = {
  identify: (id) => {
    if (trackingEnabled) {
      mixpanel.identify(id);
    } else {
      console.log('Identify', id);
    }
  },
  set: (property, value) => {
    if (trackingEnabled) {
      mixpanel.people.set(property, value);
    } else {
      console.log('Setting property', property, value);
    }
  },
  track: (name, props) => {
    if (trackingEnabled) {
      mixpanel.track(name, props);
    } else {
      console.log('Tracking event', name, props);
    }
  },
  time_event: (name) => {
    if (trackingEnabled) {
      mixpanel.time_event(name);
    } else {
      console.log('Timing event', name);
    }
  },
  register: (superProperties) => {
    if (trackingEnabled) {
      mixpanel.register(superProperties);
    } else {
      console.log('Registering super properties', superProperties);
    }
  },
  resetSuperProperties: () => {
    if (trackingEnabled) {
      mixpanel.register({
        nextGen: null,
        patientId: null,
        encounter: null
      });
    } else {
      console.log('Resetting super properties');
    }
  },
  reset: () => {
    if (trackingEnabled) {
      mixpanel.reset();
    }
  }
};

const eventType = {
  assignCaregiver: 'Assign caregiver',
  viewPatient: 'View patient',
  signMedication: 'Sign medication',
  writeChatMessage: 'Write chat message',
  writeFinalNote: 'Write final note',
  viewUnassignedPatient: 'View unassigned patient',
  selectChatResponse: 'Select chat response',
  loginFailure: 'Login failure',
  dss: 'Tab DSS',
  notes: 'Tab Notes',
  medications: 'Tab Medications',
  anamnesis: 'Tab Anamnesis',
  referrals: 'Tab Referrals',
  patientVisit: 'Visit',
  lagAfterSigning: 'Lag after signing',
  tabDisabled: 'Tab disabled',
  chatMessageType: 'Chat message',
  selectChatSearchResult: 'Select chat search result',
  clearDssData: 'Clear remaining DSS data',
  fetchErrands: 'Fetch errands',
  handleErrand: 'Handle errand',
  sortErrands: 'Sort errands',
  uploadProfileImage: 'Upload profile image',
  snoozeErrand: 'Snooze errand',
  viewPatientComment: 'View patient comment',
  updatePatientComment: 'Update patient comment',
  filterChat: 'Filter chat',
  handleKioskAssessment: 'Action|HandleKioskAssessment',
  assessTestAuscultation: 'Action|AssessTestAuscultation',
  viewJournalTab: 'Action|ViewJournalTab',
  assessMedicalRecordUpdate: 'Action|AssessMedicalRecordUpdate',
  viewAssessmentDetails: 'Action|ViewAssessmentDetails',
  sendPatientFeedback: 'Action|SendPatientFeedback',
  finalNote: 'Action|FinalNote',
  viewChatHistory: 'Action|ViewChatHistory',
  handleNotification: 'Action|HandleNotification',
  bypassNonCaregiverWarning: 'Action|BypassNonCaregiverWarning',
  sendChatNow: 'Action|SendChatNow'
};

export { Mixpanel, eventType };
