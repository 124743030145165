import React, { Component, Fragment } from 'react';
import { NavLink, Prompt } from 'react-router-dom';
import { Translate, I18n } from 'react-redux-i18n';
import { DraggableCore } from 'react-draggable';
import { connect } from 'react-redux';
import { DebounceInput } from 'react-debounce-input';
import { ErrorBoundary } from '@sentry/react';
import Referrals from '../../components/Referrals';
import MedicationView from '../../components/MedicationView';
import HealthProfile from '../../components/HealthProfile';
import PatientChat, { PatientChatErrorFallback } from '../../components/PatientChat';
import PrivateRoute from '../../components/PrivateRoute';
import MemberEventList from '../MemberEventList';
import MemberEventSummary from '../MemberEventSummary';
import NotesView from '../../components/NotesView';
import ProgramTimelineView from '../ProgramTimelineView';
import DecisionSupportView from '../../components/DecisionSupportView';
import ModalDialog from '../ModalDialog';
import FilePreview from '../FilePreview';
import ImageViewer from '../ImageViewer';
import HideOnScroll from '../HideOnScroll';
import PatientComments from './PatientComments';
import { AUTHORITY, DEFAULT_CHAT_WIDTH, MAX_NOTE_LENGTH, TEMPLATE_TYPE } from '../../constants';
import {
  getPatientData,
  chaptersSuccess,
  chaptersError,
  getUserProfile,
  clearCurrentMember,
  clearPatientData,
  getBloodPressureMeasurements,
  changeTab,
  getBloodPressureStats,
  clearReferralSession,
  getReferralSession,
  getMemberEventHistory,
  clearMemberEventHistory,
  getNotes,
  clearNotes,
  clearMedications,
  getDecisionSupportSummary,
  getMeasurementStatus,
  getBloodPressureSummary,
  clearDssData,
  getDssLabResults,
  getBloodPressureTrends,
  getDssRiskProfile,
  getDssStatus,
  getDssAllergyItem,
  getMemberMedications,
  collapseChat,
  expandChat,
  getMemberDevices,
  getObjectives,
  getAnamnesisUpdateSummary,
  getMemberFiles,
  clearMemberFiles,
  hideFullSizeImage,
  hideDayNoteModal,
  updateDayNoteContent,
  addDayNote,
  getMemberMedicineExtras,
  toggleMemberEventList,
  clearMemberActiveEvents,
  getMemberActiveEvents,
  getChatResponses,
  getReferrals,
  clearChatMessages,
  getTwoWeekTrend,
  getMemberComment,
  saveMemberComment,
  updateMemberCommentText,
  toggleMemberCommentField,
  getDocumentTemplateContent,
  getMemberMonitors,
  getCardiopulmonaryExamination,
  getMemberActionTasks
} from '../../actions';
import './PatientView.scss';
import GenericErrorFallback from '../GenericErrorFallback';
import PatientAlerts from './PatientAlerts';
import { Mixpanel } from '../../utils/mixpanel';

class PatientView extends Component {
  constructor(props) {
    super(props);

    this.changeTab = this.changeTab.bind(this);
    this.toggleChat = this.toggleChat.bind(this);
    this.addDayNote = this.addDayNote.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.saveMemberComment = this.saveMemberComment.bind(this);

    this.state = {
      size: window.innerWidth * DEFAULT_CHAT_WIDTH
    };
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.preventNavigation);
    this.props.clearCurrentMember();
    this.props.clearPatientData();
    this.props.clearChatMessages();
    this.props.clearReferralSession();
    this.props.clearNotes();
    this.props.clearMedications();
    this.props.clearDssData();
    this.props.clearMemberFiles();
    this.props.clearMemberEventHistory();
    this.props.clearMemberActiveEvents();
  }

  componentDidMount() {
    Mixpanel.resetSuperProperties();
    window.scrollTo(0, 0);

    const currentMemberGuid = this.props.match.params.memberId;

    this.props.getUserProfile(this.props.authToken, currentMemberGuid, this.props.caregiverGuid);
    this.props.getPatientData(this.props.authToken, currentMemberGuid);
    this.props.getBloodPressureMeasurements(this.props.authToken, currentMemberGuid);
    this.props.getBloodPressureStats(this.props.authToken, currentMemberGuid);
    this.props.getMemberEventHistory(this.props.authToken, currentMemberGuid);
    this.props.getNotes(this.props.authToken, currentMemberGuid);
    this.props.getDecisionSupportSummary(this.props.authToken, currentMemberGuid);
    this.props.getMeasurementStatus(this.props.authToken, currentMemberGuid);
    this.props.getBloodPressureSummary(this.props.authToken, currentMemberGuid);
    this.props.getDssLabResults(this.props.authToken, currentMemberGuid, this.props.i18n.locale);
    this.props.getBloodPressureTrends(this.props.authToken, currentMemberGuid);
    this.props.getDssRiskProfile(this.props.authToken, currentMemberGuid);
    this.props.getDssStatus(this.props.authToken, currentMemberGuid);
    this.props.getDssAllergyItem(this.props.authToken, currentMemberGuid);
    this.props.getMemberMedications(this.props.authToken, currentMemberGuid);
    this.props.getMemberDevices(this.props.authToken, currentMemberGuid);
    this.props.getObjectives(this.props.authToken, currentMemberGuid);
    this.props.getAnamnesisUpdateSummary(this.props.authToken, currentMemberGuid);
    this.props.getMemberFiles(this.props.authToken, currentMemberGuid);
    this.props.getReferralSession(this.props.authToken, currentMemberGuid);
    this.props.getMemberMedicineExtras(this.props.authToken, currentMemberGuid);
    this.props.getMemberActiveEvents(this.props.authToken, currentMemberGuid, this.props.caregiverGuid);
    this.props.getChatResponses(this.props.authToken, currentMemberGuid);
    this.props.getReferrals(this.props.authToken, currentMemberGuid);
    this.props.getTwoWeekTrend(this.props.authToken, currentMemberGuid);
    this.props.getMemberComment(this.props.authToken, currentMemberGuid);
    this.props.getMemberMonitors(this.props.authToken, currentMemberGuid);
    this.props.getCardiopulmonaryExamination(this.props.authToken, currentMemberGuid);
    this.props.getMemberActionTasks(this.props.authToken, currentMemberGuid);
  }

  preventNavigation(e) {
    // Cancel the event as stated by the standard.
    e.preventDefault();
    // Chrome requires returnValue to be set.
    e.returnValue = '';
  }

  componentDidUpdate(prevProps) {
    if (this.props.authorities.indexOf(AUTHORITY.CAREGIVER) > -1) {
      if (
        this.props.decisionSupport.hasUnsavedChanges ||
        this.props.decisionSupport.hasUnsavedBloodPressureGoalChanges ||
        this.props.decisionSupport.hasUnSavedCheckup ||
        (this.props.chat.newDraftText !== null && this.props.chat.newDraftText !== this.props.chat.originalDraftText)
      ) {
        window.addEventListener('beforeunload', this.preventNavigation);
      } else {
        window.removeEventListener('beforeunload', this.preventNavigation);
      }
    }

    if (this.props.member?.pendingDiagnosis === 'preppingForCheckup' && !prevProps.member?.pendingDiagnosis) {
      this.props.getDocumentTemplateContent(
        this.props.authToken,
        this.props.match.params.memberId,
        TEMPLATE_TYPE.diagnosisScreening
      );
    }
  }

  changeTab(tabName) {
    window.scrollTo(0, 0);
    this.props.changeTab(tabName);
  }

  toggleChat() {
    if (this.state.isDragging) {
      return;
    }

    if (this.props.ui.chatCollapsed) {
      this.props.expandChat();
    } else {
      this.props.collapseChat();
    }
  }

  addDayNote() {
    this.props.addDayNote(this.props.authToken, this.props.match.params.memberId, this.props.notes.dayNoteContent);
  }

  handleDrag(_, ui) {
    if (this.props.ui.chatCollapsed) {
      return;
    }

    if (!this.state.isDragging) {
      this.setState({
        isDragging: true
      });
    }

    this.setState((state) => {
      let newSize = state.size - ui.deltaX;

      if (newSize < window.innerWidth * DEFAULT_CHAT_WIDTH) {
        newSize = window.innerWidth * DEFAULT_CHAT_WIDTH;
      }

      return { size: newSize };
    });
  }

  handleDragEnd() {
    // Flush to the end of the event queue
    setTimeout(() => {
      this.setState({
        isDragging: false
      });
    });
  }

  saveMemberComment(callback) {
    this.props.saveMemberComment(
      this.props.authToken,
      this.props.match.params.memberId,
      this.props.notes.memberCommentText,
      callback
    );
  }

  render() {
    const {
      member,
      events,
      i18n,
      allergy,
      oversensitivity,
      decisionSupport,
      preview,
      files,
      hideFullSizeImage,
      notes,
      hideDayNoteModal,
      updateDayNoteContent,
      toggleMemberEventList
    } = this.props;

    const dragHandlers = {
      onDrag: this.handleDrag,
      onStop: this.handleDragEnd
    };

    if (member.getUserProfileError) {
      return (
        <GenericErrorFallback>
          <Translate value="patient_view.error_loading_patient" />
        </GenericErrorFallback>
      );
    }

    return (
      <Fragment>
        <Prompt
          message={(location) => {
            if (
              (notes.dayNoteContent.length ||
                decisionSupport.hasUnsavedChanges ||
                decisionSupport.hasUnsavedBloodPressureGoalChanges ||
                decisionSupport.hasUnSavedCheckup ||
                (notes.memberCommentText && notes.memberCommentText !== notes.memberComment.text)) &&
              location.pathname.indexOf(this.props.match.params.memberId) === -1
            ) {
              return I18n.t('patient_view.unsaved_changes_prompt');
            }

            return true;
          }}
        />
        <div className="columns">
          <div className="column no-padding tab-content-container">
            <HideOnScroll>
              <div className="tabs">
                <div className="columns">
                  <div className="column no-padding is-9 tab-column">
                    <ul>
                      <li>
                        <NavLink
                          exact
                          to={`${this.props.match.url}`}
                          activeClassName="is-active"
                          onClick={() => this.changeTab('')}
                        >
                          <Translate value="patient_view.tabs.decision_support" />
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={`${this.props.match.url}/anamnesis`}
                          activeClassName="is-active"
                          onClick={() => this.changeTab('anamnesis')}
                        >
                          <Translate value="patient_view.tabs.health_profile" />
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={`${this.props.match.url}/referrals`}
                          activeClassName="is-active"
                          onClick={() => this.changeTab('referrals')}
                        >
                          <Translate value="patient_view.tabs.referrals" />
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={`${this.props.match.url}/medications`}
                          activeClassName="is-active"
                          onClick={() => this.changeTab('medications')}
                        >
                          <Translate value="patient_view.tabs.medicines" />
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={`${this.props.match.url}/notes`}
                          activeClassName="is-active"
                          onClick={() => this.changeTab('notes')}
                        >
                          <Translate value="patient_view.tabs.notes" />
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={`${this.props.match.url}/timeline`}
                          activeClassName="is-active"
                          onClick={() => this.changeTab('timeline')}
                        >
                          <Translate value="patient_view.tabs.timeline" />
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                  <div className="column no-padding">
                    <MemberEventSummary
                      isLoading={events.loadingMemberActiveEvents}
                      error={events.memberActiveEventsError}
                      activeEvents={events.memberActiveEvents}
                      onClick={toggleMemberEventList}
                      toggled={events.memberEventListOpen}
                    />
                  </div>
                </div>
              </div>
            </HideOnScroll>
            <div className="patient-info-container">
              <MemberEventList
                onAnamnesisLink={() => this.changeTab('anamnesis')}
                onClickOutside={toggleMemberEventList}
                open={events.memberEventListOpen}
                active={events.memberActiveEvents}
                history={events.memberEventHistory}
                dateTimeFormat={i18n.translations && i18n.translations[i18n.locale].time.chat_timestamp_format}
                url={this.props.match.url}
                handledEventIds={events.handledEventIds}
              />
              <PatientAlerts
                member={member}
                allergy={allergy}
                oversensitivity={oversensitivity}
                allergyDssItemComments={decisionSupport.allergyDssItemComments}
              />
              <PrivateRoute exact path={`${this.props.match.url}`} component={DecisionSupportView} />
              <PrivateRoute path={`${this.props.match.url}/anamnesis`} component={HealthProfile} />
              <PrivateRoute path={`${this.props.match.url}/referrals`} component={Referrals} />
              <PrivateRoute path={`${this.props.match.url}/medications`} component={MedicationView} />
              <PrivateRoute path={`${this.props.match.url}/notes`} component={NotesView} />
              <PrivateRoute path={`${this.props.match.url}/timeline`} component={ProgramTimelineView} />
              <PrivateRoute path={`${this.props.match.url}/decisionsupport`} component={DecisionSupportView} />
            </div>
          </div>
          <div
            className={`column no-padding patient-chat-container ${
              this.state.size > window.innerWidth * DEFAULT_CHAT_WIDTH && !this.props.ui.chatCollapsed ? 'dragged' : ''
            }`}
            style={{
              width: `${(this.state.size / window.innerWidth) * 100}%`,
              right: this.props.ui.chatCollapsed ? `calc(-${(this.state.size / window.innerWidth) * 100}% + 20px)` : '0'
            }}
          >
            <ErrorBoundary fallback={() => <PatientChatErrorFallback />}>
              <PatientChat
                memberGuid={this.props.match.params.memberId}
                dragHandle={
                  <DraggableCore {...dragHandlers} offsetParent={document.body}>
                    <div
                      className={`collapse-button ${this.props.ui.chatCollapsed ? 'pointer' : 'cursor-w-resize'}`}
                      onClick={this.toggleChat}
                    >
                      {this.props.ui.chatCollapsed ? '«' : '||'}
                    </div>
                  </DraggableCore>
                }
              />
            </ErrorBoundary>
          </div>
        </div>
        {[AUTHORITY.CAREGIVER, AUTHORITY.NURSE].some((authority) => this.props.authorities.includes(authority)) &&
        !notes.memberCommentError ? (
          <PatientComments
            comment={notes.memberComment}
            commentText={notes.memberCommentText}
            onSave={this.saveMemberComment}
            onUpdateComment={this.props.updateMemberCommentText}
            isSaving={notes.savingMemberComment}
            onToggle={this.props.toggleMemberCommentField}
          />
        ) : null}
        <FilePreview
          onActionCompleted={
            [AUTHORITY.CAREGIVER, AUTHORITY.NURSE].some((authority) => this.props.authorities.includes(authority))
              ? preview.options.onActionCompleted
              : undefined
          }
          isCompleting={files.postingFileToChat}
          actionI18nKey={preview.options.actionI18nKey}
        />
        <ImageViewer
          isVisible={preview.fullSizeModalVisible}
          imageSrc={preview.fullSizeImageSrc}
          headerI18nKey={preview.previewOptions.headerI18nKey}
          size={preview.previewOptions.size}
          onClose={hideFullSizeImage}
        />
        <ModalDialog
          headerI18nKey="patient_view.chat.create_note"
          actionI18nKey="patient_view.chat.create_day_note"
          visible={notes.dayNoteModalVisible}
          onClose={hideDayNoteModal}
          onActionCompleted={this.addDayNote}
          actionCompletable={notes.dayNoteContent.length > 0}
          actionCompleting={notes.savingDayNote}
          position="topright"
          noOverlay={true}
        >
          <div className="note-container">
            <div className="columns mb-0">
              <div className="column no-padding">
                <h4>
                  <Translate value="global.note" />
                </h4>
              </div>
              <div className="column no-padding text-right">
                <h4>
                  <Translate
                    value="global.remaining_characters"
                    remaining={MAX_NOTE_LENGTH - notes.dayNoteContent.length}
                  />
                </h4>
              </div>
            </div>
            <DebounceInput
              element="textarea"
              maxLength={MAX_NOTE_LENGTH}
              onChange={updateDayNoteContent}
              value={notes.dayNoteContent}
            />
          </div>
        </ModalDialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    patientData: state.patientHealthProfile.profileData,
    events: state.events,
    member: state.currentMember,
    i18n: state.i18n,
    allergy: state.patientHealthProfile.allergy,
    oversensitivity: state.patientHealthProfile.oversensitivity,
    decisionSupport: state.decisionSupport,
    preview: state.preview,
    files: state.files,
    ui: state.ui,
    chat: state.patientChat,
    authorities: state.auth.authorities,
    notes: state.notes,
    caregiverGuid: state.auth.token.user.guid
  };
};

const mapActionsToProps = {
  getPatientData,
  chaptersSuccess,
  chaptersError,
  getUserProfile,
  clearCurrentMember,
  clearPatientData,
  getBloodPressureMeasurements,
  changeTab,
  getBloodPressureStats,
  clearReferralSession,
  getReferralSession,
  getMemberEventHistory,
  clearMemberEventHistory,
  getNotes,
  clearNotes,
  clearMedications,
  getDecisionSupportSummary,
  getMeasurementStatus,
  getBloodPressureSummary,
  clearDssData,
  getDssLabResults,
  getBloodPressureTrends,
  getDssRiskProfile,
  getDssStatus,
  getDssAllergyItem,
  getMemberMedications,
  collapseChat,
  expandChat,
  getMemberDevices,
  getObjectives,
  getAnamnesisUpdateSummary,
  getMemberFiles,
  clearMemberFiles,
  hideFullSizeImage,
  hideDayNoteModal,
  updateDayNoteContent,
  addDayNote,
  getMemberMedicineExtras,
  toggleMemberEventList,
  getMemberActiveEvents,
  clearMemberActiveEvents,
  getChatResponses,
  getReferrals,
  clearChatMessages,
  getTwoWeekTrend,
  getMemberComment,
  saveMemberComment,
  updateMemberCommentText,
  toggleMemberCommentField,
  getDocumentTemplateContent,
  getMemberMonitors,
  getCardiopulmonaryExamination,
  getMemberActionTasks
};

export default connect(mapStateToProps, mapActionsToProps)(PatientView);
