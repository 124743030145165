import React, { Component } from 'react';
import LoadingSpinner from '../LoadingSpinner';
import ErrorMessage from '../ErrorMessage';
import './ContentLoader.scss';

class ContentLoader extends Component {
  render() {
    const { children, isLoading, error, errorTitleI18n, hasContent, showEmptyContent } = this.props;

    if (!isLoading && hasContent === false && showEmptyContent === false) {
      return null;
    }

    return (
      <div className="pos-rel">
        {isLoading && !hasContent ? (
          <div className="content-loader">
            <LoadingSpinner size="small" />
          </div>
        ) : error ? (
          <ErrorMessage error={error} errorI18nKey={errorTitleI18n} />
        ) : (
          <div>{children}</div>
        )}
      </div>
    );
  }
}

export default ContentLoader;
