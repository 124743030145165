import React, { Fragment } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { Translate } from 'react-redux-i18n';
import MedicationSearchResults from './MedicationSearchResults';

const MedicationSearch = ({
  medicationName,
  onChangeNameSearchValue,
  medicationStrength,
  onChangeStrengthSearchValue,
  onResultSelected,
  searchResults,
  isLoading,
  originalMedication,
  varaBypassInfoExpanded,
  toggleExpandVaraBypass,
  varaBypassReasonType,
  varaBypassFreeTextReason,
  setVaraBypassReasonType,
  setVaraBypassFreeTextReason,
  error
}) => {
  return (
    <Fragment>
      <div className="fw-input">
        <div className="columns">
          <div className="column is-8 no-padding mr-15">
            <h4>
              <Translate value="patient_view.medications.vara_search.search_vara" />
            </h4>
            <DebounceInput
              type="text"
              debounceTimeout={100}
              maxLength="100"
              value={medicationName}
              onChange={onChangeNameSearchValue}
            />
          </div>
          <div className="column no-padding">
            <h4>
              <Translate value="global.strength" />
            </h4>
            <DebounceInput
              type="text"
              debounceTimeout={100}
              maxLength="100"
              value={medicationStrength}
              onChange={onChangeStrengthSearchValue}
            />
          </div>
        </div>
        <div className="notice mb-15">
          <Translate value="patient_view.medications.vara_search.atc_note" />
        </div>
      </div>
      <div>
        {medicationName.length && searchResults.pagination.totalNumberOfItems ? (
          <div className="mb-10">
            <small>
              {searchResults && searchResults.pagination.totalNumberOfItems} <Translate value="global.hits" />
            </small>
          </div>
        ) : null}
      </div>
      <MedicationSearchResults
        medications={searchResults.medicines}
        medicationName={medicationName}
        medicationStrength={medicationStrength}
        onResultSelected={onResultSelected}
        isLoading={isLoading}
        originalMedication={originalMedication}
        collapsed={varaBypassInfoExpanded}
        error={error}
      />

      {originalMedication &&
      originalMedication.name !== medicationName &&
      originalMedication.strength !== medicationStrength ? (
        <div
          className={`mt-10 mb-20 text-left ${!varaBypassInfoExpanded ? 'show-all-data' : 'hide-rows'}`}
          onClick={toggleExpandVaraBypass}
        >
          <Translate value="patient_view.medications.vara_search.cannot_find_header" />
        </div>
      ) : null}

      {varaBypassInfoExpanded ? (
        <Fragment>
          <div>
            <Translate value="patient_view.medications.vara_search.google_search_1" />
            <a
              href={`https://www.google.se/search?q=${originalMedication && originalMedication.name}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Translate value="patient_view.medications.vara_search.google_search_2" />
            </a>
            . <Translate value="patient_view.medications.vara_search.google_search_3" />
          </div>

          <div className="mt-20">
            <h3>
              <Translate value="patient_view.medications.vara_search.not_in_vara_header" />
            </h3>
            <div>
              <Translate value="patient_view.medications.vara_search.not_in_vara_body" />
            </div>
          </div>

          <div className="mt-20">
            <div>
              <input
                type="radio"
                id="reason-foreign-medicine"
                value="foreignMedicine"
                name="reason-foreign-medicine"
                checked={varaBypassReasonType === 'foreignMedicine'}
                onChange={setVaraBypassReasonType}
              />
              <label htmlFor="reason-foreign-medicine">
                <Translate value="patient_view.medications.vara_search.reason_foreign_medicine" />
              </label>
            </div>
            <div>
              <input
                type="radio"
                id="reason-other"
                value="other"
                name="reason-other"
                checked={varaBypassReasonType === 'other'}
                onChange={setVaraBypassReasonType}
              />
              <label htmlFor="reason-other">
                <Translate value="patient_view.medications.vara_search.reason_other" />
              </label>
              {varaBypassReasonType === 'other' ? (
                <div className="vara-bypass__reason-other">
                  <DebounceInput
                    element="textarea"
                    maxLength="2000"
                    value={varaBypassFreeTextReason}
                    debounceTimeout={300}
                    onChange={setVaraBypassFreeTextReason}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default MedicationSearch;
