import { connect } from 'react-redux';
import { SERVICE_STATUS } from '../../../constants';
import DecisionSupportSection from '../DecisionSupportSection';
import './OnboardingStatus.scss';

const OnboardingStatus = ({ member, actionProgress }) => {
  // This component is not needed when the patient is handled by the doctor.
  if (member.serviceStatus !== SERVICE_STATUS.NURSE) {
    return null;
  }

  if (!actionProgress.length) {
    return null;
  }

  const TOTAL_COLUMNS = 12;
  const columnWidth = Math.min(Math.floor(TOTAL_COLUMNS / actionProgress.length), 4);

  const previousActionsDone = (columnIndex) => {
    return columnIndex > 0 && !actionProgress[columnIndex - 1].tasks?.every((t) => t.done);
  };

  return (
    <DecisionSupportSection headerI18nKey="decision_support_view.onboarding.header" minimizable={false}>
      <div className="columns">
        {actionProgress.map((progress, i) => (
          <div
            key={progress.actionType}
            className={`column no-padding is-${columnWidth} ${previousActionsDone(i) ? 'opacity-7' : ''}`}
          >
            <TaskList tasks={progress.tasks} title={progress.actionTitle} />
          </div>
        ))}
      </div>
    </DecisionSupportSection>
  );
};

const mapStateToProps = (state) => {
  return {
    actionProgress: state.decisionSupport.actionProgress
  };
};

export default connect(mapStateToProps)(OnboardingStatus);

const TaskList = ({ title, tasks = [] }) => {
  return (
    <>
      <h3>{title}</h3>
      <ul className="onboarding">
        {tasks.map((task, i) => (
          <li key={i} className={task.done ? 'done' : ''}>
            {task.taskTitle}
          </li>
        ))}
      </ul>
    </>
  );
};
