import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import { SUPERSCRIPTED_GOAL_VALUES } from '../../../../constants';

const CustomGoalValue = (props) => {
  const isAccumboReference = props.original.severities.some((s) => s.severity === 'increased');

  let referenceValuesTitle;

  if (isAccumboReference) {
    referenceValuesTitle = props.original.severities
      .map(
        (s) =>
          `${I18n.t(`decision_support_view.severities.${s.severity}`)}: ${s.lowerBound || ''}–${
            s.upperBound || ''
          }<br />`
      )
      .join('');
  } else {
    referenceValuesTitle = props.original.severities
      .filter((s) => s.severity === 'normal')
      .map((s) => `${I18n.t('decision_support_view.severities.normal')}: ${s.lowerBound || ''}–${s.upperBound || ''}`)
      .join('');
  }

  return (
    <Fragment>
      <span data-tip={referenceValuesTitle} className="icons info"></span>
      {props.value ? (
        <Fragment>
          <span>{props.value}</span>
          {props.value && SUPERSCRIPTED_GOAL_VALUES.indexOf(props.original.code) > -1 ? <sup>1</sup> : null}
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default CustomGoalValue;
