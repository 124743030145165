import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { DATE_FORMAT } from '../../../constants';
import { decamelize } from '../../../utils';
import ContentLoader from '../../ContentLoader';
import { getMemberPaymentHistory } from '../../../actions';

const PaymentHistoryRow = ({ paymentItem }) => {
  const paymentState = paymentItem.payment.state.toLowerCase();

  return (
    <div className="columns mb-30">
      <div className="column no-padding">
        <Translate value={`global.checkup_type.${decamelize(paymentItem.checkupType || 'checkup')}`} />
      </div>
      <div className="column no-padding">
        <span className={`payment-status narrow payment-status__${paymentState}`}>
          <Translate value={`payment_state.${paymentState}`} />
        </span>
      </div>
      <div className="column no-padding">
        {moment(paymentItem.payment.stateTimestamp, DATE_FORMAT).format('YYYY-MM-DD')}
      </div>
    </div>
  );
};

class PaymentHistory extends Component {
  componentDidMount() {
    this.props.getMemberPaymentHistory(this.props.authToken, this.props.match.params.memberId);
  }

  render() {
    const { paymentHistory, isLoading, error } = this.props;

    return (
      <div className="rounded-container mw-900">
        <h2>
          <Translate value="payments_view.header" />
        </h2>
        <ContentLoader
          isLoading={isLoading && !paymentHistory.length}
          error={error}
          errorTitleI18n="payments_view.error"
        >
          {paymentHistory.length ? (
            <>
              <div className="columns">
                <div className="column no-padding">
                  <h4>
                    <Translate value="payments_view.column_header.checkup_type" />
                  </h4>
                </div>
                <div className="column no-padding">
                  <h4>
                    <Translate value="payments_view.column_header.payment_status" />
                  </h4>
                </div>
                <div className="column no-padding">
                  <h4>
                    <Translate value="payments_view.column_header.date" />
                  </h4>
                </div>
              </div>
              {paymentHistory.map((item) => (
                <PaymentHistoryRow key={item.guid} paymentItem={item} />
              ))}
            </>
          ) : (
            <span className="field__empty">
              <Translate value="payments_view.empty" />
            </span>
          )}
        </ContentLoader>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    paymentHistory: state.checkups.paymentHistory,
    isLoading: state.checkups.loadingPaymentHistory,
    error: state.checkups.paymentHistoryError
  };
};

const mapActionsToProps = {
  getMemberPaymentHistory
};

export default connect(mapStateToProps, mapActionsToProps)(PaymentHistory);
