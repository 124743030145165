import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

const UnauthorizedAccessWarning = ({ ownPatients, isNurse }) => {
  if (!ownPatients) {
    return null;
  }

  return (
    <Prompt
      message={(location) => {
        const matches = location.pathname.match(/member\/([\w+-]+)/);
        let guid;

        if (matches && matches[1]) {
          guid = matches[1];
        }

        if (matches && !ownPatients.includes(guid)) {
          return I18n.t(`global.non_${isNurse ? 'pas' : 'pal'}_warning`);
        }

        return true;
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    isNurse: state.auth.isNurse
  };
};

export default connect(mapStateToProps)(UnauthorizedAccessWarning);
