import { Translate } from 'react-redux-i18n';
import { ASSESSMENT_STATUS } from '../../../../constants';
import { decamelize } from '../../../../utils';
import styles from './AssessmentStatus.module.scss';

const AssessmentStatus = ({ assessment, user }) => {
  if (
    !assessment?.status ||
    assessment.status === ASSESSMENT_STATUS.AwaitingAssessment ||
    (assessment.status === ASSESSMENT_STATUS.AssessmentInProgress && assessment?.assignedCaregiver?.guid === user.guid)
  ) {
    return null;
  }

  const assignedCaregiverName = assessment.assignedCaregiver?.givenName
    ? `${assessment.assignedCaregiver.givenName} ${assessment.assignedCaregiver.familyName}`
    : '';

  return (
    <div className={`${styles.assignedCaregiver}`}>
      <Translate value={`kiosk_assessment.status.${decamelize(assessment.status)}`} name={assignedCaregiverName} />
    </div>
  );
};

export default AssessmentStatus;
