import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { Translate, I18n } from 'react-redux-i18n';

class RiskFactorStatusRow extends Component {
  render() {
    const { name, recommendation, currentStatus, previousStatus, previousRecommendation, showTimestamp } = this.props;

    return (
      <Fragment>
        <h2>{name}</h2>
        <div className="decision-support-table mb-10">
          <div className="columns decision-support-table-column-headers">
            <div className="column is-4">
              <Translate value="decision_support_view.risk_profile_section.column_headers.current_status" />
            </div>
            <div className="column is-4">
              <Translate value="decision_support_view.risk_profile_section.column_headers.status_at_previous_recommendation" />
            </div>
            <div className="column is-4">
              <Translate value="decision_support_view.risk_profile_section.column_headers.previous_recommendation" />
            </div>
          </div>
          <div className="simple-row row-container">
            <div className="columns table-row">
              <div className="column row-response is-4">
                <ValueWithFallback
                  value={currentStatus && currentStatus.value}
                  severity={currentStatus && currentStatus.severity}
                  compareWith={previousStatus && previousStatus.value}
                  fallbackI8nKey="decision_support_view.risk_profile_section.no_risk_factor"
                  showTimestamp={showTimestamp}
                  timestampPrefixI18nKey="decision_support_view.risk_profile_section.answered"
                />
              </div>
              <div className="column row-response is-4">
                <ValueWithFallback
                  value={previousStatus && previousStatus.value}
                  severity={previousStatus && previousStatus.severity}
                />
              </div>
              <div className="column is-4">
                <ValueWithFallback
                  value={previousRecommendation}
                  showTimestamp={true}
                  timestampPrefixI18nKey="decision_support_view.risk_profile_section.recommended"
                />
              </div>
            </div>
          </div>
        </div>

        {recommendation && recommendation.isMemberChallenge ? (
          <div
            className={`challenge-status ${
              recommendation.memberAccepted && !recommendation.memberCompleted ? 'challenge-accepted' : ''
            } ${recommendation.memberCompleted ? 'challenge-completed' : ''} ${
              recommendation.selected && !recommendation.memberAccepted ? 'not-accepted' : ''
            }`}
          >
            {recommendation.memberAccepted && !recommendation.memberCompleted
              ? `${I18n.t('decision_support_view.treatment_goals_section.member_accepted_challenge')} ${moment(
                  recommendation.memberAccepted
                ).format('YYYY-MM-DD')}.`
              : null}
            {recommendation.memberCompleted
              ? `${I18n.t('decision_support_view.treatment_goals_section.member_completed_challenge')} ${moment(
                  recommendation.memberCompleted
                ).format('YYYY-MM-DD')}.`
              : null}
            {recommendation.selected && !recommendation.memberAccepted
              ? I18n.t('decision_support_view.treatment_goals_section.member_not_accepted_challenge')
              : null}
          </div>
        ) : null}
      </Fragment>
    );
  }
}

export default RiskFactorStatusRow;

class ValueWithFallback extends Component {
  render() {
    const { value, fallbackI8nKey, showTimestamp, severity, compareWith, timestampPrefixI18nKey } = this.props;

    if (!value || !value.values || !value.values.length) {
      return <div className="opacity-7">{fallbackI8nKey ? I18n.t(fallbackI8nKey) : '-'}</div>;
    }

    const notUpdated = compareWith && value.timestamp === compareWith.timestamp;

    const displayValues = value.values.map((v, i, list) => {
      if (
        compareWith &&
        compareWith.values &&
        compareWith.values.length &&
        compareWith.values.indexOf(v) === -1 &&
        list.length > 1
      ) {
        return `${v} - ${I18n.t('global.new').toUpperCase()}`;
      }

      return v;
    });

    return (
      <Fragment>
        {displayValues.map((value, i) => {
          return (
            <div className={`${severity ? `severity ${severity}` : ''}`} key={i}>
              {value.split('\n').map((item, key) => {
                return (
                  <span key={key}>
                    {item}
                    <br />
                  </span>
                );
              })}
            </div>
          );
        })}
        {showTimestamp && value.timestamp ? (
          <div className="opacity-7">
            <Translate value={timestampPrefixI18nKey} /> {moment(value.timestamp).format('YYYY-MM-DD')}
          </div>
        ) : null}
        {notUpdated ? (
          <div className="opacity-7">
            <Translate value="decision_support_view.risk_profile_section.not_updated" />
          </div>
        ) : null}
      </Fragment>
    );
  }
}
