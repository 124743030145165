import React, { Component, Fragment } from 'react';
import moment from 'moment';
import flatten from 'lodash.flattendeep';
import {
  LineChart,
  Line as RechartsLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush
} from 'recharts';
import { DATE_FORMAT } from '../../../../constants';
import 'chartjs-plugin-annotation';
import { Line } from 'react-chartjs-2';
import { Translate, I18n } from 'react-redux-i18n';
import { getPeriodI18nKey, getMeasurementColor } from '../../../../utils';
import {
  defaultOptions as options,
  legendOptions,
  commonDataSetOptions,
  systolicDataOptions,
  diastolicDataOptions,
  heartRateDataOptions
} from '../../../../utils/graph.js';
import './MeasurementsLineGraph.scss';
import ModalDialog from '../../../ModalDialog';

options.annotation = {
  annotations: [
    {
      drawTime: 'afterDatasetsDraw',
      type: 'line',
      mode: 'horizontal',
      scaleID: 'y-axis-0',
      borderColor: 'rgba(216, 56, 74, 0.5)',
      borderWidth: 2,
      borderDash: [4, 2]
    },
    {
      drawTime: 'afterDatasetsDraw',
      type: 'line',
      mode: 'horizontal',
      scaleID: 'y-axis-0',
      borderColor: 'rgba(62, 95, 203, 0.5)',
      borderWidth: 2,
      borderDash: [4, 2]
    }
  ]
};

const chartData = {
  labels: [],
  datasets: [
    { ...commonDataSetOptions, ...systolicDataOptions },
    { ...commonDataSetOptions, ...diastolicDataOptions },
    { ...commonDataSetOptions, ...heartRateDataOptions }
  ]
};

class MeasurementsLineGraph extends Component {
  constructor(props) {
    super(props);

    this.toggleDetailedGraph = this.toggleDetailedGraph.bind(this);

    this.state = {
      shouldRedraw: false,
      detailedGraphModalVisible: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.goalSystolic &&
      nextProps.goalSystolic !== this.props.goalSystolic &&
      nextProps.goalDiastolic &&
      nextProps.goalDiastolic !== this.props.goalDiastolic
    ) {
      this.setState({
        shouldRedraw: true
      });
    } else {
      this.setState({
        shouldRedraw: false
      });
    }
  }

  toggleDetailedGraph() {
    this.setState({
      detailedGraphModalVisible: !this.state.detailedGraphModalVisible
    });
  }

  render() {
    const { trendData, isLoading, periodSize, goalSystolic, goalDiastolic, memberMeasurements } = this.props;

    if (!trendData) return null;

    options.annotation.annotations[0].value = goalSystolic;
    options.annotation.annotations[1].value = goalDiastolic;

    chartData.labels = [];

    trendData.forEach((period, i) => {
      chartData.labels[i] = `${I18n.t(getPeriodI18nKey(periodSize))} ${period.interval}${
        periodSize === 1
          ? ` (${moment(period.startTimestamp, DATE_FORMAT).format('YYYY-MM-DD')})`
          : ` (${moment(period.startTimestamp, DATE_FORMAT).format('YYYY-MM-DD')} - ${moment(
              period.endTimestamp,
              DATE_FORMAT
            ).format('YYYY-MM-DD')})`
      }`;
    });

    const systolicData = trendData.map((period) => {
      return {
        t: period.startTimestamp,
        y: period.mean ? period.mean.systolic : null
      };
    });

    const diastolicData = trendData.map((period) => {
      return {
        t: period.startTimestamp,
        y: period.mean ? period.mean.diastolic : null
      };
    });

    const heartRateData = trendData.map((period) => {
      return {
        t: period.startTimestamp,
        y: period.mean ? Math.round(period.mean.heartrate) : null
      };
    });

    chartData.datasets[0].data = systolicData;
    chartData.datasets[1].data = diastolicData;
    chartData.datasets[2].data = heartRateData;

    return (
      <Fragment>
        <div>
          <div className={`line-graph ${isLoading ? 'is-loading' : ''}`}>
            <Line data={chartData} legend={legendOptions} options={options} redraw={this.state.shouldRedraw} />
          </div>
          <div className="columns">
            <div className="column is-9 no-padding">
              <div className="line-graph-legend">
                <Translate className="legend-systolic" value="decision_support_view.legend_systolic" />
                <Translate className="legend-diastolic" value="decision_support_view.legend_diastolic" />
                <Translate className="legend-heartrate" value="decision_support_view.legend_heartrate" />
                <Translate className="legend-systolic-goal" value="decision_support_view.legend_systolic_goal" />
                <Translate className="legend-diastolic-goal" value="decision_support_view.legend_diastolic_goal" />
              </div>
            </div>
            <div className="column right-align no-padding">
              <span className="text-button chevron-right mt-10" onClick={this.toggleDetailedGraph}>
                <Translate value="decision_support_view.show_detailed_graph" />
              </span>
            </div>
          </div>
        </div>
        <ModalDialog
          headerI18nKey="patient_view.graph.measurements_header"
          actionI18nKey="global.buttons.ok"
          visible={this.state.detailedGraphModalVisible}
          onClose={this.toggleDetailedGraph}
          onActionCompleted={this.toggleDetailedGraph}
          actionCompletable={true}
          size="large"
        >
          <section>
            <div>
              {memberMeasurements && memberMeasurements.length ? (
                <ResponsiveContainer width="100%" height={600} className="graph">
                  <LineChart
                    data={flatten(memberMeasurements)}
                    margin={{ top: 15, right: 5, left: 0, bottom: 15 }}
                    background="#ffffff"
                  >
                    <XAxis
                      dataKey="date"
                      domain={['auto', 'auto']}
                      type="number"
                      tickFormatter={(unixTime) => moment(unixTime).format('YYYY-MM-DD')}
                    />
                    <YAxis unit=" mmHg" type="number" domain={[60, 'dataMax + 10']} />
                    <YAxis unit=" bpm" yAxisId="heartrate" orientation="right" domain={[40, 'auto']} />
                    <CartesianGrid stroke="#DADBDA" />
                    <Tooltip isAnimationActive={false} content={<CustomTooltip />} />
                    <Legend />
                    <RechartsLine
                      type="monotone"
                      strokeWidth={2}
                      dot={<CustomDot />}
                      animationDuration={1000}
                      dataKey="systolic"
                      name={I18n.t('patient_view.graph.systolic_pressure')}
                      unit="mmHg"
                      stroke="rgb(26,158,120)"
                    />
                    <RechartsLine
                      type="monotone"
                      strokeWidth={2}
                      dot={<CustomDot />}
                      animationDuration={1000}
                      dataKey="diastolic"
                      name={I18n.t('patient_view.graph.diastolic_pressure')}
                      unit="mmHg"
                      stroke="rgb(117,112,179)"
                    />
                    <RechartsLine
                      type="linear"
                      strokeDasharray="4 4 4 4 4 4"
                      strokeWidth={2}
                      animationDuration={1000}
                      dataKey="heartrate"
                      name={I18n.t('patient_view.graph.heartrate')}
                      yAxisId="heartrate"
                      stroke="rgb(115,6,60)"
                    />
                    <Brush
                      startIndex={
                        flatten(memberMeasurements).length > 5 ? Math.floor(flatten(memberMeasurements).length / 2) : 0
                      }
                    />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p>
                  <span className="field__empty">
                    <Translate value="patient_view.graph.no_measurements" />
                  </span>
                </p>
              )}
            </div>
            {memberMeasurements && memberMeasurements.length ? (
              <div className="measurement-times-legend">
                <span className="color-dot morning"></span>
                <span>
                  <Translate value="patient_view.graph.measurement_times.morning" />
                </span>
                <span className="color-dot day"></span>
                <span>
                  <Translate value="patient_view.graph.measurement_times.day" />
                </span>
                <span className="color-dot evening"></span>
                <span>
                  <Translate value="patient_view.graph.measurement_times.evening" />
                </span>
                <span className="color-dot night"></span>
                <span>
                  <Translate value="patient_view.graph.measurement_times.night" />
                </span>
              </div>
            ) : null}
          </section>
        </ModalDialog>
      </Fragment>
    );
  }
}

export default React.memo(MeasurementsLineGraph);

class CustomTooltip extends Component {
  render() {
    const { active, payload, label } = this.props;

    if (active && payload && payload[0]) {
      return (
        <div className="custom-tooltip">
          <p>{moment(label).format('YYYY-MM-DD HH:mm')}</p>
          <p>
            {payload[0].name}: <strong>{payload[0].value}</strong> {payload[0].unit}
          </p>
          <p>
            {payload[1].name}: <strong>{payload[1].value}</strong> {payload[1].unit}
          </p>

          {payload[2] ? (
            <p>
              {payload[2].name}: <strong>{payload[2].value}</strong> {payload[2].unit}
            </p>
          ) : null}
          <p>
            <Translate value={`patient_view.graph.measurement_mode.${payload[1].payload.measurementMode}`} />
            {payload[1].payload.measurementMode === 'symptoms' ? <span> ({payload[1].payload.comment})</span> : null}
          </p>
        </div>
      );
    }

    return null;
  }
}

class CustomDot extends Component {
  render() {
    const { cx, cy, payload } = this.props;
    const color = getMeasurementColor(payload);

    if (payload.bodyPosition === 'standing' || payload.measurementMode === 'standing') {
      return (
        <svg x={cx - 7} y={cy - 10} width="24" height="24" viewBox="0 0 32 32" version="1.1">
          <path
            fill={color}
            d="M13.139559,11.7398487 C13.1980931,11.9152682 13.1742292,12.0926441 13.0729201,12.2267629 C12.971611,12.3608817 12.8043384,12.4378315 12.613427,12.4378315 L12.1068815,12.4378315 C11.7624305,12.4378315 11.4098748,12.190027 11.304063,11.8735327 L10.7407844,10.1880271 L10.7407844,12.4639162 L10.7407844,12.5732545 L10.7407844,18.913138 C10.7407844,19.5124337 10.2358147,20 9.61512759,20 L9.53588135,20 C8.9151942,20 8.41022456,19.5124337 8.41022456,18.913138 L8.41022456,13.2253717 L7.93137016,13.2253717 L7.93137016,18.913138 C7.93137016,19.5124337 7.42640053,20 6.80571337,20 L6.72646713,20 C6.10577998,20 5.60081034,19.5124337 5.60081034,18.913138 L5.60081034,12.5732545 L5.60081034,12.4639162 L5.60081034,10.1888966 L5.03798195,11.8735327 C4.93217021,12.190027 4.5796145,12.4378315 4.23516353,12.4378315 L3.72861797,12.4378315 C3.53770658,12.4378315 3.37020885,12.3608817 3.26912487,12.2267629 C3.16804089,12.0926441 3.1437267,11.9152682 3.20226086,11.7398487 L5.17801365,5.82775411 C5.28382539,5.51125989 5.6363811,5.26345535 5.98083208,5.26345535 L6.27620442,5.26345535 L6.48737763,5.26345535 L9.85399196,5.26345535 L10.0651652,5.26345535 L10.3605375,5.26345535 C10.7049885,5.26345535 11.0575442,5.51125989 11.1633559,5.82775411 L13.139559,11.7398487 Z M8.17090993,4.7108947 C6.82575006,4.7108947 5.73161166,3.65424746 5.73161166,2.35544735 C5.73161166,1.05664725 6.82575006,0 8.17090993,0 C9.51606979,0 10.6104333,1.05664725 10.6104333,2.35544735 C10.6104333,3.65424746 9.51606979,4.7108947 8.17090993,4.7108947 Z"
            id="Shape"
          ></path>
        </svg>
      );
    }

    if (payload.measurementMode === 'symptoms') {
      return (
        <svg x={cx - 7} y={cy - 7} width="14" height="14" viewBox="0 0 32 32" version="1.1">
          <path
            fill={color}
            transform="translate(16, 16)"
            d="M-16,-5.333333333333334L-5.333333333333334,-5.333333333333334L-5.333333333333334,-16L5.333333333333334,-16L5.333333333333334,-5.333333333333334L16,-5.333333333333334L16,5.333333333333334L5.333333333333334,5.333333333333334L5.333333333333334,16L-5.333333333333334,16L-5.333333333333334,5.333333333333334L-16,5.333333333333334Z"
          ></path>
        </svg>
      );
    }

    return (
      <svg x={cx - 4} y={cy - 4} width="8" height="8" viewBox="0 0 32 32" version="1.1">
        <path fill={color} transform="translate(16, 16)" d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0"></path>
      </svg>
    );
  }
}
