import React, { Fragment, useReducer, useEffect } from 'react';
import moment from 'moment';
import uuidv4 from 'uuid/v4';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { toggleItem } from '../../../utils';
import { showNotification } from '../../../actions';
import { AUTHORITY, NULL_GUID, DATE_FORMAT, REGION_SE } from '../../../constants';
import ModalDialog from '../../ModalDialog';
import ChapterChevron from '../../../assets/icons/chapter-chevron.svg';
import api from '../../../../src/api/apiClient';
import HasAuthority from '../../HasAuthority';

const initialState = {
  createReferralModalVisible: false,
  historicReferralsModalVisible: false,
  historicReferrals: [],
  expandedHistoricReferrals: [],
  selectedPackage: '',
  selectedCustomTests: [],
  isPostingReferral: false
};

const actionTypes = {
  setModalVisibility: 'setModalVisibility',
  selectPackage: 'selectPackage',
  toggleCustomTest: 'toggleCustomTest',
  setIsPosting: 'setIsPosting',
  resetState: 'resetState',
  setHistoricModalVisibility: 'setHistoricModalVisibility',
  toggleExpandedReferral: 'toggleExpandedReferral',
  addHistoricReferral: 'addHistoricReferral',
  setHistoricReferrals: 'setHistoricReferrals'
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.setModalVisibility:
      return { ...state, createReferralModalVisible: action.visibility };
    case actionTypes.selectPackage:
      return { ...state, selectedPackage: action.selectedPackage.length ? action.selectedPackage : '' };
    case actionTypes.toggleCustomTest:
      return { ...state, selectedCustomTests: toggleItem(state.selectedCustomTests, action.test) };
    case actionTypes.setIsPosting:
      return { ...state, isPostingReferral: action.isPosting };
    case actionTypes.resetState:
      return { ...state, selectedCustomTests: [], selectedPackage: '' };
    case actionTypes.setHistoricModalVisibility:
      return { ...state, historicReferralsModalVisible: action.visibility };
    case actionTypes.toggleExpandedReferral:
      return {
        ...state,
        expandedHistoricReferrals: state.expandedHistoricReferrals.includes(action.id) ? [] : [action.id]
      };
    case actionTypes.addHistoricReferral:
      return { ...state, historicReferrals: [action.referral, ...state.historicReferrals] };
    case actionTypes.setHistoricReferrals:
      return { ...state, historicReferrals: action.payload };
    default:
      throw new TypeError('Unrecognized action type', action.type);
  }
};

const ManageReferrals = ({ member, referrals, authToken, showNotification, historicReferrals, ...props }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: actionTypes.setHistoricReferrals, payload: historicReferrals });
  }, [historicReferrals]);

  let referralPackagesForMembersRegion;

  if (member && member.region && member.region.id !== NULL_GUID && referrals.referralPackages.length) {
    referralPackagesForMembersRegion = referrals.referralPackages.find(
      (referralPackage) => referralPackage.region.id === member.region.id
    );
  }

  if (!referralPackagesForMembersRegion) return null;

  const postReferral = () => {
    let selectedCustomTests;
    let referral = {};
    if (state.selectedPackage === 'custom') {
      selectedCustomTests = referralPackagesForMembersRegion.availableCustomTests.filter((test) =>
        state.selectedCustomTests.includes(test.testCode)
      );
      referral.customTests = selectedCustomTests;
    } else {
      referral.package = referralPackagesForMembersRegion.packages.find(
        (referralPackage) => referralPackage.name === state.selectedPackage
      );
    }

    dispatch({ type: actionTypes.setIsPosting, isPosting: true });

    api
      .postReferral(authToken, member.guid, referral)
      .then(() => {
        dispatch({ type: actionTypes.setIsPosting, isPosting: false });
        dispatch({ type: actionTypes.setModalVisibility, visibility: false });
        dispatch({ type: actionTypes.resetState });
        dispatch({
          type: actionTypes.addHistoricReferral,
          referral: {
            ...referral,
            id: uuidv4(),
            createdBy: props.user,
            createdDate: moment().format(DATE_FORMAT),
            isNew: true
          }
        });
        showNotification(I18n.t('notification.create_referral.success'), 'success');
      })
      .catch(() => {
        dispatch({ type: actionTypes.setIsPosting, isPosting: false });
        showNotification(I18n.t('notification.create_referral.error'), 'error');
      });
  };

  return (
    <Fragment>
      <div className="column no-padding vertical-align" style={{ marginLeft: '-34px' }}>
        <HasAuthority authority={[AUTHORITY.CAREGIVER, AUTHORITY.NURSE]} disable={true}>
          <button
            className="button is-primary"
            onClick={() => dispatch({ type: actionTypes.setModalVisibility, visibility: true })}
          >
            <Translate value="patient_view.referrals.create_referral_header" />
          </button>
        </HasAuthority>
        {state.historicReferrals.length ? (
          <button
            className="button is-ghost ml-15"
            onClick={() => dispatch({ type: actionTypes.setHistoricModalVisibility, visibility: true })}
          >
            <Translate value="patient_view.referrals.historic_referrals_button"></Translate>
          </button>
        ) : null}
      </div>
      <ModalDialog
        headerI18nKey="patient_view.referrals.create_referral_header"
        actionI18nKey="patient_view.referrals.create_referral"
        visible={state.createReferralModalVisible}
        onClose={() => dispatch({ type: actionTypes.setModalVisibility, visibility: false })}
        onActionCompleted={postReferral}
        actionCompletable={
          (state.selectedPackage === 'custom' && state.selectedCustomTests.length > 0) ||
          (state.selectedPackage !== '' && state.selectedPackage !== 'custom')
        }
        actionCompleting={state.isPostingReferral}
        size="x-small"
      >
        <div className="select">
          <select
            value={state.selectedPackage}
            onChange={(e) => dispatch({ type: actionTypes.selectPackage, selectedPackage: e.target.value })}
          >
            <option value="">{I18n.t('patient_view.referrals.select_package')}</option>
            {referralPackagesForMembersRegion.packages &&
              referralPackagesForMembersRegion.packages.map((p, i) => (
                <option key={i} value={p.name}>
                  {p.name}&nbsp;
                </option>
              ))}
            {referralPackagesForMembersRegion.availableCustomTests &&
            referralPackagesForMembersRegion.availableCustomTests.length ? (
              <Fragment>
                <option value="line" disabled>
                  -----------------------
                </option>
                <option value="custom">{I18n.t('patient_view.referrals.custom_package')}</option>
              </Fragment>
            ) : null}
          </select>
        </div>
        <div className="included-tests-container stack">
          {state.selectedPackage && state.selectedPackage !== 'custom'
            ? referralPackagesForMembersRegion.packages
                .find((p) => p.name === state.selectedPackage)
                .includedTests.map((test, i) => <div key={i}>{test.name}</div>)
            : null}
          {state.selectedPackage && state.selectedPackage === 'custom'
            ? referralPackagesForMembersRegion.availableCustomTests.map((test, i) => (
                <div key={i}>
                  <input
                    type="checkbox"
                    checked={state.selectedCustomTests.includes(test.testCode)}
                    value={test.testCode}
                    id={`lab-test-${test.name.split(' ').join('-')}`}
                    onChange={(e) => dispatch({ type: actionTypes.toggleCustomTest, test: e.target.value })}
                  />
                  <label htmlFor={`lab-test-${test.name.split(' ').join('-')}`}>{test.name}</label>
                </div>
              ))
            : null}
        </div>
        <div className="referral-recipient">
          {member.region && member.region.id === REGION_SE ? (
            <Translate value="patient_view.referrals.referral_is_sent_to_region_se" />
          ) : (
            <Translate value="patient_view.referrals.referral_is_sent_to" region={member.region.name} />
          )}
        </div>
      </ModalDialog>
      <ModalDialog
        headerI18nKey="patient_view.referrals.historic_referrals_header"
        actionI18nKey="global.buttons.ok"
        visible={state.historicReferralsModalVisible}
        onClose={() => dispatch({ type: actionTypes.setHistoricModalVisibility, visibility: false })}
        onActionCompleted={() => dispatch({ type: actionTypes.setHistoricModalVisibility, visibility: false })}
        actionCompletable={true}
      >
        {state.historicReferrals.map((referral) => (
          <HistoricReferral
            key={referral.id}
            referral={referral}
            isExpanded={state.expandedHistoricReferrals.includes(referral.id)}
            onClick={() => dispatch({ type: actionTypes.toggleExpandedReferral, id: referral.id })}
          />
        ))}
      </ModalDialog>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    user: state.auth.token.user,
    authorities: state.auth.authorities,
    referrals: state.referrals,
    member: state.currentMember
  };
};

const mapActionsToProps = {
  showNotification
};

export default connect(mapStateToProps, mapActionsToProps)(ManageReferrals);

const HistoricReferral = ({ referral, isExpanded, onClick }) => {
  let includedTests = [];

  if (referral.package) {
    includedTests = referral.package.includedTests;
  } else if (referral.customTests) {
    includedTests = referral.customTests;
  }

  return (
    <div className={`expandable-row ${referral.isNew ? 'is-new' : ''}`} onClick={onClick}>
      <div className="columns">
        <div className="column is-6 no-padding fw-bold">
          {referral.package ? referral.package.name : I18n.t('patient_view.referrals.custom_historic_package')}
        </div>
        <div className="column is-2 no-padding">{moment(referral.createdDate, DATE_FORMAT).format('YYYY-MM-DD')}</div>
        <div className="column referral-creator no-padding">
          {referral.createdBy ? (
            `${referral.createdBy.givenName} ${referral.createdBy.familyName}`
          ) : (
            <Translate value="global.unknown_caregiver" />
          )}
        </div>
        <div className="column is-1 no-padding expand-indicator">
          <img src={ChapterChevron} alt="Chevron" className={isExpanded ? 'rotated' : ''} />
        </div>
      </div>
      {isExpanded ? (
        <ul className="ml-10">
          {includedTests.length ? (
            includedTests.map((test) => <li key={test.testCode}>{test.name}</li>)
          ) : (
            <li>
              <Translate value="patient_view.referrals.package_missing" />
            </li>
          )}
        </ul>
      ) : null}
    </div>
  );
};
