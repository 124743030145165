import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { toggleShowOnlyOwnNotes } from '../../../actions';
import { getEarliestBeforeDate, toggleItem } from '../../../utils';
import HistoricalNote from '../HistoricalNote';

const MemberNotes = ({ notes, i18n, showOnlyOwnNotes, ...props }) => {
  let filteredNotes = notes;

  if (showOnlyOwnNotes) {
    filteredNotes = filteredNotes.filter((note) => note.author.guid === props.user.guid);
  }

  const dayNotes = filteredNotes.filter((note) => note.noteType === 'daynote');
  const finalNotes = filteredNotes.filter((note) => note.noteType === 'finalnote');

  return (
    <Fragment>
      <div className="vertical-align fl-end">
        <input
          type="checkbox"
          id="notes-filter-checkbox"
          checked={showOnlyOwnNotes}
          onChange={props.toggleShowOnlyOwnNotes}
        />
        <label htmlFor="notes-filter-checkbox">
          <span className="fs-14 ml-5">
            <Translate value="patient_view.notes.show_my_notes" />
          </span>
        </label>
      </div>
      <div className="columns">
        <NotesList
          notes={dayNotes}
          headerI18nKey="patient_view.notes.day_notes_header"
          emptyI18nKey="patient_view.notes.no_day_notes"
          i18n={i18n}
        />
        <NotesList
          notes={finalNotes}
          headerI18nKey="patient_view.notes.final_notes_header"
          emptyI18nKey="patient_view.notes.no_final_notes"
          i18n={i18n}
          chatMessages={props.chatMessages}
        />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.token.user,
    notes: state.notes.notes,
    chatMessages: state.notes.chatMessages,
    showOnlyOwnNotes: state.notes.showOnlyOwnNotes,
    i18n: state.i18n
  };
};

const mapActionsToProps = {
  toggleShowOnlyOwnNotes
};

export default connect(mapStateToProps, mapActionsToProps)(MemberNotes);

const NotesList = ({ headerI18nKey, emptyI18nKey, notes, i18n, chatMessages }) => {
  const [expandedNotes, setExpandedNotes] = useState([]);

  useEffect(() => {
    setExpandedNotes(notes.length ? [notes[0].id] : []);
  }, [notes]);

  const collapseAll = () => {
    setExpandedNotes([]);
  };

  const expandAll = () => {
    setExpandedNotes(notes.map((note) => note.id));
  };

  return (
    <div className="column no-padding rounded-container notes-container">
      <div className="columns">
        <div className="column no-padding">
          <h2>
            <Translate value={headerI18nKey} />
            {notes && notes.length ? ` (${notes.length})` : null}
          </h2>
        </div>
        <div className="column no-padding text-right">
          {notes.length ? (
            <span className="text-button" onClick={expandedNotes.length === notes.length ? collapseAll : expandAll}>
              <Translate value={`global.${expandedNotes.length === notes.length ? 'hide' : 'show'}_all`} />
            </span>
          ) : null}
        </div>
      </div>
      {notes && notes.length ? (
        notes.map((note, i) => {
          return (
            <HistoricalNote
              key={i}
              note={note}
              collapsed={!expandedNotes.includes(note.id)}
              setCollapsed={() => setExpandedNotes(toggleItem(expandedNotes, note.id))}
              chatMessageId={chatMessages ? getEarliestBeforeDate(chatMessages, note.timestamp) : undefined}
              dateTimeFormat={i18n?.translations?.[i18n.locale].time.chat_timestamp_format}
              collapsible={true}
            />
          );
        })
      ) : (
        <span className="field__empty">
          <Translate value={emptyI18nKey} />
        </span>
      )}
    </div>
  );
};
