import React from 'react';
import { Translate } from 'react-redux-i18n';
import { getAgeFromPersonalNumber, initials } from '../../../utils';

const MemberListRow = ({ member, currentUser, onClick }) => {
  const pal = member.responsibleDoctor;
  const pas = member.responsibleNurse;

  return (
    <div className="member-list-row columns" onClick={onClick}>
      <div className="column">
        <strong data-sensitive>{`${member.givenName} ${member.familyName}`}</strong>,{' '}
        {getAgeFromPersonalNumber(member.personalNumber)} <Translate value="global.years" />
      </div>
      <div className="column is-2" data-sensitive>
        {member.personalNumber}
      </div>
      <div className={`column is-1 responsible-doctor ${pal && currentUser.guid === pal.guid ? 'own-patient' : ''}`}>
        {pal ? (
          <span title={`${pal.givenName} ${pal.familyName}`}>{initials(pal.givenName, pal.familyName)}</span>
        ) : (
          <Translate className="new-patient" value="inbox_view.new_patient" />
        )}
      </div>
      <div className={`column is-1 responsible-doctor ${pas && currentUser.guid === pas.guid ? 'own-patient' : ''}`}>
        {pas ? (
          <span title={`${pas.givenName} ${pas.familyName}`}>{initials(pas.givenName, pas.familyName)}</span>
        ) : (
          <Translate className="new-patient" value="inbox_view.new_patient" />
        )}
      </div>
      <div className="column is-2">
        <Translate value={`service_status.${member.serviceStatus}`} />
      </div>
      <div className="column is-2">{member.source || '-'}</div>
    </div>
  );
};

export default MemberListRow;
