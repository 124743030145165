import * as types from '../constants/actionTypes';
import { removeItemFromArray } from '../utils';

const initialState = {
  initialKioskAssessment: {},
  totalRemainingKioskAssessments: 0,
  auscultationAssessments: [],
  newClinicalAssessments: []
};

const assessmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_KIOSK_ASSESSMENTS_ERROR:
      return { ...state, loadingAssessments: false, error: action.payload };
    case types.SET_INITIAL_KIOSK_ASSESSMENT:
      return { ...state, initialKioskAssessment: action.payload };
    case types.COMPLETE_KIOSK_ASSESSMENT:
      return {
        ...state,
        totalRemainingKioskAssessments: state.totalRemainingKioskAssessments
          ? state.totalRemainingKioskAssessments - 1
          : state.totalRemainingKioskAssessments,
        initialKioskAssessment: {},
        error: undefined
      };
    case types.SET_REMAINING_KIOSK_ASSESSMENT_COUNT:
      return { ...state, totalRemainingKioskAssessments: action.payload, initialKioskAssessment: {} };
    case types.GET_AUSCULTATION_ASSESSMENTS_REQUEST:
      return { ...state, loadingAuscultationAssessments: true };
    case types.GET_AUSCULTATION_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        loadingAuscultationAssessments: false,
        auscultationAssessments: action.payload,
        auscultationAssessmentsError: undefined
      };
    case types.GET_AUSCULTATION_ASSESSMENTS_ERROR:
      return { ...state, loadingAuscultationAssessments: false, auscultationAssessmentsError: action.payload };
    case types.REMOVE_AUSCULTATION_ASSESSMENT:
      return {
        ...state,
        auscultationAssessments: removeItemFromArray(state.auscultationAssessments, {
          index: state.auscultationAssessments.findIndex((a) => a.id === action.payload)
        })
      };
    default:
      return state;
  }
};

export default assessmentReducer;
