import { Translate, I18n } from 'react-redux-i18n';
import initials from 'initials';
import { getAgeFromPersonalNumber, getGenderFromPersonalNumber, formatPersonalNumber } from '../../utils';
import PatientBadge from '../DecisionSupportView/PatientBadge';
import LoadingSpinner from '../LoadingSpinner';

const BasicPatientInfo = ({ member, getPendingDiagnosisBadgeText }) => {
  return (
    <div className="columns mb-0">
      <div className="column profile-image-container no-padding">
        <span className="profile-image__large" data-sensitive>
          {member.avatar ? (
            member.loadingAvatar ? (
              <span className="fallback">
                <LoadingSpinner size="small" />
              </span>
            ) : (
              <img src={member.avatar} alt="Avatar" />
            )
          ) : (
            <span className="fallback">
              {member.givenName && member.familyName ? initials(`${member.givenName} ${member.familyName}`) : '...'}
            </span>
          )}
        </span>
      </div>
      <div className="column no-padding">
        <span className="member-name__large">
          <strong data-sensitive>
            {member.givenName} {member.familyName}
          </strong>
          , {getAgeFromPersonalNumber(member.personalNumber)} <Translate value="global.years" />
        </span>
        <div>
          {member.personalNumber ? (
            <div className="member-personal-number" data-sensitive>
              {formatPersonalNumber(member.personalNumber)} (
              {I18n.t(`global.${getGenderFromPersonalNumber(member.personalNumber)}`)})
            </div>
          ) : null}
        </div>
        {member.phoneNumber ? (
          <div>
            <Translate value="global.phone" />: {member.phoneNumber}
          </div>
        ) : null}
        {member.pendingDiagnosis && getPendingDiagnosisBadgeText ? (
          <div className="mt-15">
            <PatientBadge
              text={I18n.t(`global.${getPendingDiagnosisBadgeText(member.pendingDiagnosis)}`)}
              type={getPendingDiagnosisBadgeText(member.pendingDiagnosis)}
            />
          </div>
        ) : null}
        {member.certificatePatient ? (
          <div className="mt-15">
            <PatientBadge text={I18n.t('global.certificate_patient')} type="certificate" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default BasicPatientInfo;
