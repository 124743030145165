import React, { Component } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ErrorBoundary } from '@sentry/react';
import { Translate } from 'react-redux-i18n';
import moment from 'moment';
import { logout } from '../../actions';
import { clearStorage } from '../../services/sessionStorage';
import { DATE_FORMAT, LOGIN_AUTHORITY } from '../../constants';
import GenericErrorFallback from '../GenericErrorFallback';

class PrivateRoute extends Component {
  constructor(props) {
    super(props);

    this.isLoggedIn = this.isLoggedIn.bind(this);
    this.logOut = this.logOut.bind(this);
  }

  isLoggedIn() {
    if (
      !this.props.authToken ||
      !this.props.authToken.jwt ||
      !this.props.authorities ||
      this.props.authorities.indexOf(LOGIN_AUTHORITY) === -1
    ) {
      return false;
    }

    const tokenExpiryDate = moment(this.props.authToken.expires, DATE_FORMAT);
    const now = moment();

    return now.isBefore(tokenExpiryDate);
  }

  logOut(props) {
    clearStorage();
    this.props.logout();

    if (this.props.redirectToReferrer) {
      return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
    }

    window.location = '/login';

    return null;
  }

  render() {
    const { component: Component, namespace, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props) =>
          this.isLoggedIn() ? (
            <ErrorBoundary
              fallback={() => (
                <GenericErrorFallback>
                  <Translate value="global.generic_error" />
                </GenericErrorFallback>
              )}
            >
              <Component {...props} namespace={namespace} />
            </ErrorBoundary>
          ) : (
            this.logOut(props)
          )
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token,
    authorities: state.auth.authorities,
    redirectToReferrer: state.auth.redirectToReferrer
  };
};

const mapActionsToProps = {
  logout
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(PrivateRoute));
