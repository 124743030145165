import { useState } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import api from '../../../api/apiClient';
import { setInitialKioskAssessment, showNotification } from '../../../actions';
import LoaderButton from '../../LoaderButton';
import { APPLICATION_BASE_PATH } from '../../../constants';
import { withRouter } from 'react-router-dom';
import ErrorMessage from '../../ErrorMessage';

const AssessmentSummary = ({
  authToken,
  assessmentCount,
  setInitialKioskAssessment,
  history,
  showNotification,
  error,
  isNurse
}) => {
  const [fetchingFirstAssessment, setFetchingFirstAssessment] = useState(false);

  const startNewAssessment = async () => {
    setFetchingFirstAssessment(true);
    try {
      const assessment = await api.getNextKioskAssessment(authToken);
      setInitialKioskAssessment(assessment);
      history.push(`/${APPLICATION_BASE_PATH}/kiosk/${assessment.sessionId}`);
    } catch (error) {
      showNotification(I18n.t('kiosk_assessment.summary.start_error'), 'error');
    } finally {
      setFetchingFirstAssessment(false);
    }
  };

  return (
    <div className="rounded-container w-50 mb-50">
      <h1 className="mb-20">
        <Translate value="kiosk_assessment.summary.header" />
      </h1>
      <div className="mb-20">
        {error ? (
          <ErrorMessage error={error} errorI18nKey="kiosk_assessment.fetch_assessment_count_error" />
        ) : assessmentCount ? (
          <span>
            <strong className="fs-26">{assessmentCount.toLocaleString()}</strong>{' '}
            <Translate value="kiosk_assessment.summary.waiting" />.
          </span>
        ) : (
          <span>
            <Translate value="kiosk_assessment.summary.empty" />
          </span>
        )}
      </div>
      {assessmentCount && isNurse ? (
        <LoaderButton onClick={startNewAssessment} isLoading={fetchingFirstAssessment}>
          <Translate value="kiosk_assessment.summary.start_assessment" />
        </LoaderButton>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    isNurse: state.auth.isNurse
  };
};

const mapActionsToProps = {
  setInitialKioskAssessment,
  showNotification
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(AssessmentSummary));
